import { useState, useEffect, useContext } from 'react'
import { LoginContext } from '@providers/Login'

const useAccessToken = (): { accessToken: string | null } => {
  const { user } = useContext(LoginContext)
  const accessTokenRedux = user?.accessToken
  const [accessToken, setAccessToken] = useState<string | null>(null)

  useEffect(() => {
    // If there's a difference, trigger the new access token (which could also be null)
    if (accessTokenRedux !== accessToken) {
      setAccessToken(accessTokenRedux)
    }
  }, [accessToken, accessTokenRedux])
  return { accessToken }
}

export default useAccessToken
