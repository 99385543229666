import { FC } from 'react'
import { FormikConsumer } from 'formik'
import { Col } from '@mch-group/uikit-components'
import { Text } from '@components'
import StepLabel from './common/StepLabel'
import { useUserAccessContext } from '../context/useUserAccessContext'
import styled from 'styled-components'


const RegisterUserSecondStep: FC = () => {
  const { state: { labels: userAccessLabels, resendLoginLinkRef, otherData }, dispatch } = useUserAccessContext()
  return(
    <FormikConsumer >
      {({ values }) => (
        <Col className={!otherData.isModal ? 'mt-0' : ''}>
          {!otherData.isModal && <h4 className='pb-10'>{userAccessLabels.Access.createAccountHeadingTextLabel}</h4>}
          <StepLabel step='Step 2' className={`${otherData.isModal ? 'mt-2' : ''}`} />
          <h5 className='mb-3'>
            {userAccessLabels.Access.verifyRegistrationHeaderLabel}
          </h5>
          <StyledText forwardedAs='p' isHtml className='mch-gray-500' >
            {values.emailSentInviteText}
          </StyledText>
          <div
            onClick={() => {
              dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
              resendLoginLinkRef.current(values, true)}
            }
            onKeyPress={() => {
              dispatch ({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
              resendLoginLinkRef.current(values, true)}
            }
            tabIndex={0}
            role='button'
            className='text-link mt-8'
          >
            {userAccessLabels.Access.resendButtonLabel}
          </div>
        </Col>
      )}
    </FormikConsumer>
  )
}

const StyledText = styled(Text)`
  p:first-child {
    strong {
      display: block;
    }
  }
`

export default RegisterUserSecondStep
