// UIKit CSS
import '@mch-group/uikit-themes/dist/artbasel/artbasel.css'
// Semantic UI CSS
import '@dmi-mch/ui/lib/dist/Container/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Grid/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Menu/theme-artbasel.min.css' // TBR?
import '@dmi-mch/ui/lib/dist/Form/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Button/theme-artbasel.min.css' // TBR?
import '@dmi-mch/ui/lib/dist/Dropdown/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Transition/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Dimmer/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Checkbox/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Table/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Label/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Modal/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Divider/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Embed/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Image/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Input/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/List/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Loader/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Message/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Popup/theme-artbasel.min.css'
//import '@dmi-mch/ui/lib/dist/Segment/theme-artbasel.min.css' // TBR? still instances in monorepo
// import '@dmi-mch/ui/lib/dist/Card/theme-artbasel.min.css' // TBR?
// import '@dmi-mch/ui/lib/dist/Accordion/theme-artbasel.min.css'
import '@dmi-mch/ui/lib/dist/Search/theme-artbasel.min.css' // TBR? still instances in monorepo
import '@dmi-mch/ui/lib/styles/artbasel/main.min.css'
