import { ErrorPage } from '@mch-group/uikit-components'
import { useRouter } from 'next/router'

const UnavailableCollection = () => {
  const router = useRouter()
  return (
    <ErrorPage
      title='Sorry, this content is no longer available'
      subtitle='The content you are trying to access has been removed.'
      button={{
        text: 'Discover all artworks',
        action: () => {
          router.push({ pathname: '/' }, '/artworks')
        }
      }}
    />
  )
}

export default UnavailableCollection
