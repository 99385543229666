import http from '../_http'
import { getSiteLanguage } from '../../utils/storage'

const Coverage = {
  getCoverage: (data) => {
    const params = { ...data }
    params.locale = getSiteLanguage()
    return http.get('/msvc/v1/newsarticles', params)
  }
}

export default Coverage
