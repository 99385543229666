import { useState, createContext, useContext, useEffect, useRef, FC, useCallback } from 'react'
import Router from 'next/router'
import { List } from 'semantic-ui-react'
import styled from 'styled-components'
import useMyCollectionsData from '@modules/collections/hooks/useMyCollectionsData'
import { cssClasses } from '@constants'
import { useCollectionsContext } from '@modules/collections/contexts/useCollectionsContext'
import { CollectionCardSmall } from '@components/collections'
import SelectorControls from '@components/SelectorControls'
import { Collections as CollectionsService } from '@services'
import { NotificationsContext } from '@providers/Notifications'
import { LoginContext } from '@providers/Login'
import { CollectionFormContext } from '../collection-form'
import { IAddToCollectionContext, IAddToCollectionProvider } from './types'
import { Modal } from '@mch-group/uikit-components'

// const AddToCollectionContext = createContext()
const AddToCollectionContext = createContext<IAddToCollectionContext>({} as IAddToCollectionContext)


const AddToCollectionProvider: FC<IAddToCollectionProvider> = (props) => {
  const { children } = props
  const { showNotification } = useContext(NotificationsContext)
  const { showLogin } = useContext(LoginContext)
  //@ts-ignore
  const [{ labels = {}, dynamicCollection }, dispatch] = useCollectionsContext()
  const { name, action } = dynamicCollection || {}
  //@ts-ignore
  const { showForm } = useContext(CollectionFormContext)
  const { myCollections, entity, setEntity, mutateMyCollections,
    myFavoritesCollection, membersValidating, myCollectionsValidating,
    isEntityFavorited, setActive } = useMyCollectionsData(false, 99)
  const [visible, setVisible] = useState(false)
  const [isFavSelected, setIsFavSelected] = useState(isEntityFavorited)
  // This variable controls the enable/disable status of the save button. Should this be a formik, there would be
  // a "dirty" prop. But it's not, so it has to be controlled manually.
  const [isSaveEnabled, setIsSaveEnabled] = useState(false)
  const { Collections: CollectionsLabels, CollectionForms: CollectionFormsLabels } = labels || {}
  const [successMessage, setSuccessMessage] = useState(null)
  const mID = 'add-to-collection'
  // Favorites
  useEffect(() => {
    setIsFavSelected(isEntityFavorited)
  }, [isEntityFavorited])

  const callbackClosePopupRef = useRef<Function>()

  const showAddToCollectionModal = useCallback((id, type, messageOK = 'Item saved to Collection', callbackAfterLoginSuccess, subEntityId, callbackAfterPopupClosed) => {
    setSuccessMessage(messageOK)
    callbackClosePopupRef.current = callbackAfterPopupClosed
    // Routinary check to see if user is logged in or not
    showLogin({
      onLoginSuccessCallback:
        () => {
          //@ts-ignore
          setEntity({ id, type, subEntityId })
          setVisible(true)
          setActive(true)
        },
      onLoginModalCloseCallback:
        () => {
          typeof callbackAfterPopupClosed === 'function' && callbackAfterPopupClosed()
        },
      /**
       * This param is a callback that executes
       * right after login is succesful, and before ATC opens
       * Can be used to refresh lists, permissions, etc. after login popup closes
       */
      onLoginFirstTimeSuccess: () => {
        typeof callbackAfterLoginSuccess === 'function' && callbackAfterLoginSuccess()
      }
    })
  }, [setActive, setEntity, showLogin])

  const closeForm = () => {
    // Will stop any hooks from re-checking stuff
    setActive(false)
    // Closes the popup
    setVisible(false)
    // Resets the latest current Entity
    setEntity(null)
    // Callback to call after popup closed
    typeof callbackClosePopupRef.current === 'function' && callbackClosePopupRef.current()
  }
  // the filter is done as we need to show the artwork scanned with qr code added in the resp coll at top
  const filteredCollWithDyname = myCollections &&
    myCollections?.filter((item) => item.name == name)
  const filteredCollwithoutdyname = myCollections &&
    myCollections?.filter((item) => item.name !== name)

  useEffect(() => {
    if (action) {
      setIsSaveEnabled(true)
    }
  }, [action])

  return (
    <AddToCollectionContext.Provider value={{ showAddToCollectionModal }}>
      {visible && !myCollectionsValidating && !membersValidating && (
        <div id='modal'>
          {/* @ts-ignore */}
          <Modal variant='sheet' show size='md' className={mID} onHide={() => closeForm()}>
            {/* @ts-ignore */}
            <Modal.Header title={CollectionFormsLabels.saveToLabel} showCloseButton onHide={() => closeForm()} />
            <Modal.Body>

              <List className={cssClasses.CMS_MODULE}>
                {action && filteredCollWithDyname.length > 0 && (
                  <CollectionCardSmall
                    item={filteredCollWithDyname?.[0]}
                    isChecked={filteredCollWithDyname?.[0]?.name === name}
                  />)
                }
                {/* Favorites */}
                {myFavoritesCollection?.items && (
                  <CollectionCardSmall
                    onClick={() => {
                      // @ts-ignore
                      setIsFavSelected(!isFavSelected)
                      setIsSaveEnabled(true)
                    }}
                    item={myFavoritesCollection.items[0]}
                    isChecked={isFavSelected}
                  />
                )}
                {/* Collections */}
                {filteredCollwithoutdyname?.map(item => item &&
                  <CollectionCardSmall
                    onClick={() => {
                      dispatch({ type: 'TOGGLE_ITEM_SELECTION', id: item.id })
                      setIsSaveEnabled(true)
                    }}
                    key={item.id}
                    item={item}
                    isChecked={item.hasSelectedEntityId}
                  />)}
              </List >

            </Modal.Body>
            <Modal.Footer>
              <SelectorControlsStyled
                resetBtnActive
                fullWidthButton={action}
                disabled={myCollectionsValidating || membersValidating || !isSaveEnabled}
                onChange={async () => {
                  setIsSaveEnabled(false)
                  const addTo = myCollections
                    ?.filter(item => item.hasSelectedEntityId)
                    .map((item) => item.id)
                  const removeFrom = myCollections
                    ?.filter(item => !item.hasSelectedEntityId)
                    .map((item) => item.id)
                  if (action) {
                    addTo.push(filteredCollWithDyname[0].id)
                  }
                  if (isFavSelected) {
                    addTo.push(myFavoritesCollection.items[0].id)
                  } else {
                    removeFrom.push(myFavoritesCollection.items[0].id)
                  }
                  const dataToSend = {
                    items: [
                      {
                        // @ts-ignore
                        entityId: entity?.id,
                        // @ts-ignore
                        entityType: entity.type,
                        addTo,
                        removeFrom,
                        // @ts-ignore
                        subEntityId: entity?.subEntityId
                      }
                    ]
                  }
                  await CollectionsService.update(dataToSend)
                  mutateMyCollections()
                  showNotification({
                    content: successMessage,
                    defaultCtaLabel: true,
                    ctaAction: () => Router.push({
                      pathname: '/'
                    }, CollectionsLabels.addToCollectionCallbackURL),
                    time: 3500
                  })
                  closeForm()
                }}
                onClickReset={() => {
                  setIsSaveEnabled(true)
                  showForm({
                    setNewCollectionSelected: true
                  })
                }}
                labels={{
                  reset: action ? '' : CollectionsLabels.createNewCollectionLabel,
                  apply: 'Save'
                }}
              />
            </Modal.Footer>
          </Modal>
        </div >
      )}
      {children}
    </AddToCollectionContext.Provider >
  )
}

const SelectorControlsStyled = styled(SelectorControls)`
  margin-bottom: -5px;
  width: 100%;

  span[role='button'] {
    color: var(--bs-mch-gray-500);
  }
`

export default AddToCollectionProvider

export { AddToCollectionContext }
