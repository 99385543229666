import { Checkbox } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { px2rem } from '@utils'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const ListItem = ({ text, onChange, checked, className, onClick }) => (
  <div
    className={className}
    role='button'
    tabIndex={0}
    onClick={onClick}
    onKeyUp={onClick}
  >
    <span className={`label ${checked ? 'label-1' : 'label-2'}`}>{text}</span>
    <Checkbox
      name='items'
      checked={checked}
      onChange={onChange}
      className='dark'
    />
  </div>)

ListItem.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  className: PropTypes.string,
  mID: PropTypes.string
}

const ListItemWrapper = styled(ListItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${px2rem(14)} ${px2rem(10)} ${px2rem(14)} 0;
  /* borders no need of px2rem. Color of border should be the same across projects,
  no need of variable */
  border-bottom: 1px solid ${tokens.color.light.base.neutrals[400].value};
  cursor: pointer;
`

export default ListItemWrapper
