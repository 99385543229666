import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'
import { useContext } from 'react'
import { mq, px2rem, hiDPIpx } from '@utils'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import { NotificationsContext } from '@providers/Notifications'
import { ConfirmContext } from '@providers/Confirm'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const FollowCta = ({
  collectionId,
  followCollectionBtnLabel,
  followingCollectionBtnLabel,
  unfollowCollectionBtnLabel,
  subscribe,
  unsubscribe,
  isSubscribed,
  dispatch,
  isBannerDismissed,
  unfollowCollectionPopupTitle,
  unfollowCollectionPopupMessage,
  unfollowCollectionPopupBtnLabel,
  cancelPopupBtnLabel
}) => {
  const [status, setStatus] = React.useState(null)
  const [{ labels }] = useMyCollectionsPageContext()
  const { showNotification } = useContext(NotificationsContext)
  const { showConfirm, setVisible } = React.useContext(ConfirmContext)
  const statusLabel = {
    follow: followCollectionBtnLabel,
    following: followingCollectionBtnLabel,
    unfollow: unfollowCollectionBtnLabel
  }

  const toggleSubscribe = () => {
    if (isSubscribed) {
      showConfirm({
        labels: {
          title: unfollowCollectionPopupTitle,
          message: unfollowCollectionPopupMessage,
          ok: unfollowCollectionPopupBtnLabel,
          cancel: cancelPopupBtnLabel
        },
        actions: {
          onOK: () => {
            if (isSubscribed && isBannerDismissed) {
              dispatch({ type: 'SET_NAV_VISIBLE_AFTER_UNFOLLOW_AND_DISMISS_BANNER', payload: true })
              showNotification({
                content: labels.Collections.unfollowingConfirmationMsgLabel,
                time: 3500
            })
            }
            unsubscribe(collectionId)
            setVisible(false)
            dispatch({ type: 'SET_BANNER_DISMISSED', payload: true })
          },
          onCancel: () => setVisible(false)
        }
      })
    }
    if (!isSubscribed) {
      subscribe(collectionId)
    }
  }

  const toggleLabelOnHover = (state) => {
    if (state === 'enter') {
      if (status === statusLabel.following) {
        setStatus(statusLabel.unfollow)
      }
    }
    if (state === 'leave') {
      if (status === statusLabel.unfollow) {
        setStatus(statusLabel.following)
      }
    }
  }

  React.useEffect(() => {
    if (isSubscribed) {
      setStatus(statusLabel.following)
    }
    if (!isSubscribed) {
      setStatus(statusLabel.follow)
    }
  }, [dispatch, isSubscribed, statusLabel.follow, statusLabel.following])

  return status !== null ? (
    <FollowCtaButton>
      <Button
        className={`label label-3 ${isSubscribed ? 'is-dark' : ''}`}
        onClick={() => toggleSubscribe()}
        onMouseEnter={() => toggleLabelOnHover('enter')}
        onMouseLeave={() => toggleLabelOnHover('leave')}
      >
        {status}
      </Button>
    </FollowCtaButton>
  ) : null
}

FollowCta.propTypes = {
  followCollectionBtnLabel: PropTypes.string,
  followingCollectionBtnLabel: PropTypes.string,
  unfollowCollectionBtnLabel: PropTypes.string,
  isCollectionOwner: PropTypes.bool,
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func,
  isSubscribed: PropTypes.bool,
  collectionId: PropTypes.string,
  dispatch: PropTypes.func,
  isBannerDismissed: PropTypes.bool,
  unfollowCollectionPopupTitle: PropTypes.string,
  unfollowCollectionPopupMessage: PropTypes.string,
  unfollowCollectionPopupBtnLabel: PropTypes.string,
  cancelPopupBtnLabel: PropTypes.string
}



const FollowCtaButton = styled.div`
  margin: 0 0 ${px2rem(8)} 0;

  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: ${px2rem(5)} ${px2rem(12)} ${px2rem(8)};
    margin: 0;
    background: var(--bs-mch-galleries) !important;
    border: none !important;
    border-radius: 0 !important;
    font-weight: ${tokens.fontWeights.artBaselHeadline0.value} !important;
    letter-spacing: ${tokens.letterSpacing[0].value} !important;

    &.is-dark {
      background: var(--bs-mch-gray-500) !important;
      color: var(--bs-mch-white) !important;

      &:hover {
        background: var(--bs-mch-light-error) !important;
      }
    }

    ${mq.between('tablet', 'largestTablet')`
      margin-bottom: ${hiDPIpx(4)};
    `}
    ${mq.lessThan('largestMobile')`
      margin-bottom: ${px2rem(4)};
    `}

    &.following {
      background: var(--bs-mch-gray-500) !important;
      color: var(--bs-mch-white) !important;

      &:hover {
        background: var(--bs-mch-light-error) !important;
      }
    }

    &:hover {
      background: var(--bs-mch-galleries) !important;
    }
  }
`

export default FollowCta