const REDUCERNAME = 'events'

const validEventsFilters = [
  'favorite',
  'orderBy',
  'exhibition',
  'virtual',
  'access',
  'eventTypes',
  'date',
  'startDate',
  'endDate',
  'cityId'
]
const userEventsFilters = ['favorite', 'access']

const initialState = {
  eventsFilters: {
    orderBy: 'endDate ASC'
  }
}

// ACTION TYPES
const actionTypes = {
  RESET_EVENTS_MODULE: `${REDUCERNAME}__RESET_EVENTS_MODULE`,
  SET_EVENTS_FILTERS: `${REDUCERNAME}__SET_EVENTS_FILTERS`
}

// ACTIONS
export const setEventsFilters = (filters, loggedIn = false, mergeToState = false) => (dispatch) => {
  const combinedFilters = mergeToState ? { ...filters } : Object.assign({}, initialState.eventsFilters, filters)
  const validFilters = Object.keys(combinedFilters)
    .filter(filter => validEventsFilters.includes(filter))
  let availableFilters

  if (loggedIn) {
    availableFilters = validFilters.reduce((sum, current) => ({ ...sum, [current]: combinedFilters[current] }), {})
  } else {
    availableFilters = validFilters
      .filter(filter => !userEventsFilters.includes(filter))
      .reduce((sum, current) => ({ ...sum, [current]: combinedFilters[current] }), {})
  }

  dispatch({ type: actionTypes.SET_EVENTS_FILTERS, payload: availableFilters, mergeToState })

  return availableFilters
}

// ACTION HANDLERS
const actionHandlers = {
  [actionTypes.RESET_EVENTS_MODULE]: () => ({ ...initialState }),
  [actionTypes.SET_EVENTS_FILTERS]: (state, action) => ({
    ...state,
    eventsFilters: action.mergeToState ? { ...state.eventsFilters, ...action.payload } : action.payload
  })
}

// REDUCER
const eventsReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export default eventsReducer
