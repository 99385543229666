import CookieKeys from '@constants/CookieKeys'
import { Tracking } from '@services'
import Cookies from 'js-cookie'

const validCookie = (key) => {
  const value = Cookies.get(key)
  return !!(value && typeof value === 'string')
}

const getExpireDate = () => {
  const yearsFromNow = new Date()
  yearsFromNow.setFullYear(yearsFromNow.getFullYear() + 10)
  return yearsFromNow
}

const getKey = async () => {
  let key: string | undefined | null = null
  /// @todo see if its worth to replace with SWR
  const getAgentKeyData = await Tracking.getAgentKey()
  if (getAgentKeyData.status === 200 && getAgentKeyData.data && 'key' in getAgentKeyData.data) {
    key = getAgentKeyData.data.key || ''
    Cookies.set(CookieKeys.AGENT_KEY, key, { path: '/', expires: getExpireDate() })
  }
  return key
}

const getAgentKey = async () =>
  !validCookie(CookieKeys.AGENT_KEY) ? await getKey() : Cookies.get(CookieKeys.AGENT_KEY)

export default getAgentKey
