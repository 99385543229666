import styled, { createGlobalStyle } from 'styled-components'
import { Icon } from '@mch-group/uikit-components'
import { mq } from '@utils'
import { FC, ReactNode } from 'react'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const GlobalStyle = createGlobalStyle`
  .is-in-modal .trigger.switch-icon .trigger-wrapper {
    .primary-icon {
      display: none !important;
    }

    .secondary-icon {
      display: block !important;
    }
  }
`

const cssID = 'trigger'

const SelectorTrigger: FC<ISelectorTrigger> = ({
  className, onClick, title, buttonText, buttonClassname, isActive, iconModal, icon = <Icon name='dropdown' />
}) => (
  <>
    {/* eslint-disable-next-line jsx-a11y/no-redundant-roles*/}
    <div
      className={`${className} ${cssID} selector-trigger js-trigger ${isActive ? 'active' : ''}`}
      role='button'
      tabIndex={0}
      onClick={onClick}
      onKeyUp={onClick}
      aria-label={title}
    >
      <div className={`${cssID}-wrapper`}>
        <div>
          <span className={`${cssID}-label label label-1 color-black`}>{title}</span>
          <div className={`${cssID}-result`}>
            <span className={`${cssID}-result-text label label-1 ${buttonClassname ? buttonClassname : ''}`}>{buttonText}</span>
          </div>
        </div>
        <div className='primary-icon'>{icon}</div>
        {iconModal && <div className='secondary-icon'>{iconModal}</div>}
      </div>
    </div>
    <GlobalStyle />
  </>)
interface ISelectorTrigger {
  className?: string,
  labels?: { [labelKey: string]: string },
  onClick: () => void,
  icon: ReactNode,
  iconModal?: ReactNode,
  title: string,
  buttonText?: string,
  isActive: boolean,
  buttonClassname?: string
}

const SelectorTriggerStyled = styled(SelectorTrigger)`
  &.${cssID} {
    padding: 3px 20px;
    border: none;
    background-color: transparent;
    width: 100%;

    &[role='button'] {
      cursor: pointer;

      .${cssID}-wrapper {
        pointer-events: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
        text-align: left;

        .${cssID}-result {
          display: flex;
          justify-content: space-between;

          &-text {
            font-weight: ${tokens.fontWeights.artBaselText1.value};
            color: var(--bs-mch-gray-400);

            .active& {
              color: var(--bs-mch-black);
            }
          }
        }

        .primary-icon {
          display: flex;

          svg {
            ${mq.lessThan('largestTablet')`
              transform: rotate(0deg);
            `};
          }
        }

        .secondary-icon {
          display: none;
        }
      }
    }
  }
`

export default SelectorTriggerStyled
