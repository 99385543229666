import React, { useState, useEffect, createContext, ReactNode } from 'react'
import Text from '@components/Text'

import { withRouter, NextRouter } from 'next/router'
import { Button, Modal } from '@mch-group/uikit-components'

/**
 * HOW TO USE (can be removed when implementation is more mature)
 * 1. Import the context/provider, if you're gonna use in the same place context/provider:
 * import Confirm, { ConfirmContext } from '@providers/Confirm'
 *
 * 2. How to call one of its functions, example, showNotification
 * <button onClick={() => showNotification({
        labels: {
          message: 'Are you sure you want to permanently delete the collection %nameofcollection%',
          ok: 'Delete',
          cancel: 'Cancel'
        },
        onOK: () => {},
        onCancel: () => {}
      })}
      >Click me!
      </button>

  * 3. If you don't want to wrap several components just to use this functionality:
  wrap your component like this:
  const RmHeadlineWithContext = () => <Confirm><YourComponent /></Confirm>
  and then export RmHeadlineWithContext:
  export default RmHeadlineWithContext
 */

interface ConfirmContextProps {
  showConfirm: (params: ConfirmParams) => void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setButtonStates: React.Dispatch<React.SetStateAction<ButtonStates | null>>;
  visible: boolean;
}

interface ConfirmProviderProps {
  children: ReactNode;
  router: NextRouter;
}

interface ConfirmParams {
  labels: { [key: string]: { [key: string]: string } }
  actions?: {
    onOK?: () => void;
    onCancel?: () => void;
  }
  buttonStates?: ButtonStates;
}

interface ButtonStates {
  disabled?: boolean;
}

const ConfirmContext = createContext<ConfirmContextProps | undefined>(undefined)

const ConfirmProvider: React.FC<ConfirmProviderProps> = (props) => {
  const { children, router } = props
  const defaultParams = {
    labels: {
      message: '',
      ok: 'Ok',
      cancel: 'Cancel',
      title: ''
    }
  }
  const [visible, setVisible] = useState(false)
  const [labels, setLabels] = useState<{ message: string; ok?: string; cancel?: string, title?: string,
     subText?: string } | null>(null)
  const [actions, setActions] = useState<{ onOK?: () => void; onCancel?: () => void } | null>(null)
  const [buttonStates, setButtonStates] = useState<ButtonStates | null>(null)

  useEffect(() => {
    // This will close the confirm popup in case it's opened and we navigate to other pages from browser back button.
    if (visible) {
      setVisible(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  const showConfirm = (params: ConfirmParams) => {
    // Merges the default params with those coming from the request
    const newParams: any = { ...defaultParams, ...params }
    // Sets the new message
    setLabels(newParams?.labels)
    setActions(newParams?.actions)
    setButtonStates(newParams?.buttonStates)
    // Makes it visible
    setVisible(true)
  }

  return (
    <ConfirmContext.Provider value={{ showConfirm, setVisible, setButtonStates, visible }}>
      {visible && (
        <Modal
          //@ts-ignore
          show={visible.toString()}
          size='sm'
          onHide={() => setVisible(false)}
          modalAriaLabel={labels?.title}
          centered
        >
          <Modal.Header title={labels?.title || ''} onHide={() => setVisible(false)} />
          <Modal.Body>
            {labels?.subText && (
              <Text isHtml className='sub-text main-text color-black'>
                {labels.subText}
              </Text>
            )}
            <Text isHtml className='message main-text color-black'>{labels?.message}</Text>
          </Modal.Body>
          {/** @ts-ignore */}
          <Modal.Footer>
            <span
              className='text-link font-color-light-base-700'
              onClick={() => actions?.onCancel?.()}
              onKeyUp={() => actions?.onCancel?.()}
              tabIndex={0}
              role='button'
            >
              {labels?.cancel}
            </span>
            <Button
              size='dynamic'
              onClick={() => actions?.onOK?.()}
              disabled={buttonStates?.disabled}
            >
              {labels?.ok}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {children}
    </ConfirmContext.Provider>
  )
}

export default withRouter(ConfirmProvider)

export { ConfirmContext }
