import Router from 'next/router'
import { cmsPageUrl, isLegacyPath } from '../utils'

const push = (url) => {
  if (isLegacyPath(url)) {
    window.location.href = url
    return
  }
  Router.push(cmsPageUrl(url), url)
}

export default push
