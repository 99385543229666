/**
 * Shallowly compares two given objects.
 * @param {Object} obj1 - 1st bject
 * @param {Object} obj2 - 2nd object
 * @return {boolean}
 */
const shallowCompare = (obj1, obj2) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(key => Object.prototype.hasOwnProperty.call(obj2, key) && obj1[key] === obj2[key])

export default shallowCompare
