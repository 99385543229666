import PropTypes from 'prop-types'
import { Image, List } from 'semantic-ui-react'
import styled from 'styled-components'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { px2rem } from '@utils'
import { Icon } from '@mch-group/uikit-components'
import { cloudinary } from '@constants'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const CollectionCardSmall = ({ item, isChecked, ...props }) => {
  const { thumbnailImageUrls, itemsCount, name } = item
  return (
    <ListStyled
      {...props}
    >
      {thumbnailImageUrls && thumbnailImageUrls[0] ?
        <Image
          src={uploadTransformationUrl(thumbnailImageUrls[0],
            'w_70,q_auto:good,dpr_1.0,ar_1,c_crop,c_fill,g_auto',
            { 'transformUrl': cloudinary.throughCloudfront }
          )}
          alt={name}
        />
       : <EmptyCollectionStyled />}

      <List.Content verticalAlign='middle'>
        <List.Header>{name}</List.Header>
        <List.Description>
          {itemsCount} items
        </List.Description>
      </List.Content>
      <ClickToAction>{isChecked ? <Icon name='checkmark' /> : <Icon name='add' />}</ClickToAction>
    </ListStyled>
  )
}

CollectionCardSmall.propTypes = {
  item: PropTypes.object,
  isChecked: PropTypes.bool
}

const ListStyled = styled(List.Item)`
  display: flex !important;
  align-items: center;
  cursor: pointer;
  padding: ${px2rem(10)} ${px2rem(15)} ${px2rem(10)} ${px2rem(10)} !important;

  img {
    width: ${px2rem(70)};
  }

  .content {
    flex-grow: 1;
    padding: 0 0 0 ${px2rem(18)} !important;
  }

  &:hover {
    background: var(--bs-mch-galleries);
  }
`

const EmptyCollectionStyled = styled.div`
  width: ${px2rem(70)};
  height: ${px2rem(70)};
  background: ${tokens.color.light.base.neutrals['200'].value};
`

const ClickToAction = styled.div`
  width: ${px2rem(20)};
`

export default CollectionCardSmall
