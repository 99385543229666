import { FC } from 'react'
import styled from 'styled-components'
import { Loader as SemanticUILoader, SemanticSIZES } from 'semantic-ui-react'

import { px2rem } from '@utils'

const Loader:FC<ILoader> = ({ className, active, size = 'small' }) =>
  <SemanticUILoader className={className} active={active} inline size={size} />

interface ILoader {
  active: boolean,
  className?: string,
  size?: SemanticSIZES,
  $noMargin?: boolean
}

export default styled(Loader)`
  &&& {
    ${(props) => !props.$noMargin ? `margin: 0 auto ${px2rem(60)};` : 'margin: 0 auto;'}
  }
`
