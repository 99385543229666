import styled from 'styled-components'
import PropTypes from 'prop-types'
import { px2rem, mq, isExternalLink } from '@utils'
import tokensRenamed from '@constants/tokensRenamed'


const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const CuratorPick = ({
  curatorName,
  curatorPickLabel,
  isHideCuratorName,
  profileUrl,
  isCuratorBannerClickable = false
}) => {
  const curatorProfileUrl = typeof window !== 'undefined' && isExternalLink(profileUrl) ? profileUrl : `${window.location.protocol}//${window.location.host}${profileUrl}`
  return (
    <ConditionalWrapper
      condition={profileUrl && isCuratorBannerClickable}
      wrapper={children => <ProfileLink href={curatorProfileUrl} target='_blank' rel='nofollow noopener'>{children}</ProfileLink>}
    >
      <>
        <CuratorPickLabel
          className='label label-3 text-white'
          isCuratorBannerClickable={isCuratorBannerClickable}
          isHideCuratorName={isHideCuratorName}
        >
          <b>
            {curatorPickLabel}
          </b>
        </CuratorPickLabel>
        <CuratorName
          className='label label-3 color-black'
          isHideCuratorName={isHideCuratorName}
          isCuratorBannerClickable={isCuratorBannerClickable}
        >
          {curatorName}
        </CuratorName>
      </>
    </ConditionalWrapper>
  )
}

CuratorPick.propTypes = {
  curatorName: PropTypes.string,
  curatorPickLabel: PropTypes.string,
  isHideCuratorName: PropTypes.bool,
  profileUrl: PropTypes.string,
  isCuratorBannerClickable: PropTypes.bool
}

const ProfileLink = styled.a`
  display: flex;
`

const CuratorName = styled.span`
  background: var(--bs-mch-white);
  padding: ${px2rem(6)} ${px2rem(12)} ${px2rem(6)};
  box-shadow: ${tokensRenamed.shadows[2]};
  ${({ isCuratorBannerClickable }) => (!isCuratorBannerClickable ? `border-top-right-radius: ${px2rem(4)};` : '')}
  border-bottom-right-radius: ${px2rem(4)};
  ${mq.lessThan('largestMobile')`
    display: ${({ isHideCuratorName }) => (isHideCuratorName ? 'none' : 'block')
    };
    border-top-right-radius: ${px2rem(4)};
  `}
`

const CuratorPickLabel = styled.span`
  background: var(--bs-mch-gray-500);
  padding: ${px2rem(6)} ${px2rem(12)} ${px2rem(6)};
  box-shadow: ${tokensRenamed.shadows[2]};
  ${mq.greaterThan('desktop')`
    ${({ isCuratorBannerClickable }) => (isCuratorBannerClickable ? `border-bottom-left-radius: ${px2rem(4)};` : '')}
  `}
  ${mq.lessThan('largestMobile')`
    ${({ isHideCuratorName }) => (!isHideCuratorName ? '' : `border-top-right-radius: ${px2rem(4)};border-bottom-right-radius: ${px2rem(4)};`)}
    ${({ isCuratorBannerClickable }) => (isCuratorBannerClickable ? `border-top-left-radius: ${px2rem(4)};border-bottom-left-radius: ${px2rem(4)};` : '')}
  `}
`
export default CuratorPick
