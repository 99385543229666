import qs from 'query-string'
import Router from 'next/router'

const changeUserAccessLinkUrl = (query = {}, as = window.location.pathname, shallow = true) => {
  Router.push({ pathname: '/', query }, as, { shallow })
}

const setQueryStringValue = (
  queryObj,
  customUrl
) => {
  const queryString = typeof window !== 'undefined' && window.location.search
  const values = qs.parse(queryString)
  let initialQuery = {}
  if ('redirect' in values) {
    let redirectQuery = { 'redirect': values.redirect }
    initialQuery = { ...initialQuery, ...redirectQuery }
  }
  const updatedQueryObj = { ...initialQuery, ...queryObj }
  const newQsValue = qs.stringify(updatedQueryObj)
  changeUserAccessLinkUrl(updatedQueryObj, (customUrl ? customUrl : (newQsValue ?
    `${window.location.pathname}?${newQsValue}` : window.location.pathname)))
}

export default setQueryStringValue