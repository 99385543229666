import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import '@dmi-mch/ui/lib/styles/slick/slick.min.css'
import '@dmi-mch/ui/lib/styles/slick/slick-theme.min.css'
import '@dmi-mch/ui/lib/styles/slick/custom-navigation.min.css'


class SlickCarousel extends React.Component {
  render() {
    const { settings, children, className, setSliderRef } = this.props

    return (
      <div className={className}>
        <Slider
          {...settings}
          ref={c => {
            setSliderRef(c)
          }}
        >
          {children}
        </Slider>
      </div>
    )
  }
}

SlickCarousel.propTypes = {
  settings: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  setSliderRef: PropTypes.func
}

export default SlickCarousel
