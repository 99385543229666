import dayjs from 'dayjs'
import fr from 'dayjs/locale/fr'
import zh_cn from 'dayjs/locale/zh-cn'
import de from 'dayjs/locale/de'
import es from 'dayjs/locale/es'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)

let localeLang = 'en'
const setDayjsLocaleLanguage = (siteLanguage) => {
  if(siteLanguage === 'fr') {
    localeLang = siteLanguage
    dayjs.locale(fr, null, true)
  } else if(siteLanguage === 'zh_CN') {
    localeLang = 'zh-cn'
    dayjs.locale(zh_cn, null, true)
  } else if(siteLanguage === 'de') {
    localeLang = 'de'
    dayjs.locale(de, null, true)
  } else if(siteLanguage === 'es') {
    localeLang = 'es'
    dayjs.locale(es, null, true)
  }
}

// e.g. formatDate(vipInvitation.dispatchDate, 'DD/MM/YYYY hh:mm', 'DD MMM YYYY')
const formatDate = (date, input, format, replaceOClock = '', setLocaleLangEN = false) =>
  dayjs(date, input).locale(setLocaleLangEN ? 'en' : localeLang).format(format).replace(':00', replaceOClock)
// example output: Aug 24 - Aug 25, 2021 OR Aug 24, 04:25 pm (BT) - Aug 25, 2021
const formatDateRange = (startDate, startDateOutputFormat, endDateOutputFormat, timezone, endDate) => {
  const start = formatDate(startDate, 'YYYY-MM-DD hh:mm', startDateOutputFormat)
  const end = formatDate(endDate, 'YYYY-MM-DD hh:mm', endDateOutputFormat)
  return `${start}${timezone ? ` (${timezone})` : ''} ${end ? `- ${end}` : ''}`
}

export { formatDate, formatDateRange, setDayjsLocaleLanguage }
