import { createContext, useContext } from 'react'

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_EDIT_PREVIEW_LIST':
      return {
        ...state,
        detailList: action.payload
      }
    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload,
        detailList: []
      }
    case 'SET_ACTIVE_TAB_ENTITY_TYPE':
      return {
        ...state,
        entityType: action.payload
      }
    case 'SET_MY_COLLECTION_ACTIVE_TAB':
      return {
        ...state,
        myCollectionPageActiveTab: action.payload
      }
    case 'SET_ORGANIZE_MODE':
      return {
        ...state,
        isOrganizeMode: action.payload
      }
    case 'SET_SELECTED_ITEMS':
      return {
        ...state,
        selectedItems: action.payload
      }
    case 'SET_LOGGED_IN':
      return {
        ...state,
        user: action.payload
      }
    case 'SET_COLLECTION_OWNER':
      return {
        ...state,
        isCollectionOwner: action.payload
      }
    case 'SET_COLLECTION_SUBSCRIBED':
      return {
        ...state,
        isSubscribed: action.payload
      }
    case 'SET_BANNER_DISMISSED':
      return {
        ...state,
        isBannerDismissed: action.payload
      }
    case 'SET_NAV_VISIBLE_AFTER_UNFOLLOW_AND_DISMISS_BANNER':
      return {
        ...state,
        isNavVisibleAfterUnfollowAndDismissBanner: action.payload
      }
    case 'SHOW_COPY_MODAL':
      return {
        ...state,
        showModal: true,
        modalData: { forModal: 'Copy', ...action.payload }
      }
    case 'SHOW_MOVE_MODAL':
      return {
        ...state,
        showModal: true,
        modalData: { forModal: 'Move', ...action.payload }
      }
    case 'HIDE_MODAL':
      return {
        ...state,
        modalData: {},
        showModal: false,
        selectedItems: []
      }
    case 'SET_LABELS':
        return {
          ...state,
          labels: action.payload
        }
    default:
      return { ...state }
  }
}

export const initialState = {
  CMSProps: null,
  labels: null,
  router: null,
  user: null,
  detailList: [],
  activeTab: null,
  entityType: null,
  isOrganizeMode: false,
  selectedItems: [],
  isCollectionOwner: null,
  isSubscribed: null,
  myCollectionPageActiveTab: null,
  isBannerDismissed: null,
  isNavVisibleAfterUnfollowAndDismissBanner: null,
  showModal: false,
  modalData: {}
}

export const StateContext = createContext(initialState)

export const useMyCollectionsPageContext = () => useContext(StateContext)
