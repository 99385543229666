import axios from 'axios'
import { sessionAccessToken } from '@utils'
import { Fetcher } from 'swr'

/**
 * This fetcher is used inside all SWR petitions
 * It comes with the header configured in case the user is logged in
 * @param url
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
const fetcher = async (url, params): Promise<Fetcher> => {
  const sessionToken = await sessionAccessToken()
  const headers = sessionToken
    ? {
        Authorization: `Bearer ${sessionToken}`
      }
    : {}

  return axios({
    url,
    params,
    headers
  }).then((response) => response.data)
}

export default fetcher
