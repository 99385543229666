import { useEffect, useState, useRef, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { User as UserAccessService } from '@services'
import { LoadingscreenContext } from '@providers/Loadingscreen'

import { getRefreshToken, getSiteLanguage } from '@utils'
import { setUserDataFromAPI as setUserDataFromAPIAction } from '@stores/user'
import { useRouter } from 'next/router'
import { SnackBarNotificationContext } from '@providers/SnackBarNotification'
import {
  setMe,
  setLoginData
} from '@stores/user'

import { signOut } from '@stores/user'
import { useLabelsFromAPI } from '@dmi-mch/hooks'

const useLogin = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { showNotification } = useContext(SnackBarNotificationContext)
  const { hideLoading } = useContext(LoadingscreenContext)
  const user = useSelector((state) => state.user)
  const [visibleTC, setVisibleTC] = useState(false)
  const isUserLogged = user?.me !== null
  const [visible, setVisible] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [isClosedAtLeastOnce, setIsClosedAtLeastOnce] = useState(false)
  const onLoginSuccess = useRef<Function | null>(null)
  const onLoginFirstTimeSuccess = useRef<Function | null>(null)
  const onLoginModalCloseCallback = useRef<Function | null>(null)
  const onTCModalSuccessCallback = useRef<Function | null>(null)
  const isHideCloseIcon = useRef(false)
  const closeLoginModalOnRouteChangeRef = useRef(false)
  const openOnlyOnceRef = useRef(false)
  const dispatchLoginData = useDispatch()
  const [isLoadingTCData, setIsLoadingTCData] = useState(false)
  const [isLogoutSuccess, setIsLogoutSuccess] = useState(false)
  const { labels } = useLabelsFromAPI(['Access'], getSiteLanguage())
  /**
   * There are 3 very specific scenarios of login, check below
   */
  const showLogin = async (params) => {
    if (params?.openOnlyOnce) {
      openOnlyOnceRef.current = params?.openOnlyOnce
    }
    closeLoginModalOnRouteChangeRef.current = params?.closeLoginModalOnRouteChange
    // Deciding to show/hide close icon
    isHideCloseIcon.current = params?.isHideCloseIcon
    // setting the close callbak in a ref
    onLoginModalCloseCallback.current = params?.onLoginModalCloseCallback
    /**
     * Scn. 1. There's a user cookie. But no user data. This is very strange scenario, happening usually in
     * pages with an unusual Header. Let's retrieve the user.me data,
     * when ready, execute the callback (in the useEffect) */
    onLoginFirstTimeSuccess.current = params?.onLoginFirstTimeSuccess
    if (!user.me && user.loginData) {
      setIsReady(true)
      dispatch(setUserDataFromAPIAction())
      onLoginSuccess.current = params?.onLoginSuccessCallback
      /**
       * Scn. 2. There is user.me, so we got everything. Just execute the callback
       */
    } else if (getRefreshToken() !== null && user?.me && user.loginData) {
      typeof params?.onLoginSuccessCallback === 'function'
        && params?.onLoginSuccessCallback(user)
    } else {
      /**
       * Scn. 3. There's nothing. Open the Login popup
       */
      if ((params?.openOnlyOnce && !isClosedAtLeastOnce) || !params?.openOnlyOnce) {
        setVisible(true)
      }
      onLoginSuccess.current = params?.onLoginSuccessCallback
    }
  }
  // This hook triggers on every route change, but in principle, makes nothing.
  // if the closeLoginModalOnRouteChangeRef prop is passed as true, the login will
  // close in case a navigation from page to page happens.
  // Actually this would be correct for most of the scenarios, but let's leave it
  // to the consumer choice.
  useEffect(() => {
    closeLoginModalOnRouteChangeRef.current && setVisible(false)
  }, [router])

  // Works in combination with scn 1.
  useEffect(() => {
    if (user.me && user.loginData && isReady) {
      onLoginSuccess.current && onLoginSuccess.current(user)
    }
  }, [user, user.loginData, user.me, isReady])

  const showTCModal = (params) => {
    if (params?.onTCAcceptedCallback) {
      onTCModalSuccessCallback.current = params.onTCAcceptedCallback
    }
    setVisible(false)
    setVisibleTC(true)
  }

  // this block will be excecuted, when the terms and conditions is not accepted by user.
  useEffect(() => {
    if (user.loginData && user.me) {
      if (visible) { setVisible(false) }
      if (user.me && user.loginData && !visibleTC && !user.loginData.termsAndConditionsAccepted) {
        setVisibleTC(true)
      } else {
        if (!user.me && !user.loginData && visibleTC) {
          setVisibleTC(false)
        }
      }
    }
  }, [user, visible, visibleTC])

  // call this function when user will accept terms and conditions.
  const handleSubmitTC = async (params) => {
    setIsLoadingTCData(true)
    const reqParams: {termsAndConditionsAccepted: boolean, userId?: string} = {
      termsAndConditionsAccepted: params.termsCondition || true
    }
    if(user?.loginData) {
      reqParams.userId = user?.loginData.userId
    }
    const response = await UserAccessService.postTCv2(reqParams)
    if (response.ok) {
      setIsLoadingTCData(false)
      // Could be merged in one dispatch
      // Watch the different naming of the TC in BE... Careful
      dispatchLoginData(setMe({ ...user.me, termConditionAccepted: true }))
      dispatchLoginData(setLoginData({ ...user.loginData, termsAndConditionsAccepted: true }))
      setVisibleTC(false)
      //   setIsLoadingTCData(false)
      typeof onTCModalSuccessCallback.current === 'function' && onTCModalSuccessCallback.current()
      onTCModalSuccessCallback.current = null
    }
  }

  // after user logut from t&c popup we will show a notification to user and close the t&c popup
  useEffect(() => {
    if (!user.me && !user.loginData && isLogoutSuccess && visibleTC) {
      setVisibleTC(false)
      showNotification(true, {
        autohide: true,
        message: labels?.Access.logoutSuccessNotificationLabel,
        style: { top: '100px' }
      })
    }
  }, [showNotification, user.loginData, user.me, isLogoutSuccess, visibleTC, labels])

  const handleLogoutUser = () => {
    if (!isLogoutSuccess) { setIsLogoutSuccess(true) }
    dispatchLoginData(signOut('/'))
    hideLoading()
  }

  return {
    visible, showLogin, isUserLogged, user, visibleTC, onLoginModalCloseCallback,
    isLoadingTCData, handleLogoutUser, setVisible, setIsClosedAtLeastOnce, labels,
    openOnlyOnceRef, onLoginFirstTimeSuccess, onLoginSuccess, isHideCloseIcon,
    handleSubmitTC, setVisibleTC, showTCModal
  }
}

export default useLogin
