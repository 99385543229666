import { memo } from 'react'
import { string, object, oneOfType } from 'prop-types'
import Link from 'next/link'
import qs from 'query-string'

const addQueryToHref = (href, additionalQuery) => {
  const isString = typeof href === 'string'
  const hrefObj = isString ? qs.parseUrl(href) : href
  const newHrefObj = { ...hrefObj, query: { ...(hrefObj.query || {}), ...additionalQuery } }
  return isString ? qs.stringifyUrl(newHrefObj) : newHrefObj
}

// It adds the current router params to the href in referer param.
// The href prop is only about what you want to pass to Next.
// So, you can set some params only in the href to avoid sharing these params on a copy of the URL.
//@ts-ignore
const LinkWithReferer = memo(({ href, ...props }) => {
  const hrefWithReferer = addQueryToHref(href, null)
  return <Link {...props} href={hrefWithReferer} legacyBehavior />
})
LinkWithReferer.displayName = 'LinkWithReferer'
//@ts-ignore
LinkWithReferer.propTypes = {
  href: oneOfType([string, object]).isRequired
}

export default LinkWithReferer
