import { combineReducers } from 'redux'

import sharedReducer from './shared'
import navigationReducer from './navigation'
import userReducer from './user'
import pressReducer from './pressAcc'
import designationReducer from './designation'
import passesReducer from './passes'
import eventsReducer from './events'
import pageReducer from './page'
import urlContentReducer from './urlContent'
import catalogReducer from './catalog'
import favoritesReducer from './favorites'

export default combineReducers({
  shared: sharedReducer,
  navigation: navigationReducer,
  user: userReducer,
  press: pressReducer,
  designation: designationReducer,
  passes: passesReducer,
  events: eventsReducer,
  page: pageReducer,
  urlContent: urlContentReducer,
  catalog: catalogReducer,
  favorites: favoritesReducer
})
