/*
* Fail-safe read properties from an object.
* Usages:
*   resolve({}, "prop1.prop2.prop3"); // Properties by name.
*   resolve({}, "prop1.0.prop3"); // Can access array items by position
*/

const getProperty = (obj, path, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator)
  return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

export default getProperty
