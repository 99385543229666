import PropTypes from 'prop-types'
import styled from 'styled-components'

import CallToAction from '@reusable-modules/common/CallToAction'
import { px2rem, mq } from '@utils'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const Navigation = ({ title, navigationUrl }) => (
  <CallToActionStyled>
    <CallToAction
      href={{ pathname: '/' }}
      hrefAs={navigationUrl}
      direction='chevron-left'
    >
      { title}
    </CallToAction>
  </CallToActionStyled>
)

const CallToActionStyled = styled.div`
  display: flex;

  a {
    display: flex;
    flex-direction: row;
    margin-bottom: ${px2rem(28)};
    ${mq.lessThan('largestMobile')`
      margin-top: ${px2rem(-22)};
      margin-bottom: ${px2rem(24)};
    `}
  }

  span {
    order: 2;
    margin-left: ${px2rem(8)};
    font-weight: ${tokens.fontWeights.artBaselText1.value};
    color: var(--bs-mch-black);
  }

  svg {
    margin-top: ${px2rem(2)};
  }
`

Navigation.propTypes = {
  title: PropTypes.string,
  navigationUrl: PropTypes.string
}

export default Navigation