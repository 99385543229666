export const buildCmsModuleComponents =
  (cmsModuleListData: Array<ABTypes.Pageviews.Module> | undefined, currentLanguage: string):
    any => {
    if (!Array.isArray(cmsModuleListData)) return []

    const cmsModules = cmsModuleListData || []

    return cmsModules.map((cmsModule) => {
      // Module fields may come directly from BE in the right format inside attribute named "fields"
      // In this case, fieldsList will come empty.
      let modulePropsFiltered = cmsModule.fields
      // If there is a fieldsList structure, use it. News articles need it, they don't support COMPACT format
      if (cmsModule.fieldsList && cmsModule.fieldsList.length > 0) {
        //@ts-ignore
        modulePropsFiltered = cmsModule.fieldsList.reduce((p, c) => {
          let fieldData = { value: '' }
          //@ts-ignore
          const fieldDataInCurrentLang = c.values.filter(v => v.locale === currentLanguage)[0]
          //@ts-ignore
          const fieldDataInEnglish = c.values.filter(v => v.locale === 'en')[0]

          // Use English as a fallback if CMS field is not defined
          if (typeof fieldDataInCurrentLang === 'undefined') {
            //@ts-ignore
            fieldData = fieldDataInEnglish
          } else {
            //@ts-ignore
            fieldData = fieldDataInCurrentLang
          }

          //@ts-ignore
          const modulePropItem = { [c.key]: fieldData.value }
          return { ...p, ...modulePropItem }
        }, {})
      }

      return {
        id: cmsModule.id,
        moduleName: cmsModule.moduleName,
        moduleId: cmsModule.moduleId,
        defaultLocale: 'en',
        visible: true,
        displayPartial: cmsModule.displayPartial,
        // componentProps doesn't belong to Module data model (you can check in BE types)
        componentProps: modulePropsFiltered
      }
    })
  }

export const formatCMSLabels = (fieldsList) => {
  const fieldItem = {}
  if (fieldsList && fieldsList.length > 0) {
    fieldsList.map((item) => {
      let itemValues = ''
      if (item.values.length > 1) {
        // module field has multiple data. e.g. label and href
        itemValues = item.values.reduce((prev, current) => {
          const { type, value } = current
          return {
            ...prev,
            ...{
              [type]: value || null
            }
          }
        }, {})
      } else if (item.values[0] !== undefined) {
        itemValues = item.values[0].value || null
      }

      fieldItem[item.key] = itemValues

      return fieldItem
    })
  }
  return fieldItem
}

const replaceObjectValuesWithLength = (source) => {
  const obj = {}

  if (typeof source === 'object' && Object.keys(source).length > 0) {
    Object.keys(source).map((label) => {
      try {
        let labelValue = source[label]
        const labelLength = labelValue.length || 0
        const labelType = typeof labelValue

        if (labelType === 'object' && Object.keys(labelValue).length > 0) {
          labelValue = replaceObjectValuesWithLength(labelValue)
        }

        obj[label] = labelLength ? `[${labelLength} chars]` : labelValue
      } catch (e) {
        // eslint-disable-next-line no-empty, no-console
        console.log(e)
      }

      return label
    })
  }

  return obj
}

export const trimModuleGroupLabels = moduleList =>
  Object.keys(moduleList).reduce((prev, current) => {
    const obj = {}
    try {
      const currentModule = moduleList[current] || null

      if (currentModule) {
        obj[current] = {}

        if (Array.isArray(currentModule)) {
          obj[current] = trimModuleGroupLabels({ ...currentModule })
        } else {
          Object.keys(currentModule).map((field) => {
            const fieldLength = currentModule[field].length

            if (field === 'labels' || field === 'componentProps') {
              obj[current][field] = replaceObjectValuesWithLength(currentModule[field])
            } else {
              obj[current][field] = fieldLength ? `[${fieldLength} chars]` : currentModule[field]
            }

            return field
          })
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-empty, no-console
      console.log(e)
    }

    return {
      ...prev,
      ...obj
    }
  }, {})

