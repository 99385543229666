import React from 'react'
import { string, object, func, bool, array } from 'prop-types'
import { Modal, Form, Button, Grid, Input, Header, Loader } from 'semantic-ui-react'
import { Modal as StyledModal } from '@components'
import styled from 'styled-components'
import classNames from 'classnames'
import SelectorTrigger from '@components/SelectorTrigger'
import SelectorControls from '@components/SelectorControls'
import axios from 'axios'
import { logger, useDebounce } from '@dmi-mch/utils'
import { Icon } from '@mch-group/uikit-components'

import {
  px2rem,
  mq,
  StyleModalFullscreen
} from '@utils'
import update from 'immutability-helper'
import IntersectionCapture from '@reusable-modules/IntersectionCapture'
import LayoutListItem from './Layouts/ListItem'
import LayoutGridItem from './Layouts/GridItem'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const mID = 'super-selector'

const CancelToken = axios.CancelToken

/**
 * This is a Component that allows any list of items to have:
 * Infinite scroll
 * 2 grid views
 * Search
 * Toggling of items
 * Popup behavior
 */
const SuperSelectorForm = React.memo(({
  value,
  className,
  service,
  searchTextPropertyName = 'searchTerm',
  imageFieldName = 'image',
  letterFieldName = 'firstLetter',
  extraParams = {},
  serviceDefaultOptions,
  onChange,
  onClickOkButton,
  labels,
  id,
  isFilterSet
}) => {
  const valuesFrozen = React.useRef(value)
  const valueButton = React.useRef({})
  // source will be used to cancel the search API
  const source = React.useRef(null)
  const [isFormOpened, setIsFormOpened] = React.useState(false)
  const [gridView, setGridView] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const [searchString, setSearchString] = React.useState('')
  // Puts the text of the first selected item in the button. Uses the string passed in children
  const [buttonText, setButtonText] = React.useState(labels.subtitle)
  // Some state for the transition (only for the hide)
  // All the "in transition" stuff is related to animation, so it "dirts" the code
  const [inTransition, setInTransition] = React.useState(false)
  // The main list of items
  const [list, setList] = React.useState(null)

  const [filters, setFilters] = React.useState({
    searchString: '',
    filterLetter: 'az'
  })

  // Used for showing A, B, C, etc. in the list of items
  const currentLetter = React.useRef(null)

  const currentRouter = React.useRef(JSON.stringify(filters))

  // Pagination. It loads page 1 on start.
  const page = React.useRef(1)
  const pageSize = 45

  // Generating abc... lettersDropdown
  const lettersDropdown = React.useMemo(() => {
    let lettersList = ('abcdefghijklmnopqrstuvwxyz').split('').map(letter =>
      ({ key: letter, value: letter, text: letter.toUpperCase() }))
    // Appending the "a-z" dropdown option
    lettersList.unshift({ key: 'az', value: 'az', text: 'A-Z' })
    return lettersList
  }, [])

  const scrollToTop = React.useCallback(() => {
    if (document.querySelector(`#${id}-scrollable`)) {
      document.querySelector(`#${id}-scrollable`).scrollTop = 0
    }
  }, [id])

  // The main search of items
  const search = React.useCallback(
    async () => {
      // check if we need to cancel the previous API first
      if (source.current) {
        source.current.cancel()
      }
      const offset = pageSize * (page.current - 1) // This is way to calculate offset from a page
      const limit = pageSize
      setIsLoading(true)
      try {
        const params = {
          [searchTextPropertyName]: filters.searchString,
          offset: offset,
          limit: limit,
          ...extraParams
        }
        if (filters.filterLetter !== 'az') {
          params.atozLetter = filters.filterLetter
        }
        source.current = CancelToken.source()
        const req = await service({ params, cancelToken: source.current.token })
        if (req.ok && req.status === 200) {
          /* This cannot be handled just like a "simple" list that accumulates items.
          In page 1, no problems.
          But in page 2 of the infinite scroll, we will pass a hybrid object of the latest page API,
          plus the items of the first. This way we keep the hasMore, total, etc of the latest. */
          let newObj = {}
          newObj = update(newObj, { $set: req.data })
          if (page.current > 1) {
            if (list) {
              // This is the line that makes the "magic" said above
              // It just overwrites the list itself, and leaves the rest untouched (hasMore, limit, offset, etc.)
              newObj = update(newObj, { items: { $unshift: list.items } })
            }
          } else {
            // if page is 1, reset scroll
            // scrollToTop()
          }
          setList(newObj)
          setIsLoading(false)
        }
      } catch (err) {
        logger(err)
        setIsLoading(false)
      }
    },
    [searchTextPropertyName,filters.searchString, filters.filterLetter, extraParams, service, list]
  )

  // Called on infinite scroll
  const loadNextPage = React.useCallback(() => {
    page.current = page.current + 1
    search()
  }, [search])

  /* All the code for delaying the user text input (debouncing) */
  const debouncedSearchTerm = useDebounce(searchString ? searchString : '', 750)
  const searchTerm = React.useRef(null)

  React.useEffect(() => {
    if (debouncedSearchTerm !== searchTerm.current) {
      setFilters({
        searchString: debouncedSearchTerm,
        filterLetter: filters.filterLetter
      })
      searchTerm.current = debouncedSearchTerm
    }
  }, [debouncedSearchTerm, filters.filterLetter])
  /* End of debouncing code */

  // To detect when the filters have changed, and retrigger a search
  React.useEffect(() => {
    // If new filters combination is different from the previous one (or it's the first lyfecicle pass), trigger the API
    if ((!list && isFormOpened) ||
      (currentRouter.current.localeCompare(JSON.stringify(filters)) !== 0)) {
      // osmosis complete!
      currentRouter.current = JSON.stringify(filters)
      // Put page back to 1, on each filter change
      page.current = 1
      search()
    }
  }, [filters, list, isFormOpened, search])

  // The purpose of this is to update the main button text on every navigation
  React.useEffect(() => {
    async function setButton() {
      let text = labels.subtitle
      let firstItemName = null
      if (value.length > 0) {
        if (!valueButton.current || (valueButton.current.id !== value[0])) {
          const response = await serviceDefaultOptions(value[0])
          if (response.ok && response.status === 200) {
            firstItemName = response.data.name
            valueButton.current.id = value[0]
            valueButton.current.name = firstItemName
          }
        } else {
          firstItemName = valueButton.current.name
        }
        text = `${firstItemName}${value.length > 1 ?
          `, +${value.length - 1}` :
          ''}`
      }
      setButtonText(text)
    }
    setButton()
  }, [labels.subtitle, serviceDefaultOptions, value])

  const onClickOk = async () => {
    setInTransition(true)
    // This timeout is just to give time to the Fade Out transition.
    // Not friend of writing JS for Fades and stuff, I would prefer
    // to simply make no "out" transition in some cases. To comment with design.
    setTimeout(() => {
      setIsFormOpened(false)
      valuesFrozen.current = value
      typeof onClickOkButton !== 'undefined' && onClickOkButton(null, { value })
      setInTransition(false)
    }, 420)

  }

  // Abstracting the function that checks if item is checked or not, because it's used many times.
  // Compare string vs string. Can't compare numbers.
  // because not always the items are numbers. Example, 8a43-c883-433.
  const isItemChecked = (items, itemId) =>
    !!(items.length > 0 && items.find(item => item.toString() === itemId.toString()))

  return (
    <>
      <SelectorTrigger
        onClick={(e) => {
          if (e.code === 'Tab') {
            setIsFormOpened(false)
          } else {
            setIsFormOpened(true)
          }
        }}
        buttonText={buttonText}
        title={labels.title}
        icon={<Icon name='ovr-list' />}
        iconModal={<Icon name='chevron-right' />}
        className={classNames(mID, className, 'switch-icon')}
        isActive={isFilterSet}
      />
      <StyledModal
        open={isFormOpened}
        onClose={onClickOk}
        closeOnDimmerClick
        className={classNames(mID, className, inTransition ? 'in-transition' : '')}
        centered={false}
        role='dialog'
        aria-label={labels.title}
      >
        <Modal.Header>
          <div className='title'>
            <Icon name='chevron-left' onClick={() => setIsFormOpened(false)} />
            <span className='label label-1'>{labels.title}</span>
            <span>&nbsp;</span>
          </div>
          <div className='ui form'>
            <Form.Field>
              <Input
                name='searchString'
                placeholder={labels.placeholderText}
                onChange={(e, { value: val }) => {
                  setSearchString(val)
                }}
                autoComplete='off'
                value={searchString}
                className='input-search'
                icon={<Icon name='search' />}
                iconPosition='left'
                //tabIndex='0'
              />
              <span className='controls'>
                {searchString.length > 0 &&
                  <span
                    className='label label-1'
                    onClick={() => setSearchString('')}
                    onKeyPress={() => setSearchString('')}
                    role='button'
                    tabIndex='0'
                  >{labels.clearLabel}
                  </span>}
                <Icon name='close' onClick={onClickOk} />
              </span>
            </Form.Field>
            <Grid columns={2} className='filters-row'>
              <Grid.Column width={4}>
                <Form.Select
                  selection
                  id='filterLetter'
                  aria-label={labels.jumpToLabel}
                  name='filterLetter'
                  label={labels.jumpToLabel}
                  onChange={(e, { value: val }) => {
                    setFilters({
                      searchString: filters.searchString,
                      filterLetter: val
                    })
                  }}
                  selectOnBlur={false}
                  options={lettersDropdown}
                  value={filters.filterLetter}
                  fluid
                />
              </Grid.Column>
              <Grid.Column textAlign='right' width={8}>
                <Form.Field>
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor='gridSelector'>&nbsp;</label>
                  <div className='grid-selector'>
                    <Button
                      active={gridView}
                      size='mini'
                      type='button'
                      onClick={() => {
                        setGridView(true)
                        scrollToTop()
                      }}
                    >
                      <Icon name='grid-square-alt' />
                    </Button>
                    <Button
                      active={!gridView}
                      size='mini'
                      type='button'
                      onClick={() => {
                        setGridView(false)
                        scrollToTop()
                      }}
                    >
                      <Icon name='list-alt' />
                    </Button>
                  </div>
                </Form.Field>
              </Grid.Column>
            </Grid>
          </div>
        </Modal.Header>
        <Modal.Content scrolling={list && list.items.length > 0} className='ui form' id={`${id}-scrollable`}>
          <Grid columns={1}>
            {!list &&
              <Grid.Row>
                <Grid.Column width={1}>
                  <LoaderStyled active inline size='small' />
                </Grid.Column>
              </Grid.Row>}
            <Grid.Row>
              {list && list.items.map((tag, i) => {
                // "isDifferent" handles the abecedary letters in the list.
                // If the letter changed, show it
                let isDifferent = false
                if (currentLetter.current !== tag[letterFieldName]) {
                  currentLetter.current = tag[letterFieldName]
                  isDifferent = true
                }

                return (
                  <React.Fragment key={tag.id}>
                    {/* Only for list view. If letter changed, or it's the first in the list, show the letter */}
                    {(!gridView && (isDifferent || i === 0)) && (
                      <Grid.Column width={12} className='first-letter'>
                        <Header as='h3'>{tag[letterFieldName]}</Header>
                      </Grid.Column>
                    )}
                    {/* gridView ? 6 : 6 for mobile, in case a different combination is required for the future */}
                    <Grid.Column
                      mobile={gridView ? 6 : 6}
                      computer={gridView ? 4 : 6}
                      className={gridView ? 'grid-view' : 'list-view'}
                    >
                      {gridView ?
                        <LayoutGridItem
                          text={tag.name}
                          image={tag[imageFieldName]}
                          // It finds the item in the list is checked or not
                          checked={isItemChecked(value, tag.id)}
                          onChange={(e, { checked }) => onChange(e, { checked }, tag)}
                          onClick={(e) => onChange(e, { checked: !isItemChecked(value, tag.id) }, tag)}
                        /> :
                        <LayoutListItem
                          text={tag.name}
                          // It finds the item in the list is checked or not
                          checked={isItemChecked(value, tag.id)}
                          onChange={(e, { checked }) => onChange(e, { checked }, tag)}
                          onClick={(e) => onChange(e, { checked: !isItemChecked(value, tag.id) }, tag)}
                        />}

                    </Grid.Column>
                  </React.Fragment>)
              })}
              {list && list.items.length === 0 &&
                <Grid.Column width={12} className='no-results main-text'>
                  {labels.noMatchingResultsLabel}
                </Grid.Column>
              }
            </Grid.Row>
          </Grid>
          {/* Invisible trigger that loads next page */}
          <IntersectionCapture
            onIntersect={loadNextPage}
            active={list && list.hasMore && !isLoading}
            params={{
              rootMargin: '400px',
              // Infinite scrolls inside layers, need to specify the parent / root
              root: document.querySelector(`#${id}-scrollable`)
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <SelectorControls
            onClickReset={(e) => onChange(e, { checked: false }, -1)}
            onChange={onClickOk}
            resetBtnActive={value.length > 0}
            labels={{
              reset: labels.resetButton,
              apply: `${labels.showResultsLabel}${((value.length > 0)) ?
                ` (${value.length})` : ''}`
            }}
          />
        </Modal.Actions>
      </StyledModal>
      {isFormOpened && <StyleModalFullscreen />}
    </>
  )
})
SuperSelectorForm.displayName = 'SuperSelectorForm'

const LoaderStyled = styled(Loader)`
  &::after {
    border-color: black transparent transparent !important;
  }
`

const SuperSelectorStyled = styled(SuperSelectorForm)`
  &.${mID} {
    span {
      &[role='button'] {
        cursor: pointer;
      }
    }

    &.ui.modal {
      .filters-row {
        margin: -16px -16px 0 -16px;
      }

      ${mq.greaterThan('desktop')`
        width: ${px2rem(683)}
      `};

      > .header {
        ${mq.lessThan('largestTablet')`
          padding-right: ${px2rem(16)} !important;
        `}

        .title {
          display: flex;
          justify-content: space-between;

          .label-1 {
            font-weight: inherit;
          }

          svg {
            margin-top: ${px2rem(7)};
          }

          ${mq.greaterThan('desktop')`
            display: none;
          `};
        }
      }

      .scrolling.content {
        flex-grow: 1;
        padding-top: 0;

        .ui.grid.column {
          margin: -${px2rem(16)} -${px2rem(12)};

          .column {
            padding-left: ${px2rem(12)};
            padding-right: ${px2rem(12)};

            &.grid-view {
              margin-top: ${px2rem(10)};
              margin-bottom: ${px2rem(10)};
            }

            &.no-results {
              margin-bottom: ${px2rem(50)};
            }
          }
        }
      }

      .actions {
        border-top: 1px solid var(--bs-mch-galleries) !important;
      }

      .grid-selector {
        position: relative;
        top: ${px2rem(10)};

        button.ui.button {
          padding: 0;
          background: none;
          border: none;

          &:not(.active) {
            opacity: 0.5;
          }

          & + button.ui.button {
            margin-left: ${px2rem(1)};
          }
        }
      }

      .input-search {
        /* Magnifying glass icon */
        svg {
          left: 0;
          position: absolute;
          top: ${px2rem(10)};
        }

        /* Reset text button and X button */
        & + .controls {
          position: absolute;
          right: 0;
          top: ${px2rem(6)};
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          > span {
            cursor: pointer;
            margin-right: ${px2rem(10)};
            color: var(--bs-mch-gray-400);
            text-decoration: underline;

            &::after {
              content: "|";
              margin-left: ${px2rem(10)};
              color: ${tokens.color.light.base.neutrals['500'].value};
              font-size: ${tokens.fontSize[4].value};
              ${mq.lessThan('largestTablet')`
                display: none;
              `}
            }
          }

          svg {
            cursor: pointer;
            top: ${px2rem(4)};
            position: relative;
            ${mq.lessThan('largestTablet')`
              display: none;
            `}
          }
        }

        input {
          padding-bottom: ${px2rem(22)};
        }
      }

      .first-letter {
        margin-bottom: ${px2rem(15)};
      }

      .column + .first-letter {
        margin-top: ${px2rem(15)};
      }

      span[role='button'] {
        cursor: pointer;
      }
    }
  }
`

SuperSelectorForm.propTypes = {
  className: string,
  title: string,
  value: array,
  service: func,
  serviceDefaultOptions: func,
  labels: object,
  onChange: func,
  onClickOkButton: func,
  disabled: bool,
  extraParams: object,
  searchTextPropertyName: string,
  imageFieldName: string,
  letterFieldName: string,
  placeholderText: string,
  isFilterSet: bool,
  id: string
}

export default SuperSelectorStyled
