import React, { FC } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
interface IMediaPlaceholder {
  children: React.ReactNode
  aspectRatio?: string
  className?: string,
  animate?: boolean
}

const MediaPlaceholder: FC<IMediaPlaceholder> = ({ children, aspectRatio = '1:1', className, animate = true }) => {
  const calcPaddingBottom = (ar: string) => {
    const [width, height] = (ar.split(':') as unknown) as number[]
    return `${(height / width) * 100}%` // e.g. 16:9 => 56.25% or 16:10 => 62.5%
  }
  return (
    <StyledMediaPlaceholder
      className={classNames(className, { animate })}
      paddingBottom={calcPaddingBottom(aspectRatio)}
    >
      {children}
    </StyledMediaPlaceholder>
  )
}

const StyledMediaPlaceholder = styled.div<{ paddingBottom?: string }>`
  height: 0;
  overflow: hidden;
  padding-bottom: ${(props) => props.paddingBottom};
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export default MediaPlaceholder
