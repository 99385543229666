import React from 'react'
import queryString from 'query-string'
import { logger } from '../utils'

import { urlActionMap } from '../constants/cms'
import { setActionModuleData } from '../stores/shared'

/*
  Component will parse ?action from the URL Query String and dispatch the data for corresponding component/modal.

  Actions:
  [ Reset Password, Create Password, Activate/Decline Account, Accept Invitation, ... ]
*/
const withQSActionHandler = App =>
  (class extends React.Component {
    static displayName = `withQSActionHandler(${App.displayName})`

    static async getInitialProps(ctx) {
      // eslint-disable-line react/sort-comp
      const {
        ctx: { query, reduxStore, asPath }
      } = ctx
      const props = App.getInitialProps ? await App.getInitialProps(ctx) : {}
      const path = asPath ? asPath.replace('/', '') : '/' // trim first slash (/) from the path
      let actionModuleData = null

      try {
        const action = query && typeof query === 'object' && 'action' in query ? query.action : null
        const qsParsed = queryString.parse(path)
        let actionParsed = typeof qsParsed === 'object' && 'action' in qsParsed ? qsParsed.action : action
        if (qsParsed.type) {
          // This will generate an action alike 'acceptinvitation_rsvp', to differentiate from 'acceptinvitation'
          actionParsed += `_${qsParsed.type}`
        }
        const actionModuleKey = actionParsed ? urlActionMap[actionParsed] : null

        if (actionModuleKey) {
          actionModuleData = actionModuleKey
        }
      } catch (e) {
        logger(e)
      }

      reduxStore.dispatch(setActionModuleData(actionModuleData))

      return {
        ...props
      }
    }

    render() {
      return <App {...this.props} />
    }
  })

  export default withQSActionHandler
