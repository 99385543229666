// @ts-nocheck
import http from './_http'
import { getSiteLanguage } from '../utils/storage'

const Search = {
  getResults: (data: Paths.SearchInAllCategoriesUsingGET.QueryParameters):
    Promise<{ data: ABTypes.Globalsearch.SearchResult, ok: boolean }> => {
    const params = { ...data }
    params.locale = getSiteLanguage()
    return http.get('/msvc/v1/globalsearch/results', params)
  },
  getPageResults: (id, data) => {
    const params = { ...data }
    params.locale = getSiteLanguage()
    return http.get(`/msvc/v1/globalsearch/category/${id}/results`, params)
  }
}

export default Search
