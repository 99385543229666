import { memo } from 'react'
import PropTypes from 'prop-types'

const Group = memo(({ groupKey, renderTitle, children, marginTop }) => (
  <>
    <h2 className={`${marginTop ? marginTop : ''} mb-8 h3`}>{renderTitle ? renderTitle(groupKey) : groupKey}</h2>
    {children}
  </>
))
Group.displayName = 'Group'

Group.propTypes = {
  groupKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  renderTitle: PropTypes.func,
  children: PropTypes.node,
  marginTop: PropTypes.string
}

export default Group
