import { createGlobalStyle } from 'styled-components'
import { px2rem, hiDPIpx, mq } from '@utils'

const EnquiryGlobalStyles = createGlobalStyle`
  .modals.dimmer .ui.scrolling.modal {
    ${mq.lessThan('largestTablet')`
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    `}
  }

  .ui.tiny.modal {
    ${mq.lessThan('largestTablet')`
      height: 100%;
      width: 100%;
    `}
  }

  .ui.small.modal {
    svg {
      cursor: pointer;
      ${mq.lessThan('largestMobile')`
        top: ${px2rem(6)};
        right: ${px2rem(20)};
      `}

      ${mq.lessThan('largestTablet')`
        top: ${hiDPIpx(6)};
        right: ${hiDPIpx(20)};
      `}

      ${mq.greaterThan('desktop')`
        top: ${px2rem(40)};
      `}
    }
  }

  .ui.dimmer {
    ${mq.lessThan('largestTablet')`
      padding: 0;
    `}
  }

  .scrolling.dimmable.dimmed > .dimmer {
    ${mq.lessThan('largestTablet')`
      overflow-x: hidden;
    `}
  }

  .actions.success-ok {
    ${mq.greaterThan('desktop')`
      padding: ${px2rem(38)} ${px2rem(40)} ${px2rem(16)} ${px2rem(36)} !important;
    `}
    ${mq.lessThan('largestMobile')`
      position: absolute;
      bottom: 0;
      width: 100%;
    `}

    button {
      width: 100%;
      float: unset;
    }
  }

  .success-modal {
    h3.ui.header {
      ${mq.lessThan('largestMobile')`
        margin-top: ${px2rem(40)}
      `}
    }
  }
`

export default EnquiryGlobalStyles