const REDUCERNAME = 'urlContent'

const initialState = {
}

// ACTION TYPES
export const actionTypes = {
  SET_DATA: `${REDUCERNAME}__SET_DATA`
}

// ACTIONS
export const setUrlContentData = payload => (dispatch) => {
  dispatch({
    type: actionTypes.SET_DATA,
    payload
  })
}

// ACTION HANDLERS
const actionHandlers = {
  [actionTypes.SET_DATA]: (state, action) => ({
      ...state,
      ...action.payload
    })
}

// REDUCER
const urlContentReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export default urlContentReducer