import styled from 'styled-components'
import PropTypes from 'prop-types'

import { px2rem, hiDPIpx, mq } from '@utils'
import CustomLinkTag from '@components/CustomLinkTag'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import tokensRenamed from '@constants/tokensRenamed'

const SectorsConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const SectorSquare = ({ description, type = 'detail', isSectorClickable, sectorPrefilterUrl, sectorTextColor }) => {
  const colorCode = description ? description.toLowerCase() : null

  return (
    <SectorsConditionalWrapper

      condition={isSectorClickable && sectorPrefilterUrl}
      wrapper={children => <CustomLinkTag url={sectorPrefilterUrl}>{children}</CustomLinkTag>}
    >
      <StyledSectorSquare className='sector-square'>
        {
          type === 'detail' && (
            <>
              {colorCode && <DetailSquare $color={colorCode} />}
              <DetailDescription>
                <h5 className={`${sectorTextColor}`}>{description}</h5>
              </DetailDescription>
            </>
          )
        }
        {
          type === 'card' && (
            <>
              {colorCode && <CardSquare $color={colorCode} />}
              <CardDescription>
                <h5 className={`${sectorTextColor}`}>{description}</h5>
              </CardDescription>
            </>
          )
        }
        {
          type === 'teaser' && (
            <>
              {colorCode && <TeaserSquare $color={colorCode} />}
              <TeaserDescription>
                <h5 className={`${sectorTextColor}`}>{description}</h5>
              </TeaserDescription>
            </>
          )
        }
        {
          type === 'specialroom' && (
            <>
              {colorCode && <TeaserSquare $color={colorCode} specialroom />}
              <TeaserDescription>
                <h5 className={`${sectorTextColor}`}>{description}</h5>
              </TeaserDescription>
            </>
          )
        }
      </StyledSectorSquare>
    </SectorsConditionalWrapper>
  )
}

SectorSquare.propTypes = {
  description: PropTypes.string,
  type: PropTypes.string,
  isSectorClickable: PropTypes.bool,
  sectorPrefilterUrl: PropTypes.string,
  sectorTextColor: PropTypes.string
}

const StyledSectorSquare = styled.div`
  display: flex;
  align-items: center;
`

const DetailSquare = styled.div`
  width: ${px2rem(13)};
  height: ${px2rem(13)};
  margin-top: -${px2rem(6)};
  display: block;
  margin-right: ${px2rem(6)};
  ${({ $color }) => $color ? `background: var(--bs-mch-${$color}, ${tokens.color.light.base.neutrals[400].value});` : ''}
  ${mq.between('tablet', 'largestTablet')`
    width: ${hiDPIpx(13)};
    height: ${hiDPIpx(13)};
  `}
  ${mq.lessThan('largestMobile')`
    width: ${px2rem(13)};
    height: ${px2rem(13)};
  `}
`

const DetailDescription = styled.div``

const CardSquare = styled.div`
  width: ${px2rem(13)};
  height: ${px2rem(13)};
  margin-top: -${px2rem(6)};
  display: block;
  margin-right: ${px2rem(5)};
  ${({ $color }) => $color ? `background: var(--bs-mch-${$color}, ${tokens.color.light.base.neutrals[400].value});` : ''}
  ${mq.between('tablet', 'largestTablet')`
    width: ${hiDPIpx(13)};
    height: ${hiDPIpx(13)};
  `}
`

const CardDescription = styled.div`
  h4.ui.header {
    font-size: ${tokens.fontSize[4].value} !important;
    line-height: ${tokensRenamed.lineHeights[0].value} !important;
    margin-bottom: 0 !important;
    ${mq.between('tablet', 'largestTablet')`
      font-size: ${hiDPIpx(20)} !important;
      line-height: ${hiDPIpx(16)} !important;
    `}
    ${mq.lessThan('largestMobile')`
      font-size: ${tokens.fontSize[4].value} !important;
      line-height: ${tokensRenamed.lineHeights[0].value} !important;
    `}
  }
`

const TeaserSquare = styled.div`
  width: ${px2rem(13)};
  height: ${px2rem(13)};
  ${({ specialroom }) => specialroom ? `width: ${px2rem(15)}; height: ${px2rem(15)};` : ''}
  display: block;
  margin-right: ${px2rem(5)};
  ${({ $color }) => $color ? `background: var(--bs-mch-${$color}, ${tokens.color.light.base.neutrals[400].value});` : ''}
  ${mq.between('tablet', 'largestTablet')`
    width: ${hiDPIpx(13)};
    height: ${hiDPIpx(13)};
    ${({ specialroom }) => specialroom ? `width: ${hiDPIpx(15)}; height: ${hiDPIpx(15)};` : ''}
  `}
`

const TeaserDescription = styled.div`
  position: relative;
  top: ${px2rem(3)};
`



export default SectorSquare
