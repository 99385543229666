import { environment } from '@dmi-mch/utils'

import { track } from '@utils/logger'
import fetcher from './axios.config.gb'
import { clearUserTokens } from '@utils/storage'
import { removePasswordsFromResponse } from '@utils'
import { ApiResponse } from 'apisauce'

/**
 * Swr context configuration
 * It mimics the current implementation in fetch.js
 * @type {{onError: swrContextConfig.onError}}
 */
const swrContextConfig = {
  fetcher: fetcher,
  revalidateOnFocus: false,
  shouldRetryOnError: false,
  onError: error => {
    const { response }: { response: ApiResponse<any, any> } = error
    if (
      response &&
      response.status === 401 &&
      response.data &&
      response.data.message.indexOf('Invalid access token') !== -1
    ) {
      clearUserTokens()
    }

    try {
      /**
       * Track error information
       * Remove data information as it makes the response too verbose
       */
      if (response.data && 'trace' in response.data) {
        response.data.trace = '{removed}'
      }

      const logData = {
        url: response.config?.baseURL,
        method: response.config?.method?.toUpperCase(),
        status: response.status,
        problem: response.problem,
        error: response.data.error || '',
        message: response.data.message,
        exception: response.data.exception,

        response: {
          data: response.data,
          headers: response.headers
        },
        request: {
          headers: response.config?.headers
        }
      }

      const responseWithoutPassword = removePasswordsFromResponse(response, logData)

      // Log the relevant info to the Telemetry Console
      track(`${logData.status} ${logData.error}: ${logData.method} ${logData.url}`, responseWithoutPassword)
    } catch (e) {
      // eslint-disable-next-line no-console
      environment.isNotPro && console.log(e)
    }
  }
}

export default swrContextConfig
