import { FC } from 'react'
import { FormikConsumer } from 'formik'
import { Col, Button, Text } from '@mch-group/uikit-components'
import { PasswordValidationCheck } from '@components'
import { useUserAccessContext } from '../context/useUserAccessContext'

interface ICreatePassword {
  redirectUserToSpecificUrl: Function,
  showLoadingScreen: Function
}

const CreatePasswordStep: FC<ICreatePassword> = ({
  redirectUserToSpecificUrl,
  showLoadingScreen
}) => {
  const { state: { apiErrorMessage, isLoading, labels: userAccessLabels }, dispatch } = useUserAccessContext()
  return (
    <FormikConsumer >
      {({ handleBlur, values, errors, touched, handleChange }) => (
        <Col className='mt-0'>
          <h4 className='pb-10'>{userAccessLabels.Access.loginHeadingTextLabel}</h4>
          <h5>{userAccessLabels.Access.createPasswordLabel}</h5>
          <p className='text-medium mt-3'>
            {userAccessLabels.Access.helperTextForCreatePassword}
          </p>
          <Text
            label={userAccessLabels.Access.passwordLabel}
            type='password'
            value={values.newPassword}
            onChange={(e) => {
                  handleChange(e)
                  dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
                }}
            onBlur={handleBlur}
            id='newPassword'
            helpText={(touched.newPassword && errors.newPassword) ? errors.newPassword as string : apiErrorMessage}
            helpTextType={(errors.newPassword && touched.newPassword) || apiErrorMessage ? 'danger' : 'muted'}
          />
          <PasswordValidationCheck
            passwordHeadingLabel={userAccessLabels.Access.passwordValidationHeaderLabel}
            passwordString={values.newPassword}
            minCharLabel={userAccessLabels.Access.validateLengthFieldLabel}
            upperCaseLabel={userAccessLabels.Access.validateUpperCaseFieldLabel}
            numericalCharLabel={userAccessLabels.Access.validateNumericFieldLabel}
            className='mt-7'
            isPasswordValidated={!!(touched.newPassword && errors.newPassword)}
          />
          <div className='d-grid mt-8'>
            <Button
              type='submit'
              className='next-button'
              isLoading={isLoading}
            >
              {userAccessLabels.Access.createPasswordButtonTextLabel}
            </Button>
            <span
              className='text-link mt-8'
              onClick={() => {
                    redirectUserToSpecificUrl()
                    showLoadingScreen(userAccessLabels?.Access.loginSuccess)
                  }}
              onKeyUp={() => {
                    redirectUserToSpecificUrl()
                    showLoadingScreen(userAccessLabels?.Access.loginSuccess)
                  }}
              tabIndex={0}
              role='button'
            >
              {userAccessLabels.Access.skipForNowLabel}
            </span>
          </div>
        </Col>
      )}
    </FormikConsumer >
  )
}

export default CreatePasswordStep
