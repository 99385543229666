import { FC } from 'react'
import { FormikConsumer } from 'formik'
import { Col } from '@mch-group/uikit-components'
import { Text } from '@components'
import { useUserAccessContext } from '../context/useUserAccessContext'


const ResendLoginLinkStep: FC = () => {
  const { state: {
    apiErrorMessage,
    labels: userAccessLabels,
    resendLoginLinkRef,
    otherData
  }, dispatch } = useUserAccessContext()
  return(
    <FormikConsumer >
      {({ values }) => (
        <Col className={!otherData.isModal ? 'mt-0' : ''}>
          {!otherData.isModal &&
            <h4 className='pb-8'>{userAccessLabels.Access.loginHeadingTextLabel}</h4>
          }
          <h5 className='mt-3'>{userAccessLabels.Access.emailSendLabel}</h5>
          <Text forwardedAs='p' isHtml className='mch-gray-500' >
            {values.emailSentInviteText || userAccessLabels.Access.verifyEmailSentInfoGenericText}
          </Text>
          {apiErrorMessage &&
            <span className='text-danger form-text mb-4'>{apiErrorMessage}</span>
              }
          <div
            onClick={() => {
              dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
              resendLoginLinkRef.current(values, true)}
            }
            onKeyPress={() => {
              dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
              resendLoginLinkRef.current(values, true)}
            }
            tabIndex={0}
            role='button'
            className='text-link mt-8'
          >
            {userAccessLabels.Access.resendButtonLabel}
          </div>
        </Col>
      )}
    </FormikConsumer >
  )
}

export default ResendLoginLinkStep
