import React from 'react'
import { useIdleTimer } from 'react-idle-timer'

function useIdleTimeTracking() {
	const timeout = 15000
  const [elapsed, setElapsed] = React.useState(0)
  const [isIdle, setIsIdle] = React.useState(false)

  /* When use goes to another tab this event will trigger */
  const handleOnActive = (event) => {
    if(event.type === 'visibilitychange') setIsIdle(true)
    else setIsIdle(false)
  }

  /* When user stays on the same page and don't do any movement on the page this event will trigger */
  const handleOnIdle = () => setIsIdle(true)

  /* this event will trigger on any movement in the page */
  const handleOnAction = () => {
    if(isIdle) setIsIdle(false)
  }

  const { getElapsedTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    onAction: handleOnAction
  })

  /**
    this block is used to call the tracking api in the interval of 10sec.
    Based on elapsedTime we will call the api in the main component.
  **/
  React.useEffect(() => {
    setElapsed(getElapsedTime())
    const timer = setInterval(() => { setElapsed(getElapsedTime()) }, 10000)
    return () => clearInterval(timer)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    elapsedTime: (elapsed / 60000).toFixed(2), //converting it to minutes
    isIdle
  }
}

export default useIdleTimeTracking