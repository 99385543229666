import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import { useDispatch } from 'react-redux'

import { environment } from '@dmi-mch/utils'
import { initialState, reducer, StateContext } from '@modules/SpecialRoom/SpecialRoomModule/useSpecialRoomContext'

import { setUserDataFromAPI as setUserDataFromAPIAction } from '@stores/user'
import { LoginContext } from '@providers/Login'

const SpecialRoomProvider = (props) => {
  const { children, router, pageMetaInfo } = props
  const { user } = useContext(LoginContext)
  // user service promise finished and the user is logged in
  const dispatchLogin = useDispatch()
  if (!user?.me && user?.loginData) {
    dispatchLogin(setUserDataFromAPIAction())
  }

  const [hydratedInitialState] = React.useState({
    labels: null,
    router,
    user,
    pageMetaInfo
  })

  React.useMemo(() => {
    if (JSON.stringify(Object.keys(hydratedInitialState).sort()) !== JSON.stringify(Object.keys(initialState).sort())) {
      environment.isNotPro && console.error('Initial state vs context doesnt match!')
    }
  }, [hydratedInitialState])

  return (
    <StateContext.Provider value={React.useReducer(reducer, hydratedInitialState)}>
      {children}
    </StateContext.Provider>
  )
}

SpecialRoomProvider.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
  router: PropTypes.object,
  CMSProps: PropTypes.object,
  user: PropTypes.object,
  pageMetaInfo: PropTypes.object
}

export default withRouter(SpecialRoomProvider)
