

import PropTypes from 'prop-types'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { CollectionsAlbumCard } from '@mch-group/uikit-components'
import { cloudinary } from '@constants'
import { CustomLinkTag } from '@components'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'

const CollectionAlbumCard = ({
  item,
  isCreator,
  hideContextMenu,
  handleEditClick,
  handleDeleteClick,
  handleUnfollowClick
}) => {
  const { id, thumbnailImageUrls = [], itemsCount = 0, collectionType, name, ownerName, numFollowers } = item
  const [{ labels, CMSProps }] = useMyCollectionsPageContext()
  const imageUrls = []

  thumbnailImageUrls.length > 0 && thumbnailImageUrls.forEach((img) => {
    imageUrls.push(uploadTransformationUrl(img,
      'q_auto:good,dpr_1.0,ar_1:1,c_crop,c_fill,g_auto,w_500',
      { 'transformUrl': cloudinary.throughCloudfront }
    ))
  })

  return (
    <div>
      <CollectionsAlbumCard
        variant='album'
        author={ownerName}
        viewpoint={isCreator ? 'creator' : 'follower'}
        hideContextMenu={hideContextMenu}
        collectionName={collectionType === 'FAVORITES' ? labels?.Collections.favoriteCollectionTitle : name}
        images={imageUrls}
        numberOfFollowers={numFollowers}
        collectionLink={`${CMSProps.mcm_grp1_edit_page}?id=${id}`}
        numberOfItems={itemsCount}
        imagesAlt={collectionType === 'FAVORITES' ? labels?.Collections.favoriteCollectionTitle : `${name} ${labels?.AltTags?.altTagBy} ${ownerName}`}
        linkRenderer={(link, label) => (
          <CustomLinkTag url={link}>
            {label}
          </CustomLinkTag>
        )}
        editContextMenu={{
            label: labels?.Collections.editCollectionBtnLabel,
            onClick : handleEditClick
        }}
        deleteContextMenu={{
            label: labels?.Collections.deleteCollectionBtnLabel,
            onClick : handleDeleteClick
        }}
        unfollowContextMenu={{
            label: labels?.Collections.unfollowCollectionBtnLabel,
            onClick : handleUnfollowClick
        }}
      />
    </div>
  )
}

CollectionAlbumCard.propTypes = {
  myCollections: PropTypes.array,
  item: PropTypes.object,
  isCreator: PropTypes.bool,
  isFollowCollectionTab: PropTypes.bool,
  setIsOpenShareModal: PropTypes.func,
  isOpenShareModal: PropTypes.bool,
  contextMenuOptions: PropTypes.array,
  dropDownClassName: PropTypes.string,
  hideContextMenu: PropTypes.bool,
  handleEditClick: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  handleUnfollowClick: PropTypes.func
}

export default CollectionAlbumCard
