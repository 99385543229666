import Router, { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import { useCallback, useContext, useEffect, useState } from 'react'
import useSWR from 'swr'
import Cookies from 'js-cookie'

import { logger } from '@dmi-mch/utils'
import { LoginContext } from '@providers/Login'
import { Translations as TranslationsService, User as UserService } from '@services'
import { useWindowSize } from '@hooks'

import useRequestLabels from '@hooks/useRequestLabels'

import { User as UserAccessService } from '@services'
import { getSiteLanguage, setUserAccessCookies, redirectToB2bAfterLogin } from '@utils'
import { SnackBarNotificationContext } from '@providers/SnackBarNotification'

import {
  setSessionSummary,
  setMe,
  removeSessionSummary,
  setUserProfilesFromAPI,
  setLoginData,
  setAccessToken,
  setRefreshToken,
  signOut
} from '@stores/user'
import { LoadingscreenContext } from '@providers/Loadingscreen'

import { useUserAccessContext } from '../context/useUserAccessContext'

import {
  LOGIN_WITH_OR_WITHOUT_PASSWORD,
  REGISTER_NEW_USER_FIRST_STEP,
  RESEND_LOGIN_LINK,
  REGISTER_NEW_USER_SECOND_STEP,
  CREATE_PASSWORD_STEP,
  LINK_EXPIRED_STEP,
  RESEND_ACTIVATE_ACCOUNT_LINK_STEP,
  HIDE_LOGIN_FORM,
  RESET_PASSWORD_STEP,
  RESET_PASSWORD_LINK_EXPIRED_STEP,
  RECOVER_ACCOUNT_FIRST_STEP,
  RECOVER_ACCOUNT_SECOND_STEP
} from '../utils/AllSteps'

const useUserAccessData = () => {
  const { showLoading, hideLoading, visible } = useContext(LoadingscreenContext)
  const { showNotification } = useContext(SnackBarNotificationContext)
  const { user, showTCModal } = useContext(LoginContext)

  const { state: {
    nextStep,
    apiErrorMessage,
    labels,
    redeemKeyErrorMessage,
    defaultRedirectUrl,
    loginAccessPageUrl,
    resendLoginLinkRef,
    resendResetPasswordLinkRef,
    otherData,
    isDisableFullPageLoadingSpinner
  }, dispatch } = useUserAccessContext()

  const dispatchLoginData = useDispatch()
  const router = useRouter()

  useRequestLabels(['Access', 'ResetPassword', 'AltTags'], useUserAccessContext)

  const size = useWindowSize()

  const isDesktop = size.width > 767

  const [userInfo, setUserInfo] = useState<ABTypes.Userprofiles.UserBasicInfo | null>(null)
  const [isDataReadyToLoad, setIsDataReadyLoaded] = useState(false)
  const [activateAccountLabel, setActivateAccountLabel] = useState('')
  const [recoverAccountMoreInfoLabel, setAccountMoreInfoLabel] = useState('')
  const invitationKey = typeof window !== 'undefined' && Cookies.get('invitationkey')
  const hasExtraActionInURL = router.query.hasOwnProperty('extraAction')
  const isVIPLoginLink = router?.query.action === 'loginWithVIPCode' && router?.query.vipAccessCode
  const redirectUrlAfterLogin = (router?.query.redirect ? router.query.redirect : defaultRedirectUrl)
    || defaultRedirectUrl
  const redeemInvitationPageUrl = '/dashboard/redeem-invitation?access=true'

  const { data: countries } = useSWR<Array<ABTypes.Countries.Country>>('/msvc/v1/countries/')

  const showLoadingScreen = useCallback((text1) => {
    if (!isDisableFullPageLoadingSpinner) {
      showLoading({
        text1: text1,
        text2: labels?.Access.pleaseWaitTextLabel,
        showSpinner: true,
        theme: 'light'
      })
    }
    const routeChanged = () => {
      hideLoading()
    }
    router.events.on('routeChangeComplete', routeChanged)
    return () => router.events.off('routeChangeComplete', routeChanged)
  }, [isDisableFullPageLoadingSpinner, router.events, showLoading, labels?.Access.pleaseWaitTextLabel, hideLoading])

  const showNotificationAfterLoginSucessForModalCase = useCallback((callback, reqData) => {
    showNotification(true, {
      autohide: true,
      position: !isDesktop ? 'top-center' : 'top-end',
      message: labels?.Access.loginSuccess,
      className: 'position-fixed',
      type: 'success',
      style: { top: '100px' }
    })
    typeof callback === 'function' && callback(reqData)
  }, [labels?.Access.loginSuccess, showNotification, isDesktop])

  const handleBackArrowClick = (prevStep, title, backText, handleBackBtnClickCallback) => {
    {/* remove the invitation key from local storage if it exist */ }
    if (invitationKey) {
      Cookies.remove('invitationkey', { path: '/' })
    }
    dispatch({ type: 'SET_NEXT_STEP', payload: prevStep })
    resetState()
    if (otherData.isModal) {
      typeof otherData.setModalData === 'function' && otherData.setModalData({
        title: title,
        backText: backText,
        handleBackBtnClick: handleBackBtnClickCallback
      })
    }
  }

  const verifyUserAlreadyRegistered = async (values: { email: 'string' }) => {
    try {
      if (values.email) {
        dispatch({ type: 'SET_IS_LOADING', payload: true })
        dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
        const response = await UserAccessService.userBasicInfo({ email: values.email })
        if (response.ok && response.data) {
          dispatch({ type: 'SET_IS_LOADING', payload: false })
          setUserInfo(response.data)
          if (!response.data.requiredRecovery) {

            {/* setting the login modal data */ }
            typeof otherData.setModalData === 'function' &&
              otherData.setModalData({
                title: labels.Access.loginHeadingTextLabel,
                backText: labels.Access.backButtonLabel,
                handleBackBtnClick: () => {
                  handleBackArrowClick(null, labels.Access.logInLabel, '', () => { })
                }
              })

            return dispatch({ type: 'SET_NEXT_STEP', payload: LOGIN_WITH_OR_WITHOUT_PASSWORD })
          } else {
            try {
              const responseData = await TranslationsService.getDynamicLabelValue(
                'Access',
                'AccountActivationInProgressMoreInfoLabel',
                getSiteLanguage(),
                `2,${response?.data.email}`
              )
              //@ts-ignore
              setAccountMoreInfoLabel(responseData?.data?.value)
              dispatch({ type: 'SET_IS_LOADING', payload: false })
            } catch (e) {
              logger(e)
            }
            dispatch({ type: 'SET_NEXT_STEP', payload: RECOVER_ACCOUNT_FIRST_STEP })
            {/* setting the login modal data */ }
            typeof otherData.setModalData === 'function' && otherData.setModalData({
              title: `${labels.Access.WelcomeBackTitle}, ${response?.data?.firstName}! <br> ${labels.Access.recoverAccountTitle}`,
              subTitle: labels.Access.recoverAccountSubtitle,
              backText: labels.Access.backButtonLabel,
              handleBackBtnClick: () => {
                handleBackArrowClick(null, labels.Access.logInLabel, '', () => { })
              }
            })
          }
        }
        switch (response.status) {
          case 404:
            dispatch({ type: 'SET_IS_LOADING', payload: false })
            typeof otherData.setModalData === 'function' &&
              otherData.setModalData({
                title: labels.Access.createAccountHeadingTextLabel,
                backText: labels.Access.backButtonLabel,
                handleBackBtnClick: () => {
                  handleBackArrowClick(null, labels.Access.logInLabel, '', () => { })
                }
              })
            return dispatch({ type: 'SET_NEXT_STEP', payload: REGISTER_NEW_USER_FIRST_STEP })

          case 406:
            dispatch({ type: 'SET_IS_LOADING', payload: false })
            dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: labels?.Access.accountErrorLabel })
            break
        }
      } else {
        dispatch({ type: 'SET_IS_LOADING', payload: false })
        dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: labels.Access.emailRequiredLabel })
      }
    } catch (err) {
      dispatch({ type: 'SET_IS_LOADING', payload: false })
      dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: labels?.Access.accountErrorLabel })
      logger(err)
    }
  }


  const redirectUserToSpecificUrl = useCallback(
    async (vipAccessCode?: string): Promise<boolean | string | undefined> => {
    if (isVIPLoginLink || vipAccessCode) {
      return Router.push({ pathname: '/', search: '?vipProfilingAccess=true' }, redirectUrlAfterLogin?.toString())
    } else if (invitationKey) {
      return Router.push({ pathname: '/' }, redeemInvitationPageUrl)
    } else {
      if (router && (router.query.redirectGallery || router.query.redirectUser)) {
        const checkIfUserIsGallery = await UserService.getProfiles()
        if (checkIfUserIsGallery?.data?.find((i) => i.type === 'Gallery')) {
          return window.location.href = router?.query?.redirectGallery as string
        } else {
          return window.location.href = router?.query?.redirectUser as string
        }
      }
      if (redirectUrlAfterLogin?.includes('/b2b')) {
        redirectToB2bAfterLogin(redirectUrlAfterLogin?.toString())
      } else {
        return Router.push({ pathname: '/' }, redirectUrlAfterLogin?.toString())
      }
    }
  }, [invitationKey, redirectUrlAfterLogin, router, isVIPLoginLink])

  /**
   * Function called after login with TC ready
   * @param reqData
   * @param meData
   * @param isDisplayCreatePasswordStep
   * @returns
   */

  const actionsAfterloginSuccess = useCallback(async (reqData, meData, isDisplayCreatePasswordStep) => {
    if (otherData.isModal) {
      if (invitationKey) {
        const callback = typeof otherData.onCloseLoginModal === 'function' && otherData.onCloseLoginModal
        showNotificationAfterLoginSucessForModalCase(callback, user.me)
        return Router.push({ pathname: '/' }, redeemInvitationPageUrl)
      }
      if (reqData.accessCode || reqData.cardCode) {
        const callback = typeof otherData.callback === 'function' && otherData.callback
        showNotificationAfterLoginSucessForModalCase(callback, user.me)
      } else {
        const callback = typeof otherData.callback === 'function' && otherData.callback
        showNotificationAfterLoginSucessForModalCase(callback, meData.data)
      }
    } else {
      if (isDisplayCreatePasswordStep) {
        hideLoading()
        dispatch({ type: 'SET_NEXT_STEP', payload: CREATE_PASSWORD_STEP })
      } else {
        showLoadingScreen(router.query.action === 'activate' ? labels?.Access.accountCreatedLabel : labels?.Access.loginSuccess)
        if (router?.query?.redirect?.includes('/b2b')) {
          redirectToB2bAfterLogin(router.query.redirect as string)
        } else {
          redirectUserToSpecificUrl(reqData?.cardCode || null)
        }
      }
    }
  }, [dispatch, hideLoading, invitationKey, labels, otherData, redirectUserToSpecificUrl,
    router, showLoadingScreen, showNotificationAfterLoginSucessForModalCase, user])

  /* This service will send an email with the access code to the user registered email address */
  const sendMagicLinkForLogin = useCallback(async (values, isResend = false, activateAccount = false) => {
    let reqParams: {
      redirect?: string | undefined,
      email?: string | undefined,
      userId?: string | undefined,
      access?: boolean,
      vipAccessCode?: string | undefined
    } = {}
    const userId = router?.query.userId
    if (router?.query.redirect) {
      reqParams.redirect = router.query.redirect as string | undefined
    }
    if (invitationKey) {
      reqParams.redirect = redeemInvitationPageUrl
    }
    if (values.email) {
      reqParams.email = values.email
    }
    if (userId) {
      reqParams.userId = userId as string | undefined
    }

    if(values.cardCode) {
      reqParams.vipAccessCode = values.cardCode
    }

    /* this case is specially for login modal */
    if (otherData.isModal) {
      if (invitationKey) { reqParams.access = true }
      else {
        // send redirect params to api, when user comes from link sent to email this will help
        // user to redirect into particular url.
        if (!router?.query.redirect) {
          reqParams.redirect = router.asPath
        }
      }
    }

    dispatch({ type: 'SET_IS_LOADING', payload: true })
    dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })

    try {
      const response = await UserAccessService.sendAccessCodeToEmailId(reqParams)
      dispatch({ type: 'SET_IS_LOADING', payload: false })
      if (response.status === 200) {
        hideLoading()
        if (!isResend) {
          return activateAccount
            ? dispatch({ type: 'SET_NEXT_STEP', payload: RESEND_ACTIVATE_ACCOUNT_LINK_STEP })
            : dispatch({ type: 'SET_NEXT_STEP', payload: RESEND_LOGIN_LINK })
        } else {
          const position = otherData.isModal ? 'top-center' : 'bottom-center'
          showNotification(true, {
            autohide: true,
            message: labels?.Access.resendEmailSuccessLabel,
            position: !isDesktop ? position : 'top-end',
            className: otherData.isModal ? 'position-fixed' : '',
            style: { top: '100px' }
          })
        }
      } else {
        if (response.data && response.data.status === 429) {
          dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: labels?.Access.accountTemporarilyBlockedWarningLabel })
        } else {
          dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: response.data.error })
        }
      }
    } catch (err) {
      dispatch({ type: 'SET_IS_LOADING', payload: false })
      logger(err)
    }
  }, [router.query.userId, router.query.redirect, router.asPath, invitationKey,otherData.isModal,dispatch,
    hideLoading, showNotification, labels?.Access.resendEmailSuccessLabel,
    labels?.Access.accountTemporarilyBlockedWarningLabel, isDesktop])

  const submitUserLoginDetail = useCallback(async (
    reqData,
    isDisplayCreatePasswordStep: boolean
  ) => {
    dispatch({ type: 'SET_IS_LOADING', payload: true })
    setIsDataReadyLoaded(true)
    dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
    try {
      const response = await UserAccessService.signInUser(reqData)

      switch (response.status) {
        case 200: {
          try {
            if (response.data) {
              if(localStorage.getItem('isPasswordUpdate')) {
                localStorage.removeItem('isPasswordUpdate')
              }
              if(reqData.password) {
                // this logic is to handle update psswrd popup with new psswrd policy
                if(response.data.sessionSummary.requiredPwdUpdate) {
                  const isPasswordUpdate = response.data.sessionSummary.requiredPwdUpdate ? 'true' : 'false'
                  localStorage.setItem('isPasswordUpdate', isPasswordUpdate)
                } else {
                  localStorage.removeItem('isPasswordUpdate')
                }
              }
              dispatchLoginData(setSessionSummary(response.data.sessionSummary))
              setUserAccessCookies(response)
              await setUserProfilesFromAPI()
              dispatchLoginData(removeSessionSummary())
              const meData = await UserAccessService.getMe()
              dispatch({ type: 'SET_IS_LOADING', payload: false })
              if (meData.data && meData.ok) {
                dispatchLoginData(setMe(meData.data))
                dispatchLoginData(setLoginData(response.data.sessionSummary))
                dispatchLoginData(setAccessToken(response.data.accessToken))
                dispatchLoginData(setRefreshToken(response.data.sessionSummary.refreshToken))
              }
              if (response.data.sessionSummary.termsAndConditionsAccepted) {
                if (isVIPLoginLink) {
                  actionsAfterloginSuccess(reqData, meData, false)
                } else { actionsAfterloginSuccess(reqData, meData, isDisplayCreatePasswordStep) }
              } else { // If no TC ACCEPTED
                // Ideally we should close the Login modal here, because looks ugly.
                // But because hooks are working like butter, and there is no user, etc, the login stays open. We'll fix the last.
                if (!otherData.isModal) {
                  dispatch({ type: 'SET_NEXT_STEP', payload: HIDE_LOGIN_FORM })
                }
                showTCModal({
                  onTCAcceptedCallback: async () => {
                    if (!otherData.isModal) {
                      dispatch({ type: 'SET_NEXT_STEP', payload: CREATE_PASSWORD_STEP })
                    }
                    // FINALLY DOING THE POST TC ACTIONS
                    actionsAfterloginSuccess(reqData, meData, isDisplayCreatePasswordStep)
                  }
                })
              }
            }

          } catch (e) {
            hideLoading()
            dispatch({ type: 'SET_NEXT_STEP', payload: LINK_EXPIRED_STEP })
            logger(e)
          }

        }
          break
        case 401: {
          dispatch({ type: 'SET_IS_LOADING', payload: false })
          //this error code comes when user is trying to access the magic link for second time.
          // @ts-ignore errorCode doesn't exist in data model, but it's still called
          if ((response.data?.errorCode === 0 || response.data?.errorCode === 5) && hasExtraActionInURL) {
            dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: labels?.Access.codeExpiredLabel })
            hideLoading()
            dispatch({ type: 'SET_NEXT_STEP', payload: LINK_EXPIRED_STEP })
          }
          // @ts-ignore errorCode doesn't exist in data model, but it's still called
          if (response.data?.errorCode === 6 || response.data?.errorCode === 2 || response.data.errorCode === 3) {
            dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: labels?.Access.accountErrorLabel })
          }

          // error code === 0 is also used for invalid credentials.
          // @ts-ignore errorCode doesn't exist in data model, but it's still called
          if (response.data.errorCode === 0 && reqData.password) {
            dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: labels?.Access.incorrectPasswordErrorMsgLabel })
          }
          /* to many login attempts using access code errorCode === 5 */
          // @ts-ignore errorCode doesn't exist in data model, but it's still called
          if (response.data.errorCode === 5 && reqData.password) {
            dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: labels?.Access.accountTemporarilyBlockedWarningLabel })
          }
          break
        }
        case 403: {
          dispatch({ type: 'SET_IS_LOADING', payload: false })
          // @ts-ignore errorCode doesn't exist in data model, but it's still called
          dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: response.data?.message })
          // if user did not activated his account and trying to login with password or without password
          // @ts-ignore errorCode doesn't exist in data model, but it's still called
          if (response.data.errorCode === 4) {
            let params = {}
            if(reqData.cardCode) {
              params = { cardCode: reqData.cardCode }
            }
            if(userInfo?.email) {
              params = { email: userInfo?.email }
            }
            sendMagicLinkForLogin(params, false, false)
            hideLoading()
            dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
          }

        }
      }
    } catch (err) {
      dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: labels?.Access.accountErrorLabel })
      dispatch({ type: 'SET_IS_LOADING', payload: false })
      hideLoading()
      logger(err)
    }
  }, [dispatch, dispatchLoginData, actionsAfterloginSuccess, otherData.isModal, showTCModal,
    hideLoading, hasExtraActionInURL, labels, sendMagicLinkForLogin, userInfo, isVIPLoginLink])

  resendLoginLinkRef.current = (values: { email?: string, vipcode?: string }, isResend: boolean) => {
    let params = {}
    const vipAccessCode = router?.query.vipAccessCode || values.vipcode
    if(vipAccessCode) {
      params = { cardCode: vipAccessCode }
    }
    if(values?.email) {
      params = { email: values.email }
    }
    sendMagicLinkForLogin(params, isResend)
  }

  resendResetPasswordLinkRef.current = async () => {
    try {
      dispatch({ type: 'SET_IS_LOADING', payload: false })
      const email = router?.query.email || ''
      //@ts-ignore
      const response = await UserService.sendResetPasswordLink(email)
      if (response.ok) {
        dispatch({ type: 'SET_IS_LOADING', payload: false })
        showNotification(true, {
          autohide: true,
          position: !isDesktop ? 'top-center' : 'top-end',
          message: labels?.ResetPassword.SentEmailNotificationTextLabel,
          className: 'position-fixed',
          style: { top: '100px' }
        })
      }
    } catch (e) {
      logger(e)
    }
  }

  const handleUserRegistration = async (values) => {
    dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })

    try {
      dispatch({ type: 'SET_IS_LOADING', payload: true })
      const selectedCountryObj = countries?.filter((data) => data.name === values.country)
      let reqParams = { ...values, country: selectedCountryObj && selectedCountryObj[0].crmGuid }
      if (router?.query.redirect) {
        reqParams.redirect = router.query.redirect
      } else {
        if (otherData.isModal) {
          reqParams.redirect = router.asPath
        }
      }
      if(router.query.extraAction) {
        reqParams.extraAction = router.query.extraAction
      }
      const response = await UserAccessService.userAccessRegister(reqParams)
      dispatch({ type: 'SET_IS_LOADING', payload: false })
      if (response.ok && response.status === 200) {
        typeof otherData.setModalData === 'function' &&
          otherData.setModalData({
            title: labels.Access.createAccountHeadingTextLabel,
            backText: labels.Access.backButtonLabel,
            handleBackBtnClick: () => {
              handleBackArrowClick(
                REGISTER_NEW_USER_FIRST_STEP,
                labels.Access.createAccountHeadingTextLabel,
                labels.Access.backButtonLabel,
                function () {
                  handleBackArrowClick(null, labels.Access.logInLabel, '', () => { })
                }
              )
            }
          })
        dispatch({ type: 'SET_NEXT_STEP', payload: REGISTER_NEW_USER_SECOND_STEP })
      } else {
        // @ts-ignore message doesn't exist in data model, but it's still called
        const errorText = response.data && response.data.message ? response.data.message :
          'An unexpected error occurred.'
        dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: errorText })
      }
    } catch (errors) {
      dispatch({ type: 'SET_IS_LOADING', payload: false })
      logger('User Register API failed: ', { errors })
    }
  }

  useEffect(() => {
    {/* Logout the user and display the login screen */ }
    if (user.accessToken && !isDataReadyToLoad) {
      dispatchLoginData(signOut())
    }
  }, [dispatchLoginData, isDataReadyToLoad, redirectUrlAfterLogin, user.accessToken])

  useEffect(() => {
    const fetchLabel = async () => {
      try {
        // @ts-ignore this function is deprecated, should use labels from API, not from Redux
        const response = await TranslationsService.getDynamicLabelValue(
          'Access',
          'verifyRegistrationEmailTextLabel',
          getSiteLanguage(),
          `${userInfo?.email},2`
        )
        // @ts-ignore - response not correctly typed, due to ignore reason above
        setActivateAccountLabel(response?.data?.value)
      } catch (e) {
        logger(e)
      }
    }

    if (!activateAccountLabel && userInfo?.email) {
      fetchLabel()
    }
  }, [activateAccountLabel, router, userInfo])

  const validateResetPasswordLink = useCallback(async() => {
      try {
        const params = {
          email: router.query.email,
          token: router.query.token
        }
        //@ts-ignore
        const response = await UserAccessService.validateResetPasswordLink(params)
        hideLoading()
        if(response?.status === 200) {
          return dispatch({ type: 'SET_NEXT_STEP', payload: RESET_PASSWORD_STEP })
        }
        if(response.status === 406) {
          return dispatch({ type: 'SET_NEXT_STEP', payload: RESET_PASSWORD_LINK_EXPIRED_STEP })
        }
      } catch(e) {
          logger(e)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, router.query.email, router.query.token])

  useEffect(() => {
    // reset password link from email
      if (router.query.action === 'resetPassword') {
          showLoading({
            showSpinner: true,
            theme: 'light'
          })
          validateResetPasswordLink()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[router.query.action, validateResetPasswordLink])


  useEffect(() => {
    // when url contains userId. Login using the userId and access code.
    if (router.query.userId && router.query.code && hasExtraActionInURL) {
      const reqParams = { userId: router.query.userId, emailCode: router.query.code }
      if (!visible && labels && !user.accessToken && !isDataReadyToLoad) {
        if (router.query.action === 'activate') {
          showLoading({
            text1: labels?.Access.accountCreatedLabel,
            text2: labels?.Access.pleaseWaitTextLabel,
            showSpinner: true,
            theme: 'light'
          })
        } else {
          showLoading({
            showSpinner: true,
            theme: 'light'
          })
        }
      }
      if (!user.accessToken && !user.sessionSummary && labels && !apiErrorMessage && !isDataReadyToLoad) {
        if (router.query.action === 'activate') {
          submitUserLoginDetail(reqParams, false)
        } else {
          submitUserLoginDetail(reqParams, true)
        }
      }
    }
    if (isVIPLoginLink && !isDataReadyToLoad) {
      showLoading({ showSpinner: true, theme: 'light' })
      const reqParams = { cardCode: router.query.vipAccessCode }
      submitUserLoginDetail(reqParams, true)
    }
  }, [apiErrorMessage, hasExtraActionInURL, isDataReadyToLoad, isVIPLoginLink, labels, router,
    showLoading, submitUserLoginDetail, user, visible])

  const resetState = () => {
    dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
    dispatch({ type: 'SET_IS_LOADING', payload: false })
  }

  const createNewPassword = async (values) => {
    dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
    if (values.newPassword && user.loginData !== null) {
      dispatch({ type: 'SET_IS_LOADING', payload: true })
      try {
        const response = await UserAccessService.setPasswordForLoggedInUser({ newPassword: values.newPassword, 'policy': 'PWD_POLICY_V2' })
        if (response.status === 200) {
          dispatch({ type: 'SET_IS_LOADING', payload: false })
          redirectUserToSpecificUrl()
          showLoadingScreen(labels?.Access.loginSuccess)
        }
      } catch (err) {
        dispatch({ type: 'SET_IS_LOADING', payload: false })
        logger(err)
      }
    } else {
      dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: labels?.Access.fieldRequiredLabel })
    }
  }

  const resetPassword = async (values) => {
    try {
      dispatch({ type: 'SET_IS_LOADING', payload: true })
      const reqParams = {
        newPassword: values.newPassword,
        newPasswordConfirm: values.newPasswordConfirm,
        token: router.query.token,
        email: router.query.email,
        'policy': 'PWD_POLICY_V2'
      }
      const response = await UserAccessService.resetPassword(reqParams)
      setIsDataReadyLoaded(true)
      if (response.status === 200 && response.data) {
        localStorage.setItem('isResetPasswordDoneOnce', 'true')
        dispatchLoginData(setSessionSummary(response.data.sessionSummary))
        setUserAccessCookies(response)
        dispatchLoginData(setAccessToken(response.data.accessToken))
        dispatchLoginData(setRefreshToken(response.data.sessionSummary.refreshToken))
        return redirectUserToSpecificUrl()
      }
      if (response.status === 403) {
        dispatch({ type: 'SET_IS_LOADING', payload: false })
        dispatch({ type: 'SET_NEXT_STEP', payload: RESET_PASSWORD_LINK_EXPIRED_STEP })
      }
    } catch (err) {
      dispatch({ type: 'SET_IS_LOADING', payload: false })
      logger(err)
    }
  }

  const recoverDeletedAccount = async (params) => {
    try {
      dispatch({ type: 'SET_IS_LOADING', payload: true })
      const response = await UserAccessService.postRecoveryAccountData(params)
      if (response.status === 200) {
        {/* setting the login modal data */ }
        typeof otherData.setModalData === 'function' && otherData.setModalData({
          title: labels.Access.AccountActivationInProgressTitle,
          backText: ''
        })
        dispatch({ type: 'SET_NEXT_STEP', payload: RECOVER_ACCOUNT_SECOND_STEP })
      }
    } catch (e) {
      dispatch({ type: 'SET_IS_LOADING', payload: false })
      logger(e)
    }
  }

  return {
    router,
    verifyUserAlreadyRegistered,
    nextStep,
    dispatch,
    submitUserLoginDetail,
    sendMagicLinkForLogin,
    countries,
    handleUserRegistration,
    redeemKeyErrorMessage,
    loginAccessPageUrl,
    resetState,
    isDataReadyToLoad,
    redirectUserToSpecificUrl,
    createNewPassword,
    showLoadingScreen,
    handleBackArrowClick,
    user,
    userInfo,
    activateAccountLabel,
    resetPassword,
    recoverAccountMoreInfoLabel,
    setAccountMoreInfoLabel,
    recoverDeletedAccount
  }
}

export default useUserAccessData
