import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import ShadowOverlay from './ShadowOverlay'
import DefaultToggle from './DefaultToggle'
import MainContent from './MainContent'

const useClientRect = (contentHeight) => {
  const [rect, setRect] = useState(null)
  const ref = useCallback(node => {
    if (node !== null) {
      setRect(contentHeight)
    }
  }, [contentHeight])

  return [rect, ref]
}

const AnimatedShowMore = ({
  toggle,
  height = 200,
  shadowColor,
  speed,
  children,
  contentHeight,
  handleToggleHeightCallback=() =>{},
  isMaskText,
  resetStateOnRouteChange
}) => {
  const Toggle = toggle || DefaultToggle

  /**
   * We manage 3 heights:
   *  - height: desired collapsed height
   *  - contentsHeight: height of the actual content
   *  - currentHeight: the active state between those 2 ( contentsHeight | height )
   */
  const [rect, contentContainerRef] = useClientRect(contentHeight)
  const contentsHeight = rect ? rect.height : 0
  const [currentHeight, setCurrentHeight] = useState(height)
  const [isOpen, setIsOpen] = useState(false)

  /**
   * Toggle between the maximum height (height of the content)
   */
  const handleToggleHeight = () => {
    if (currentHeight === height) {
      setCurrentHeight(contentsHeight)
      setIsOpen(true)
    } else {
      setCurrentHeight(height)
      setIsOpen(false)
      handleToggleHeightCallback()
    }
  }

  React.useEffect(() => {
    if(resetStateOnRouteChange) {
      setCurrentHeight(height)
      setIsOpen(false)
    }
  },[height, resetStateOnRouteChange])

  const shouldShowShadow = (contentsHeight !== height) && (height < contentsHeight) && !isOpen
  const shouldShowToggle = isOpen || shouldShowShadow

  return (
    <>
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        {/* ShadowOverlay */}
        { shouldShowShadow && <ShadowOverlay color={shadowColor} /> }

        {/* Main content area */}
        <MainContent
          height={currentHeight}
          animationSpeed={speed}
          isMaskText={isMaskText}
          shouldShowToggle={shouldShowToggle}
          isOpen={isOpen}
        >
          {children}
        </MainContent>

        {/* Invisible conent container */}
        <div
          ref={contentContainerRef}
          style={{ display: 'none' }}
          aria-hidden='true'
        >
          {children}
        </div>
      </div>

      {/* The Toggle */}
      {shouldShowToggle && (
        <button
          className='AnimatedShowMore__ToggleButton'
          style={{
            display: 'block',
            border: 'none',
            outline: 'none',
            background: 'transparent',
            padding: 0,
            color: 'inherit',
            font: 'inherit',
            position: 'relative'
          }}
          onClick={handleToggleHeight}
        >
          <Toggle isOpen={isOpen} />
        </button>
      )}
    </>
  )
}

AnimatedShowMore.propTypes = {
  toggle : PropTypes.func,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  shadowColor: PropTypes.string,
  speed: PropTypes.number,
  children: PropTypes.node,
  contentHeight: PropTypes.object,
  handleToggleHeightCallback: PropTypes.func,
  isMaskText: PropTypes.bool,
  resetStateOnRouteChange: PropTypes.number
}

export default AnimatedShowMore

AnimatedShowMore.displayName = 'AnimatedShowMore'
