import Head from 'next/head'
import { metadata } from '@constants'
import { canonicalUrl, hostProtocol } from '@utils'
import { mediaStyles } from '@utils/createMedia'
import useHeadMeta from './useHeadMeta'
import { FC } from 'react'
import OneTrustScripts from './oneTrustCookieManagement'


const HeadMeta: FC<IHeadMeta> = (props) => {
  const {
    site,
    pageTitle,
    getDescription,
    imageForSocialNetworks,
    gtmId,
    gtmScript,
    gtmOneTrustScript,
    host,
    query,
    expiryDate,
    openGraphData
  } = useHeadMeta(props)

  const title = pageTitle
  const metaSitename = metadata[site]?.og?.sitename

  const titleRender = `${title} | ${metaSitename}`

  return (
    <Head>
      <script dangerouslySetInnerHTML={{ __html: gtmOneTrustScript }} />
      {/* Google Analytics + Tag Manager + MCH Marketing scripts*/}
      {gtmId &&
        <script id='gtm-head' dangerouslySetInnerHTML={{ __html: gtmScript }} />}
      {getEnvConfig?.fe?.onetrust?.dataDomainScript && (
        <>
          {/* <!-- OneTrust Cookies Consent Notice start for artbasel.com --> */}
          <script defer type='text/javascript' src={`https://cdn.cookielaw.org/consent/${getEnvConfig?.fe?.onetrust?.dataDomainScript}/OtAutoBlock.js`} />
          <script type='text/javascript' data-document-language='true' data-domain-script={getEnvConfig?.fe?.onetrust?.dataDomainScript} defer id='gtm-head-test' src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js' />
          <script defer type='text/javascript' dangerouslySetInnerHTML={{ __html: OneTrustScripts }} />
        </>
      )}
      <title>{titleRender}</title>
      {getDescription() && <meta name='description' content={getDescription()} />}

      {/* Canonical */}
      <link rel='canonical' href={`${canonicalUrl(host, query)}`} />
      {/* Alternate / HrefLang */}
      <link rel='alternate' href={`${canonicalUrl(host, query)}`} hrefLang='x-default' />
      <link rel='alternate' href={`${canonicalUrl(host, query)}?lang=en`} hrefLang='en' />
      <link rel='alternate' href={`${canonicalUrl(host, query)}?lang=zh_CN`} hrefLang='zh' />
      <link rel='alternate' href={`${canonicalUrl(host, query)}?lang=fr`} hrefLang='fr' />
      <link rel='alternate' href={`${canonicalUrl(host, query)}?lang=de`} hrefLang='de' />
      <link rel='alternate' href={`${canonicalUrl(host, query)}?lang=es`} hrefLang='es' />

      {/* no index pages */}
      {query?.includes('/search') && <meta name='robots' content='noindex' />}

      {/* Open Graph Data */}
      <meta property='og:site_name' content={metadata[site]?.og?.sitename} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={pageTitle} />
      <meta property='og:url' content={`${hostProtocol(host)}${query}`} />
      <meta property='og:description' content={getDescription()} />
      <meta property='og:image' content={imageForSocialNetworks} />

      {/* Twitter */}
      <meta name='twitter:card' content='summary' />
      <meta name='twitter:title' content={pageTitle} />
      <meta name='twitter:site' content={metadata[site]?.twitter?.site} />
      <meta name='twitter:description' content={openGraphData?.description || ''} />
      <meta name='twitter:image' content={metadata[site]?.twitter?.image} />

      {/* Google, Bing and Facebook App Verification */}
      <meta name='google-site-verification' content={metadata[site]?.google?.siteVerification} />
      <meta name='msvalidate.01' content={metadata[site]?.microsoft?.bing} />
      <meta property='fb:app_id' content={metadata[site]?.facebook?.appId} />
      {metadata[site]?.facebook?.domainVerification && (<meta name='facebook-domain-verification' content={metadata[site]?.facebook?.domainVerification} />)}

      {/* Expire the page from google engine */}
      {expiryDate && <meta name='GOOGLEBOT' content={`unavailable_after: ${expiryDate}`} />}
      <link
        rel='preload'
        id='FontAwesome_900_woff2'
        href='/static/fonts/FontAwesome/fa-solid-900.woff2'
        as='font'
        type='font/woff2'
        crossOrigin='anonymous'
      />
      <style id='responsive-breakpoints' type='text/css' dangerouslySetInnerHTML={{ __html: mediaStyles }} />
    </Head>
  )
}

export interface IHeadMeta {
  openGraphData?: ABTypes.Pageviews.OpenGraphData,
  externalTitle?: string,
  expiryDate?: string,
  type?: string,
  description?: string,
  longParagraphText?: string,
  artistName?: string,
  displayName?: string,
  host?: string,
  query?: string,
  title?: string
}

export default HeadMeta