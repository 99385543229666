import PropTypes from 'prop-types'
import styled from 'styled-components'
import { px2rem } from '@utils'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { Icon } from '@mch-group/uikit-components'
import { getSiteLanguage } from '@utils/storage'

const CircleButton = (props) => {
  const { labels: apiLabels = {} } = useLabelsFromAPI(['AltTags'], getSiteLanguage())
  const { AltTags } = apiLabels
  return (
    <button {...props} title={AltTags?.addToCollection}>
      <Icon
        name='collections-add'
        color='var(--bs-mch-white)'
        //@ts-ignore
        size='20'
      />
    </button>
  )
}



const StyledCircleButton = styled(CircleButton)`
  &:hover {
    background: var(--bs-mch-gray-500);
  }

  border-radius: 50%;
  width: ${px2rem(26.67)};
  height: ${px2rem(26.67)};
  background: var(--bs-mch-black);
  border: none;
  outline: none;
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  ${(props) => (props.$isSmall ? 'transform: scale(0.8);' : '')}

  div {
    display: flex !important;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
`

CircleButton.propTypes = {
  className: PropTypes.string
}

export default StyledCircleButton
