import React, { FC } from 'react'
import { MediaCaption, Container, Row, Col } from '@mch-group/uikit-components'
import { Media, MediaContextProvider } from '@responsive'
import { useEditorialModule } from '@hooks'
import IEMImageMosaic from '@modules/EMImageMosaic/types'
import StyledMediaCaption from './StyledMediaCaption'

const ThreeImagesStructured: FC<IEMImageMosaic> = ({
  emim_image_upload_1,
  emim_image_upload_2,
  emim_image_upload_3,
  emim_image_caption_1,
  emim_image_caption_2,
  emim_image_caption_3
}) => {
  const { getImageUrl, ar } = useEditorialModule()
  return (
    <MediaContextProvider>
      <Media lessThan='md'>
        <StyledMediaCaption ar={ar(emim_image_upload_1)}>
          <MediaCaption
            caption={!!emim_image_caption_1}
            captionText={emim_image_caption_1}
            imageAltText={emim_image_caption_1}
            urlImage={getImageUrl(emim_image_upload_1, 752, '')}
            fullWidth
            media='image'
          />
        </StyledMediaCaption>
        <Container className='px-5 px-lg-8 px-md-7 mt-9'>
          <Row gutter='g-5 g-md-6 g-xl-7 mb-0'>
            <Col xs={12} className='mb-7'>
              <MediaCaption
                caption={!!emim_image_caption_2}
                captionText={emim_image_caption_2}
                imageAltText={emim_image_caption_2}
                urlImage={getImageUrl(emim_image_upload_2, 752, '1:1')}
                className='mb-0 pb-0'
                fullWidth={false}
                media='image'
              />
            </Col>
            <Col xs={12}>
              <MediaCaption
                caption={!!emim_image_caption_3}
                captionText={emim_image_caption_3}
                imageAltText={emim_image_caption_3}
                urlImage={getImageUrl(emim_image_upload_3, 752, '1:1')}
                className='mb-0 pb-0'
                fullWidth={false}
                media='image'
              />
            </Col>
          </Row>
        </Container>
      </Media>
      <Media greaterThanOrEqual='md'>
        <Container className='px-5 px-lg-8 px-md-7 mt-0'>
          <Row>
            <Col
              md={{ span: 10, offset: 1 }}
              lg={{ span: 8, offset: 2 }}
              xl={{ span: 10, offset: 1 }}
              xxl={{ span: 10, offset: 1 }}
              className='mb-9 mb-md-8'
            >
              <MediaContextProvider>
                <Media at='md'>
                  <StyledMediaCaption ar={ar(emim_image_upload_1)}>
                    <MediaCaption
                      caption={!!emim_image_caption_1}
                      captionText={emim_image_caption_1}
                      imageAltText={emim_image_caption_1}
                      urlImage={getImageUrl(emim_image_upload_1, 796)}
                      fullWidth={false}
                      media='image'
                    />
                  </StyledMediaCaption>
                </Media>
                <Media at='lg'>
                  <StyledMediaCaption ar={ar(emim_image_upload_1)}>
                    <MediaCaption
                      caption={!!emim_image_caption_1}
                      captionText={emim_image_caption_1}
                      imageAltText={emim_image_caption_1}
                      urlImage={getImageUrl(emim_image_upload_1, 789)}
                      fullWidth={false}
                      media='image'
                    />
                  </StyledMediaCaption>
                </Media>
                <Media at='xl'>
                  <StyledMediaCaption ar={ar(emim_image_upload_1)}>
                    <MediaCaption
                      caption={!!emim_image_caption_1}
                      captionText={emim_image_caption_1}
                      imageAltText={emim_image_caption_1}
                      urlImage={getImageUrl(emim_image_upload_1, 1208)}
                      fullWidth={false}
                      media='image'
                    />
                  </StyledMediaCaption>
                </Media>
                <Media greaterThanOrEqual='xxl'>
                  <StyledMediaCaption ar={ar(emim_image_upload_1)}>
                    <MediaCaption
                      caption={!!emim_image_caption_1}
                      captionText={emim_image_caption_1}
                      imageAltText={emim_image_caption_1}
                      urlImage={getImageUrl(emim_image_upload_1, 1221)}
                      fullWidth={false}
                      media='image'
                    />
                  </StyledMediaCaption>
                </Media>
              </MediaContextProvider>
            </Col>
            <Col
              md={{ span: 5, offset: 1 }}
              lg={{ span: 4, offset: 2 }}
              xl={{ span: 5, offset: 1 }}
              xxl={{ span: 5, offset: 1 }}
              className='pe-0'
            >
              <MediaContextProvider>
                <Media at='md'>
                  <StyledMediaCaption ar='1/1'>
                    <MediaCaption
                      caption={!!emim_image_caption_2}
                      captionText={emim_image_caption_2}
                      imageAltText={emim_image_caption_2}
                      urlImage={getImageUrl(emim_image_upload_2, 398, '1:1')}
                      fullWidth={false}
                      media='image'
                    />
                  </StyledMediaCaption>
                </Media>
                <Media at='lg'>
                  <StyledMediaCaption ar='1/1'>
                    <MediaCaption
                      caption={!!emim_image_caption_2}
                      captionText={emim_image_caption_2}
                      imageAltText={emim_image_caption_2}
                      urlImage={getImageUrl(emim_image_upload_2, 395, '1:1')}
                      fullWidth={false}
                      media='image'
                    />
                  </StyledMediaCaption>
                </Media>
                <Media at='xl'>
                  <StyledMediaCaption ar='1/1'>
                    <MediaCaption
                      caption={!!emim_image_caption_2}
                      captionText={emim_image_caption_2}
                      imageAltText={emim_image_caption_2}
                      urlImage={getImageUrl(emim_image_upload_2, 604, '1:1')}
                      fullWidth={false}
                      media='image'
                    />
                  </StyledMediaCaption>
                </Media>
                <Media greaterThanOrEqual='xxl'>
                  <StyledMediaCaption ar='1/1'>
                    <MediaCaption
                      caption={!!emim_image_caption_2}
                      captionText={emim_image_caption_2}
                      imageAltText={emim_image_caption_2}
                      urlImage={getImageUrl(emim_image_upload_2, 611, '1:1')}
                      fullWidth={false}
                      media='image'
                    />
                  </StyledMediaCaption>
                </Media>
              </MediaContextProvider>
            </Col>
            <Col
              md={{ span: 5, offset: 0 }}
              lg={{ span: 4, offset: 0 }}
              xl={{ span: 5, offset: 0 }}
              xxl={{ span: 5, offset: 0 }}
              className='ps-0'
            >
              <MediaContextProvider>
                <Media at='md'>
                  <StyledMediaCaption ar='1/1'>
                    <MediaCaption
                      caption={!!emim_image_caption_3}
                      captionText={emim_image_caption_3}
                      imageAltText={emim_image_caption_3}
                      urlImage={getImageUrl(emim_image_upload_3, 398, '1:1')}
                      fullWidth={false}
                      media='image'
                    />
                  </StyledMediaCaption>
                </Media>
                <Media at='lg'>
                  <StyledMediaCaption ar='1/1'>
                    <MediaCaption
                      caption={!!emim_image_caption_3}
                      captionText={emim_image_caption_3}
                      imageAltText={emim_image_caption_3}
                      urlImage={getImageUrl(emim_image_upload_3, 395, '1:1')}
                      fullWidth={false}
                      media='image'
                    />
                  </StyledMediaCaption>
                </Media>
                <Media at='xl'>
                  <StyledMediaCaption ar='1/1'>
                    <MediaCaption
                      caption={!!emim_image_caption_3}
                      captionText={emim_image_caption_3}
                      imageAltText={emim_image_caption_3}
                      urlImage={getImageUrl(emim_image_upload_3, 604, '1:1')}
                      fullWidth={false}
                      media='image'
                    />
                  </StyledMediaCaption>
                </Media>
                <Media greaterThanOrEqual='xxl'>
                  <StyledMediaCaption ar='1/1'>
                    <MediaCaption
                      caption={!!emim_image_caption_3}
                      captionText={emim_image_caption_3}
                      imageAltText={emim_image_caption_3}
                      urlImage={getImageUrl(emim_image_upload_3, 611, '1:1')}
                      fullWidth={false}
                      media='image'
                    />
                  </StyledMediaCaption>
                </Media>
              </MediaContextProvider>
            </Col>
          </Row>
        </Container>
      </Media>
    </MediaContextProvider>
  )
}

export default ThreeImagesStructured
