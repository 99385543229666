export const ROOMS_TAB = 'rooms'
export const ARTWORKS_TAB = 'artworks'
export const GALLERIES_TAB = 'galleries'
export const ARTISTS_TAB = 'artists'
export const EVENTS_TAB = 'events'
export const NEWS_TAB = 'news'
export const SPECIALROOMS_TAB = 'specialRooms'
const TABS = {
  artworks: {
    id: ARTWORKS_TAB,
    entityType: '2',
    fields: 'artistName,artistCleanTitle,galleryId,galleryCleanTitle,galleryName,cleanTitle,displayName,priceInUsd,showFullName,showYear,showName,sponsorGalleries,sponsorArtists,images,year,endYear,toPrice,fromPrice,secondCurrency,priceInSecondCurrency,sold,viewAtFair,reservedArtwork'
  },
  rooms: {
    id: ROOMS_TAB,
    entityType: '4c',
    fields: 'hybridShow,images,sectors,eventImage,title,year,ownerAccount,id,cleanTitle,openingDateTimes,shortParagraphText,accessPermission,mainSectors,additionalSectors'
  },
  specialRooms: {
    id: SPECIALROOMS_TAB,
    entityType: '4d',
    fields: ''
  },
  galleries: {
    id: GALLERIES_TAB,
    entityType: '1',
    fields: ''
  },
  artists: {
    id: ARTISTS_TAB,
    entityType: '3',
    fields: ''
  },
  events: {
    id: EVENTS_TAB,
    entityType: '4a,4b',
    fields: ''
  },
  news: {
    id: NEWS_TAB,
    entityType: '4'
  }
}

export default TABS
