import { string, func } from 'prop-types'
import styled from 'styled-components'
import { mq, px2rem } from '@utils'
import {
  Text
} from '@components'
import CallToAction from '@reusable-modules/common/CallToAction'

/**
 * This components allows you to wrap a text, and its height will be automatically shortened if it's over a certain
 * number of height pixels. Currently this number is hardcoded to 300. Can be customized in future versions of this
 * component
 * @param {*} id: used to avoid problems in case of 2 MaskText components in one page, so id is unique.
 */
const MaskText = ({ id, className, text, label, onClick }) => {
  // @ts-ignore
  const currentText = React.useRef(text)
  const initialState = {
    foldStatus: '',
    isFirstLoad: true,
    isToggleNeeded: false,
    debug: false
  }
  // @ts-ignore
  const [status, setStatus] = React.useState(initialState)

  // Cannot calculate div height in server, so, useEffect
  // @ts-ignore
  React.useEffect(() => {
    // Every time text changes, fold the Component. Also, first time text didn't change so it has to be triggered
    if (currentText.current !== text || status.isFirstLoad) {
      const wrappedText = document.querySelector(`#${id}`)
      // @ts-ignore
      const wrappedTextHeight = wrappedText.getBoundingClientRect().height
      const fullText = document.querySelector(`#${id} .text-block`)
      // @ts-ignore
      const fullTextHeight = fullText.getBoundingClientRect().height
      const newStatus = { ...status }
      newStatus.foldStatus = 'folded'
      newStatus.isFirstLoad = false
      newStatus.isToggleNeeded = false
      if (fullTextHeight > wrappedTextHeight) {
        newStatus.isToggleNeeded = true
      }
      setStatus(newStatus)
      currentText.current = text
    }
  }, [text, id, status])


  return (
    <div id={id} className={className}>
      <div className={`text ${status.foldStatus} ${(status.isToggleNeeded) ? 'toggle' : ''}`}>
        <Text className='text-block' isHtml>{text}</Text>
      </div>
      {status.isToggleNeeded &&
        // @ts-ignore
        <CallToAction noWordwrap hideArrow bold cursor='pointer' onClick={onClick}>
          {label}
        </CallToAction>
      }
    </div>
  )
}

const MaskTextStyled = styled(MaskText)`
  .text {
    /* Some of these Mqueries are repetitive, not for "representation", change if needed. */
    ${mq.greaterThan('wide')`
      height: ${px2rem(123)};
    `}

    ${mq.between('breakpoint1680', 'largestLarge')`
      height: ${px2rem(76)};
    `}

    ${mq.between('breakpoint1366', 'breakpoint1479')`
      height: ${px2rem(76)};
    `}

    ${mq.between('breakpoint1480', 'breakpoint1679')`
      height: ${px2rem(76)};
    `}

    /* Client devices require exact px sizes, otherwise px2rem would make 58px not render actually 58px */
    ${mq.between('large', 'largestMedium')`
      height: 58px;
    `}

    ${mq.between('desktop', 'largestDesktop')`
      height: 58px;
    `}

    overflow: hidden;

    &.toggle {
      mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
    }

    &.unfolded {
      transform: scaleY(1);
      height: initial;
      mask-image: initial;
    }
  }

  [role='button'] {
    cursor: pointer;
    position: absolute;
  }
`

MaskText.propTypes = {
  id: string,
  className: string,
  text: string,
  label: string,
  onClick: func
}

export default MaskTextStyled
