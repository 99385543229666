import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NoSSR from 'react-no-ssr'
import { SharingButton } from '@mch-group/uikit-components'

const ActionsBar = ({ controls, className }) => (
  <div className={`actions-bar ${className}`}>
    {controls.map(
        (control) =>
          !control.hidden && (
            <React.Fragment key={control.key}>
              {control.key === 'Share' && (
                <NoSSR>
                  <SharingButton
                    size='lg'
                    variant='outline'
                    title='Share to social media'
                    media={['facebook', 'whatsapp', 'linkedin', 'twitter', 'weebo', 'wechat', 'mail', 'link-default']}
                  />
                </NoSSR>
              )}
              {control.key !== 'Share' && control.type === 'component' && (
                <div className='item component ms-3'>{control.component()}</div>
              )}
              {control.type === 'link' && (
                <div className='item link ms-3' title={controls.title}>
                  {control.text}
                  {control.icon()}
                </div>
              )}
            </React.Fragment>
          )
      )}
  </div>
  )

ActionsBar.propTypes = {
  controls: PropTypes.array,
  className: PropTypes.string
}

const StyledActionsBar = styled(ActionsBar)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: inherit;
`

export default StyledActionsBar
