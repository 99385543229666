import { TrackJS } from 'trackjs'
/* eslint-disable no-console, global-require */
const feConfig = (getEnvConfig && getEnvConfig.fe) || {}
const { fairName, theme } = feConfig
let initialized = false

/**
 * Configure and Setup TrackJS
 */
const tjsEnvOpts = {
  version: process?.env?.softwareVersion || 'n/a',
  console: {
    display: process.env.NODE_ENV !== 'production'
  },
  network: { error: false }, // network requests tracked in lib/fetch.js
  ...(feConfig.trackJs || {}),
  // hardcoding for now, should come from config
  token: 'e46fb9563cd44dbab295d5a8fbe6ca48'
}

const init = () => {
  TrackJS.install(tjsEnvOpts)
  initialized = true
  TrackJS.addMetadata('fair', fairName)
  TrackJS.addMetadata('theme', theme)
  TrackJS.addMetadata('SSR', typeof window === 'undefined' ? 'SSR' : 'No SSR')
  TrackJS.addMetadata('environment', process?.env?.environment ? process?.env?.environment : 'No ENV defined')
  TrackJS.addMetadata('NODE_ENV', process?.env?.NODE_ENV ? process?.env?.NODE_ENV : 'No NODE_ENV defined')
  TrackJS.addMetadata('application', feConfig?.trackJs?.application ? feConfig?.trackJs?.application : 'No application defined')
}

!initialized && init()

export const track = (log: string, response = {}): void => {
  try {
    TrackJS.console.debug(response)
    TrackJS.track(log)
  } catch (e) {
    console.log(log)
  }
}
