import { breakpoints } from './styled-mq'

const mqMatch = query => window.matchMedia(query).matches

const jsMq = {
  lessThan: breakpoint =>
    mqMatch(`(max-width: ${breakpoints[breakpoint]})`),

  greaterThan: breakpoint =>
    mqMatch(`(min-width: ${breakpoints[breakpoint]})`),

  between: (minBreakpoint, maxBreakpoint) =>
    mqMatch(`(min-width: ${breakpoints[minBreakpoint]}) and (max-width: ${breakpoints[maxBreakpoint]})`)
}

const calcResponsiveValue = ({ mobile, tablet, computer, largeScreen }) => {
  if (jsMq.lessThan('largestMobile')) {
    return mobile
  } else if (jsMq.lessThan('largestTablet')) {
    return tablet
  } else if (jsMq.lessThan('largestDesktop')) {
    return computer
  }
  return largeScreen
}

export default { mq: jsMq, calcResponsiveValue }
