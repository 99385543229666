import React from 'react'
import Head from 'next/head'
import { metadata } from '@constants'

const HeadComponent = React.memo(() => {
  let assetFolder = 'artbasel'
  const cdnURL = getEnvConfig.staticFolder || '/public/static'

  return (
    <Head>
      <meta charSet='utf-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <meta name='robots' content='max-image-preview: large' />
      {/* 3rd party resources */}
      <link rel='dns-prefetch' href='//res.cloudinary.com' />

      {/* <!--[if IE]> */}
      <link rel='shortcut icon' href={`${cdnURL}/favicon/${assetFolder}/${metadata[assetFolder].favicon.ico}`} />
      {/* <![endif]--> */}
      <meta
        name='msapplication-TileImage'
        content={`${cdnURL}/favicon/${assetFolder}/${metadata[assetFolder].microsoft.tileImage}`}
      />

      <meta name='apple-itunes-app' content={`app-id=${getEnvConfig.fe.nativeApp.id.ios}`} />
      <meta name='google-play-app' content={`app-id=${getEnvConfig.fe.nativeApp.id.android}`} />
      <link
        rel='apple-touch-icon'
        href={`${cdnURL}/favicon/${assetFolder}/${metadata[assetFolder].favicon.appleTouchIcon}`}
      />
      <link
        rel='android-touch-icon'
        href={`${cdnURL}/favicon/${assetFolder}/${metadata[assetFolder].favicon.appleTouchIcon}`}
      />

      <link
        rel='icon'
        type='image/png'
        href={`${cdnURL}/favicon/${assetFolder}/${metadata[assetFolder].favicon.size128}`}
        sizes='128x128'
      />
      <link
        rel='icon'
        type='image/png'
        href={`${cdnURL}/favicon/${assetFolder}/${metadata[assetFolder].favicon.size96}`}
      />

      <link
        rel='apple-touch-icon-precomposed'
        sizes='144x144'
        href={`${cdnURL}/favicon/${assetFolder}/${metadata[assetFolder].favicon.size144}`}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='114x114'
        href={`${cdnURL}/favicon/${assetFolder}/${metadata[assetFolder].favicon.size114}`}
      />
      <link
        rel='apple-touch-icon-precomposed'
        sizes='72x72'
        href={`${cdnURL}/favicon/${assetFolder}/${metadata[assetFolder].favicon.size72}`}
      />
      <link
        rel='apple-touch-icon-precomposed'
        href={`${cdnURL}/favicon/${assetFolder}/${metadata[assetFolder].favicon.size57}`}
      />
    </Head>
  )
})
HeadComponent.displayName = 'HeadComponent'

export default HeadComponent
