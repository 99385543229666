/** Changes the key of the objects in array. Example:
 * const arr = [{ id: 'val' }]
 * After calling renameKey(arr, 'id', 'code')
 * output will be [{ code: 'val' }]
 * Can be called several times, to change more than one key
 * Useful for transforming JSONs for example for Dropdowns.
 */
function renameKey(arr, oldKey, newKey) {
  return arr.map(obj => {
    obj[newKey] = obj[oldKey]
    delete obj[oldKey]
    return obj
  })
}

export default renameKey
