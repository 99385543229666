import styled from 'styled-components'
import sanitizeHtmlReact from 'sanitize-html-react'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import tokensRenamed from '@constants/tokensRenamed'
import { FC, ReactNode } from 'react'

function respectHtml(html, sanitizeHtml) {
  if (sanitizeHtml) {
    return sanitizeHtmlReact(html)
  }
  return html
}

const Text: FC<IText> = ({ sanitizeHtml = false, ...props }) => {
  const Element: any = props.as || 'div'

  if (props.isHtml) {
    return (
      <Element
        title={props.title}
        className={props.className}
        dangerouslySetInnerHTML={{
          __html: respectHtml(props.children, sanitizeHtml)
        }}
      />)
  }

  return <Element as={props.as || 'div'} className={props.className}>{props.children}</Element>
}

const TextStyled = styled(Text)<any>`
    font-family: ${({ isSmall }) => !isSmall && tokens.fontFamilies.artBaselText.value};
    font-size: ${({ isSmall }) => isSmall && tokens.fontSize[1].value};
    line-height: ${({ isSmall }) => isSmall && tokensRenamed.lineHeights[6].value};
    font-weight: ${({ isStrong }) => isStrong ? tokens.fontWeights.artBaselHeadline0.value : null};
    ${({ textColor }) => textColor ? `color: ${textColor};` : ''};
    ${({ inlineBlock }) => inlineBlock && 'display: inline-block;'}
    ${({ preWrap }) => preWrap && 'white-space: pre-wrap;'}
    ${({ fixNewLines }) => fixNewLines && 'white-space: pre-line;'}

    p:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &.softText {
      color: ${tokens.color.light.base.neutrals['700'].value};
    }
  `

interface IText {
  children: ReactNode,
  className: string,
  as: string,
  isHtml: boolean,
  fixNewLines: boolean,
  isPlaceHolder: boolean,
  isArticleText: boolean,
  isSoftText: boolean,
  sanitizeHtml: boolean,
  title: string
}

export default TextStyled
