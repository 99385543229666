import { FC } from 'react'
import { FormikConsumer } from 'formik'
import { Col, Button } from '@mch-group/uikit-components'
import { Text } from '@components'
import { useUserAccessContext } from '../context/useUserAccessContext'

interface IResendActivateAccountLink {
  activateAccountLabel: string
}

const ResendActivateAccountLink: FC<IResendActivateAccountLink> = ({ activateAccountLabel }) => {
  const { state: {
    apiErrorMessage,
    labels: userAccessLabels,
    otherData
  } } = useUserAccessContext()

  return(
    <FormikConsumer >
      {() => (
        <Col className={!otherData.isModal ? 'mt-0' : 'mt-9'}>
          {!otherData.isModal && <h4 className='pb-8'>{userAccessLabels.Access.verifyRegistrationHeaderLabel}</h4>}
          {otherData.isModal && <h5 className='pb-4'>{userAccessLabels.Access.verifyRegistrationHeaderLabel}</h5>}
          {activateAccountLabel &&
            <Text forwardedAs='p' isHtml className='mch-gray-500' >
              {activateAccountLabel}
            </Text>
          }
          {apiErrorMessage &&
            <span className='text-danger form-text mb-4'>{apiErrorMessage}</span>
          }
          <div className='d-grid mt-8'>
            <Button
              type='submit'
              className='next-button'
            >
              {userAccessLabels.Access.resendLinkBtnLabel}
            </Button>
          </div>
        </Col>
      )}
    </FormikConsumer >
  )
}

export default ResendActivateAccountLink
