/**
 * This component is used to check the password validation that password should have
 *  included these values i.e. 8 char min length, 1 uppercase and 1 numeric char in the password
 */

import { FC } from 'react'
import { Icon } from '@mch-group/uikit-components'

interface IPasswordSafetyCheck {
  passwordString: string,
  minCharLabel: string,
  upperCaseLabel: string,
  numericalCharLabel: string,
  passwordHeadingLabel: string,
  className: string,
  isPasswordValidated: boolean
}

/**
 * This component need some params and that are required.
 * @param {string} passwordHeadingLabel label for password heading.
 * @param {string} passwordString password enter by user.
 * @param {string} minCharLabel label for password length.
 * @param {string} upperCaseLabel label for uppercase characther.
 * @param {string} numericalCharLabel label for numericial characther.
 * @param {string}  isPasswordValidated boolean to check for password validated or not
 * @returns {JSX.Element}
 */

const PasswordSafetyCheck: FC<IPasswordSafetyCheck> = ({
  passwordHeadingLabel,
  passwordString,
  minCharLabel,
  upperCaseLabel,
  numericalCharLabel,
  className,
  isPasswordValidated
}) => {

  const isNumberRegx = /\d/ // Regex to check password should have 1 numeric char
  const upperCaseRegex = /^(.*[A-Z].*)$/ // Regex to check password should have 1 uppercase cahr

  const isValidPasswordLength = passwordString?.length >= 12
  const checkNumericalCharExist = isNumberRegx.test(passwordString)
  const checkUpperCaseCharExist = upperCaseRegex.test(passwordString)
  const passwordValidated = !isPasswordValidated ? 'var(--bs-mch-gray-300)' : 'var(--bs-mch-red)'
  return(
    <div className={`${className} password-meter text-left mb-4`}>
      <p className='text-medium'>{passwordHeadingLabel}</p>
      <div>
        <Icon
          color={isValidPasswordLength ? 'var(--bs-mch-green)' : passwordValidated}
          name='checkmark-checkbox'
        />
        <p className='text-medium d-inline-block'>{minCharLabel}</p>
      </div>
      <div>
        <Icon
          color={checkUpperCaseCharExist ? 'var(--bs-mch-green)' : passwordValidated}
          name='checkmark-checkbox'
        />
        <p className='text-small d-inline-block'>{upperCaseLabel}</p>
      </div>
      <div>
        <Icon
          color={checkNumericalCharExist ? 'var(--bs-mch-green)' : passwordValidated}
          name='checkmark-checkbox'
        />
        <p className='text-small d-inline-block'>{numericalCharLabel}</p>
      </div>
    </div>
  )}

export default PasswordSafetyCheck
