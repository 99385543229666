/* Note this file is used in all the tabs under my collections we used this file to write down the common function
 that are used in different places but doing the same thing
*/

import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'

const useCollectionUtils = () => {
  //@ts-ignore
  const [{ selectedItems }, dispatch] = useMyCollectionsPageContext()

  const prepareUnavailableEventData = (event) => {
    let eventItem = {}
    const eventsTypes = []
    const eventsLabels = []
    const dateFields = event.summary?.extraInfo10 ? event.summary?.extraInfo10.split(',') : []
    const startTime = dateFields?.length > 0 ? dateFields[0].split(' ') : []
    const endTime = dateFields?.length > 0 ? dateFields[1].split(' ') : []
    const venueFields = event.summary?.extraInfo11 && event.summary?.extraInfo11.split(',')
    const eventsLabelsFields = event.summary?.extraInfo13 && event.summary?.extraInfo13.split(',')
    const eventsTypesFields = event.summary?.extraInfo14 && event.summary?.extraInfo14.split(',')

    eventsLabelsFields &&
      eventsLabelsFields.forEach((value, index) => {
        const eventLabelObj = {
          id: index,
          title: value
        }
        //@ts-ignore
        eventsLabels.push(eventLabelObj)
      })

      eventsTypesFields &&
      eventsTypesFields.forEach((value, index) => {
        const eventTypeObj = {
          id: index,
          type: value
        }
        //@ts-ignore
        eventsTypes.push(eventTypeObj)
      })
    eventItem = {
      id: event.entityId,
      title: event.summary?.name,
      cleanTitle: event.summary?.extraInfo6,
      accessPermission: event.summary?.extraInfo12,
      eventLabels: eventsLabels,
      eventTypes: eventsTypes,
      ownerAccount: { name: event?.summary?.extraInfo1 } ,
      virtual: !(event.summary?.extraInfo3 === 'false'),
      eventImage: event.summary?.image,
      exhibition : !(event.summary?.extraInfo15 === 'false')
    }
    if(dateFields) {
      eventItem = {
        ...eventItem,
        startDate: dateFields?.length > 0 ? dateFields[0] : '',
        endDate:dateFields?.length > 0 ? dateFields[1] : '',
        openingDateTimes: [{
          id: event?.entityId || '',
          startTime: startTime?.length > 0 ? startTime[1] : '',
          endTime: endTime?.length>0 ? endTime[1] : ''
        }]
      }
    }
    if(venueFields) {
      eventItem = {
        ...eventItem,
        cityName: venueFields?.length > 0 && venueFields[0],
        venue: {
          name:  venueFields?.length > 0 && venueFields[3],
          street:  venueFields?.length > 0 && venueFields[2] === ' ' ? '' : venueFields[2] || venueFields[3],
          city:  venueFields?.length > 0 && venueFields[1] === ' ' ? '' : venueFields[3] ? venueFields[3] : ''
        }
      }
    }
    return eventItem
  }
  // this is used to check the index of the selected organize item , if not present then add the same item in context
  const isItemSelected = (arr, id) => arr.findIndex(obj => obj.entityId === id)

  // this is used for organize mode
  const onSelectCard = (id, entityType, subEntityId) => {
    const currentSelection = [...selectedItems]
    const objIndex = currentSelection?.length > 0 ? isItemSelected(currentSelection, id) : -1
    if (objIndex === -1) {
      if(subEntityId) {
        currentSelection.push({ entityId: id, entityType, subEntityId })
      } else {
        currentSelection.push({ entityId: id, entityType })
      }
    } else {
      currentSelection.splice(objIndex, 1)
    }
    dispatch({ type: 'SET_SELECTED_ITEMS', payload: currentSelection })
  }

  return {
    onSelectCard,
    prepareUnavailableEventData
  }
}

export default useCollectionUtils
