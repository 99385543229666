import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { isExternalLink } from '@utils'

import Text from '../../Text'
import isSpecialUrl from '../../../utils/isSpecialUrl'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import { getLinkRel } from '@utils'
import { Icon } from '@mch-group/uikit-components'

const mID = 'call-to-action'

const CTA: FC<ICTA> = ({ className, children, hideArrow, ssr, direction = 'chevron-right', ...props }) => {
  // Links of the like of 'https://google.com', open in a new window
  const isExternal = props.hrefAs && isExternalLink(props.hrefAs)
  if (isExternal) {
    return (
      <a
        className={`${mID} ${className}`}
        target='_blank'
        title={props.title}
        href={props.hrefAs}
        rel={getLinkRel(props.hrefAs || '')}
      >
        <Text isSmall forwardedAs='span'>
          {children}
        </Text>
        {!hideArrow && <Icon name={direction} />}
      </a>
    )
  }

  // Links of the like of... check constants dependancy in ../utils/specialUrls, open in same window, but with refresh

  const isInternalWithRefresh = props.hrefAs && isSpecialUrl(props.hrefAs)
  if (isInternalWithRefresh || ssr) {
    return (
      <a className={`${mID} ${className}`} title={props.title} href={props.hrefAs}>
        <Text isSmall forwardedAs='span'>
          {children}
        </Text>
        {!hideArrow && <Icon name={direction} />}
      </a>
    )
  }

  // Links that open in the same window, asynchronously
  const isInternal = props.href
  if (isInternal) {
    return (
      <Link href={props.href} as={props.hrefAs} legacyBehavior>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className={`${mID} ${className}`} title={props.title}>
          <Text isSmall forwardedAs='span'>
            {children}
          </Text>
          {!hideArrow && <Icon name={direction} />}
        </a>
      </Link>
    )
  }

  // No link
  if (!props.href && !props.onClick) {
    return (
      <span className={`${mID} ${className}`}>
        <Text isSmall forwardedAs='span'>
          {children}
        </Text>
        {!hideArrow && <Icon name={direction} />}
      </span>
    )
  }

  // For buttons
  if (props.onClick) {
    return (
      <span
        role='button'
        tabIndex={0}
        onClick={props.onClick}
        onKeyUp={props.onClick}
        className={`${mID} ${className}`}
      >
        <Text isSmall forwardedAs='span'>
          {children}
        </Text>
        {!hideArrow && <Icon name={direction} />}
      </span>
    )
  }
  return null
}

interface ICTA {
  href: string,
  title?: string,
  text?: string,
  className?: string,
  children: ReactNode,
  hrefAs?: string,
  hideArrow?: boolean,
  ssr?: boolean,
  onClick?: () => {},
  direction?: string
}

const StyledCTA = styled(CTA)<any>`
  position: relative;
  width: 100%;
  display: ${(props) => (props.inline ? 'inline' : 'flex')};
  text-decoration: ${(props) => (props.textDecoration ? 'underline' : 'none')} !important;
  align-items: center;
  font-weight: ${(props) => (props.bold ? `${tokens.fontWeights.artBaselText2.value} !important` : null)};
  ${(props) => props.noWordwrap && 'white-space: nowrap;'}
  ${(props) =>
    !props.hideArrow
      ? `span {
      margin-right: ${MFP.vars['6px']};
      font-weight: ${tokens.fontWeights.artBaselHeadline0.value};
    }`
      : ''}

  footer span {
    font-weight: ${tokens.fontWeights.artBaselHeadline0.value};
  }

  span {
    font-size: ${(props) => (props.small ? MFP.vars['12px'] : null)};
    text-decoration: ${(props) => (props.textDecoration ? 'underline' : 'none')};
    ${(props) => props.white && `color: ${tokens.color.base.primary.white.value};`}
  }

  svg {
    ${(props) => props.white && `fill: ${tokens.color.base.primary.white.value};`}
  }

  &:hover {
    color: var(--bs-mch-black);

    svg {
      fill: var(--bs-mch-gray-500);
      ${(props) => props.white && `fill: ${tokens.color.base.primary.white.value};`}
    }
  }

  ${(props) => props.cursor && `cursor: ${props.cursor}`}
`

// eslint-disable-next-line react/prop-types
const callToAction = ({ as = 'link', ...props }) => {
  const hrefAs = as
  return <StyledCTA hrefAs={hrefAs} {...props} />
}

export default callToAction
