import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import useCollectionUtils from '@modules/collections/hooks/useCollectionUtils'
import IntersectionCapture from '@reusable-modules/IntersectionCapture'
import ArtistItem from '@modules/Catalog/Artists/ArtistItem'
import { Col, Row } from '@mch-group/uikit-components'
import { Loader } from '@components'
import TabContentHeader from '../TabContentHeader'

const ArtistsTab = (props) => {
  const {
    editPreviewCollectionItems,
    isRefreshing,
    isEmpty,
    isLoadingInitialData,
    editPreviewCollectionItemsValidating,
    setSize,
    size,
    isLoadingMore,
    totalItems,
    isTabExist
  } = props
  // @ts-ignore
  const [{ labels, isOrganizeMode, selectedItems }] = useMyCollectionsPageContext()
  const { onSelectCard } = useCollectionUtils()

  const isIntersectionActive =
    editPreviewCollectionItems &&
    !editPreviewCollectionItemsValidating &&
    !isRefreshing &&
    !isEmpty &&
    !isLoadingInitialData &&
    !isLoadingMore

  const loadCards = () =>
    editPreviewCollectionItems.map((artist) => (
      <Col xs={12} sm={12} md={6} lg={4} xl={3} xxl={3} className='mb-lg-8 mb-md-8 d-flex' key={artist.id}>
        <div className='w-100'>
          <ArtistItem
            id={artist.item.id}
            cleanTitle={artist.item.cleanTitle}
            name={artist.item.name}
            imageUrl={artist.item.imageUrl}
            isOrganizeMode={isOrganizeMode}
            onSelectCard={onSelectCard}
            isSelectedInCollections={
              typeof selectedItems?.find(
                (x) => x.entityId?.toString() === (artist?.id?.toString() || artist?.entityId?.toString())
              ) !== 'undefined'
            }
            labels={{
              imageAlt: labels?.AltTags?.generalReadMoreArtist,
              addBtn: labels?.AltTags?.addToCollection,
              ariaLabel: labels?.AltTags?.generalReadMoreAbout
            }}
          />
        </div>
      </Col>
    ))
  return (
    <div>
      <TabContentHeader
        total={totalItems}
        counterSubText={labels?.Collections.totalArtistsLabel}
        isTabExist={isTabExist}
      />
      {totalItems > 0 && (
        <>
          <Row>{loadCards()}</Row>
          <IntersectionCapture
            // @ts-ignore
            onIntersect={() => setSize(size + 1)}
            active={isIntersectionActive}
            params={{ rootMargin: '0px' }}
          />
        </>
      )}
      {(isLoadingInitialData || isLoadingMore) && (
        <LoaderContainer>
          <Loader active />
        </LoaderContainer>
      )}
    </div>
  )
}
ArtistsTab.propTypes = {
  editPreviewCollectionItems: PropTypes.array,
  isRefreshing: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isEmpty: PropTypes.bool,
  isLoadingInitialData: PropTypes.bool,
  editPreviewCollectionItemsValidating: PropTypes.bool,
  setSize: PropTypes.func,
  size: PropTypes.number,
  isLoadingMore: PropTypes.bool,
  totalItems: PropTypes.number,
  isTabExist: PropTypes.bool
}

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`
export default ArtistsTab
