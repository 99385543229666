// @ts-ignore
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { cloudinary } from '@constants'

// get image by tag, e.g. main-panoramic
export const getImageByTag = (images, tag) => images?.find(image => image?.tag === tag)

export const findImageUrl = (images, imageType, params) => {
  const findImageByTag = getImageByTag(images, imageType)
  const placeholder = `${cloudinary.UPLOAD}/v1599746309/gray-placeholder_wkdex3.jpg` /* we might need more placeholders*/
  // @ts-ignore
  return findImageByTag?.url ? uploadTransformationUrl(getImageByTag(images, imageType).url,params) : placeholder
}

interface IGetEventImage {
  hybridShow: boolean | undefined,
  eventImage: string | null | undefined,
  images: string[] | null,
  imageType: 'main-regular' | 'main-mobile' | 'video-regular' | 'video-panoramic' | 'main-panoramic' | 'unavailable-view' | 'video-mobile',
  size: 'small' | 'rectangle-main' | 'square' | 'medium' | 'preview-module' | null,
  isRectangularMainParams?: boolean
}
// request the image url for an event
export const getEventImage = ({
  hybridShow,
  eventImage,
  images,
  imageType,
  size,
  isRectangularMainParams
}: IGetEventImage): string => {
  let imageUrl: string | null = null
  const placeholder = `${cloudinary.UPLOAD}/v1599746309/gray-placeholder_wkdex3.jpg`
  let regularCardParams = 'ar_16:10,c_fill,dpr_1.0,f_jpg,g_auto,q_auto:good,w_514'
  const regularDesktopParams = 'ar_16:10,c_fill,dpr_1.0,f_jpg,g_auto,q_auto:good,w_1514'
  const panoramicDesktopParams = 'ar_21:7,c_fill,dpr_1.0,f_jpg,g_auto,q_auto:good,w_1600'
  const rectangularDesktopParams = 'ar_16:9,c_fill,dpr_2.0,f_jpg,g_auto,q_60,w_1600'
  const mobileParams = 'w_600'
  const rectangularMainParams = 'q_auto:good,ar_16:21,w_500'
  const previewModuleParams = 'ar_16:10,c_fill,dpr_1.0,f_jpg,g_auto,q_auto:good,w_1514'
  const rectangularUnavilableMainParams = 'q_auto:good,ar_16:18,w_500'
  const squareImage = 'c_fill,ar_1:1,q_auto:good,dpr_1.0,f_jpg,g_auto,w_716'
  // Does this event belongs to a Hybrid Show?
  if (hybridShow) {
    // is it an hybrid room?
    if (images && images.length > 0) {
      const findImageByTag = getImageByTag(images, imageType)
      // which type of image?
      if (imageType === 'main-regular' || imageType === 'video-regular') {
        // cloudinary params variations (width, height, cropping, etc.)
        if(size === 'rectangle-main') {
          imageUrl = findImageUrl(images, imageType, 'q_auto:good,ar_16:21,w_600')
        } else if (size === 'small') {
          imageUrl = findImageUrl(images, imageType, regularCardParams)
        } else if (size === 'medium') {
          imageUrl = findImageUrl(images, imageType, regularDesktopParams)
        } else if (size === 'preview-module') {
          imageUrl = findImageUrl(images, imageType, previewModuleParams)
        } else {
          imageUrl = findImageUrl(images, imageType, regularDesktopParams)
        }
      }
      if (imageType === 'main-panoramic' || imageType === 'video-panoramic') {
        const clParams = isRectangularMainParams ? rectangularDesktopParams : panoramicDesktopParams
        imageUrl = findImageByTag ?
          // @ts-ignore
          uploadTransformationUrl(getImageByTag(images, imageType).url,clParams) : placeholder
      }
      if (imageType === 'main-mobile' || imageType === 'video-mobile') {
        imageUrl = findImageByTag ?
          // @ts-ignore
          uploadTransformationUrl(getImageByTag(images, imageType).url, size === 'square' ? squareImage : mobileParams) : placeholder
      }
    } else {
        if(eventImage) {
          if(imageType === 'unavailable-view') {
            // @ts-ignore
            imageUrl = uploadTransformationUrl(eventImage, rectangularUnavilableMainParams)
          } else if(isRectangularMainParams) {
            // @ts-ignore
            imageUrl = uploadTransformationUrl(eventImage,rectangularMainParams)
          } else {
            // @ts-ignore
            imageUrl = uploadTransformationUrl(eventImage,regularCardParams)
          }
        } else {
          imageUrl = placeholder
        }
      }
  } else {
    // Else its not hybrid room...
    // does this event has image?
    if (eventImage) {
      if (size === 'small') {
        imageUrl = uploadTransformationUrl(eventImage,regularCardParams)
      }
      if (size === 'medium') {
        imageUrl = eventImage
      }
      if(size === 'square'){
        imageUrl = uploadTransformationUrl(eventImage,squareImage)
      }
    } else {
      // if this event doesnt have image
      imageUrl = placeholder
    }
  }
  return cloudinary.throughCloudfront(imageUrl)
}

export default {}
