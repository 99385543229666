import http from './_http'
/****** DON'T MODIFY THIS FILE, EXCEPT FOR REMOVING LINES. IT'S DEPRECATED !!!  */

/**
 * usedLabelEntities is DEPRECATED. this list was not supposed to grow.
 * This list makes the JSON in the source code grow, and makes site heavier.
 * use "useLabelsFromAPI" instead.
 */

const usedLabelEntities = 'ArtworkUploader,VideoPlayer,' //can be removed after the (app/src/components/reusable-modules/VideoM/VideoJSPlayer.js) component will be converted to functional component

const Translations = {
  getTranslationsByEntityType: (entityType, locale) =>
    http.get(`/msvc/v1/translations/${entityType}?locales=${locale}`),
  getTranslationsByEntityId: (id, locale) =>
    http.get(`/msvc/v1/translations/Label?entityIds=${id}&locales=${locale}`),
  getWebsiteLabelValues: (locale) =>
    http.get(`/msvc/v1/translations/values/Label/${usedLabelEntities}/allFields/${locale}`),
  getDynamicLabelValue: (entityId, fieldName, locale, params) =>
    http.get(`/msvc/v1/translations/values/Label/${entityId}/${fieldName}/${locale}/value?params=${params}`)
}

export default Translations
