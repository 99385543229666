import { FC, useContext } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { LoginContext } from '@providers/Login'
import { Modal, Button, Row, Col, Checkbox } from '@mch-group/uikit-components'

interface ITermsAndConditionsPopup {
  handleSubmitTC: Function,
  handleLogoutUser: Function
}

const TermsAndConditionsPopup: FC<ITermsAndConditionsPopup> = ({ handleSubmitTC, handleLogoutUser }) => {
  const { isLoadingTCData, labels } = useContext(LoginContext)
  if (!labels) return null
  return (
    // @ts-ignore
    <Modal wizard size='sm' show >
      {/** @ts-ignore */}
      <Formik
        initialValues={{ termsCondition: false }}
        validationSchema={() =>
          Yup.object().shape({
            termsCondition: Yup.string().oneOf(['true'], labels?.Access.termscondtionWarningLabel)
          })
        }
        onSubmit={(values) => {
          if (values.termsCondition) {
            handleSubmitTC(values.termsCondition)
          }
        }}
      >
        {({ handleChange, handleBlur, values, errors, touched }) =>
          <Row>
            <Col>
              <Modal.Header title={labels?.Access.termsAndConditionTitleLabel} showCloseButton={false} />
              <Form>
                <Modal.Body>
                  <p className='text-medium'>
                    {labels?.Access.tcAcceptTitleLabel}
                  </p>
                  <Checkbox
                    id='termsCondition'
                    label={labels?.Access.agreeLabel}
                    onChange={handleChange}
                    checked={values.termsCondition}
                    onBlur={handleBlur}
                    className='d-inline-block mt-7'
                    // @ts-ignore
                    status={errors.termsCondition && touched.termsCondition ? 'error' : ''}
                  />
                  <a href='/terms-of-use' target='_blank'>
                    <strong>{' '}{labels?.Access.termsAndConditionLinkLabel}</strong>
                  </a>
                  <div className='d-flex'>
                    {errors.termsCondition && touched.termsCondition && (
                      <span className='text-danger form-text mb-4 ms-6'>{errors.termsCondition}</span>
                    )}
                  </div>
                </Modal.Body>
                <div className='d-grid d-md-flex mt-8'>
                  <Button
                    variant='secondary'
                    onClick={() => { handleLogoutUser() }}
                    className='me-md-7 order-2 order-md-1'
                  >
                    {labels?.Access.logoutLabel}
                  </Button>
                  <Button type='submit' isLoading={isLoadingTCData} className='mb-5 mb-md-0  order-1 order-md-2'>
                    {labels?.Access.continueLabel}
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        }
      </Formik>
    </Modal>
  )
}

export default TermsAndConditionsPopup

