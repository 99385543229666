import { Container } from '@mch-group/uikit-components'
import PropTypes from 'prop-types'

const NoComponent = ({ ...props }) =>
  (process.env.NODE_ENV !== 'production' || !!getEnvConfig.fe.cms.showUnsupportedModules ? (
    <Container>
      <div className='segment'>
        Component not found for <strong>{props.moduleName}</strong> CMS module. <br />
        Either mapping is wrong or this module has no Frontend component yet.
      </div>
    </Container>
  ) : null)

NoComponent.propTypes = {
  moduleName: PropTypes.string,
  moduleId: PropTypes.number
}

export default NoComponent
