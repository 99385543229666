const linkToTitle = (link) => {
  let title = link.trim()
  const titleRemoves = ['http://', 'www.']

  titleRemoves.forEach((remove) => {
    const foundIndex = title.indexOf(remove)
    if (foundIndex === 0) title = title.substr(remove.length)
  })

  return title
}

export default linkToTitle
