import { FC, useContext } from 'react'
import { SpecialRoomCard } from '@mch-group/uikit-components'
import { formatSpecialRoomLink, formatArtworkTitle, cmsPageUrl } from '@utils'
import { LinkWithBackNavigation } from '@components'
import { AddToCollectionContext } from '@components/collections/AddToCollection'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { cloudinary } from '@constants'
import IEvent from '../types/IEvent'

const SpecialRoomCardItem: FC<ISpecialRoomCardItem> = ({
  item,
  isSelectedInCollections,
  isUserAllowed,
  callbackToReloadShow,
  isOrganizeMode,
  isCollectionCard,
  onSelectCard,
  theme,
  onClick,
  unavailableToView,
  entityId,
  isSpecialRoomArchived,
  disableBackNavigation,
  labels,
  navigationKey
}) => {
  const {
    eventImage,
    alreadyVisited,
    sectors,
    id,
    cleanTitle,
    openingDateTimes,
    artworks,
    onlineShow
  } = item as IEvent
  const prepareSectorData = (sectorsData: Array<ABTypes.Monolith.Sector> | string): Array<ABTypes.Monolith.Sector> => {
    const sectorDataIsString = typeof (sectorsData) == 'string'
    const sectorDataArray = sectorDataIsString ? sectorsData.split(' ') : sectorsData
    const Sectors: Array<ABTypes.Monolith.Sector> = []
    sectorDataArray && sectorDataArray.forEach((itemData, index) => {
      const obj = {
        id: sectorDataIsString ? index : itemData?.id,
        name: sectorDataIsString ? itemData : itemData?.name || ''
      }
      Sectors.push(obj)
    })
    return Sectors
  }
  const { showAddToCollectionModal } = useContext(AddToCollectionContext)
  const artistName = artworks?.length > 0 ? artworks[0]?.artists?.map((i) => i.name).join(', ') : ''
  const artistNames = isSpecialRoomArchived ? item?.artistName : artistName
  let showDataLabel = ''

  if (onlineShow) {
    showDataLabel = `${onlineShow?.name} ${onlineShow?.year ? `(${onlineShow?.year})` : ''}`
  } else {
    showDataLabel = `${item?.extraInfo9} ${item?.extraInfo16 ? `(${item?.extraInfo16})` : ''}`
  }
  const cardTitle = artworks?.length > 0 && artworks[0]?.displayName ? formatArtworkTitle(artworks[0]?.displayName, artworks[0]?.year, artworks[0]?.endYear) : ''
  const specialRoomTitle = artworks ? cardTitle : item?.extraInfo4

  if (!id) return null

  return (
    <SpecialRoomCard
      type='hybrid'
      // IEvent from swagger doesn't have an "image" prop, so what is that item.image?
      //@ts-ignore
      imageUrl={uploadTransformationUrl(unavailableToView ? item?.image : eventImage || item?.image, 'ar_4:5,c_fill,q_auto,w_716', {
        transformUrl: cloudinary.throughCloudfront
      })}
      artistName={artistNames || item?.extraInfo4}
      visited={{
        //@ts-ignore
        active: !isCollectionCard && alreadyVisited,
        label: labels?.ovrVisitedRoomLabel
      }}
      theme={theme}
      //@ts-ignore
      title={specialRoomTitle}
      imageAlt={`${labels?.generalViewSpecialRoom} ${specialRoomTitle}`}
      roomLink={formatSpecialRoomLink(id ? id : entityId,
        cleanTitle, openingDateTimes?.length ?
        openingDateTimes[0].id : null)}
      linkRenderer={(link, children, ariaLabel) => (
        <LinkWithBackNavigation
          passHref
          as={link}
          href={cmsPageUrl(link, '/specialsector', { id })}
          navigationKey={navigationKey}
          disableBackNavigation={disableBackNavigation}
          extraAction={onClick}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a aria-label={ariaLabel}>{children}</a>
        </LinkWithBackNavigation>
      )}
      a11yData={{
        addBtn: `${labels?.addToCollection || 'Add to collection'}`,
        ariaLabel: `${`${labels?.generalViewSpecialRoom}:` || 'View the Special Room:'}`
      }}
      //@ts-ignore
      sectorsData={prepareSectorData(sectors || item?.extraInfo8)}
      openingSoon={{
        active: !isUserAllowed,
        label: labels?.showComingSoonLabel
      }}
      selectMode={{
        //@ts-ignore
        active: isOrganizeMode,
        checked: isSelectedInCollections,
        onChange: () => {
          //@ts-ignore
          isOrganizeMode && onSelectCard(id ? id : entityId, '4d')
        }
      }}
      collection={{
        //@ts-ignore
        active: isCollectionCard,
        onClick: () => {
          showAddToCollectionModal(id, '4d', undefined, callbackToReloadShow)
        }
      }}
      unavailableToView={{
        //@ts-ignore
        active: unavailableToView,
        label: labels?.notAvailableForViewLabel
      }}
      show={{
        //@ts-ignore
        active: isCollectionCard,
        label: showDataLabel
      }}
    />
  )
}

interface ISpecialRoomCardItem {
  callbackToReloadShow?: () => void,
  disableBackNavigation?: boolean,
  entityId?: number,
  isCollectionCard?: boolean,
  isLiveShow?: boolean,
  isOrganizeMode?: boolean,
  isSelectedInCollections?: boolean,
  isSpecialRoomArchived?: boolean,
  isUserAllowed: boolean,
  index?: number,
  item?: IEvent,
  onClick?: () => void,
  onRoomCardClick?: () => void, // CAN BE REMOVED?
  onSelectCard?: () => void,
  showAddToCollection?: boolean,
  theme?: string,
  unavailableToView?: boolean,
  labels?: any,
  navigationKey?: string
}

export default SpecialRoomCardItem
