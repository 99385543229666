export const cmsSkeletonModules = {
  pageMenu: 'Page menus',
  title: 'Title',
  headerVideo: 'Video Player',
  secondaryHeader: 'SECOM'
}

// Connects the value "action" in the URL query string
// with the corresponding modules
export const urlActionMap = {
  changePassword: {
    moduleName: 'REP - Reset Password',
    moduleId: -10737
  },
  activateAccount: {
    moduleName: 'SIU - Sign Up',
    moduleId: -10733
  },
  createnewpassword: {
    moduleName: 'GENPWD - Generate Password Popup',
    moduleId: -10759
  },
  acceptinvitation: {
    moduleName: 'INVACC - Accept Invitation Popup',
    moduleId: -10797
  },
  declineinvitation: {
    moduleName: 'INVDEC - Decline Invitation Popup',
    moduleId: -10798
  },
  acceptinvitation_rsvp: {
    moduleName: 'EVD-RSVP attendance',
    moduleId: -10686
  },
  declineinvitation_rsvp: {
    moduleName: 'EVD-RSVP attendance',
    moduleId: -10686
  },
  eventdetails_rsvp: {
    moduleName: 'EVD-RSVP attendance',
    moduleId: -10686
  }
}

export default {}
