import React from 'react'
import axios from 'axios'
import useSWR from 'swr'
import { getFingerprint, getAgentKey, getAccessToken } from '@utils'

const fetcher = async (url) => {
  const userToken = getAccessToken()
  const headers = userToken ? { Authorization: `Bearer ${userToken}` } : {}
  const clientInfo = { 'agentKey': await getAgentKey(), 'fingerprint': await getFingerprint() }
  return axios.post(url, clientInfo, { headers }).then((response) => response.data)
}

function useTracking() {
  const [params, setRedeemTrackingParams] = React.useState({
    id: null,
    action: null,
    errorMessage: null
  })

  const validateRequest = () => {
    let errorMessageQs = params?.errorMessage ? `?errorMessage=${params?.errorMessage}` : ''
    if (params?.id && params?.action) {
      return `/msvc/v1/tracking/${params?.action}/${params?.id}${errorMessageQs}`
    }
    return null
  }

  const { data, isValidating, error } = useSWR(validateRequest(), fetcher)

  return {
    data,
    isValidating,
    error,
    setRedeemTrackingParams
  }
}

export default useTracking