import { Modal } from 'semantic-ui-react'
import styled, { createGlobalStyle } from 'styled-components'

const ModalCustom = (props) => (
  <>
    <FadeTransition />
    <Modal {...props} />
  </>
)

const FadeTransition = createGlobalStyle`
  @keyframes opacityTransition {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  .ui.dimmer {
    animation: opacityTransition 420ms;
  }
`

/* This modal appears in the bottom of the screen, with a fade In animation
used e.g. in Share Modal mobile */
const MobileBottomModal = styled(ModalCustom).withConfig({ componentId: 'dmi-ui-bottom-modal' })`
  &.ui.modal {
    bottom: 0;

    &.in-transition {
      transition: all 420ms;
      opacity: 0;
    }
  }
`

ModalCustom.propTypes = {}

export default MobileBottomModal