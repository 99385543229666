// @ts-nocheck
import qs from 'query-string'

import http from './_http'

const VipInvitation = {
  getVipInvitation: () =>
    http.get('/msvc/v1/invitation'),
  getVipAddress: guestEventCrmGuid =>
    http.get(`/msvc/v1/invitation/details/address?guestEventCrmGuid=${guestEventCrmGuid}`),
  updateVipAddress: (values, guestEventCrmGuid, addressTypeId, updateStatus) => {
    const params = qs.stringify({
      guestEventCrmGuid,
      deliveryType: addressTypeId,
      updateStatus
    })
    return http.post(`/msvc/v1/invitation/accept?${params}`, values)
  },
  declineInvitation: guestEventCrmGuid =>
    http.post(`/msvc/v1/invitation/decline?guestEventCrmGuid=${guestEventCrmGuid}`),

  accept: (data: {
    invitationId: string,
    invitationToken: string
  }): Promise<{
    data: {
      sessionSummary: ABTypes.Login.UserSummary,
      accessToken: string,
      errorCode: number,
      message: string
    }, ok: boolean
  }> =>
    http.post('/msvc/v1/invitation/oneClickAccept', data),

  decline: (data: {
    invitationId: string,
    invitationToken: string
  }): Promise<{
    data: {
      sessionSummary: ABTypes.Login.UserSummary,
      accessToken: string,
      errorCode: number,
      message: string
    }, ok: boolean
  }> =>
    http.post('/msvc/v1/invitation/oneClickDecline', data),

  mine: data =>
    http.get('/msvc/v1/invitation/mine', data),
  mineLatest: data =>
    http.get('/msvc/v1/invitation/mine/latest', data),
  bookSlots: (showId, data) =>
    http.post(`/msvc/v1/showslots/show/${showId}/bookings`, data)

}

export default VipInvitation
