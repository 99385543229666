/**
 * Given a URL, and a transformation (optional),
 * it creates a new URL that uses cloudfront URL
 * instead of Cloudinary, and adds the transformation, ex. w_100
 */

import { cloudinary } from '@constants'
import { uploadTransformationUrl } from '@dmi-mch/utils'

const cloudinary2cloudfront = (url: string, transformations = '') => {
  const cloudfrontURL = `https://${getEnvConfig.fe.cdn.cloudfrontKey}.cloudfront.net${getEnvConfig.fe.cdn.cloudinaryMappingPath}${getEnvConfig.fe.cloudinary.name}/image/fetch/`
  return `${cloudfrontURL}${transformations}/${uploadTransformationUrl(url, '', {
    transformUrl: cloudinary.throughCloudfront
  })}`
}

export default cloudinary2cloudfront