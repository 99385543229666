import { uploadTransformationUrl } from '@dmi-mch/utils'
import { stringReplacement } from '@utils'
import { cloudinary, metadata } from '@constants'
import { IHeadMeta } from '.'

const useHeadMeta = ({
  openGraphData,
  externalTitle,
  expiryDate,
  description,
  displayName,
  artistName,
  host,
  query,
  title
}: IHeadMeta) => {
  const site = 'artbasel'
  const gtmId = metadata[site]?.google?.gtm

  let gtmOneTrustScript = `
  // Define dataLayer and the gtag function.
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}

  // Default ad_storage to 'denied'.
  gtag('consent', 'default', {
        ad_storage: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        'wait_for_update': 500
     });
  `
  let gtmScript = `
    window.addEventListener('load', function(event) {
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MQSNM7C3');
    })`
  const pageTitle = title || openGraphData?.title || externalTitle || metadata[site].og.title

  const imageForSocialNetworks =
    (openGraphData?.imageUrl &&
      uploadTransformationUrl(openGraphData.imageUrl, 'w_500,q_auto', {
        transformUrl: cloudinary.throughCloudfront
      })) ||
    metadata[site]?.og?.image

  const getDescription = () => {
    const truncate = (source, size) => (source.length > size ? `${source.slice(0, size - 1)} (...)` : source)
    if (openGraphData?.description || description) {
      return truncate(stringReplacement(/<[^>]*>/g, '', openGraphData?.description || description), 150)
    } else if (displayName && artistName) {
      return `${displayName} by ${artistName}`
    }
    return ''
  }

  return {
    site,
    gtmScript,
    gtmOneTrustScript,
    pageTitle,
    imageForSocialNetworks,
    gtmId,
    host,
    query,
    expiryDate,
    openGraphData,
    getDescription
  }
}

export default useHeadMeta