// @ts-nocheck
import http from './_http'

const ContactForm = {
  read: (type, ids) => http.put(`/msvc/v1/contactform/reports/${type}/read`, { ids }, null),
  getAllArtwork: '/msvc/v1/contactform/reports/artwork',
  getAllNewsletter: '/msvc/v1/contactform/reports/subscription',
  getAllGeneral: '/msvc/v1/contactform/reports/account',
  archivedOrDeleteSalesInquires: params => {
    const requestMethod = params.type === 'archive' ? http.put : http.del
    return requestMethod(`/msvc/v1/contactform/artwork/${params.type}?creationDate=${params.date}&contactType=artwork`)
  },
  deleteNewsLetterInquires: params => http.del(`/msvc/v1/contactform/subscription/${params.type}?creationDate=${params.date}&contactType=subscription`)
}
export default ContactForm
