import React, { FC } from 'react'
import { MediaCaption, Row, Col } from '@mch-group/uikit-components'
import { Media, MediaContextProvider } from '@responsive'
import { useEditorialModule } from '@hooks'
import IEMImageMosaic from '@modules/EMImageMosaic/types'
import StyledMediaCaption from './StyledMediaCaption'

const FourImages: FC<IEMImageMosaic> = ({
  emim_image_upload_1,
  emim_image_upload_2,
  emim_image_upload_3,
  emim_image_caption_1,
  emim_image_caption_2,
  emim_image_caption_3,
  emim_image_upload_4
}) => {
  const { getImageUrl } = useEditorialModule()
  return (
    <Row gutter='g-5 g-md-6 g-xl-7 mb-0'>
      <Col
        xs={{ span: 12, offset: 0 }}
        sm={{ span: 12, offset: 0 }}
        md={{ span: 12, offset: 0 }}
        lg={{ span: 8, offset: 2 }}
        xl={{ span: 6, offset: 0 }}
        className='mb-3 mb-md-0'
      >
        <MediaContextProvider>
          <Media at='xs'>
            <StyledMediaCaption ar='16/9'>
              <MediaCaption
                imageAltText={emim_image_caption_1}
                urlImage={getImageUrl(emim_image_upload_1, 720, '16:9')}
                fullWidth={false}
                media='image'
                caption={false}
              />
            </StyledMediaCaption>
          </Media>
          <Media at='sm'>
            <StyledMediaCaption ar='16/9'>
              <MediaCaption
                imageAltText={emim_image_caption_1}
                urlImage={getImageUrl(emim_image_upload_1, 720, '16:9')}
                fullWidth={false}
                media='image'
                caption={false}
              />
            </StyledMediaCaption>
          </Media>
          <Media greaterThanOrEqual='tablet'>
            <StyledMediaCaption ar='16/9'>
              <MediaCaption
                imageAltText={emim_image_caption_1}
                urlImage={getImageUrl(emim_image_upload_1, 724, '16:9')}
                fullWidth={false}
                media='image'
                caption={false}
              />
            </StyledMediaCaption>
          </Media>
        </MediaContextProvider>
      </Col>
      <Col
        xs={{ span: 12, offset: 0 }}
        sm={{ span: 12, offset: 0 }}
        md={{ span: 12, offset: 0 }}
        lg={{ span: 8, offset: 2 }}
        xl={{ span: 6, offset: 0 }}
        className='mb-3 mb-md-0'
      >
        <MediaContextProvider>
          <Media at='xs'>
            <StyledMediaCaption ar='16/9'>
              <MediaCaption
                imageAltText={emim_image_caption_2}
                urlImage={getImageUrl(emim_image_upload_2, 365, '16:9')}
                fullWidth={false}
                media='image'
                caption={false}
              />
            </StyledMediaCaption>
          </Media>
          <Media at='sm'>
            <StyledMediaCaption ar='16/9'>
              <MediaCaption
                imageAltText={emim_image_caption_2}
                urlImage={getImageUrl(emim_image_upload_2, 720, '16:9')}
                fullWidth={false}
                media='image'
                caption={false}
              />
            </StyledMediaCaption>
          </Media>
          <Media greaterThanOrEqual='tablet'>
            <StyledMediaCaption ar='16/9'>
              <MediaCaption
                imageAltText={emim_image_caption_2}
                urlImage={getImageUrl(emim_image_upload_2, 724, '16:9')}
                fullWidth={false}
                media='image'
                caption={false}
              />
            </StyledMediaCaption>
          </Media>
        </MediaContextProvider>
      </Col>
      <Col
        xs={{ span: 12, offset: 0 }}
        sm={{ span: 12, offset: 0 }}
        md={{ span: 12, offset: 0 }}
        lg={{ span: 8, offset: 2 }}
        xl={{ span: 6, offset: 0 }}
        className='mb-3 mb-md-0'
      >
        <MediaContextProvider>
          <Media at='xs'>
            <StyledMediaCaption ar='16/9'>
              <MediaCaption
                imageAltText={emim_image_caption_3}
                urlImage={getImageUrl(emim_image_upload_3, 720, '16:9')}
                fullWidth={false}
                media='image'
                caption={false}
              />
            </StyledMediaCaption>
          </Media>
          <Media at='sm'>
            <StyledMediaCaption ar='16/9'>
              <MediaCaption
                imageAltText={emim_image_caption_3}
                urlImage={getImageUrl(emim_image_upload_3, 720, '16:9')}
                fullWidth={false}
                media='image'
                caption={false}
              />
            </StyledMediaCaption>
          </Media>
          <Media greaterThanOrEqual='tablet'>
            <StyledMediaCaption ar='16/9'>
              <MediaCaption
                imageAltText={emim_image_caption_3}
                urlImage={getImageUrl(emim_image_upload_3, 724, '16:9')}
                fullWidth={false}
                media='image'
                caption={false}
              />
            </StyledMediaCaption>
          </Media>
        </MediaContextProvider>
      </Col>
      <Col
        xs={{ span: 12, offset: 0 }}
        sm={{ span: 12, offset: 0 }}
        md={{ span: 12, offset: 0 }}
        lg={{ span: 8, offset: 2 }}
        xl={{ span: 6, offset: 0 }}
        className='mb-0'
      >
        <MediaContextProvider>
          <Media at='xs'>
            <StyledMediaCaption ar='16/9'>
              <MediaCaption
                urlImage={getImageUrl(emim_image_upload_4, 720, '16:9')}
                fullWidth={false}
                media='image'
                caption={false}
              />
            </StyledMediaCaption>
          </Media>
          <Media at='sm'>
            <StyledMediaCaption ar='16/9'>
              <MediaCaption
                urlImage={getImageUrl(emim_image_upload_4, 720, '16:9')}
                fullWidth={false}
                media='image'
                caption={false}
              />
            </StyledMediaCaption>
          </Media>
          <Media greaterThanOrEqual='tablet'>
            <StyledMediaCaption ar='16/9'>
              <MediaCaption
                urlImage={getImageUrl(emim_image_upload_4, 724, '16:9')}
                fullWidth={false}
                media='image'
                caption={false}
              />
            </StyledMediaCaption>
          </Media>
        </MediaContextProvider>
      </Col>
    </Row>
  )
}

export default FourImages
