import { Header } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { px2rem, hiDPIpx, mq } from '@utils'

const ModuleTitle = ({ title }) => (
  <StyledTitle>
    <Header as='h1'>{title}</Header>
  </StyledTitle>
)

ModuleTitle.propTypes = {
  title: PropTypes.string
}

const StyledTitle = styled.section`
  margin-bottom: ${px2rem(75)};
  ${mq.between('tablet', 'largestTablet')`
    margin-bottom: ${hiDPIpx(40)};
  `}

  ${mq.lessThan('largestMobile')`
    margin-bottom: ${px2rem(40)};
  `}
`

export default ModuleTitle