const ConvertWwwLinkToValidLink = (url) => {
  // Check if the URL starts with "www."
  if (url.startsWith('www.')) {
      // Prepend "//" to the URL
      return `//${ url}`
  }
  // Return the original URL if it doesn't start with "www."
  return url
}

export default ConvertWwwLinkToValidLink