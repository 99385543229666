import { useContext } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { EventCard } from '@mch-group/uikit-components'
import { AddToCollectionContext } from '@components/collections/AddToCollection'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { formatEventLink, formatDate, formatHour } from '@utils'
import { LinkWithBackNavigation } from '@components'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { cloudinary, date } from '@constants'
import { useBackNavigation } from '@hooks'
import { getSiteLanguage } from '@utils/storage'

const PRIVATE_INVITATION = 'PRIVATE_INVITATION'
const VIP = 'VIP'

const EventCardList = ({
  item,
  isSelectedInCollections,
  isCollectionCard,
  eventsLabels,
  unavailableToView,
  isOrganizeMode,
  notAvailableLabel,
  onSelectCard,
  entityId,
  entityType,
  subEntityId
}) => {
  const { eventImage, id, cleanTitle, openingDateTimes, exhibition, eventLabels, eventTypes } = item
  const organizerName = item?.organisers?.length > 0 ? item.organisers[0] : item?.ownerAccount?.name

  const { labels = {} } = useLabelsFromAPI(['EventTypes', 'EventsAndExhibitions', 'AltTags'], getSiteLanguage())
  const { EventTypes = {}, EventsAndExhibitions = {} } = labels

  const { keys } = useBackNavigation()
  const navigationKey = isCollectionCard ? keys.COLLECTION.INSIDE_MY_COLLECTION : keys.EVENTS.INSIDE_EVENTS
  const { showAddToCollectionModal } = useContext(AddToCollectionContext)

  const prepareTagsData = (type, label) => {
    const Sectors = []
    const tags = [...label, ...type]
    if (item.accessPermission === VIP || item.accessPermission === PRIVATE_INVITATION) {
      const labelValue =
        item.accessPermission === VIP
          ? eventsLabels.las_grp1_vip || VIP
          : eventsLabels.las_grp1_private_invitation || 'Private Invitation'
      Sectors.push(
        //@ts-ignore
        { id: Math.random(), label: labelValue, type: 'premium', theme: 'dark' }
      )
    }

    tags &&
      tags.forEach((itemData) => {
        const obj = {
          id: `${Math.random()}-${itemData?.id}`,
          label: itemData?.title || itemData?.type,
          type: 'label',
          theme: 'light'
        }
        // @ts-ignore
        Sectors.push(obj)
      })
    return Sectors.length > 0 && Sectors
  }

  const getVenue = (currentData) => {
    let venue = {
      name: '',
      street: '',
      city: ''
    }
    if (currentData.venue && !currentData.isOnSite) {
      if (currentData.virtual) {
        venue.street = EventsAndExhibitions.onlineLocationLabel || 'Online'
      } else {
        venue = {
          name: currentData?.exhibition ? currentData?.ownerAccount?.name : organizerName,
          street: currentData?.venue?.street || currentData?.venue?.city,
          city: currentData?.venue?.street ? currentData?.venue?.city : ''
        }
      }
    } else {
      venue = currentData.hall
    }
    return venue
  }

  const getTimeAndLocation = () => {
    if (item.openingDateTimes?.length > 0) {
      const startTime =
        (item.openingDateTimes[0].startTime && formatHour(item.openingDateTimes[0].startTime, 'hh:mm', eventsLabels)) ||
        ''
      let endTime = ''
      if (item.openingDateTimes[0].endTime) {
        endTime = formatHour(item.openingDateTimes[0].endTime, 'hh:mm', eventsLabels)
      } else {
        endTime = eventsLabels.las_grp1_onwards || 'onwards'
      }
      if (unavailableToView && item?.exhibition) {
        return ''
      } else {
        return `${startTime} - ${endTime} ${item?.cityName ? `(${item?.cityName})` : ''}`
      }
    }
  }

  const getEventType = () => {
    if (item.exhibition) {
      return EventTypes.Exhibition
    } else {
      if (item.virtual) {
        return EventsAndExhibitions.virtualEventLabel
      } else {
        return EventTypes.Event
      }
    }
  }

  return (
    <EventCard
      a11yData={{
        // @ts-ignore
        eventVenue: getVenue(item)?.name || getVenue(item)?.street || '',
        eventLocation: getVenue(item)?.city || '',
        eventDate: item.exhibition
          ? item?.startDate &&
            item?.endDate &&
            `${formatDate(item.startDate, date.BE_DATE_FORMAT, date.FE_DATE_FORMAT)} - ${formatDate(
              item.endDate,
              date.BE_DATE_FORMAT,
              date.FE_DATE_FORMAT
            )}`
          : formatDate(item.startDate, date.BE_DATE_FORMAT, date.FE_WEEK_DATE_FORMAT),
        eventDayTime: getTimeAndLocation() || ''
      }}
      responsive={false}
      type={getEventType()}
      title={item.title}
      alt={`${item.title}${organizerName ? ` ${labels?.AltTags?.altTagBy} ${organizerName}` : ''}`}
      image={uploadTransformationUrl(
        eventImage ?? item?.image,
        'w_510,q_auto:good,dpr_1.0,ar_1.6,c_crop,c_fill,g_auto',
        {
          transformUrl: cloudinary.throughCloudfront
        }
      )}
      date={
        item.exhibition
          ? item?.startDate &&
            item?.endDate &&
            `${formatDate(item.startDate, date.BE_DATE_FORMAT, date.FE_DATE_FORMAT)} - ${formatDate(
              item.endDate,
              date.BE_DATE_FORMAT,
              date.FE_DATE_FORMAT
            )}`
          : formatDate(item.startDate, date.BE_DATE_FORMAT, date.FE_WEEK_DATE_FORMAT)
      }
      eventLink={formatEventLink(id, cleanTitle, openingDateTimes?.length ? openingDateTimes[0].id : null)}
      linkRenderer={(link, title, children) => (
        <LinkWithBackNavigation passHref as={link} navigationKey={navigationKey}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a title={title}>{children}</a>
        </LinkWithBackNavigation>
      )}
      // @ts-ignore
      venue={getVenue(item)}
      schedule={getTimeAndLocation()}
      collection={{
        active: isCollectionCard,
        onClick: () => {
          //@ts-ignore
          showAddToCollectionModal(id, exhibition ? '4b' : '4a', undefined, null, item?.openingDateTimes[0]?.id)
        }
      }}
      // @ts-ignore
      tags={prepareTagsData(eventTypes, eventLabels)}
      unavailableToView={{
        active: unavailableToView,
        label: notAvailableLabel
      }}
      selectMode={{
        active: isOrganizeMode,
        checked: isSelectedInCollections,
        onChange: () => {
          isOrganizeMode && onSelectCard(id ? id : entityId, entityType, subEntityId)
        }
      }}
    />
  )
}

EventCardList.propTypes = {
  item: PropTypes.object,
  isSelectedInCollections: PropTypes.bool,
  isCollectionCard: PropTypes.bool,
  eventsLabels: PropTypes.object,
  unavailableToView: PropTypes.bool,
  isOrganizeMode: PropTypes.bool,
  notAvailableLabel: PropTypes.string,
  onSelectCard: PropTypes.func,
  entityId: PropTypes.string,
  entityType: PropTypes.string,
  subEntityId: PropTypes.string
}

const EventCardListConnected = connect(
  ({ shared }) => ({
    // @ts-ignore
    eventsLabels: shared.globalModules.labelModule.labels || {}
  }),
  null
)(EventCardList)

export default EventCardListConnected
