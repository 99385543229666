// this enables/blocks the artworks cards (e.g. upcoming vs open)
const isUserAllowedToEnterArtworks = (onlineShowData) => {
  if(onlineShowData) {
    if(onlineShowData.supervisor) {
      return true
    }
    if (onlineShowData.accessPermitted) {
      if (onlineShowData.currentPhase === 'OPEN_FOR_VIP' && onlineShowData.vip) {
        return true
      } else if (onlineShowData.currentPhase === 'OPEN_FOR_EVERYONE' && onlineShowData.allowedVisitor) {
        return true
      } else if (
        onlineShowData.currentPhase === 'SPECIAL_ROOM_OPEN_FOR_EVERYONE' &&
        onlineShowData.accessPermitted &&
        onlineShowData.specialRoomVisitor
      ) {
        // both vip and public are upcoming
        if (onlineShowData.secondsToVipStartDate > 0 && onlineShowData.secondsToPublicStartDate > 0) {
          return false
        } else if (
          // vip opened before and public is upcoming, and user is vip
          onlineShowData.secondsToVipStartDate < 0 &&
          onlineShowData.vip &&
          onlineShowData.secondsToPublicStartDate > 0
        ) {
          return true
        } else if (
          // public is upcoming, and vip is in the past and user is public
          onlineShowData.secondsToPublicStartDate > 0 &&
          onlineShowData.secondsToVipStartDate < 0 &&
          !onlineShowData.vip
        ) {
          return false
        }
      } else {
        return false
      }
    }
  } else {
    return false
  }
}

export default isUserAllowedToEnterArtworks
