import { FC, createContext } from 'react'
import LoginModal from '@components/LoginModal'
import TermsAndConditionsPopup from './TermsAndConditionsPopup'
import useLogin from './hooks'
import { ILoginContext, LoginProviderProps } from './types'
import UpdatePassword from '@components/ot-modules/PersonalDetail/UpdatePassword'

const LoginContext = createContext<ILoginContext>({} as ILoginContext)
/**
 * Encapsulation of the Login modal.
 */
const LoginProvider: FC<LoginProviderProps> = ({ children }) => {
  const {
    visible, showLogin, isUserLogged, user, visibleTC,
    setVisible, isLoadingTCData, handleLogoutUser,
    setIsClosedAtLeastOnce, openOnlyOnceRef, onLoginModalCloseCallback, onLoginFirstTimeSuccess,
    isHideCloseIcon, handleSubmitTC, labels, showTCModal, onLoginSuccess
  } = useLogin()

  return (
    <LoginContext.Provider value={{ showLogin, isUserLogged, user, visibleTC,
    isLoadingTCData, labels, showTCModal, setVisible, visible }}>
      {visible && (
        <LoginModal
          onCloseModal={() => {
            setVisible(false)
            openOnlyOnceRef.current && setIsClosedAtLeastOnce(true)
            typeof onLoginModalCloseCallback.current === 'function' &&
              onLoginModalCloseCallback.current()
          }}
          loginFormCallback={(me) => {
            const userData = { me }
            setVisible(false)
            /**
             * This callback can be considered "optional", it only executes
             * after the login popup closes after a successful login
             */
            typeof onLoginFirstTimeSuccess.current === 'function'
              && onLoginFirstTimeSuccess.current(userData)
            // This is the classic callback happening after login success
            onLoginSuccess.current && onLoginSuccess.current(userData)
          }}
          isHideCloseIcon={isHideCloseIcon.current}
        />
      )}
      {visibleTC &&
        <TermsAndConditionsPopup
          handleSubmitTC={handleSubmitTC}
          handleLogoutUser={handleLogoutUser}
        />
      }
      {user?.loginData && user?.me && <UpdatePassword />}

      {children}
    </LoginContext.Provider>
  )
}
export default LoginProvider
export { LoginContext }