export function formatArtworkLink(id, cleanTitle) {
  return `/catalog/artwork/${id}/${cleanTitle}`
}

export function formatOVRArtworkDetailLink(id, cleanTitle) {
  return `/catalog/artwork/${id}/${cleanTitle}?moreInfo=true&backToOvrGrid=true`
}

export function formatOVRArtworkLink(id, cleanTitle) {
  return `/catalog/artwork/${id}/${cleanTitle}?backToOvrGrid=true`
}

export default { formatArtworkLink, formatOVRArtworkDetailLink, formatOVRArtworkLink }
