// @ts-nocheck
import http from './_http'

const Passes = {
  getCompany: () =>
    http.get('/msvc/v1/exhibitorpasses/company'),
  setCompanyName: data =>
    http.post('/msvc/v1/exhibitorpasses/company', data),
  updateCompanyName: data =>
    http.put('/msvc/v1/exhibitorpasses/company', data),
  addExhibitorPass: data =>
    http.post('/msvc/v1/exhibitorpasses/additional', data),
  deleteExhibitorPass: id =>
    http.del(`/msvc/v1/exhibitorpasses/additional/${id}`),
  getExhibitorPasses: data =>
    http.get('/msvc/v1/exhibitorpasses', data)
}
export default Passes
