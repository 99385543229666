// @ts-nocheck
import http from './_http'
import { getSiteLanguage } from '@utils'

const Shows = {
  getAll: params => http.get('/msvc/v1/shows/all', params),
  getAccountExhibitions: (accountId, params) => http.get(`/msvc/v1/exhibitorprofile/items/${accountId}/exhibitions`, params),
  getOnlineShow: onlineShowId => http.get(`/msvc/v1/onlineshows/views/${onlineShowId}?locale=${getSiteLanguage()}`)
}

export default Shows
