import React, { FC } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import { ConvertWwwLinkToValidLink, getLinkRel } from '@utils'

import { cmsPageUrl, isExternalLink, isLegacyPath, isSpecialUrl } from '@utils'

const CustomLinkTag: FC<ICustomLinkTag> = ({
  url,
  children,
  className,
  page = '/',
  title,
  ariaLabel,
  extraParams,
  newTab,
  linkRef,
  noRel,
  onClick = () => null,
  ...props
}) => {
  if (url) {
    // CMS user explicity wants to open this URL in a new tab (independently if it's internal or external page)
    if (newTab) {
      return (
        <a
          href={url}
          className={className}
          target='_blank'
          title={title || undefined}
          aria-label={ariaLabel || undefined}
          ref={linkRef}
          rel={noRel ? 'external' : getLinkRel(url)}
          onClick={onClick}
        >
          {children}
        </a>
      )
    }
    // Legacy paths are not wrapped in NextJS Link
    if (isLegacyPath(url)) {
      return (
        <a
          href={url}
          title={title || undefined}
          aria-label={ariaLabel || undefined}
          className={classNames('x-legacy-path', className)}
          ref={linkRef}
          onClick={onClick}
        >
          {children}
        </a>
      )
    }
    if (isExternalLink(url)) {
      return (
        <a
          href={ConvertWwwLinkToValidLink(url)}
          className={className}
          target={isSpecialUrl(url) ? '_self' : '_blank'}
          title={title || undefined}
          aria-label={ariaLabel || undefined}
          rel={noRel ? 'external' : getLinkRel(url)}
          ref={linkRef}
          onClick={onClick}
        >
          {children}
        </a>
      )
    }
    if (isSpecialUrl(url)) {
      return (
        <a title={title || undefined} aria-label={ariaLabel || undefined} href={url} className={className}>
          {children}
        </a>
      )
    }

    return (
      <Link href={cmsPageUrl(url, page, extraParams)} as={url} passHref {...props} legacyBehavior>
        <a
          title={title || undefined}
          aria-label={ariaLabel || undefined}
          className={className}
          ref={linkRef}
          onClick={onClick}
          role='link'
          onKeyDown={() => { }}
          tabIndex={0}
          {...props}
        >
          {children}
        </a>
      </Link>
    )
  }
  return <>{children}</>
}

interface ICustomLinkTag {
  url: string
  children: React.ReactNode
  className?: string
  page?: string
  extraParams?: { [key: string]: string | undefined } | null | undefined
  title?: string | undefined
  newTab?: boolean
  ariaLabel?: string,
  noRel?: boolean
  linkRef?: React.Ref<HTMLAnchorElement>;
  onClick?: () => void;
}

export default CustomLinkTag