import React, { FC } from 'react'
import { MediaCaption, Row, Col } from '@mch-group/uikit-components'
import { Media, MediaContextProvider } from '@responsive'
import { useEditorialModule } from '@hooks'
import IEMImageMosaic from '@modules/EMImageMosaic/types'
import StyledMediaCaption from './StyledMediaCaption'

const TwoImages: FC<IEMImageMosaic> = ({
  emim_image_upload_1,
  emim_image_upload_2,
  emim_image_caption_1,
  emim_image_caption_2
}) => {
  const { getImageUrl, ar } = useEditorialModule()
  return (
    <>
      <Row gutter='g-5 g-md-6 g-xl-7 mb-9 mb-lg-12'>
        {emim_image_upload_1 && (
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 9, offset: 1 }}
            lg={{ span: 9, offset: 1 }}
            xl={{ span: 9, offset: 1 }}
            xxl={{ span: 8, offset: 1 }}
          >
            <MediaContextProvider>
              <Media at='xs'>
                <StyledMediaCaption ar={ar(emim_image_upload_1)}>
                  <MediaCaption
                    caption={!!emim_image_caption_1}
                    captionText={emim_image_caption_1}
                    imageAltText={emim_image_caption_1}
                    urlImage={getImageUrl(emim_image_upload_1, 365, '')}
                    fullWidth={false}
                    media='image'
                  />
                </StyledMediaCaption>
              </Media>
              <Media at='sm'>
                <StyledMediaCaption ar={ar(emim_image_upload_1)}>
                  <MediaCaption
                    caption={!!emim_image_caption_1}
                    captionText={emim_image_caption_1}
                    imageAltText={emim_image_caption_1}
                    urlImage={getImageUrl(emim_image_upload_1, 720, '')}
                    fullWidth={false}
                    media='image'
                  />
                </StyledMediaCaption>
              </Media>
              <Media greaterThanOrEqual='tablet'>
                <StyledMediaCaption ar={ar(emim_image_upload_1)}>
                  <MediaCaption
                    caption={!!emim_image_caption_1}
                    captionText={emim_image_caption_1}
                    imageAltText={emim_image_caption_1}
                    urlImage={getImageUrl(emim_image_upload_1, 973, '')}
                    fullWidth={false}
                    media='image'
                  />
                </StyledMediaCaption>
              </Media>
            </MediaContextProvider>
          </Col>
        )}
      </Row>
      <Row>
        {emim_image_upload_2 && (
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 10, offset: 2 }}
            lg={{ span: 10, offset: 2 }}
            xl={{ span: 9, offset: 3 }}
            xxl={{ span: 9, offset: 3 }}
          >
            <MediaContextProvider>
              <Media at='xs'>
                <StyledMediaCaption ar={ar(emim_image_upload_2)}>
                  <MediaCaption
                    caption={!!emim_image_caption_2}
                    captionText={emim_image_caption_2}
                    imageAltText={emim_image_caption_2}
                    urlImage={getImageUrl(emim_image_upload_2, 365)}
                    fullWidth={false}
                    media='image'
                  />
                </StyledMediaCaption>
              </Media>
              <Media at='sm'>
                <StyledMediaCaption ar={ar(emim_image_upload_2)}>
                  <MediaCaption
                    caption={!!emim_image_caption_2}
                    captionText={emim_image_caption_2}
                    imageAltText={emim_image_caption_2}
                    urlImage={getImageUrl(emim_image_upload_2, 720)}
                    fullWidth={false}
                    media='image'
                  />
                </StyledMediaCaption>
              </Media>
              <Media greaterThanOrEqual='tablet'>
                <StyledMediaCaption ar={ar(emim_image_upload_2)}>
                  <MediaCaption
                    caption={!!emim_image_caption_2}
                    captionText={emim_image_caption_2}
                    imageAltText={emim_image_caption_2}
                    urlImage={getImageUrl(emim_image_upload_2, 1096)}
                    fullWidth={false}
                    media='image'
                  />
                </StyledMediaCaption>
              </Media>
            </MediaContextProvider>
          </Col>
        )}
      </Row>
    </>
  )
}

export default TwoImages
