// @ts-nocheck
import http from './_http'

const Collections = {
  create: (type, data) => http.post(`/msvc/v1/collections/${type}`, data),
  edit: (id, data) => http.put(`/msvc/v1/collections/collection/${id}`, data),
  delete: (type) => http.del(`/msvc/v1/collections/collection/${type}`),
  update: (data) => http.put('/msvc/v1/collections/items', data),
  getMyCollectionsEndpoint: '/msvc/v1/collections/MANUAL/mine',
  getCollectionEndpoint: '/msvc/v1/collections/collection',
  getFavoritesCollectionEndpoint: '/msvc/v1/collections/FAVORITES/mine',
  getCollectionsByMembersEndpoint: (entityType, entityId) => `/msvc/v1/collections/memberships/${entityType}/mine?entityIds=${entityId}`,
  unsubscribeCollection: (collectionId) => http.del(`/msvc/v1/collections/subscriptions/collection/${collectionId}`),
  subscribeCollection: (collectionId) => http.post(`/msvc/v1/collections/subscriptions/collection/${collectionId}`),
  getSubscribedCollection: '/msvc/v1/collections/subscriptions/mine'
}
export default Collections
