const groupContents = (data) => {
  const aggregatedData = []
  if (data) {
    // Split the whole footer options per line
    const dataArray = data.split(/\r?\n/)

    let columnData = []
    let currentLineObjectData = {}

    if (dataArray) {
      dataArray.forEach((element) => {
        // if line is not empty, add datas in the column object
        if (element.trim() !== '') {
          // split with comma to get "label" and "url"
          const elementValues = element.trim().split(',')
          currentLineObjectData.label = elementValues.length > 0 ? elementValues[0].trim() : ''
          currentLineObjectData.link = elementValues.length > 1 ? elementValues[1].trim() : ''
          columnData.push(currentLineObjectData)
          currentLineObjectData = {}
        } else {
          // if line is empty, it means column is complete
          aggregatedData.push(columnData)
          columnData = []
        }
      })
      // in case the last line is not an empty line, push the remaining column in the result
      if (columnData.length > 0) {
        aggregatedData.push(columnData)
      }
    }
    // get max 6 columns
    return aggregatedData.slice(0, 6)
  }
  return aggregatedData
}

export default groupContents