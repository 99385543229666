import Dexie from 'dexie'

const database = new Dexie('artbasel:site')

// be aware that the version number is used to determine if the database schema has changed
// https://dexie.org/docs/Tutorial/Design#database-versioning
// if you change the schema, you need to increment the version number
// https://dexie.org/docs/Tutorial/Understanding-the-basics#modify-schema

database.version(5).stores({
  evgcompact: '++id, mid, name, data, params, paths, origin, scrollPosition, listHeight, documentHeight, dateCreated, dateUpdated, version',
  oscompactrooms: '++id, mid, name, data, params, paths, origin, scrollPosition, listHeight, documentHeight, dateCreated, dateUpdated, version',
  oscompactspecialrooms: '++id, mid, name, data, params, paths, origin, scrollPosition, listHeight, documentHeight, dateCreated, dateUpdated, version',
  oscompactartworks: '++id, mid, name, data, params, paths, origin, scrollPosition, listHeight, documentHeight, dateCreated, dateUpdated, version'
})

export const EVGCompactTable = database.table('evgcompact')
export const OSCompactRoomsTable = database.table('oscompactrooms')
export const OSCompactSpecialRoomsTable = database.table('oscompactspecialrooms')
export const OSCompactArtworksTable = database.table('oscompactartworks')

export default database