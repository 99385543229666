import { useEffect, useState, useCallback } from 'react'
import 'intersection-observer'

const useIntersection = (onIntersect, { root = null, rootMargin = '1500px', threshold = 0 } = {}) => {
  const [node, setNode] = useState(null)
  const [intersecting, setIntersecting] = useState(null)

  const handleIntersect = useCallback(([entry]) => {
    setIntersecting(entry.isIntersecting)

    if (onIntersect && entry.isIntersecting) {
      onIntersect(entry)
    }
  }, [onIntersect])

  useEffect(() => {
    if (node) {
      const observer = new IntersectionObserver(handleIntersect, { root, rootMargin, threshold })

      observer.observe(node)

      return () => observer.disconnect()
    }
  }, [node, handleIntersect, root, rootMargin, threshold])

  return [setNode, intersecting]
}

export default useIntersection
