// If the seconds to X showphase < 60 seconds, then we request the Show API
// Otherwise we mock the seconds keys and implement the countdown in clientside
import PropTypes from 'prop-types'

const updateCountdown = (showData, fetchShow, setShowData, seconds = 30) => {
  const shouldFetchShow = () => {
    if (
      (showData?.mockSecondsToVipStartDate <= 120 && showData?.mockSecondsToVipStartDate > 0) ||
      (showData?.mockSecondsToPublicStartDate <= 120 && showData?.mockSecondsToPublicStartDate > 0) ||
      (showData?.mockSecondsToSpecialRoomStartDate <= 120 && showData?.mockSecondsToSpecialRoomStartDate > 0) ||
      (showData?.mockSecondsToEndDate <= 120 && showData?.mockSecondsToEndDate > 0)
    ) {
      return true
    }
    return false
  }

  const extendShowData = () => {
    const newShowData = { ...showData }
    const subtract = (deadline) => parseInt(deadline - seconds, 10)

    newShowData.isMockCounterActive = true

    if (newShowData.mockSecondsToVisibleDate) {
      newShowData.mockSecondsToVisibleDate = subtract(newShowData.mockSecondsToVisibleDate)
    }
    if (newShowData.mockSecondsToPublicStartDate) {
      newShowData.mockSecondsToPublicStartDate = subtract(newShowData.mockSecondsToPublicStartDate)
    }
    if (newShowData.mockSecondsToVipStartDate) {
      newShowData.mockSecondsToVipStartDate = subtract(newShowData.mockSecondsToVipStartDate)
    }
    if (newShowData.mockSecondsToSpecialRoomStartDate) {
      newShowData.mockSecondsToSpecialRoomStartDate = subtract(newShowData.mockSecondsToSpecialRoomStartDate)
    }
    if (newShowData.mockSecondsToEndDate) {
      newShowData.mockSecondsToEndDate = subtract(newShowData.mockSecondsToEndDate)
    }

    setShowData(newShowData)
  }

  // 1. the show is available for vip and public
  if (shouldFetchShow()) {
    fetchShow()
  } else {
    extendShowData()
  }
}

updateCountdown.propTypes = {
  showData: PropTypes.object,
  fetchShow: PropTypes.func,
  setShowData: PropTypes.func
}

export default updateCountdown
