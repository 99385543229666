const path = require('path')

// store entire config object in a constant
const C = {}

/* Availabe fair options */
C.fairs = ['artbasel']

/* Current Environment Theme -- will be used by web app and build tasks... */
C.envTheme = process.env.mch_fe_theme || 'artbasel'

C.cloudinary = {
  name: process.env.mch_fe_cloudinary_key,
  key: process.env.mch_fe_cloudinary_name
}

/**
 * DO NOT put any environment specific/runtime configuration into "C.globals" Use instead:
 * const { publicRuntimeConfig } = require('next/config').default()
 * See: server/api/envConfig.js
 * All data in "C.globals" remain SAME in all environments (test, stage, prod, qa, ...)
 * --
 * "C.globals" getting transpiled by "babel-transform-define-plugin" in compile time
 * Which transforms the keys defined here with their evaluated values in all files its used
 * e.g. "PORT" becomes "3000", "process.env.NODE_ENV" becomes "production", ...
 * See: babelrc.js and https://git.io/fxfWF for more info
 */
C.globals = {}

C.globals.PORT = parseInt(process.env.PORT, 10) || 3000

/**
 * Custom environment id to identify which server executing the app.
 * On bundle-phase/CI it's set to "build" on runtime Docker sets dynamically.
 *
 * | @name process.env.environment
 * | @static `build|test|staging|production|dev|qa|{String*any}`
 * | @default {String} "build"
 * | @see process.env.NODE_ENV
 *
 */
C.globals.SERVER_ENV = process.env.environment || 'build'



/**
 * `NODE_ENV` specifically is used to state whether a particular environment
 * is a "production" or a "development" environment. Mostly used for
 * additional debugging or enabling dev-only tools while running in a development environment.
 *
 * | @name process.env.NODE_ENV
 * | @static `development|production`
 * | @default {undefined} "It's CRITICAL to ensure this variable defined before executing any task."
 *
 */
C.globals.DEV_MODE = process.env.NODE_ENV !== 'production'

C.globals.APP_DEBUG = process.env.x_app_debug === 'true'
C.globals.COOKIE_KEYS = {
  LANGUAGE: 'mfpLocale',
  CSRF_TOKEN: 'csrfToken',
  ACCESS_TOKEN: 'access_token', // Must remain for compatibility with all current sessions
  REFRESH_TOKEN: 'refresh_token', // Must remain for compatibility with all current sessions
  IS_MOBILE_WEBVIEW: 'mfpIsMobileWebview',
  AGENT_KEY: 'agentKey',
  COOKIES_AND_DATA_ANALYTICS: 'acceptedCookies'
}

/* Paths */
C.paths = {}

/* Paths -- App */
C.paths.app = {}
C.paths.app.static = path.resolve(__dirname, './app/src', 'static')

C.paths.app.webpack = {
  '@reusable-modules': path.resolve(__dirname, './app/src/components/reusable-modules'),
  '@ot-modules': path.resolve(__dirname, './app/src/components/ot-modules'),
  '@components': path.resolve(__dirname, './app/src/components'),
  '@utils': path.resolve(__dirname, './app/src/utils'),
  '@providers': path.resolve(__dirname, './app/src/providers'),
  '@constants': path.resolve(__dirname, './app/src/constants'),
  '@services': path.resolve(__dirname, './app/src/services'),
  '@stores': path.resolve(__dirname, './app/src/stores'),
  '@icons': path.resolve(__dirname, './app/src/components/icons'),
  '@reducers': path.resolve(__dirname, './app/src/hooks/reducers'),
  '@modules': path.resolve(__dirname, './app/src/modules'),
  '@hooks': path.resolve(__dirname, './app/src/hooks'),
  '@types': path.resolve(__dirname, './app/src/types'),
  '@hocs': path.resolve(__dirname, './app/src/hocs'),
  '@responsive': path.resolve(__dirname, './app/src/utils/createMedia')
}

// ! - Object paths of environment varibles to be exposed to the client/browser
C.publicConfigObjectPaths = [
  'mch.fe.legacyPaths',
  'mch.fe.legacyRegexps',
  'mch.fe.cdn.disabled',
  'mch.fe.cdn.prefix',
  'mch.fe.cdn.useCloudinaryBinariesProxy',
  'mch.fe.cdn.cloudfrontKey',
  'mch.fe.cdn.cloudinaryMappingPath',
  'mch.fe.cloudinary.name',
  'mch.fe.cloudinary.apiKey',
  'mch.fe.onetrust.dataDomainScript',
  'mch.fe.recaptcha.apiKey',
  'mch.fe.S3',
  'mch.fe.google.analytics_id',
  'mch.fe.google.maps_key',
  'mch.fe.theme',
  'mch.fe.trackJs.token',
  'mch.fe.trackJs.application',
  'mch.fe.cms.showUnsupportedModules',
  'mch.fe.errorMessages.40x',
  'mch.fe.errorMessages.50x',
  'mch.fe.fairName',
  'mch.fe.nativeApp',
  'mch.fe.analytics',
  'mch.fe.eventsandexhibitions',
  'mch.fe.verificationMetaTag',
  'mch.fe.site',
  'mch.fe.uploadPreset',
]

/* -----------------------------------------------
  * Initial default value of the environment variables is "" - Empty String
  * Add the path below if you wanna change the default of the path
  * Environment Variable -> API Data -> Default Value
----------------------------------------------- */
C.publicConfigDefaults = {
  'mch.fe.uploadPreset': {},
  'mch.fe.legacyPaths': [],
  'mch.fe.cms.showUnsupportedModules': false,
  'mch.fe.nativeApp': {
    author: 'MCH Messe Schweiz (Basel) AG',
    id: { ios: '1050156803', android: 'com.mch.artbasel' },
    title: 'Art Basel - Official App'
  },
  'mch.fe.cloudinary': {
    name: '',
    id: '',
    url: ''
  },
  // See: https://docs.trackjs.com/browser-agent/sdk-reference/agent-config/ for all possible options.
  'mch.fe.trackJs.token': null,
  'mch.fe.trackJs.application': 'mchmfp-unknown',
  'mch.fe.verificationMetaTag': {},
  'mch.fe.site': {}
}

module.exports = C
