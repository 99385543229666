export const entityTypes = {
  gallery: 1,
  artwork: 2,
  artist: 3,
  event: 4,
  project: 6
}

export const filterEntityTypes = {
  sector: 'SECTOR',
  medium: 'MEDIUM',
  show: 'SHOW'
}

export default entityTypes
