import styled from 'styled-components'
import { px2rem } from '@utils'
import PropTypes from 'prop-types'

const ViewAtFair = (props) => {
  const { icon = '', desktopTextLabel, mobileTextLabel = '' } = props
  return (
    <Wrapper>
      {icon && icon }
      {desktopTextLabel &&
        <ViewLabelWeb className='typo text small color-black tablet hidden mobile hidden'>
          {desktopTextLabel}
        </ViewLabelWeb>
      }
      {mobileTextLabel &&
        <ViewLabelMob className='typo text small color-black mobile only tablet only'>{mobileTextLabel}</ViewLabelMob>
      }
    </Wrapper>
 )
}

const Wrapper = styled.div`
  background: var(--bs-mch-white);
  border-radius: ${px2rem(4)};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${px2rem(1)} ${px2rem(7)} ${px2rem(2)} ${px2rem(6)};
`

const ViewLabelWeb = styled.span`
  margin-left: ${px2rem(5)};
`

const ViewLabelMob = styled.span`
  margin-left: ${px2rem(5)};
`

ViewAtFair.propTypes = {
  icon: PropTypes.node,
  desktopTextLabel: PropTypes.string,
  mobileTextLabel: PropTypes.string
}

export default ViewAtFair