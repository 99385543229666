import React, { FC } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { useBackNavigation } from '@hooks'

/*
  * LinkWithBackNavigation
  * @param {string} as - The path you want to navigate to
  * @param {string} navigationKey - The key you want to use to navigate back
  * @param {boolean} applyEffect - If you want to apply the effect of navigating back
  * @param {string} href - The path you want to navigate to
  * @param {string} className - The className you want to apply to the component
  * @param {boolean} withoutLink - If you want to use the component without a link
  * @param {boolean} isLoading - If you want to use the component as a loading state
  * @param {boolean} disableBackNavigation - If you want to disable the back navigation
  * @param {boolean} passHref - If you want to pass the href to the Link component
  * @param {function} extraAction - If you want to apply an extra action
  * @param {any} children - The children you want to render
*/
const LinkWithBackNavigation: FC<ILinkWithBackNavigation> = ({
  withoutLink = false,
  isLoading = false,
  extraAction,
  applyEffect = true,
  disableBackNavigation = false,
  navigationKey,
  href = '/',
  className,
  as,
  children,
  ...props
}) => {
  const { set } = useBackNavigation()
  const handleClick = () => {
    if (typeof extraAction === 'function') {
      extraAction()
    }
    return applyEffect && !disableBackNavigation && applyEffect ? set(navigationKey) : {}
  }

  if (isLoading) {
    return children
  }
  if (withoutLink) {
    return (
      <LinkWithNavigationWrapper className={className} onClick={handleClick}>
        {children}
      </LinkWithNavigationWrapper>
    )
  } else {
    return (
      <LinkWithNavigationWrapper className={className} onClick={handleClick}>
        <Link {...props} href={href} as={as} legacyBehavior>
          {children}
        </Link>
      </LinkWithNavigationWrapper>
    )
  }
}

const LinkWithNavigationWrapper = styled.div``

interface ILinkWithBackNavigation {
  as: string
  children
  navigationKey?: string
  applyEffect?: boolean
  href?: any
  className?: string
  withoutLink?: boolean
  isLoading?: boolean
  disableBackNavigation?: boolean
  passHref?: boolean
  extraAction?: () => void
}

export default LinkWithBackNavigation
