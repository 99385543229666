import styled from 'styled-components'
import { Header } from 'semantic-ui-react'
import PropTypes from 'prop-types'

import { Text } from '@components'
import { Button } from '@dmi-mch/components'
import { mq, px2rem, hiDPIpx } from '@utils'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import tokensRenamed from '@constants/tokensRenamed'

const PromoBanner = ({
  ownerName,
  followCollectionMessage,
  followCollectionTitle,
  followCollectionBtnLabel,
  dismissBtnLabel,
  subscribe,
  collectionId,
  dispatch
}) => {
  const [isVisible, setVisible] = React.useState(true)
  const description = `<b>${ownerName}</b> ${followCollectionMessage}`
  const onSubscribe = () => {
    subscribe(collectionId)
  }
  const onDismiss = () => {
    setVisible(false)
    dispatch({ type: 'SET_BANNER_DISMISSED', payload: true })
  }

  return isVisible ? (
    <PromoBannerWrapper>
      <PromoBannerInformation>
        <Header as='h3'>{followCollectionTitle}</Header>
        <Text isHtml forwardedAs='p'>{description}</Text>
      </PromoBannerInformation>
      <PromoBannerActions>
        <Button transparent onClick={() => onSubscribe()}>{followCollectionBtnLabel}</Button>
        <Button transparent onClick={() => onDismiss()}>{dismissBtnLabel}</Button>
      </PromoBannerActions>
    </PromoBannerWrapper>
  ) : null
}

PromoBanner.propTypes = {
  ownerName: PropTypes.string,
  followCollectionMessage: PropTypes.string,
  followCollectionTitle: PropTypes.string,
  followCollectionBtnLabel: PropTypes.string,
  dismissBtnLabel: PropTypes.string,
  subscribe: PropTypes.func,
  collectionId: PropTypes.string,
  dispatch: PropTypes.func
}

const PromoBannerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--bs-mch-black);
  box-shadow: ${tokensRenamed.shadows[1]};
  border-radius: ${px2rem(10)};
  padding: ${px2rem(24)} ${px2rem(40)};
  margin: ${px2rem(0)} auto -${px2rem(48)};
  ${mq.lessThan('largestTablet')`
    flex-direction: column;
  `}
  ${mq.between('tablet', 'largestTablet')`
    padding: ${hiDPIpx(17)} ${hiDPIpx(16)} ${hiDPIpx(13)} ${hiDPIpx(16)};
    margin: 0 auto ${hiDPIpx(30)};
  `}
  ${mq.lessThan('largestMobile')`
    padding: ${px2rem(17)} ${px2rem(16)} ${px2rem(13)} ${px2rem(16)};
    margin: -${px2rem(25)} auto ${px2rem(30)};
  `}
`

const PromoBannerInformation = styled.div`
  display: flex;
  flex-direction: column;
  ${mq.greaterThan('desktop')`
    width: 40%;
  `}
  ${mq.between('tablet', 'largestTablet')`
    margin-bottom: ${hiDPIpx(10)};
  `}
  ${mq.lessThan('largestMobile')`
    margin-bottom: ${px2rem(10)};
  `}

  h3.ui.header,
  p {
    color: ${tokens.color.base.primary.white.value} !important;
  }

  h3 {
    margin: 0 0 ${px2rem(7)} 0 !important;
    ${mq.between('tablet', 'largestTablet')`
      font-size: ${hiDPIpx(22)};
      line-height: ${hiDPIpx(28)};
    `}
    ${mq.lessThan('largestMobile')`
      font-size: ${tokens.fontSize[4].value};
      line-height: ${tokensRenamed.lineHeights[7].value};
    `}
  }
`

const PromoBannerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${mq.greaterThan('desktop')`
    margin-left: auto;
  `}
  ${mq.lessThan('largestTablet')`
    margin-right: auto;
  `}

  button {
    color: ${tokens.color.base.primary.white.value} !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    ${mq.greaterThan('desktop')`
      margin-left: ${px2rem(40)} !important;
    `}
    ${mq.between('tablet', 'largestTablet')`
      margin-right: ${hiDPIpx(40)} !important;
    `}
    ${mq.lessThan('largestMobile')`
      margin-right: ${px2rem(40)} !important;
    `}
  }
`

export default PromoBanner
