import styled from 'styled-components'
import PropTypes from 'prop-types'

import { getSiteLanguage, mq, px2rem } from '@utils'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import { cssClasses } from '@constants'
import { Loader } from '@components'
import IntersectionCapture from '@reusable-modules/IntersectionCapture'
import useRemoveCollection from '@modules/collections/hooks/useRemoveCollection'
import { Translations as TranslationsService } from '../../../../services'

import { CollectionFormContext } from '../../collection-form'
import CollectionAlbumCard from '../CollectionAlbumCard'
import CollectionAlbumCardCreate from '../CollectionAlbumCardCreate'

const MyCollectionsTab = ({
  onClick,
  myFavoritesCollection,
  myCollections,
  isLoadingInitialData,
  myCollectionsValidating,
  isRefreshing,
  isLoadingMore,
  size,
  setSize,
  isEmpty
}) => {
  // Move this to the Context labels
  const [{ CMSProps }] = useMyCollectionsPageContext()
  const { moduleName, id } = CMSProps
  const { showForm } = React.useContext(CollectionFormContext)
  const { removeCollection } = useRemoveCollection()

  const isIntersectionActive =
    myCollections && !myCollectionsValidating && !isRefreshing && !isEmpty && !isLoadingInitialData && !isLoadingMore

  const handleEditCollection = (item) => showForm({ id: item.id })

  const handleDeleteCollection = async (item) => {
    let subText = ''
    if (item.numFollowers > 0) {
      const subTextLabel = await TranslationsService.getDynamicLabelValue(
        'Collections',
        'deleteFollowedCollectionAlertMsgLabel',
        getSiteLanguage(),
        item.numFollowers
      )
      subText = subTextLabel.data.value
    }
    removeCollection(item.id, name, subText)
  }

  return (
    <>
      <Cards id={`${moduleName}-${id}`} className={cssClasses.CMS_MODULE}>
        <Card>
          <CollectionAlbumCardCreate
            myCollections={myCollections}
            onClick={onClick}
            editCollectionUrl={CMSProps.mcm_grp1_edit_page}
          />
        </Card>
        {myFavoritesCollection?.items && (
          <Card>
            <CollectionAlbumCard item={myFavoritesCollection.items[0]} hideContextMenu isCreator />
          </Card>
        )}
        {myCollections &&
          myCollections.length > 0 &&
          myCollections.map((i) => (
            <Card key={Math.random()} className='actions-bar'>
              <CollectionAlbumCard
                item={i}
                isCreator
                handleEditClick={() => handleEditCollection(i)}
                handleDeleteClick={() => handleDeleteCollection(i)}
              />
            </Card>
          ))}
      </Cards>
      <IntersectionCapture
        onIntersect={() => setSize(size + 1)}
        active={isIntersectionActive}
        params={{ rootMargin: '400px' }}
      />
      {isLoadingInitialData || isLoadingMore ? (
        <LoaderContainer>
          <Loader centered active />
        </LoaderContainer>
      ) : null}
    </>
  )
}

MyCollectionsTab.propTypes = {
  onClick: PropTypes.func,
  myFavoritesCollection: PropTypes.object,
  myCollections: PropTypes.array,
  isLoadingInitialData: PropTypes.bool,
  myCollectionsValidating: PropTypes.bool,
  isRefreshing: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isLoadingMore: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  size: PropTypes.number,
  setSize: PropTypes.func,
  isEmpty: PropTypes.bool
}

const Cards = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 2.5%;
  grid-template-columns: repeat(4, 23.125%);
  grid-row-gap: inherit;
  ${mq.lessThan('largestTablet')`
    grid-gap: 0%;
    grid-template-columns: repeat(1, 100%);
  `}
`

const Card = styled.div`
  margin-bottom: ${px2rem(30)};
`
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`

export default MyCollectionsTab
