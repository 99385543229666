import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import { convertHexToRgba } from '@utils/cssHelpers'
import PropTypes from 'prop-types'
import { Dimmer, Loader } from 'semantic-ui-react'
import styled from 'styled-components'

const mID = 'full-screen-loader'

const FullScreenLoader = ({ className, ...props }) => (
  <div className={className}>
    <Dimmer active={props.active} className={mID}>
      <Loader size='large' />
    </Dimmer>
  </div>
)

FullScreenLoader.propTypes = {
  props: PropTypes.object,
  className: PropTypes.string,
  active: PropTypes.bool
}

const StyledFullScreenLoader = styled(FullScreenLoader)`
  .${mID} {
    &.dimmed.dimmable > .ui.animating.dimmer,
    &.dimmed.dimmable > .ui.visible.dimmer,
    &.ui.active.dimmer {
      display: flex !important;
      position: fixed;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: rgba(${convertHexToRgba(tokens.color.light.base.neutrals[1000].value, tokens.opacity[30].value)});
    }
  }
`

export default StyledFullScreenLoader
