import styled from 'styled-components'

interface Props {
  ar: string | null
}

const StyledMediaCaption = styled.div`
  figure,
  img {
    aspect-ratio: ${(props: Props) => (props.ar ? props.ar : 'inherit')};
  }

  figure {
    margin-bottom: 0;
  }

  img {
    display: flex;
  }

  .dynamic {
    margin-top: 200px;
  }
`

export default StyledMediaCaption