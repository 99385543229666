import { uploadTransformationUrl } from '@dmi-mch/utils'
import { cloudinary } from '@constants'
import { detectDpr, detectAspectRatio } from '@utils'

/**
 * @name useEditorialModule
 * @description - hook to get image url with cloudinary transformation
 * @returns getImageUrl - function to get image url with cloudinary transformation
 */
const useEditorialModule = () => {
  /**
   * @param imageUrl
   * @param width
   * @param ar
   * @returns
   */
  const getImageUrl = (imageUrl = '', width, ar = '') => {
    let getAr = ar ? `ar_${ar},` : ''
    let url = uploadTransformationUrl(
      imageUrl?.split('?')[0],
      `q_auto,dpr_${detectDpr('dpr_')},${getAr}c_fill,f_auto,w_${width},fl_progressive`,
      {
        transformUrl: cloudinary.throughCloudfront
      }
    )
    return url
  }

  /**
   * @param imageUrl
   * @returns aspect ratio
   */
  const ar = (imageUrl = '') => detectAspectRatio(imageUrl, 'qs', 'inline')

  /**
   *
   * @param entry
   * @returns boolean
   */
  const containedLayout = (entry) => ['4 images', '2 images', '3 images - dynamic'].includes(entry)

  /**
   *
   * @param input
   * @returns string
   */
  const cleanTitle = (input = '') => input?.replace(/<\/?p[^>]*>/g, '')

  /**
   *
   * @param input
   * @returns string
   */
  const cleanAllTags = (input = '') => input?.replace(/<[^>]*>/g, '')

  return {
    getImageUrl,
    cleanTitle,
    cleanAllTags,
    ar,
    containedLayout
  }
}

export default useEditorialModule