import memoize from 'memoize-one'

// =========================================================================
/**
 * == Divide provided argument with base font size.
 * Finally, append "rem" to the divided result...
 *
 * ⚠️ This function has been used 314 times in the project! (24.11.2018)
 * Which does calculation and string replacement every single time...
 * By using memoization the result can be cached by arguments,
 * which will return cached result if its evaluated before.
 *
 * 1st call: memoize(px2rem(10)) -> 0.5555555556 // eval & return
 * 2st call: memoize(px2rem(10)) -> 0.5555555556 // return from cache
 */
// @ts-ignore
const px2rem = pxVal => `${pxVal / MFP.vars.emSize.replace(/px/g, '')}rem`

// =========================================================================
/**
 * == Multiply pixels with predefined HiDPI ratio
 *
 * .example {
 *   width: ${px2rem(20)};
 *   height: ${px2rem(20)};
 *   @media (min-width: ${tabletBreakpoint}) and (max-width: ${largestTabletScreen}) {
 *    width: ${hiDPIpx(20)};
 *    height: ${hiDPIpx(20)};
 *   }
 * }
 */
const hiDPIpx = pxVal => `${pxVal * MFP.vars.retinaCalc}px`

// =========================================================================
/**
 * == Return Summed N amount of pixel arguments
 * sumAllToRem('1rem','2rem','3rem') // 6rem
 */
const sumAllToRem = (...args) => {
  if (args.length === 0) {
    return 0
  }

  if (args.length === 1) {
    return args[0]
  }

  // remove 'rem' and convert to number
  const units = args.map(item => Number(item.replace('rem', '', item)))

  return `${units.reduce((acc, cur) => acc + cur, 0)}rem` // sum all args then add rem string to the end
}

// =========================================================================
/**
 * == Using very long variables inside styled components makes the code verbose and passing linters problematic
 * All component variables has prefix, like: siteHeaderHeight this helper to remove prefix so, it becomes: height
 *
 * 1. Get items start with "prefix" fn. argument in theme variables (MFP.vars) object
 * 2. Trim "prefix" from object key/variable name "siteHeaderHeight -> Height"
 * 3. Lowercase the first character of un-prefixed variable name "Height -> height"
 *
 * @return The variable object with un-prefixed names
 */
export const prettifyThemeVarNames = (prefix) => {
  const styleVars = {}

  Object.keys(MFP.vars)
    .filter(varName => varName.startsWith(prefix))
    .map((varName) => {
      const shortName = varName.replace(prefix, '')
      const camelCase = shortName.charAt(0).toLowerCase() + shortName.slice(1)
      styleVars[camelCase] = MFP.vars[varName]
      return varName
    })

  return styleVars
}

export const convertHexToRgba = memoize((color: string, opacity?: number) => {
  const red = parseInt(color.substring(1, 3), 16)
  const green = parseInt(color.substring(3, 5), 16)
  const blue = parseInt(color.substring(5, 7), 16)
  return `${red},${green},${blue}${opacity ? `,${opacity}` : ''}`
})

/** #################################################
 * == EXPORT "ONLY" MEMOIZED UTIL METHODS 🚀
 */
export const memoHiDPI = memoize(hiDPIpx)
export const memoPx2Rem = memoize(px2rem)
export const memoSumRems = memoize(sumAllToRem)
