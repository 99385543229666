import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

const BasicModal = ({ children, ...props }) => (
  <StyledModal {...props}>{children}</StyledModal>
)

BasicModal.propTypes = {
  children: PropTypes.node
}

const StyledModal = styled(Modal)`
  ${(props) => props.reset === 'true' && 'margin: 0 !important; padding: 0 !important;'}
`

export default BasicModal