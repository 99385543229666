import { string, number, func, object } from 'prop-types'
import styled from 'styled-components'
import { debounce, detectDpr, roundImageDimension, mq } from '@utils'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { cloudinary } from '@constants'
import tokensRenamed from '@constants/tokensRenamed'
import { ZoomViewer as ZoomViewerUIKit } from '@mch-group/uikit-components'

/**
 * @param  {number} h
 * @param  {string} roomType
 */
const calculateHeight = (h, roomType) => {
  const wallHeight = document.getElementsByClassName('wall')[0].clientHeight
  let config = { cm: 0, px: 0 }
  if (roomType === 'STANDARD') {
    config = { cm: 354, px: 1336 } // wall cm vs px
  }
  if (roomType === 'SMALL') {
    config = { cm: 240, px: 1465 } // wall cm vs px
  }
  const artworkHeight = (wallHeight * parseFloat((((h * config.px) / config.cm) * 100) / config.px).toFixed(2)) / 100
  return parseInt(artworkHeight, 10)
}

/**
 * @param  {string} roomType
 * @param  {number} verticalOffset=0
 */
const calculateHanging = (roomType, verticalOffset = 0) => {
  const wallHeight = document.getElementsByClassName('wall')[0].clientHeight
  let config = { cm: 0 + verticalOffset, px: 0 }
  if (roomType === 'STANDARD') {
    config = { cm: 7, px: 354 } // offset cm vs wall px
    const marginBottom = (wallHeight * ((config.cm - verticalOffset) * 2)) / config.px
    return { marginBottom: `${-marginBottom}px` }
  }
  if (roomType === 'SMALL') {
    config = { cm: 30 + verticalOffset, px: 240 } // offset cm vs wall px
    return { marginTop: `-${(wallHeight * (config.cm * 2)) / config.px}px` }
  }
  return config
}

/**
 * @param  {string} img
 * @param  {number} height
 * @param  {string} title
 * @param  {string} roomType
 * @param  {number} verticalOffset
 */
const ViewRoomArtwork = ({ img, height, title, roomType, verticalOffset }) => {
  const [imageProps, setImageProps] = React.useState({
    artHeight: null,
    offset: null
  })
  const params = `q_auto,${detectDpr()},c_fit`
  const dprClass = detectDpr('.0')
  const dprValue = dprClass.replace(/[^0-9]/g, '')
  const imageClass = `${!img.url.includes('.png') ? 'art' : 'transparent'} ${dprClass}`
  const imageSrc = uploadTransformationUrl(img.url, `h_${roundImageDimension(imageProps.artHeight, 100)},${params}`, { 'transformUrl': cloudinary.throughCloudfront })
  const imageHeight = imageProps.artHeight * dprValue

  React.useEffect(() => {
    setImageProps({
      artHeight: calculateHeight(height, roomType),
      offset: calculateHanging(roomType, verticalOffset)
    })
  }, [height, roomType, verticalOffset])

  React.useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setImageProps({
        artHeight: calculateHeight(height, roomType),
        offset: calculateHanging(roomType, verticalOffset)
      })
    }, 50)
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [height, roomType, verticalOffset])

  return imageProps.artHeight && imageProps.offset ? (
    <>
      <ZoomViewerUIKit.Gallery>
        <ZoomViewerUIKit.Item
          original={img.url}
          thumbnail={img.url}
          height={img.height}
          width={img.width}
          //@ts-ignore
          alt={title}
        >
          {({ ref, open }) => (
            <>
              (
              <RoomImage
                onLoad={() => {
                  if (document.querySelectorAll('.art').length > 0) {
                    document.querySelector('.art').style.visibility = 'visible'
                    if (document.querySelectorAll('.transparent').length > 0) {
                      document.querySelector('.transparent').style.visibility = 'hidden'
                    }
                  }
                  if (document.querySelectorAll('.transparent').length > 0) {
                    document.querySelector('.transparent').style.visibility = 'visible'
                    if (document.querySelectorAll('.art').length > 0) {
                      document.querySelector('.art').style.visibility = 'hidden'
                    }
                  }
                }}
                ref={ref}
                height={imageHeight}
                src={imageSrc}
                alt={title}
                className={imageClass}
                style={imageProps.offset}
                onClick={open}
              />

            </>
          )}
        </ZoomViewerUIKit.Item>
      </ZoomViewerUIKit.Gallery>
    </>) : null
}

export const RoomImage = styled.img`
  margin: 0 auto;
  display: block;

  &.art {
    cursor: zoom-in;
    image-rendering: smooth;
    box-shadow: ${tokensRenamed.shadows[1]};
    ${mq.between('tablet', 'largestTablet')`
      box-shadow: ${tokensRenamed.shadows[1]};
    `}
    ${mq.lessThan('largestMobile')`
      box-shadow: ${tokensRenamed.shadows[1]};
    `}
  }

  &.dpr_2 {
    transform: scale(0.5);
  }

  &.dpr_3 {
    transform: scale(0.33333);
  }
`

ViewRoomArtwork.propTypes = {
  img: object.isRequired,
  height: number.isRequired,
  title: string,
  roomType: string,
  setImageLoad: func,
  verticalOffset: number
}

export default ViewRoomArtwork
