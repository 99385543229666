import { FC } from 'react'
import { FormikConsumer } from 'formik'
import { Col, Button } from '@mch-group/uikit-components'
import { useUserAccessContext } from '../context/useUserAccessContext'

const LinkExpiredStep: FC = () => {
  const { state: { labels: userAccessLabels } } = useUserAccessContext()
  return (
    <FormikConsumer >
      {() => (
        <Col className='mt-0'>
          <h4 className='pb-3'>{userAccessLabels.Access.errorMessageTitleForExpiredLinkLabel}</h4>
          <p className='text-medium mt-3'>
            {userAccessLabels.Access.linkExpiredMessageLabel}
          </p>
          <div className='d-grid mt-8'>
            <Button
              type='submit'
              className='next-button'
            >
              {userAccessLabels.Access.resendLinkBtnLabel}
            </Button>
          </div>
        </Col>
      )}
    </FormikConsumer>
  )
}

export default LinkExpiredStep
