import { string, object, func } from 'prop-types'
import styled from 'styled-components'
import {
  Text
} from '@components'

/**
 * This components allows you to wrap a text, and its height will be automatically shortened if it's over a certain
 * number of height pixels. Currently this number is hardcoded to 300. Can be customized in future versions of this
 * component
 * @param {*} id: used to avoid problems in case of 2 readmore components in one page, so id is unique.
 */
const Readmore = ({ id = 'read-more', className, children, labels = {}, onFoldCallback }) => {
  // const currentText = React.useRef(children)
  const initialState = {
    foldStatus: 'folded',
    isFirstLoad: true,
    isToggleNeeded: false
  }
  const [status, setStatus] = React.useState(initialState)

  // Cannot calculate div height in server, so, useEffect
  React.useEffect(() => {
    const newStatus = { ...status }
    if (newStatus.isFirstLoad) {
      // Every time text changes, fold the Component. Also, first time text didn't change so it has to be triggered
      const outer = document.querySelector(`#${id}`)
      const textHeightOuter = outer.getBoundingClientRect().height
      const inner = document.querySelector(`#${id} .text-block`)
      const textHeightInner = inner.getBoundingClientRect().height

      if (textHeightInner <= textHeightOuter) {
        newStatus.isToggleNeeded = false
      } else {
        newStatus.isToggleNeeded = true
      }
      newStatus.isFirstLoad = false
      setStatus(newStatus)
    }
  }, [id, status])

  const onTrigger = () => {
    const newStatus = { ...status }
    newStatus.foldStatus = newStatus.foldStatus !== 'unfolded' ? 'unfolded' : 'folded'
    setStatus(newStatus)
    // When it goes from Opened to Closed...
    if (status.foldStatus === 'unfolded' && typeof onFoldCallback === 'function') {
      onFoldCallback()
    }
  }

  return (
    <div className={className}>
      <div id={id}>
        <div className={`text ${status.foldStatus} ${(status.isToggleNeeded) ? 'toggle' : ''}`}><Text className='text-block' isHtml>{children}</Text></div>
        {status.isToggleNeeded &&
          <div
            role='button'
            tabIndex={0}
            onClick={onTrigger}
            onKeyUp={onTrigger}
          ><b>{status.foldStatus === 'folded' ? `+ ${labels.ctaUnfoldText}` : `- ${labels.ctaFoldText}`}</b>
          </div>
        }
      </div>
    </div>
  )
}

const ReadmoreStyled = styled(Readmore)`
  position: relative;

  .text {
    height: 240px;
    overflow: hidden;

    &.toggle {
      mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
    }

    &.unfolded {
      transform: scaleY(1);
      height: initial;
      mask-image: initial;
    }
  }

  [role='button'] {
    cursor: pointer;
    position: absolute;
  }
`

Readmore.propTypes = {
  id: string,
  className: string,
  children: string,
  labels: object,
  onFoldCallback: func
}

export default ReadmoreStyled
