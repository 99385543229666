import { FC, memo } from 'react'
import { ErrorPage } from '@mch-group/uikit-components'
import { createGlobalStyle } from 'styled-components'
import { Media, MediaContextProvider } from '@responsive'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { getSiteLanguage } from '@utils/storage'

const LayoutStyles = memo(createGlobalStyle`
  .page-only-desktop .site-content {
    @media (max-width: 1023px) {
      display: none;
    }
  }
`)

const PageNotAvailableForMobile: FC = () => {
  const { labels } = useLabelsFromAPI(['ErrorPageMessages'], getSiteLanguage())

  return typeof labels !== 'undefined' ? (
    <MediaContextProvider>
      <Media lessThan='lg'>
        {/* @ts-ignore */}
        <ErrorPage
          title={labels['ErrorPageMessages'].errorPageScreenTooSmallTitle}
          subtitle={labels['ErrorPageMessages'].errorPageScreenTooSmallSubtitle}
          icon='resize-window'
        />
      </Media>
      <LayoutStyles />
    </MediaContextProvider>
  ) : null
}

export default PageNotAvailableForMobile
