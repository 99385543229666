/* eslint-disable no-underscore-dangle */
import React from 'react'
import initializeStore from '../stores'

const isServer = typeof window === 'undefined'
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__'

function getOrCreateStore(initialState) {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore(initialState)
  }

  // Store in global variable if client
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState)
  }
  return window[__NEXT_REDUX_STORE__]
}

const withReduxStore = App =>
  (class extends React.Component {
    static displayName = 'withReduxStore(ReduxStore)'

    static async getInitialProps(appContext) { // eslint-disable-line react/sort-comp
      // @ts-ignore
      const reduxStore = getOrCreateStore()

      // Provide the store to getInitialProps of pages
      appContext.ctx.reduxStore = reduxStore // eslint-disable-line no-param-reassign

      return {
        ...(App.getInitialProps ? await App.getInitialProps(appContext) : {}),
        initialReduxState: reduxStore.getState()
      }
    }

    constructor(props) {
      super(props)
      // @ts-ignore
      this.reduxStore = getOrCreateStore(props.initialReduxState) // eslint-disable-line react/prop-types
    }

    render() {
      // @ts-ignore
      return <App {...this.props} reduxStore={this.reduxStore} />
    }
  })

  export default withReduxStore
