import { FC } from 'react'
import { FormikConsumer } from 'formik'
import { Col, Button, Icon } from '@mch-group/uikit-components'
import { Separator, Text } from '@components'
import { useUserAccessContext } from '../context/useUserAccessContext'

interface IResendActivateAccountLink {
  recoverAccountMoreInfoLabel: string,
  onClickCloseBtn: Function
}

const RecoverAccountSecondStep: FC<IResendActivateAccountLink> = ({ recoverAccountMoreInfoLabel, onClickCloseBtn }) => {
  const { state: {
    labels: userAccessLabels,
    otherData
  } } = useUserAccessContext()

  return (
    <FormikConsumer >
      {() => (
        <Col className={!otherData.isModal ? 'mt-0' : ''}>
          <div className='d-flex'>
            {!otherData.isModal &&
              <>
                {!otherData.isModal && <h4 className='pb-8 flex-grow-1'>{userAccessLabels.Access.AccountActivationInProgressTitle}</h4>}
                <span
                  //@ts-ignore
                  onClick={onClickCloseBtn}
                  //@ts-ignore
                  onKeyUp={onClickCloseBtn}
                  tabIndex={0}
                  role='button'
                >
                  <Icon name='close' />
                </span>
              </>
            }
          </div>
          {!otherData.isModal && <Separator noPadding noMarginBottom className='mb-8 mb-md-12' />}
          {recoverAccountMoreInfoLabel &&
            <Text forwardedAs='p' isHtml className='mch-gray-500 mb-10 mb-md-12' >
              {recoverAccountMoreInfoLabel}
            </Text>
          }
          <Separator noPadding noMarginBottom className='mb-9' />
          <div className='d-grid mt-8'>
            <Button
              type='submit'
              className='next-button'
              onClick={() => { onClickCloseBtn() }}
            >
              {userAccessLabels.Access.doneBtnLabel}
            </Button>
          </div>
        </Col>
      )}
    </FormikConsumer >
  )
}

export default RecoverAccountSecondStep
