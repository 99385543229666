import React from 'react'
/**
 * This hooks help in those situations where DEV uses SWR and it doesn't notify when
 * the API is ready, and DEV needs specifically to be notified when "isValidating" goes
 * from "true" to "false"
 * @param {*} isValidating: the prop coming from the useSWR hook
 */

const useIsSWRReady = (isValidating) => {
  const [isReady, setIsReady] = React.useState(false)
  const isValidatingRef = React.useRef(null)

  React.useEffect(() => {
    // Only executes in the exact moment when isValidating goes from True to False.
    if (isValidatingRef.current === true && isValidating === false) {
      setIsReady(true)
    }
    isValidatingRef.current = isValidating
  }, [isValidating])

  return { isReady, setIsReady }
}

export default useIsSWRReady
