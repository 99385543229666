import React, { useState } from 'react'
import axios from 'axios'
import useSWR from 'swr'
import { getFingerprint, getAgentKey, getAccessToken } from '@utils'


/**
 * This fetcher is used inside all SWR petitions
 * It comes with the header configured in case the user is logged in
 * @param url
 */

/**
 * This hook can be used to track with ease and to avoid un needed re renders
 * Action can be set or not, for example, a component that could track different items, you must use the setAction,
 * but if only one is in use, you can go with the action.
 * @param itemId
 * @param action
 */
function useTracking(itemId, action = null) {
  const [internalAction, setInternalAction] = React.useState(action)
  const [entityId, setEntityId] = React.useState(null)
  const [reqParams, setReqParams] = useState({})

  const fetcher = async (url) => {
    const userToken = getAccessToken()
    const headers = userToken ? { Authorization: `Bearer ${userToken}` } : {}
    let clientInfo = { 'agentKey': await getAgentKey(), 'fingerprint': await getFingerprint() }
    if (Object.values(reqParams).some(value => !!value)) {
      clientInfo = { ...clientInfo, ...reqParams }
    }
    return axios.post(url, clientInfo, { headers }).then((response) => response.data)
  }

  const shouldTrackAction = () => {
    if (internalAction && itemId) {
      return `/msvc/v1/tracking/${internalAction}/${itemId}`
    }
    if (entityId && itemId) {
      return `/msvc/v1/tracking/${entityId}/${itemId}`
    }
    return null
  }

  const { data } = useSWR(shouldTrackAction(), fetcher)

  //we need to reset the internal action to null once the tracking api
  //is called otherwise this is creating some side effects(like calling
  //the tracking api again with the same action) while using the
  //useTracking hook.
  React.useEffect(() => {
    if (typeof data !== 'undefined') {
      setInternalAction(null)
      setReqParams({})
    }
  }, [data, setInternalAction])

  /**
   * This little trick is used to not re render in case the action send to the callback is equal to the one set it lets
   * you use the setAction() inside the same flow of the app without the necessity of worrying about side effects nor
   * re renders
   */
  const setAction = React.useCallback(
    (actionInCallback, params = {}) => {
      if (!reqParams) {
        setReqParams(params)
      }
      if (internalAction !== actionInCallback) {
        setInternalAction(actionInCallback)
      }
    },
    [internalAction, reqParams]
  )

  /**
   * Used to track OSIntro
   */
  const trackAction = React.useCallback(
    (actionInCallback) => {
      setEntityId(actionInCallback)
    }, [])

  return {
    // Nobody seems to be calling these lines, so commenting them. To be removed if it proofs correct.
    /*
    hasTracked: typeof data !== 'undefined' && !error,
    isTryingToTrack: typeof data === 'undefined' || isValidating,
    hasAnError: !!error,
    */
    setAction,
    trackAction
  }
}

export default useTracking