import PropTypes from 'prop-types'

import { formatDateRange } from '@utils'
import { date } from '@constants'

/**
 *
 * @param {*} startDate Date in format YYYY-MM-DD hh:mm
 * @param {*} startDateOutputFormat Date in format YYYY-MM-DD hh:mm
 * @param {*} endDate Date in format YYYY-MM-DD hh:mm
 * @param {*} endDateOutputFormat Date in format YYYY-MM-DD hh:mm
 * @param {*} timezone string
 * @returns DateRangeRenderer Component
 */

// example output: Aug 24 - Aug 25, 2021 OR Aug 24, 04:25 pm (BT) - Aug 25, 2021
const DateRangeRenderer = ({
  startDate,
  startDateOutputFormat = date.FE_DATE_FORMAT,
  endDate,
  endDateOutputFormat = date.FE_DATE_FORMAT,
  timezone,
  className
}) => (
  <div className={`h4 ${className}`}>
    {formatDateRange(startDate, startDateOutputFormat, endDateOutputFormat, timezone, endDate)}
  </div>
  )

DateRangeRenderer.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  startDateOutputFormat: PropTypes.string,
  endDateOutputFormat: PropTypes.string,
  timezone: PropTypes.string,
  className: PropTypes.string
}

export default DateRangeRenderer
