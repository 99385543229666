import React from 'react'

const ShadowOverlay = () => (
  <div
    className='AnimatedShowMore__ShadowOverlay'
    style={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '60px'
    }}
  />
)

export default ShadowOverlay