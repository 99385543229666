import React from 'react'
import { Collections as CollectionsService } from '@services'
import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'
import { useCollectionsContext } from '../contexts/useCollectionsContext'

/**
 * @param {} on: This param indicates wether the Hooks are active by default or not.
 * It can be set to true with the setActive function
 */
const useMyCollectionsData = (on, pageSize = 36) => {
  const [{ list: myCollectionList }, dispatch] = useCollectionsContext()
  const [entity, setEntity] = React.useState(null)
  const [active, setActive] = React.useState(on)
  const entityTypesForParams = '2,4c,1,3,4a,4b,4d'

  // Collections
  const {
    data: myCollections,
    error: myCollectionsError,
    isValidating: myCollectionsValidating,
    mutate: mutateMyCollections,
    size,
    setSize
  } = useSWRInfinite(
    (index) =>
      /**
       * I'm afraid this could be rephrased, but...
       * Just want to make sure that it triggers ONLY when it's active or there's an entityId + active)
       */

      active && (entity || active) && `${CollectionsService.getMyCollectionsEndpoint}?limit=${pageSize}&offset=${index * pageSize}${!entity ? `&countOnlyItemsOfTypes=${entityTypesForParams}` : '&orderBy=name'}&field=id,itemsCount,name,numFollowers,shareUrl,thumbnailImageUrls,collectionType`
    , {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      revalidateAll: true
    })

  // Relation of Collections and Entities
  const {
    data: relatedCollections,
    isValidating: membersValidating
  } = useSWR(
    () => active && (entity) && CollectionsService.getCollectionsByMembersEndpoint(entity.type, entity.id)
  )

  // Favorites
  const {
    data: myFavoritesCollection,
    error: myFavoritesCollectionError,
    isValidating: myFavoritesCollectionValidating,
    mutate: mutateMyFavoritesCollection
  } = useSWR(active && (entity || active) && `${CollectionsService.getFavoritesCollectionEndpoint}?limit=1&countOnlyItemsOfTypes=${entityTypesForParams}&field=id,itemsCount,thumbnailImageUrls,collectionType`,
    {
      revalidateOnReconnect: false,
      revalidateOnMount: size === 1 ? true : false,
      revalidateOnFocus: false
    })

  /** Merging the collections list with the entities passed, so the resulting list will be clone of the original,
    * plus a boolean in each collection, indicating if that entityId (artwork, gallery, etc) is included in that
    * Collection.
    */
  React.useEffect(() => {
    if (active) {
      if (entity && myCollections && relatedCollections) {
        let myCollectionsCrossedWithEntityId = [...myCollections]
        if (relatedCollections.length > 0) {
          const matchedEntities = relatedCollections[0].collectionIds
          myCollectionsCrossedWithEntityId = myCollections ? myCollections
            .map(i => {
              const newObj = { ...i }
              newObj.items = newObj.items.map((item) => {
                const newItem = { ...item }
                matchedEntities && matchedEntities.forEach((collection) => {
                  if (item.id === collection) {
                    newItem.hasSelectedEntityId = entity && relatedCollections?.find((match) => match?.subEntityId ?
                    match.subEntityId === entity.subEntityId.toString() : match.entityId === entity.id.toString())
                  }
                })
                return newItem
              })
              return newObj
            }) : []
        }
        dispatch({ type: 'SET_LIST', payload: myCollectionsCrossedWithEntityId })
      } else if (active && myCollections) {
        dispatch({ type: 'SET_LIST', payload: myCollections })
      }
    }

  }, [entity, dispatch, myCollections, relatedCollections, active])

  const itemsLength = myCollectionList.length
  const items = itemsLength ? myCollectionList.map(i => i.items) : []
  const posts = items ? [].concat(...items) : []
  const isLoadingInitialData = !myCollectionList && !myCollectionsError
  const isEmpty = itemsLength ? !myCollectionList.slice(-1)[0].hasMore : true
  const isReachingEnd = isEmpty || (itemsLength ? !myCollectionList[0].hasMore : false)
  const isRefreshing = myCollectionsValidating && itemsLength && myCollectionList[0].items?.length === size
  const isLoadingMore = isLoadingInitialData || (size > 0 && itemsLength && typeof myCollectionList[size - 1] === 'undefined')
  // Find if current entity id is a favorited one or not
  const isEntityFavorited = entity && relatedCollections?.find((match) => match?.subEntityId ?
    match.subEntityId === entity?.subEntityId.toString() : match.entityId === entity?.id.toString())?.collectionIds?.some(v => v.includes('favs_'))

  return {
    // Colletions
    isLoadingInitialData,
    size,
    setSize,
    isReachingEnd,
    isRefreshing,
    isLoadingMore,
    isEmpty,
    myCollections: posts,
    myCollectionPages: items,
    myCollectionsError,
    myCollectionsValidating,
    mutateMyCollections,
    mutateMyFavoritesCollection,
    // Favorites
    myFavoritesCollection,
    myFavoritesCollectionError,
    myFavoritesCollectionValidating,
    // Matching with Entities
    setEntity,
    entity,
    membersValidating,
    isEntityFavorited,
    active,
    setActive
  }
}

export default useMyCollectionsData
