import React, { createContext, FC, useState } from 'react'
import Loadingscreen, { ILoadingscreen } from '@components/Loadingscreen'

const LoadingscreenContext = createContext<ILoadingscreenContext>({} as ILoadingscreenContext)

/**
 * This Provider creates a context to store info that can be shared between components
 */
const LoadingScreenProvider: FC<ILoadingscreenProps> = ({ children }) => {
  const [visible, setVisible] = useState(false)
  const [optionParams, setOptionParams] = useState<ILoadingscreen>({})

  const showLoading = async (params: ILoadingscreen) => {
    setOptionParams({
      text1: params.text1,
      text2: params.text2,
      showSpinner: params.showSpinner,
      theme: params.theme
    })
    setVisible(true)
  }

  const hideLoading = () => {
    setOptionParams({})
    setVisible(false)
  }

  return (
    <LoadingscreenContext.Provider value={{ showLoading, hideLoading, visible }}>
      {visible &&
        <Loadingscreen
          text1={optionParams.text1}
          text2={optionParams.text2}
          showSpinner={optionParams.showSpinner}
          theme={optionParams.theme}
        />
      }
      {children}
    </LoadingscreenContext.Provider>
  )
}

// Props the Provider will receive. Usually it's just the "children" for all Providers
interface ILoadingscreenProps {
  children: React.ReactNode
}

// Properties the Context returns
interface ILoadingscreenContext {
  showLoading: (params: ILoadingscreen) => void
  hideLoading: Function,
  visible: boolean
}

export default LoadingScreenProvider

export { LoadingscreenContext }
