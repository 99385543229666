import React from 'react'
import { OverlayContext } from '@providers/Overlay'

const Overlay = () => {
  //@ts-ignore
  React.useContext(OverlayContext)

  return null
}
Overlay.displayName = 'Overlay'

export default Overlay
