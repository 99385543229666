import React, { useReducer, useRef } from 'react'

/**
 * React is cool, but has a problem, when you change state from hide to visible, it's tough to make a transition
 * This hook makes the transition from HIDDEN to SHOWN in 2 steps, for example, you can first show your element
 * in visible: 0, and in a second step, visible: 1, and with a transition: opacity 1s you would have a 1s fade in.
 * Opacity is just an example, can be used with any kind of transition. There will be an example
 * of this in Notifications.js
 */

const initial = {
  exists: false,
  visible: false
}

const reducer = (current, action) => {
  if (action.type == 'SHOW_STEP_1') {
    return {
      exists: true,
      visible: false
    }
  }
  if (action.type == 'SHOW') {
    return {
      exists: true,
      visible: true
    }
  }
  if (action.type == 'HIDE') {
    return initial
  }
}

/**
 *
 * @param {*} transitionTime: Time the transition will take, in miliseconds. Usually only needed when hiding,
 * it's the margin time the item has to complete its animation, before it's removed from the DOM
 */
const useVisibilityTransition = (transitionTime) => {
  const isGoingToShow = useRef(false)
  const [state, dispatch] = useReducer(reducer, initial)
  React.useEffect(() => {
    if (state.exists && !state.visible) {
      if (isGoingToShow.current) {
        dispatch({ type: 'SHOW' })
      } else {
        // Leave some time to the Notification before removing from the DOM, in order to complete the animation
        setTimeout(() => {
          dispatch({ type: 'HIDE' })
        }, transitionTime)
      }
    }
  }, [state, transitionTime])

  function show() {
    isGoingToShow.current = true
    dispatch({ type: 'SHOW_STEP_1' })
  }

  function hide() {
    isGoingToShow.current = false
    dispatch({ type: 'SHOW_STEP_1' })
  }

  return { show, hide, state }
}

export default useVisibilityTransition
