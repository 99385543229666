/*
This hook fires the resize event when scrolling up/down in iPhone
Not recommended to use until this is fixed.
*/

import { useState, useEffect } from 'react'
import { debounce } from 'lodash'
import ISize from '../types/ISize'

function useWindowSize(debounceTime: number = 500): ISize {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState<ISize>({
    width: 0,
    height: 0
  })

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = debounce(() => {
      if(typeof window !== 'undefined') {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        })
      }
    }, debounceTime)

    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [debounceTime])
  return windowSize
}

export default useWindowSize