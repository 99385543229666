import { FC } from 'react'
import { FormikConsumer } from 'formik'
import { Col, Button, Icon } from '@mch-group/uikit-components'
import { useUserAccessContext } from '../context/useUserAccessContext'
import Separator from '@components/Separator'

interface IRecoverAccountFirstStep {
  onClickCloseBtn: Function,
  onClickBackBtn: Function,
  firstName?: string,
  backLabel: string
}

const RecoverAccountFirstStep: FC<IRecoverAccountFirstStep> = ({
    onClickCloseBtn,
    onClickBackBtn,
    firstName,
    backLabel
}) => {
  const { state: { labels: userAccessLabels, otherData, isLoading } } = useUserAccessContext()

  return (
    <FormikConsumer >
      {({ setFieldValue }) => (
        <Col className={!otherData.isModal ? 'mt-0' : ''}>
          <div className='d-flex mb-8'>
            {!otherData.isModal &&
              <>
                <span
                  //@ts-ignore
                  onClick={() => { onClickBackBtn() }}
                  //@ts-ignore
                  onKeyUp={() => { onClickBackBtn() }}
                  tabIndex={0}
                  role='button'
                  className='flex-grow-1'
                >
                  <Icon name='arrow-left' />
                  <p className='text-medium d-inline-block ps-3 mb-3'>{backLabel}</p>
                </span>
                <span
                  //@ts-ignore
                  onClick={onClickCloseBtn}
                  //@ts-ignore
                  onKeyUp={onClickCloseBtn}
                  tabIndex={0}
                  role='button'
                >
                  <Icon name='close' />
                </span>
              </>
            }
          </div>
          {!otherData.isModal &&
            <>
              <h4>{userAccessLabels.Access.WelcomeBackTitle}, {firstName}!</h4>
              <h4 className='pb-3'>{userAccessLabels.Access.recoverAccountTitle}</h4>
              <p className='text-medium mt-3 pb-5'>
                {userAccessLabels.Access.recoverAccountSubtitle}
              </p>
              <Separator noPadding noMarginBottom className='mb-9' />
            </>
          }
          <div className='d-grid mt-8'>
            <Button
              type='submit'
              className='next-button'
              isLoading={isLoading}
              onClick={() => {
                setFieldValue('submitBtn', 'type 1')
              }}
            >
              {userAccessLabels.Access.recoverAccountBtnLabel}
            </Button>
          </div>
          <div className='d-grid mt-6'>
            <Button
              type='submit'
              variant='secondary'
              onClick={() => {
                setFieldValue('submitBtn', 'type 2')
              }}
            >
              {userAccessLabels.Access.CancelBtnLabel}
            </Button>
          </div>
        </Col>
      )}
    </FormikConsumer>
  )
}

export default RecoverAccountFirstStep
