/** This file has the classic Context/Provider structure */
import { FC } from 'react'

import { createContext, useContext } from 'react'
import _ from 'lodash'
import { PageContext } from '@providers/PageContext'
import type { IStaticData } from 'types/IAppProps'

const AppContext = createContext<IAppContextStore>({
  state: {}
} as IAppContextStore)

export const useModuleContext = () => useContext(AppContext)

const AppProvider: FC<IAppProvider> = ({ children }) => {
  const { page: { staticData } } = useContext(PageContext)

  const isLoaded = staticData && Object.keys(staticData).length > 0
  !global.persistentSSRData && Object.assign(global, { persistentSSRData: staticData })
  const persistentData = isLoaded ? staticData : global.persistentSSRData
  return (
    <AppContext.Provider value={{ state: persistentData }}>{children}</AppContext.Provider>
  )
}

interface IAppContextStore {
  state: IStaticData
}

interface IAppProvider {}

export default AppProvider

export { AppContext }