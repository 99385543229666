import { ApiResponse } from 'apisauce'

/**
 *  This function removes passwords from the response object to avoid logging them in the console or in the logs
 *
 * @param response Receives a response
 * @param logData This is a custom object created by DEVs, maybe could be removed when in a save branch
 * @returns returns a response but manipulated to remove passwords
 */
export const removePasswordsFromResponse = (response: ApiResponse<any,any>, logData): ApiResponse<any,any> => {
  // Clearing passwords
  let responseWithoutPassword = { ...response }
  if (logData.method?.toUpperCase() === 'POST' || logData.method?.toUpperCase() === 'PUT') {
    if (responseWithoutPassword?.config?.data) {
      const onlyDataNodeJSON = JSON.parse(responseWithoutPassword.config.data)
      if (onlyDataNodeJSON.password) {
        delete onlyDataNodeJSON.password
        responseWithoutPassword.config.data = JSON.stringify(onlyDataNodeJSON)
      } else if (onlyDataNodeJSON.properties) {
        const index = onlyDataNodeJSON.properties.findIndex((item) => item.name === 'password')
        if (index > -1) {
          onlyDataNodeJSON.properties.splice(index, 1)
          responseWithoutPassword.config.data = JSON.stringify(onlyDataNodeJSON)
        }
      } else if (onlyDataNodeJSON.newPassword) {
        delete onlyDataNodeJSON.newPassword
        responseWithoutPassword.config.data = JSON.stringify(onlyDataNodeJSON)
      }
    }
  }
  return responseWithoutPassword
}