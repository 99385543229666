/**
 * This hook returns a value that indicates if the tab is active yes/no
 * This could be much simpler, but found this code that apparently checks multiple devices
 * https://blog.sethcorker.com/harnessing-the-page-visibility-api-with-react/
 */
import { useEffect, useState } from 'react'

const getBrowserVisibilityProp = ():string => {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange'
  } else if (typeof document['msHidden'] !== 'undefined') {
    return 'msvisibilitychange'
  } else if (typeof document['webkitHidden'] !== 'undefined') {
    return 'webkitvisibilitychange'
  }
  return 'visibilitychange'
}

const getBrowserDocumentHiddenProp = ():string => {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden'
  } else if (typeof document['msHidden'] !== 'undefined') {
    return 'msHidden'
  } else if (typeof document['webkitHidden'] !== 'undefined') {
    return 'webkitHidden'
  }
  return 'hidden'
}

const getIsDocumentHidden = ():boolean => typeof document !== 'undefined' && !document[getBrowserDocumentHiddenProp()]

const useTabActive = ():boolean => {
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden())
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden())

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp()

    document && document.addEventListener(visibilityChange, onVisibilityChange, false)

    return () => {
      document && document.removeEventListener(visibilityChange, onVisibilityChange)
    }
  },[])

  return isVisible
}

export default useTabActive
