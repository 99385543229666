import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

// Some tokens we rename for order purpose, or similar
const tokensRenamed = {
  // Line heights have weird order in Lib, so better to reorder
  lineHeights: {
    0: tokens.lineHeights[14],
    1: tokens.lineHeights[11],
    2: tokens.lineHeights[15],
    3: tokens.lineHeights[4],
    4: tokens.lineHeights[13],
    5: tokens.lineHeights[3],
    6: tokens.lineHeights[12],
    7: tokens.lineHeights[2],
    8: tokens.lineHeights[1],
    9: tokens.lineHeights[16],
    10: tokens.lineHeights[7],
    11: tokens.lineHeights[0],
    12: tokens.lineHeights[19],
    13: tokens.lineHeights[8],
    14: tokens.lineHeights[6],
    15: tokens.lineHeights[9],
    16: tokens.lineHeights[5],
    17: tokens.lineHeights[10],
    18: tokens.lineHeights[18],
    19: tokens.lineHeights[17]
  },
  // These shadows are used for box-shadows Need to check this with Josep
  shadows: {
    1: `${tokens.shadows.outerShadow['01'][1].x.value}px ${tokens.shadows.outerShadow['01'][1].y.value}px ${tokens.shadows.outerShadow['01'][1].blur.value}px ${tokens.shadows.outerShadow['01'][1].spread.value}px ${tokens.shadows.outerShadow['01'][1].color.value}, ${tokens.shadows.outerShadow['01'][2].x.value}px ${tokens.shadows.outerShadow['01'][2].y.value}px ${tokens.shadows.outerShadow['01'][2].blur.value}px ${tokens.shadows.outerShadow['01'][2].spread.value}px ${tokens.shadows.outerShadow['01'][2].color.value}`,
    2: `${tokens.shadows.outerShadow['02'][1].x.value}px ${tokens.shadows.outerShadow['02'][1].y.value}px ${tokens.shadows.outerShadow['02'][1].blur.value}px ${tokens.shadows.outerShadow['02'][1].spread.value}px ${tokens.shadows.outerShadow['02'][1].color.value}, ${tokens.shadows.outerShadow['02'][2].x.value}px ${tokens.shadows.outerShadow['02'][2].y.value}px ${tokens.shadows.outerShadow['02'][2].blur.value}px ${tokens.shadows.outerShadow['02'][2].spread.value}px ${tokens.shadows.outerShadow['02'][2].color.value}`,
    3: `${tokens.shadows.outerShadow['03'][1].x.value}px ${tokens.shadows.outerShadow['03'][1].y.value}px ${tokens.shadows.outerShadow['03'][1].blur.value}px ${tokens.shadows.outerShadow['03'][1].spread.value}px ${tokens.shadows.outerShadow['03'][1].color.value}, ${tokens.shadows.outerShadow['03'][2].x.value}px ${tokens.shadows.outerShadow['03'][2].y.value}px ${tokens.shadows.outerShadow['03'][2].blur.value}px ${tokens.shadows.outerShadow['03'][2].spread.value}px ${tokens.shadows.outerShadow['01'][2].color.value}`
  }
}

// The original lines generated by Figma... With the "correct" ordering, generated with Excel
// 0	mch_line_heights_14	1	16px
// 1	mch_line_heights_11	1.125	18px
// 2	mch_line_heights_15	1.25	20px
// 3	mch_line_heights_4	1.3125	21px
// 4	mch_line_heights_13	1.375	22px
// 5	mch_line_heights_3	1.4375	23px
// 6	mch_line_heights_12	1.5	24px
// 7	mch_line_heights_2	1.75	28px
// 8	mch_line_heights_1	1.875	30px
// 9	mch_line_heights_16	2	32px
// 10	mch_line_heights_7	2.125	34px
// 11	mch_line_heights_0	2.1875	35px
// 12	mch_line_heights_19	2.25	36px
// 13	mch_line_heights_8	2.5625	41px
// 14	mch_line_heights_6	2.625	42px
// 15	mch_line_heights_9	2.875	46px
// 16	mch_line_heights_5	3	48px
// 17	mch_line_heights_10	3.125	50px
// 18	mch_line_heights_18	3.375	54px
// 19	mch_line_heights_17	4	64px

export default tokensRenamed
