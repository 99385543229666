import { createContext, useContext } from 'react'

// The infrastructure is set, but not used
export const reducer = (state, action) => {
  switch (action.type) {
    default:
      return { ...state }
  }
}

export const initialState = {
  labels:  null,
  router: null,
  user: null,
  pageMetaInfo: null
}

export const StateContext = createContext(initialState)

export const useSpecialRoomContext = () => useContext(StateContext)
