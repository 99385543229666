
import React from 'react'
import { Container } from '@mch-group/uikit-components'
import PropTypes from 'prop-types'

const RmHeadlineTitle = ({ className, ...props }) => (
  <Container
    id={props.id}
    as='section'
    className={`px-5 px-lg-8 px-md-7 my-md-12 my-8 ${className}`}
    >
    {props['hch-grp1_title_style'] === 'title_style_2' ?
      <h2 className='header-uppercase-1 h3'>{props['hch-grp1_header']}</h2> : <h2 className='header-uppercase-1 h4'>{props['hch-grp1_header']}</h2>}
  </Container>
)

RmHeadlineTitle.propTypes = {
  'hch-grp1_title_style': PropTypes.string,
  'hch-grp1_header': PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.number
}

export default RmHeadlineTitle
