import urls from '../constants/specialUrls'

const isSpecialUrl = (currentUrl) => {
  const urlFound = urls.find((url) => {
    if (currentUrl.indexOf(url) > -1) {
      return url
    }
    return false
  })
  return urlFound
}

export default isSpecialUrl
