export const logPageView = (userMe: ABTypes.Userprofiles.User, siteLanguage, meAccountType) => {
  const langs = {
    en: 'english',
    zh_CN: 'chinese',
    fr: 'french',
    de: 'german',
    es: 'spanish'
  }
  // init data layer
  //@ts-ignore
  window.dataLayer = window.dataLayer || []

  const isGalleryUser = meAccountType?.length > 0 ? meAccountType[0].type === '102900001' : false
  let getGalleryName = null
  if (isGalleryUser) {
    if (meAccountType[0].type === '102900001') {
      getGalleryName = meAccountType[0].name
    }
  }

  const dataToSend = {
    event: 'pageview',
    loginStatus: userMe !== null ? 'logged_in' : 'guest',
    vipStatus: userMe !== null && userMe.vipStatus !== 'none' ? 'vip' : 'non_vip',
    vipTier: userMe !== null && userMe.vipStatus !== 'none' ? userMe.vipStatus : 'none',
    siteLanguage: langs[siteLanguage],
    userId: userMe !== null ? userMe.accountCrmId : null,
    galleryUser: isGalleryUser,
    galleryName: getGalleryName
    //accountName: meAccountType?.length > 0 ? meAccountType[0].name : null,
    //accountType: meAccountType?.length > 0 ? meAccountType[0].typeDescription : 'guest'
  }
  dataLayer.push(dataToSend)
}

export const logDeletedUser = () => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    window.dataLayer = window.dataLayer || []
  }
  // prepare the object to send to the data layer
  //When Client will provide the detail we will add it.
  const objectToSend = {}
  // push the object to the data layer
  //@ts-ignore
  dataLayer.push(objectToSend)
}

export default {}
