import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import thunk from 'redux-thunk'

import rootReducer from './_reducers'
import timing from '../lib/redux-performance-mark'

const isProd = process.env.NODE_ENV !== 'development'

const initializeStore = (initialState = {}) => {
  const middlewares = [thunk]
  if (!isProd && process.browser) {
    //@ts-ignore
    middlewares.push(timing)
  }

  const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middlewares)))

  return store
}

export default initializeStore
