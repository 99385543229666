import React, { FC, ReactNode } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const mID = 'rm-add-to-calendar'

interface AddEventCalendarProps {
  label?: string,
  title: string,
  description: string,
  location?: string,
  startTime: string,
  endTime: string,
  timezoneId: string,
  className: string,
  text: string,
  virtual: boolean,
  virtualUrl: string,
  isDisplayCalendarIcon: boolean,
  shareIcon: ReactNode,
  moduleId: string
}

const AddEventCalendar:FC<AddEventCalendarProps> = ({
  startTime,
  endTime,
  timezoneId,
  title,
  text,
  description,
  location,
  className,
  virtual,
  virtualUrl,
  isDisplayCalendarIcon,
  shareIcon,
  moduleId
}) => {
  const [isCalendarReady, setCalendarReady] = React.useState(false)

  /* Installs the Calendar Plugin */
  React.useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cdn.addevent.com/libs/atc/1.6.2/atc.min.js'
    document.head.appendChild(script)
    !isCalendarReady && setCalendarReady(true)
  }, [isCalendarReady])

  return isCalendarReady ? (
    <div id={`addevent-calendar-${moduleId}`} className={`calendar ${classNames(className)}`}>
      <div className={classNames('addeventatc', mID)}>
        {text && !isDisplayCalendarIcon && text}
        <span className='start d-none'>{startTime}</span>
        <span className='end d-none'>{endTime}</span>
        <span className='timezone d-none'>{timezoneId}</span>
        <span className='title d-none'>{title}</span>
        <span className='description d-none'>{description?.substring(0,5000)}</span>
        <span className='location d-none'>{virtual ? virtualUrl : location}</span>
        {isDisplayCalendarIcon && shareIcon}
      </div>
    </div>
  ) : null
}

const AddEventCalendarStyled = styled(AddEventCalendar)`
  .${mID} {
    box-shadow: none !important;
    border: none !important;
    padding: 0;
    font: inherit !important;
    color: inherit !important;

    &_icon {
      display: none;
    }

    &[id] {
      display: inline-block;
      z-index: 0;
    }

    .addeventatc_icon {
      display: none;
    }
  }

  .icon {
    background-color: ${tokens.color.light.base.neutrals[0].value};
  }
`

export default AddEventCalendarStyled
