import useSWR from 'swr'

const useDynamicLabel = (entityType, entityId, fieldName, locale, params) => {
  const { data, error, isValidating } =
    useSWR(`/msvc/v1/translations/values/${entityType}/${entityId}/${fieldName}/${locale}/value?${params}`,
    {
      revalidateOnFocus: false
    })
  return { error, data, isValidating }
}

export default useDynamicLabel
