import { Megamenu, SkipToMainContent } from '@mch-group/uikit-components'
import findColorSeparator from '@utils/findColorSeparator'
import { CustomLinkTag } from '@components'
import useMegamenu from './useMegamenu'

const SiteHeader = () => {
  const {
    accessToken,
    constructedProfileFlyoutData,
    globalModuleLabels,
    labels,
    languageSelectedInMenu,
    listRender,
    megaMenuDataWithImagesCompressed,
    router,
    siteHostName,
    userMe,
    secondaryNav,
    secondaryNavActiveIndex
  } = useMegamenu()

  return (
    <>
      <SkipToMainContent targetId='content' label={labels?.AltTags?.generalSkipToMain || 'Jump to Content'} />
      <Megamenu
        secondaryNavActiveKey={secondaryNavActiveIndex}
        // @ts-ignore
        secondaryNavLinkRenderer={({ label, link }) => (
          <CustomLinkTag url={link} ariaLabel='label' noRel>
            {label}
          </CustomLinkTag>
        )}
        showSecondaryNavbar={secondaryNav?.isDisplaySecondNavbar}
        secondaryNavData={secondaryNav?.menuItems}
        onClickSecondaryNavItem={(url, label) => <a href={url}>Whatever custom link {label}</a>}
        profileWelcomeHeader='Welcome, '
        userData={{
          name: userMe?.fullName as string,
          vipStatus: userMe?.vip as boolean,
          isUserLoggedIn: !!accessToken
        }}
        onSearch={(searchTerm) => {
          router?.push(
            {
              pathname: '/',
              query: { q: searchTerm }
            },
            `/search?q=${searchTerm}`
          )
        }}
        // @ts-ignore
        linkRenderer={(link, label, target, ariaLabel, linkRef) => (
          <CustomLinkTag url={link} ariaLabel={ariaLabel} linkRef={linkRef} noRel>
            {label}
          </CustomLinkTag>
        )}
        profileData={constructedProfileFlyoutData as any}
        /* This fallback follows UIKit team suggestion */
        menuData={megaMenuDataWithImagesCompressed || []}
        languageData={{
          languageHeader: labels && labels.MegaMenus.languageSelector as any,
          languageSelected: languageSelectedInMenu === 'zh_CN' ? 'zh' : languageSelectedInMenu as any,
          languageEntries: listRender as any,
          // @ts-ignore
          onLanguageClick: (type) => {
            const url = new URL(window.location.href)
            // Note: In the core side we are using zh_CN for language change so here we are setting the searchparmas with that
            url.searchParams.set('lang', type === 'zh' ? 'zh_CN' : type)
            window.location.href = url.href
          }
        }}
        // @ts-ignore
        loggedCollectionUrl={labels && labels.Collections.myCollectionsURL}
        // @ts-ignore
        unloggedCollectionUrl={labels && `/access?redirect=${labels.Collections.myCollectionsURL}`}
        collectionButtonTitle={labels && labels.Collections.myCollectionsTabLabel}
        searchPlaceholder={globalModuleLabels?.las_grp1_search_header}
        logoVariant={
          siteHostName?.variant === 'artbasel' && router ? findColorSeparator(router.asPath) : siteHostName?.logoVariant
        }
        // @ts-ignore
        sponsorData={
          siteHostName?.variant === 'artbasel'
            ? {
              name: 'ubs',
              link: 'https://www.ubs.com/global/en/our-firm/art.html',
              target: '_blank',
              label: labels?.Sponsors?.ubsLabel || 'Global Lead Partner'
            }
            : null
        }
      />
    </>
  )
}

export default SiteHeader
