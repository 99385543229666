import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Row, Col } from '@mch-group/uikit-components'

import { Loader } from '@components'
import SpecialRoomCardItem from '@components/SpecialRoomCard'
import IntersectionCapture from '@reusable-modules/IntersectionCapture'
import useCollectionUtils from '@modules/collections/hooks/useCollectionUtils'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import TabContentHeader from '../TabContentHeader'
import { useBackNavigation } from '@hooks'

const SpecialRoomsTab = (props) => {
  const {
    editPreviewCollectionItems,
    isRefreshing,
    isEmpty,
    isLoadingInitialData,
    editPreviewCollectionItemsValidating,
    setSize,
    size,
    isLoadingMore,
    totalItems,
    isTabExist
  } = props
  //@ts-ignore
  const [{ labels, isOrganizeMode, selectedItems }] = useMyCollectionsPageContext()
  const { onSelectCard } = useCollectionUtils()
  const { keys } = useBackNavigation()

  const isIntersectionActive =
    editPreviewCollectionItems &&
    !editPreviewCollectionItemsValidating &&
    !isRefreshing &&
    !isEmpty &&
    !isLoadingInitialData &&
    !isLoadingMore

  const prepareSpecialRoomData = (event) => {
    let roomItem = {}
    let sectorData = ''
    if (event.summary?.extraInfo8 && event.summary?.extraInfo2){
      sectorData = (event.summary?.extraInfo8).concat(`,${event.summary?.extraInfo2}`)
    }
    else{
      if (event.summary?.extraInfo8){
        sectorData = event.summary?.extraInfo8
      }
      else{
        sectorData = event?.summary?.extraInfo2
      }
    }
    const sectors = sectorData ? sectorData?.split(',').map((val, i) => ({ id: i, name: val })) : []
    roomItem = {
      id: event.entityId,
      cleanTitle: event.summary?.extraInfo6,
      sectors,
      virtual: event.summary?.extraInfo3,
      artworks: [
        {
          id: 0,
          displayName: event.summary?.extraInfo4 ? event.summary.extraInfo4 : '',
          year: ''
        }
      ],
      eventImage: event.summary?.image,
      image: event.summary?.image,
      onlineShow: { name: event.summary?.extraInfo9, year: event.summary?.extraInfo16 },
      artistName: event.summary?.extraInfo5,
      specialRoom: true,
      hybridShow: true /* special sector rooms are only available in hybrid show */
    }
    return roomItem
  }

  return (
    <div>
      <TabContentHeader
        total={totalItems}
        counterSubText={labels.Collections.specialRoomSubheadingLabel}
        isTabExist={isTabExist}
      />
      {editPreviewCollectionItems.length > 0 && (
        <>
          <Row>
            {editPreviewCollectionItems.map((event) => {
              const isSpecialRoomtNotAvailable = Object.values(event.status).some((item) => item === true)
              let roomItemObjForArchivedRoom = prepareSpecialRoomData(event)
              let roomItemObjForForbiddenRoom = prepareSpecialRoomData(event)

              const dataToSend = () => {
                if (!isSpecialRoomtNotAvailable) {
                  return event.item
                } else if (event.status.archived && !event.status.hidden) {
                  return roomItemObjForArchivedRoom
                } else if (event.status.forbidden || event.status.hidden) {
                  return roomItemObjForForbiddenRoom
                } else {
                  return event?.summary
                }
              }

              return (
                <Col xs={12} sm={12} md={6} lg={4} xl={3} xxl={3} className='mb-lg-8 mb-md-8' key={event.id}>
                  {!isSpecialRoomtNotAvailable ? (
                    <SpecialRoomCardItem
                      item={dataToSend()}
                      isOrganizeMode={isOrganizeMode}
                      isUserAllowed
                      isCollectionCard
                      navigationKey={keys.COLLECTION.INSIDE_MY_COLLECTION}
                      //@ts-ignore
                      onSelectCard={onSelectCard}
                      isSelectedInCollections={
                        typeof selectedItems?.find((x) => x.entityId?.toString() === (event.id?.toString() || event?.entityId?.toString())) !== 'undefined'
                      }
                      labels={{
                        ovrVisitedRoomLabel: labels?.OnlineShowRoom?.ovrVisitedRoomLabel,
                        addToCollection: labels?.AltTags?.addToCollection,
                        generalViewSpecialRoom: labels?.AltTags?.generalViewSpecialRoom || 'View the Special Room:',
                        showComingSoonLabel: labels?.OnlineShowRoom?.showComingSoonLabel,
                        notAvailableForViewLabel: labels?.Collections?.notAvailableForViewLabel
                      }}
                    />
                  ) : (
                    <SpecialRoomCardItem
                      item={dataToSend()}
                      isOrganizeMode={isOrganizeMode}
                      isUserAllowed={event.status.hidden ? event.status.hidden : !event.status.forbidden}
                      unavailableToView={event.status.hidden}
                      isCollectionCard
                      navigationKey={keys.COLLECTION.INSIDE_MY_COLLECTION}
                      isSpecialRoomArchived={event.status.archived}
                      //@ts-ignore
                      onSelectCard={onSelectCard}
                      isSelectedInCollections={
                        typeof selectedItems?.find((x) => x.entityId === (event.id || event?.entityId)) !== 'undefined'
                      }
                      labels={{
                        ovrVisitedRoomLabel: labels?.OnlineShowRoom?.ovrVisitedRoomLabel,
                        addToCollection: labels?.AltTags?.addToCollection,
                        generalViewSpecialRoom: labels?.AltTags?.generalViewSpecialRoom || 'View the Special Room:',
                        showComingSoonLabel: labels?.OnlineShowRoom?.showComingSoonLabel,
                        notAvailableForViewLabel: labels?.Collections?.notAvailableForViewLabel
                      }}
                    />
                  )}
                </Col>
              )
            })}
          </Row>

          <IntersectionCapture
            //@ts-ignore
            onIntersect={() => setSize(size + 1)}
            active={isIntersectionActive}
            params={{ rootMargin: '400px' }}
          />
        </>
      )}
      {(isLoadingInitialData || isLoadingMore) && (
        <LoaderContainer>
          {/* @ts-ignore */}
          <Loader centered active />
        </LoaderContainer>
      )}
    </div>
  )
}

SpecialRoomsTab.propTypes = {
  editPreviewCollectionItems: PropTypes.array,
  isRefreshing: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isEmpty: PropTypes.bool,
  isLoadingInitialData: PropTypes.bool,
  editPreviewCollectionItemsValidating: PropTypes.bool,
  setSize: PropTypes.func,
  size: PropTypes.number,
  isLoadingMore: PropTypes.bool,
  totalItems: PropTypes.number,
  isTabExist: PropTypes.bool
}

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`
export default SpecialRoomsTab
