import { FC } from 'react'
import styled from 'styled-components'

import { Icon, Container, Row } from '@mch-group/uikit-components'
import { useUserAccessContext } from './context/useUserAccessContext'

interface ModuleLayoutProps {
  backLabel?: string,
  isDisplayBackButton?: boolean,
  onClickBackBtn?: Function
}

const ModuleLayout: FC<ModuleLayoutProps> = ({ backLabel, onClickBackBtn, isDisplayBackButton, children }) => {
  const { state: { otherData } } = useUserAccessContext()
  const wrapperClass = isDisplayBackButton ? 'pt-7 pt-md-8 pt-lg-9' : 'pt-11 pt-md-12 pt-lg-13'
  return (
    <div
      className={`${!otherData.isModal ? `d-flex justify-content-center flex-nowrap ${wrapperClass}` : 'pt-0'}`}
    >
      <div>
        {isDisplayBackButton && !otherData.isModal &&
          <span
            //@ts-ignore
            onClick={() => { onClickBackBtn() }}
            //@ts-ignore
            onKeyUp={() => { onClickBackBtn() }}
            tabIndex={0}
            role='button'
          >
            <Icon name='arrow-left' />
            <p className='text-medium d-inline-block ps-3 mb-3'>{backLabel}</p>
          </span>
        }
        <ModuleLayoutWrapper
          className='bg-white'
          //@ts-ignore
          isModal={otherData.isModal}
        >
          <Container className={otherData.isModal ? 'overflow-hidden' : ' my-6 my-md-8 mx-6 mx-md-7'}>
            <Row className={`${otherData.isModal ? 'pt-0 ps-md-0' : 'pt-10 px-2 px-md-5 pb-9'}`}>
              {children}
            </Row>
          </Container>
        </ModuleLayoutWrapper>
      </div>
    </div>
  )
}

const ModuleLayoutWrapper = styled.div`
  ${
    //@ts-ignore
    (props) => (props.isModal ? '' : 'width: 442px;')
  }
  border-radius: 4px;
  margin-top: -5px;

  @media (max-width: 767px) {
    ${
      //@ts-ignore
      (props) => (props.isModal ? '' : 'width: 342px;')
    }
  }
`

export default ModuleLayout
