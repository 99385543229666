import { createContext, useContext } from 'react'

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LIST':
      return {
        ...state,
        list: action.payload
      }
    case 'TOGGLE_ITEM_SELECTION':
      toggleItem(state.list, action.id)
      return {
        ...state,
        ...action.payload
      }
    case 'SET_DYNAMIC_COLLECTION':
      return {
        ...state,
        dynamicCollection: action.payload
      }
    case 'SET_LABELS':
      return {
        ...state,
        labels: action.payload || {}
      }
    default:
      return { ...state }
  }
}

/**
 * Sets a collection item as selected/unselected
 */
const toggleItem = (list, id) => {
  const newList = [...list]
  if (newList && newList.length > 0) {
    newList.forEach((page) => {
      const item = page.items.find(i => i.id === id)
      item.hasSelectedEntityId = !item.hasSelectedEntityId
    })
  }
}

export const initialState = {
  labels: null,
  router: null,
  isUserLogged: null,
  list: [],
  dynamicCollection: null
}

export const StateContext = createContext(initialState)

export const useCollectionsContext = () => useContext(StateContext)
