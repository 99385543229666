const REDUCERNAME = 'passes'

const initialState = {
  deadline: null,
  company: null
}

// ACTION TYPES
export const actionTypes = {
  SET_COMPANY: `${REDUCERNAME}__SET_COMPANY`,
  SET_DEADLINE: `${REDUCERNAME}__SET_DEADLINE`,
  CLEAR_PASSES: `${REDUCERNAME}__CLEAR_PASSES`
}

// ACTIONS
export const setCompany = payload => dispatch =>
  dispatch({
    type: actionTypes.SET_COMPANY,
    payload
  })
export const setDeadline = payload => dispatch =>
  dispatch({
    type: actionTypes.SET_DEADLINE,
    payload
  })
export const clearPasses = () => dispatch =>
  dispatch({
    type: actionTypes.CLEAR_PASSES
  })

// ACTION HANDLERS
const actionHandlers = {
  [actionTypes.SET_COMPANY]: (state, action) => ({
    ...state,
    company: action.payload
  }),
  [actionTypes.SET_DEADLINE]: (state, action) => ({
    ...state,
    deadline: action.payload
  }),
  [actionTypes.CLEAR_PASSES]: () => initialState
}

// REDUCER
const passesReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export default passesReducer
