import { useState, useEffect, useContext, useMemo } from 'react'
import useSWR from 'swr'
import { User as UserService } from '@services'
import { useAccessToken } from '@hooks'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { signOut } from '@stores/user'
import { useRouter, NextRouter } from 'next/router'
import { getSiteLanguage } from '@utils/storage'
import ISiteHostName from '../../../../types/ISiteHostName'
import { AppContext } from '@providers/AppContext'
import parseMegamenuJSONToAdaptImageWidths from '@utils/parseMegamenuJSONToAdaptImageWidths'
import { PageContext } from '@providers/PageContext'

const useMegamenu = (): IMegamenuDataReturned => {
  const { shared } = useSelector((state) => state)
  const {
    state: { siteHostName, languagesList }
  } = useContext(AppContext)
  const { page: { pageData } } = useContext(PageContext)
  const urlFromCategory = pageData?.magazineCategory?.landingPageUrl
  const [languageSelectedInMenu, setLanguageSelectedInMenu] = useState<string | null | undefined>(null)
  const languagesListForRender: Array<any> | undefined = useMemo(
    () =>
      languagesList?.map((lang) => ({
        type: lang.localeCode,
        label: lang.friendlyName
      })),
    [languagesList]
  )
  useEffect(() => {
    setLanguageSelectedInMenu(languagesListForRender?.find((lang) => lang.type === getSiteLanguage())?.type)
  }, [languagesListForRender])
  // @ts-ignore // because of FE data unconsistency in .labels
  const globalModuleLabels = shared?.globalModules?.labelModule?.labels || null
  const { labels } = useLabelsFromAPI(['Collections', 'MegaMenus', 'Sponsors', 'AltTags'], getSiteLanguage())
  const { accessToken } = useAccessToken()
  const [isProfileMenuTriggered, setIsProfileMenuTriggered] = useState(false)

  const { data: megaMenuData } = useSWR(`/msvc/v1/translations/Label/MegaMenus/HeaderJSON${siteHostName?.brand}`)
  const index =
    megaMenuData && megaMenuData?.findIndex((item) => item.key.localeCode == getSiteLanguage().toLowerCase())

  const dataJSON =
    megaMenuData && megaMenuData[index] && megaMenuData[index]?.value ? JSON.parse(megaMenuData[index].value) : {}

  // accessibiity labels, the defaults can be removed once
  // https://mch-applicationservices.atlassian.net/browse/AB-3218 is completed by BE
  const a11yData = {
    general: {
      logo: labels?.AltTags?.megamenuArtBaselLogo || '',
      sponsorLogoTitle: `${labels?.AltTags?.megamenuSponsorLogo} ${labels?.AltTags?.generalOpensInNewWindow}` || 'UBS (opens in a new window)',
      sponsorLinkAria: labels?.AltTags?.megamenuSponsorLogoTitle || 'Visit our sponsor'
    },
    megaMenu: {
      langBtnIconTitle: labels?.AltTags?.megamenuLanguageButton || 'Open Language Selection Flyout',
      searchBtnIconTitle: labels?.AltTags?.megamenuSearchButton || 'Open Search',
      profileBtnIconTitle: labels?.AltTags?.megamenuProfileButton || 'Open Profile Flyout',
      collectionBtnIconTitle: labels?.AltTags?.megamenuCollectionsButton || 'View Collections',
      menuOpenBtnIconTitle: labels?.AltTags?.menuOpenBtnIconTitle || 'Open Menu'
    },
    search: {
      searchActionBtnIconTitle: labels?.AltTags?.megamenuSearch || 'Search',
      searchOpenBtnIconTitle: labels?.AltTags?.megamenuSearchButton || 'Open search',
      searchCloseBtnIconTitle: labels?.AltTags?.megamenuCloseSearchButton || 'Close search'
    }
  }
  let megaMenuDataWithImagesCompressed = megaMenuData ? { ...dataJSON, a11yData } : {}

  if (megaMenuDataWithImagesCompressed && megaMenuDataWithImagesCompressed.entries) {
    megaMenuDataWithImagesCompressed.entries = parseMegamenuJSONToAdaptImageWidths(
      megaMenuDataWithImagesCompressed.entries
    )
  } else {
    megaMenuDataWithImagesCompressed = {
      logoLink: '/',
      a11yData,
      entries: [
        {
          label: '',
          mobileLabel: '',
          link: '',
          flyout: {
            menuSection: {
              cols: []
            },
            cardSection: {
              cols: []
            }
          }
        }
      ]
    }
  }

  const { data: profileFlyoutData } = useSWR(() =>
    isProfileMenuTriggered
      ? [
          `${UserService.profileFlyoutOptions}?locale=en&at=${!!accessToken}${
            siteHostName?.brand && `&brand=${siteHostName?.brand}`
          }`
        ]
      : null
  )
  const { data: userMe } = useSWR<ABTypes.Userprofiles.User>(
    () => accessToken && '/msvc/v2/userprofiles/user/me?fields=fullName%2Cvip'
  )
  const dispatch = useDispatch()
  const router: NextRouter = useRouter()

  // Preparing the object structure that MegaMenu needs to receive the different events
  const constructedProfileFlyoutData = profileFlyoutData || { entries: [] }
  constructedProfileFlyoutData.onProfileOpen = () => setIsProfileMenuTriggered(true)
  constructedProfileFlyoutData.onProfileClose = () => setIsProfileMenuTriggered(false)
  constructedProfileFlyoutData.onProfileLogout = () => dispatch(signOut('/'))
  /**
   * @Note
   * we modified the languagesListForRender array to pass the 'zh' value as a language type because
   * In Mega Menu we are using zh and in core we are using zh_CN for chinese language
   * */
  const listRender = languagesListForRender?.map((code) => ({
    type: code.type === 'zh_CN' ? 'zh' : code.type,
    label: code.label
  }))

  let secondaryNav = null
  let secondaryNavActiveIndex = 0
  // naming is bad here, just digging for the value we need.
  megaMenuDataWithImagesCompressed.entries.forEach((item) => {
    item.flyout?.menuSection.cols.forEach((colList) => {
      colList.menuEntries.forEach((colListItem) => {
        const menuFound = colListItem.menuItems.find((it) => it.link === (urlFromCategory || router?.query?.slug))
        const indexFound = colListItem.menuItems.findIndex((it) => it.link === (urlFromCategory || router?.query?.slug))
        if (menuFound) {
          secondaryNav = colListItem
          secondaryNavActiveIndex= indexFound
        }
      })
    })
  })

  // if the server/be crashes, we dont crash the FE
  if (!globalModuleLabels) {
    return {}
  }

  return {
    // alphabetical
    accessToken,
    constructedProfileFlyoutData,
    globalModuleLabels,
    labels,
    languageSelectedInMenu,
    listRender,
    megaMenuDataWithImagesCompressed,
    router,
    siteHostName,
    userMe,
    secondaryNav,
    secondaryNavActiveIndex
  }
}

interface IMegamenuDataReturned {
  accessToken?: string | undefined | null
  constructedProfileFlyoutData?: string
  globalModuleLabels?: any
  labels?: { [key: string]: { [key: string]: string } }
  languageSelectedInMenu?: string | null | undefined
  listRender?: Array<{ type: string; label: string }>
  megaMenuDataWithImagesCompressed?: any
  router?: NextRouter
  siteHostName?: ISiteHostName
  userMe?: ABTypes.Userprofiles.User
  secondaryNav?: any, // UIKIT TS is broken
  secondaryNavActiveIndex?: number
}

export default useMegamenu