//@ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import { Modal, Text, Button, Spinner } from '@mch-group/uikit-components'
import validationConstants from '@constants/validation'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { getSiteLanguage, logger } from '@utils'
import { PasswordValidationCheck } from '@components'
import { User } from '@services'
import { SnackBarNotificationContext } from '@providers/SnackBarNotification'
import styled from 'styled-components'

const UpdatePassword = () => {
  const { labels } = useLabelsFromAPI(['Access'], getSiteLanguage())
  const [isLoading, setIsLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const isPasswordUpdate = typeof localStorage !== 'undefined' && localStorage.getItem('isPasswordUpdate')
    if (isPasswordUpdate) {
      setIsVisible(isPasswordUpdate)
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      newPasswordConfirm: ''
    },
    validationSchema: Yup.object().shape({
      newPassword: Yup.string()
        .required('This Field is required')
        .min(12, labels?.Access?.passwordValidationFailedLabel)
        .matches(
          validationConstants.AT_LEAST_8CHAR_ONE_UPPERCASE_ONE_NUMBER,
          labels?.Access?.passwordValidationFailedLabel)
        .matches(
          validationConstants.NON_ROMAN, labels?.Access?.passwordValidationFailedLabel),
      newPasswordConfirm: Yup.string()
        .required('This Field is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords don\'t match')
    }),
    onSubmit: async (values) => {
      setIsLoading(true)
      if (values.newPassword) {
        try {
          const response = await User.setPasswordForLoggedInUser({ 'newPassword': values.newPassword, 'policy': 'PWD_POLICY_V2' })
          if (response.status === 200) {
            showNotification(true, {
              autohide: true,
              message: labels?.Access?.passwordUpdatesSuccessMsg,
              position: 'top-end',
              className: 'position-fixed',
              style: { top: '100px' }
            })
            localStorage.setItem('isPasswordUpdate', 'false')
            setIsVisible('false')
          }
        } catch (err) {
          logger(err)
        } finally {
          setIsLoading(false)
        }
      }
    }
  })

  const { values, touched, errors, handleChange, handleBlur } = formik

  const { showNotification } = useContext(SnackBarNotificationContext)

  return (
    <StyledModal
      show={isVisible === 'true'}
      size='sm'
      className=''
    >
      {!labels ?
        <div className='d-flex align-items-center justify-content-center mb-5'>
          <Spinner variant='button' theme='light' />
        </div>
        :
        <>
          <Modal.Header title={labels?.Access?.updatePasswordTitle || ''} showCloseButton={false} />
          <form onSubmit={formik.handleSubmit}>
            <Modal.Body className='pb-0 pt-7 pt-md-8'>
              <p className='text-medium'>{labels?.Access?.updatePasswordSubTitle}</p>
              <div className='mt-8'>
                <Text
                  label='Password'
                  type='password'
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id='newPassword'
                  helpText={touched.newPassword && errors.newPassword || ''}
                  helpTextType={errors.newPassword && touched.newPassword ? 'danger' : 'muted'}
                />
                <div className='mt-7'>
                  <Text
                    label='Re type Password'
                    type='password'
                    value={values.newPasswordConfirm}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id='newPasswordConfirm'
                    helpText={touched.newPasswordConfirm && errors.newPasswordConfirm || ''}
                    helpTextType={errors.newPasswordConfirm && touched.newPasswordConfirm ? 'danger' : 'muted'}
                  />
                </div>
              </div>
              <PasswordValidationCheck
                passwordHeadingLabel={labels?.Access?.passwordValidationHeaderLabel}
                passwordString={values?.newPassword}
                minCharLabel={labels?.Access?.validateLengthFieldLabel}
                upperCaseLabel={labels?.Access?.validateUpperCaseFieldLabel}
                numericalCharLabel={labels?.Access?.validateNumericFieldLabel}
                className='mt-7'
                isPasswordValidated={!!(touched.newPassword && errors.newPassword)}
              />
            </Modal.Body>
            <Modal.Footer orientation='vertical'>
              <Button
                type='submit'
                className='next-button'
                isLoading={isLoading}
              >
                Save
              </Button>
            </Modal.Footer>
          </form>
        </>
      }
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  .modal-content {
    min-height: 450px;
  }
`

export default UpdatePassword
