// @ts-nocheck
import http from './_http'

export const PreferedLanguages = {
  getPreferedLanguages: () => http.get('/msvc/v1/optionsets/contact/dl_preferedlanguage')
}

export const AddressTypes = {
  getMain: () => http.get('/msvc/v1/optionsets/contact/dl_address1_addresstype'),
  getChinese: () => http.get('/msvc/v1/optionsets/contact/dl_chineseaddress1_addresstype')
}

export const Salutation = {
  getSalutationOptions: () =>
    http.get('/msvc/v1/optionsets/contact/dl_mailingsalutationoptions')
}

export const OptionSet = {
  getOptionSet: params =>
    http.get(`/msvc/v1/optionsets/${params.entityName}/${params.type}`)
}

