export const LOGIN_WITH_OR_WITHOUT_PASSWORD = 'login-withor-without-password'

export const REGISTER_NEW_USER_FIRST_STEP = 'register-new-user-first-step'

export const RESEND_LOGIN_LINK = 'resend-login-screen'

export const REGISTER_NEW_USER_SECOND_STEP = 'register-new-user-second-step'

export const CREATE_PASSWORD_STEP = 'create-password-step'

export const LINK_EXPIRED_STEP = 'link-expired-step'

export const RESEND_ACTIVATE_ACCOUNT_LINK_STEP = 'resend-activate-account-link-step'

export const LOADING_SCREEN_STEP = 'Loading-screen'

export const HIDE_LOGIN_FORM = 'hide-login-form'

export const RESET_PASSWORD_STEP = 'reset-password'

export const RESET_PASSWORD_LINK_EXPIRED_STEP = 'reset_password_link_expired'

export const RECOVER_ACCOUNT_FIRST_STEP = 'recover_password_first_step'

export const RECOVER_ACCOUNT_SECOND_STEP = 'recover_password_second_step'