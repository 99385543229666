import { FC } from 'react'

interface StepLabelProps {
  step?: string,
  className?: string
}

const StepLabel: FC<StepLabelProps> = ({ step, className }) => (
  <p style={{ color: 'var(--bs-mch-gray-400)' }} className={`text-label-large mb-4 ${className}`}>{step} of 2</p>
)

export default StepLabel