import { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NoSSR from 'react-no-ssr'
import { getSiteLanguage } from '@utils/storage'
import { px2rem, mq, hiDPIpx } from '@utils'
import CollectionForm from '@components/collections/edit-collection-form'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { ButtonIcon, SharingButton } from '@mch-group/uikit-components'
import { LoginContext } from '@providers/Login'

const EditCollectionShareButtons = ({
  collectionDetail,
  dispatch,
  isOrganizeMode,
  labels,
  isSubscribed,
  removeCollection,
  mutateCollectionDetail,
  myCollectionsReturnURL,
  submitCallback,
  isCollectionOwner,
  isBannerDismissed,
  CMSProps,
  isDisabledOrganizeMode,
  activeTab
}) => {
  const { user: userData } = useContext(LoginContext)
  const { labels: apiLabels = {} } = useLabelsFromAPI(['AltTags'], getSiteLanguage())
  const { AltTags } = apiLabels
  const [isEditCollectionModalOpen, setEditCollectionModalOpen] = React.useState(false)
  const isFavorite = collectionDetail && collectionDetail.collectionType === 'FAVORITES'
  const options = [
    {
      key: 'organize',
      text: 'Organise',
      onClick: () => !isDisabledOrganizeMode && dispatch({ type: 'SET_ORGANIZE_MODE', payload: !isOrganizeMode }),
      disabled: isDisabledOrganizeMode
    }
  ]
  if (!isFavorite) {
    options.unshift({
      key: 'edit-collection',
      text: labels?.Collections.editCollectionPopupLabel,
      value: '1',
      onClick: () => setEditCollectionModalOpen(true)
    })
  }

  const itemRef = React.useRef(null)
  const [loaded, setLoaded] = React.useState(false)


  React.useEffect(() => {
    !loaded && setLoaded(true)
  }, [loaded])

  const shareTitle = useMemo(
    () => (userData?.me?.firstName ? `${userData?.me?.firstName} ${labels?.Collections.dynamicCollectionShareLabel}` : ''),
    [userData?.me?.firstName, labels]
  )

  return loaded ? (
    <HeaderWrapper
      className={`${!isCollectionOwner ? 'is-visitor' : ''} ${!isCollectionOwner && isSubscribed ? 'is-follower' : ''
        } ${!isCollectionOwner && !isSubscribed && isBannerDismissed ? 'is-unfollower' : ''}`}
    >
      {isCollectionOwner && (
        <>
          <div className='right-sec actions-bar' ref={itemRef}>
            {!isFavorite && CMSProps.cepm_grp1_hide_share_feature !== 'true' && (
              <div className='item component'>
                <NoSSR>
                  <SharingButton
                    title={AltTags?.shareCollection}
                    media={[
                      'facebook',
                      'whatsapp',
                      'linkedin',
                      'twitter',
                      'weebo',
                      'wechat',
                      'mail',
                      'link-default'
                    ]}
                    variant='outline'
                    size='lg'
                    disabled={isOrganizeMode}
                    shareData={{
                      title: shareTitle,
                      url: `${collectionDetail.shareUrl}&activeTab=${activeTab}`
                    }}
                  />
                </NoSSR>
              </div>
            )}
            {!isFavorite && (
              <ButtonIcon
                icon='edit'
                onClick={() => {
                  setEditCollectionModalOpen(true)
                }}
                disabled={isOrganizeMode}
                title={AltTags?.editCollection}
                variant='outline'
                size='lg'
              />
            )}
            <ButtonIcon
              onClick={() => {
                dispatch({ type: 'SET_ORGANIZE_MODE', payload: !isOrganizeMode })
              }}
              icon='organise'
              disabled={isOrganizeMode}
              title={AltTags?.organizeCollection}
              variant='outline'
              size='lg'
            />
          </div>
          {isEditCollectionModalOpen && (
            <CollectionForm
              collectionDetail={collectionDetail}
              labels={labels}
              setEditCollectionModalOpen={setEditCollectionModalOpen}
              removeCollection={removeCollection}
              mutateCollectionDetail={mutateCollectionDetail}
              myCollectionsReturnURL={myCollectionsReturnURL}
              submitCallback={submitCallback}
            />
          )}
        </>
      )}
    </HeaderWrapper>
  ) : null
}

EditCollectionShareButtons.propTypes = {
  collectionDetail: PropTypes.object,
  showForm: PropTypes.func,
  dispatch: PropTypes.func,
  labels: PropTypes.object,
  removeCollection: PropTypes.func,
  mutateCollectionDetail: PropTypes.func,
  myCollectionsReturnURL: PropTypes.string,
  submitCallback: PropTypes.func,
  isOrganizeMode: PropTypes.bool,
  isCollectionOwner: PropTypes.bool,
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func,
  isSubscribed: PropTypes.bool,
  isBannerDismissed: PropTypes.bool,
  CMSProps: PropTypes.object,
  isDisabledOrganizeMode: PropTypes.bool,
  activeTab: PropTypes.string
}

const HeaderWrapper = styled.div`
  display: flex;
  width: fit-content;
  height: ${px2rem(48)};
  flex-direction: row;
  align-items: center;
  justify-content: inherit;

  &.is-visitor {
    margin-top: ${px2rem(88)};
    ${mq.between('tablet', 'largestTablet')`
      margin-top: -${hiDPIpx(5)};
    `}
    ${mq.lessThan('largestMobile')`
      margin-top: -${px2rem(5)};
    `}
    &.is-follower,
    &.is-unfollower {
      margin-top: ${px2rem(11)} !important;
    }
  }

  .left-sec {
    flex: 1;
  }

  .right-sec {
    height: ${px2rem(48)};
    align-items: center;
    display: flex;
    align-content: center;
    justify-content: center;

    .separator {
      border-right: 1px solid var(--bs-mch-galleries) !important;
      height: ${px2rem(32)};
    }

    button {
      margin-left: 8px;
    }

    svg {
      cursor: pointer;
    }

    .collection {
      top: -${px2rem(25)};
      right: 0;

      .ui.dropdown {
        width: ${px2rem(52)};
      }
    }
  }

  .mobile-only {
    ${mq.greaterThan('desktop')`
      display: none;
    `}
  }

  .desktop-only {
    ${mq.lessThan('largestTablet')`
      display: none;
    `}
  }

  .item.component {
    position: relative;
  }
`

export default EditCollectionShareButtons
