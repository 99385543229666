import { logger, validateAxiosResponse } from '@dmi-mch/utils'
import { Cms as CmsService } from '../services'
import { getSiteLanguage } from '../utils/storage'

const REDUCERNAME = 'navigation'

const initialState = {
  secondary: {}
}

// ACTION TYPES
export const actionTypes = {
  SET_SECONDARY_NAVIGATION: `${REDUCERNAME}__SET_SECONDARY_NAVIGATION`
}

// ACTION HANDLERS
const actionHandlers = {
  [actionTypes.SET_SECONDARY_NAVIGATION]: (state, action) => {
    const newSecondaryData = { ...state }
    newSecondaryData.secondary = { ...action.payload.secondary }
    return ({
      ...state,
      secondary: newSecondaryData.secondary
    })
  }
}

export const setSecondaryNavigation = secondaryMenuId => async (dispatch) => {
  try {
    if (secondaryMenuId) {
      const secondaryMenuDefinition = await CmsService.getSecondaryNavigation(secondaryMenuId, getSiteLanguage())
      if (validateAxiosResponse(secondaryMenuDefinition)) {
        const payload = {
          secondary: {}
        }
        payload.secondary[secondaryMenuId] = secondaryMenuDefinition.data.entries

        dispatch({
          type: actionTypes.SET_SECONDARY_NAVIGATION,
          payload
        })
      }
    }
  } catch (e) {
    logger(e)
  }
}

// REDUCER
const navigationReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export default navigationReducer