
/**
 *
 * @param {*} artworkName
 * @param {*} artworkYear
 * @param {*} artworkEndDate
 * @param {*} formatType: Format to render the Artwork name. Feel free to create/improve formats.
 * Format 0 is: "Pablo Picasso, 1930 - 1940". It will hide the year if not available
 * Format 0 is used in places like meta title, artwork details, contact gallery forms.
 */
const formatArtworkTitle = (artworkName, artworkYear, artworkEndDate, formatType = 0) => {
  if (formatType === 0) {
    return `${artworkName}${artworkYear ? `, ${artworkYear}` : ''}${artworkEndDate ? ` - ${artworkEndDate}` : ''}`
  }
}

export default formatArtworkTitle
