import React, { useState, createContext, FC, ReactChild } from 'react'

const StopRedrawContext = createContext({})

type StopRedrawProviderProps = {
  children: ReactChild
}

const StopRedrawProvider:FC<StopRedrawProviderProps> = ({ children }) => {

  const [isRedrawActive, setIsRedrawActive] = useState<boolean>(true)

  return (
    <StopRedrawContext.Provider value={{ setIsRedrawActive, isRedrawActive }}>
      {children}
    </StopRedrawContext.Provider >
  )
}

export default StopRedrawProvider

export { StopRedrawContext }
