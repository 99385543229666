import { memo, useState, useContext, FC } from 'react'
import styled from 'styled-components'
import type IArtworkCard from 'types/IArtworkCard'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { formatPriceNoCurrencySymbol, formatPriceRange, uploadTransformationUrl } from '@dmi-mch/utils'
import { cloudinary } from '@constants'
import { formatArtworkLink, formatGalleryLink, formatArtworkTitle, cmsPageUrl } from '@utils'
import { useTracking, useBackNavigation } from '@hooks'
import { getSiteLanguage } from '@utils/storage'
import LinkWithBackNavigation from '@components/utils/LinkWithBackNavigation'
import { AddToCollectionContext } from '@components/collections/AddToCollection'
import { StopRedrawContext } from '@providers/StopRedraw'
import EnquiryModal from '@components/EnquiryModal'
import { ArtworkTile } from '@mch-group/uikit-components'
import { formatArtistLink } from '@utils/artists'
import CustomLinkTag from '@components/CustomLinkTag'

const ArtworkItem: FC<IArtworkCard> = memo(
  ({
    id,
    isSelectedInCollections = false,
    cleanTitle,
    images: [image],
    displayName = '',
    artistName,
    year = '',
    galleryId,
    galleryName,
    galleryCleanTitle,
    sponsorArtists = [],
    endYear = '',
    isOrganizeMode,
    isCollectionArtwork = false,
    sold,
    priceInUsd,
    artworkOwnerData,
    priceInSecondCurrency,
    secondCurrency,
    fromPrice,
    recommendedArtwork = false,
    toPrice,
    comingFromOVRIntro,
    isCatalogueArtwork,
    isHideGalleryName,
    comingFromCollection,
    comingFromOVRRoom = false,
    showFullName,
    inquiryEnabled,
    reservedArtwork,
    onSelectCard,
    showViewAtFair,
    pageType,
    imageType,
    entityId,
    viewAtFair,
    unavailableToView,
    unavailableCardLabel,
    disableBackNavigation,
    showYear,
    comingFromCompact = false,
    labels = null
  }) => {
    const { keys, set } = useBackNavigation()
    const { showAddToCollectionModal } = useContext(AddToCollectionContext)
    //@ts-ignore
    const { setIsRedrawActive } = useContext(StopRedrawContext)
    const [showInquiryPopup, setShowInquiryPopup] = useState(false)
    // This should be eventually removed, and pass the labels as a param, to avoid connecting the ArtworkCard with
    // certain entities, because there is no conceptual relation between these concepts (ArtworkCard and entity)
    const { labels: labelsAPI } = useLabelsFromAPI(['OnlineShowRoom', 'CollectionForms'], getSiteLanguage(), !labels)
    const labelsAPIRestructured = labelsAPI &&
      labelsAPI.OnlineShowRoom &&
      labelsAPI.CollectionForms && { ...labelsAPI.OnlineShowRoom, ...labelsAPI.CollectionForms }
    const filteredLabels = labels ? labels : labelsAPIRestructured
    const { setAction } = useTracking(id)
    const url1 = comingFromOVRRoom ? '?comingFromOVRRoom=true' : ''
    const url2 = comingFromCollection ? '?comingFromCollection=true' : ''
    const url3 = recommendedArtwork ? '?comingFromRecommendation=true' : ''
    const artworkLink = `${formatArtworkLink(id, cleanTitle)}${url1}${url2}${url3}`
    const priceRange = fromPrice || toPrice ? formatPriceRange(fromPrice, toPrice) : null
    const queryParams = { id, cleanTitle, comingFromOVRIntro }
    const prepareArtistData = () => {
      const ArtistData = sponsorArtists
        ? sponsorArtists.map((item) => ({
            id: item.artistId,
            name: item.artistName,
            url: formatArtistLink(item.artistId, item.artistCleanTitle),
            linkRenderer: (link, linkTitle, children) => (
              <CustomLinkTag url={link} title={linkTitle}>
                {children}
              </CustomLinkTag>
            )
          }))
        : null
      return ArtistData
    }
    const getGalleryName = () => {
      if (isHideGalleryName) {
        return null
      } else if (galleryName) {
        return galleryName
      } else if (artworkOwnerData?.displayName) {
        return artworkOwnerData?.displayName
      }
      return null
    }
    const getGalleryLink = () => {
      const findGalleryId = galleryId || artworkOwnerData?.id || null
      const findGalleryTitle = galleryCleanTitle || artworkOwnerData?.cleanTitle || null
      if (findGalleryId && findGalleryTitle) {
        return formatGalleryLink(findGalleryId, findGalleryTitle)
      }
    }

    let priceFormat = ''
    let artworkStatusLabel = ''

    if (priceInSecondCurrency) {
      priceFormat = `${secondCurrency} ${formatPriceNoCurrencySymbol(priceInSecondCurrency)}`
    } else if (priceRange) {
      priceFormat = priceRange
    } else if (priceInUsd) {
      priceFormat = `USD ${formatPriceNoCurrencySymbol(priceInUsd)}`
    }

    if (sold) {
      artworkStatusLabel = filteredLabels?.artworkSoldLabel ?? ''
    } else if (reservedArtwork) {
      artworkStatusLabel = filteredLabels?.reservedArtworkLabel ?? ''
    }

    const { labels: { AltTags = {} } = {} } = useLabelsFromAPI(['AltTags'], getSiteLanguage())
    const altText = `${displayName} ${AltTags.altTagBy} ${artistName} ${year ? `(${year})` : ''}`
    const artworkTitle = (
      <ArtworkItemWrapper
        //@ts-ignore
        height={image.height ? image.height : '500'}
        width={image.width ? image.width : '500'}
        className={`pb-5 pb-lg-0 pb-xl-3 ${imageType === 'masonry' ? 'is-masonry' : ''} ${
          comingFromCompact ? 'mb-5 mb-lg-0 mb-xl-3 d-flex w-100' : ''
        }`}
      >
        <ArtworkTile
          a11yData={{
            addBtn: `${AltTags?.addToCollection || 'Add to a collection'}`,
            viewArtist: `${AltTags.generalReadMoreArtist || 'View the Artist:'}`,
            viewArtwork: `${AltTags.generalViewArtwork || 'View the Artwork:'}`,
            viewGallery: `${AltTags.generalViewGallery || 'View the Gallery:'}`
          }}
          pageType={pageType}
          alt={altText}
          imageUrl={uploadTransformationUrl(
            image.url,
            unavailableToView ? 'c_fill,h_500,w_500,q_auto:good' : 'c_fill,q_auto:good,w_716',
            {
              transformUrl: cloudinary.throughCloudfront
            }
          )}
          artworkLink={artworkLink}
          linkRenderer={(link, linkTitle, children) => (
            <LinkWithBackNavigation
              passHref
              as={link || artworkLink}
              href={cmsPageUrl(artworkLink, '/artwork', queryParams)}
              navigationKey={
                !recommendedArtwork ? keys.OVR_INTRO.INSIDE_ARTWORK : keys.OVR_INTRO.INSIDE_ARTWORK_RECOMMENDATION
              }
              disableBackNavigation={disableBackNavigation}
              extraAction={() =>
                !recommendedArtwork
                  ? set(keys.OVR_INTRO.INSIDE_ARTWORK)
                  : set(keys.OVR_INTRO.INSIDE_ARTWORK_RECOMMENDATION)
              }
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a title={linkTitle}>{children}</a>
            </LinkWithBackNavigation>
          )}
          artworkTitle={`${displayName}`}
          year={`${year} ${endYear ? ` - ${endYear}` : ''}`}
          //@ts-ignore
          artistsData={prepareArtistData()}
          imageType={imageType}
          collection={{
            //@ts-ignore
            active: isCollectionArtwork,
            onClick: () => {
              setIsRedrawActive(false)
              //@ts-ignore
              showAddToCollectionModal(id, '2', filteredLabels?.artworkSavedToCollectionLabel, null, null, () =>
                setIsRedrawActive(true)
              )
            }
          }}
          selectMode={{
            //@ts-ignore
            active: isOrganizeMode,
            checked: isSelectedInCollections,
            onChange: () => {
              //@ts-ignore
              isOrganizeMode && onSelectCard(id ? id : entityId, '2')
            }
          }}
          show={{
            //@ts-ignore
            active: isCollectionArtwork,
            label: `${showFullName ? showFullName : ''} ${showYear ? `(${showYear})` : ''}`
          }}
          //@ts-ignore
          showsData={[{ id: 1, name: showFullName || 'Art Basel' }]}
          price={!isCatalogueArtwork && priceFormat || ''}
          galleryName={getGalleryName() || ''}
          galleryLink={getGalleryLink()}
          status={artworkStatusLabel}
          inquiry={{
            active: !isCollectionArtwork && inquiryEnabled || false,
            label: filteredLabels?.artworkInquiryButtonLabel || '',
            onClick: () => {
              setIsRedrawActive(false)
              setShowInquiryPopup(true)
            }
          }}
          viewAtFair={{
            active: showViewAtFair && viewAtFair || false,
            label: filteredLabels?.viewAtFairLabel || ''
          }}
          onlineExclusive={{
            active: showViewAtFair && viewAtFair == false || false,
            label: filteredLabels?.onlineExclusiveLabel || ''
          }}
          //@ts-ignore
          unavailableToView={{ active: unavailableToView, label: unavailableCardLabel }}
        />
        {inquiryEnabled && showInquiryPopup && (
          <EnquiryModal
            onShowModal={() => {
              setAction(3)
            }}
            onSubmitSuccess={() => {
              setAction(4)
            }}
            image={image.url}
            title={formatArtworkTitle(displayName, year, endYear)}
            imgAlt={altText}
            subtitle={artistName}
            id={id}
            showWhenCatalog
            isShowArtworkDetail
            submitButtonAttributes={{
              'data-event': 'event',
              'data-category': 'ovr',
              'data-action': 'submit-button',
              'data-label': `${id}/${cleanTitle}`,
              'data-value': `${id}/${cleanTitle}`
            }}
            contactBtnClass='contact-sales-inquiry'
            showPopupWithoutButton={showInquiryPopup}
            closeCallback={() => {
              setShowInquiryPopup(false)
              setIsRedrawActive(true)
            }}
            closeLoginModalCallback={() => {
              setShowInquiryPopup(false)
              setIsRedrawActive(true)
            }}
          />
        )}
      </ArtworkItemWrapper>
    )

    return <>{artworkTitle}</>
  }
)

ArtworkItem.displayName = 'ArtworkItem'

/* special case for masonry plugin */
const ArtworkItemWrapper = styled.div`
  &.is-masonry {
    .image-frame {
      ${(props) => {
        //@ts-ignore
        if (props.width && props.height) {
          //@ts-ignore
          return `padding-bottom: ${(props.height / props.width).toFixed(2) * 100}%;`
        }
      }}
      position: relative;
      width: 100%;
      height: auto;

      .artwork-card {
        position: absolute !important;
      }

      img {
        left: 0;
        top: 0;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        position: absolute !important;
      }
    }
  }
`

export default ArtworkItem
