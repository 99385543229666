import React, { FC, useContext } from 'react'
import { EditorialQuote, Container } from '@mch-group/uikit-components'
import IEMQuote from '@modules/EMQuote/types'
import StyledQuote from './StyledQuote'
import { PageContext } from '@providers/PageContext'
import classNames from 'classnames'

const EMQuote: FC<IEMQuote> = ({ id, emeq_quote_text, emeq_quote_author, emeq_job_title }) => {
  const { page: { siteLanguage } } = useContext(PageContext)

  if (!emeq_quote_text) return null
  return (
    <section
      className={classNames('em-quote', siteLanguage === 'zh_CN' ?
        'mb-7 mb-md-8' : 'mb-9 mb-md-12')}
      id={id}>
      <Container className='px-5 px-lg-8 px-md-7 mt-0'>
        <StyledQuote>
          <EditorialQuote author={emeq_quote_author} jobTitle={emeq_job_title} quote={emeq_quote_text} />
        </StyledQuote>
      </Container>
    </section>)
}


export default EMQuote
