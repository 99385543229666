import { FC, useMemo, useReducer, useRef } from 'react'

import { environment } from '@dmi-mch/utils'
import { isOkForHydration } from '@utils'

import { reducer, StateContext } from '../context/useUserAccessContext'

interface IUserAccessProvider {
  isModal?: boolean,
  callback?: (param?: any) => void,
  onCloseLoginModal?: () => void,
  setModalData?: any,
  defaultRedirectUrl?: string
}

const UserAccessProvider:FC<IUserAccessProvider> = ({ defaultRedirectUrl, ...props }) => {
  const resendLoginLinkRef = useRef(null)
  const resendResetPasswordLinkRef = useRef(null)
  const initialState = useMemo(() => {
    const stateData = {
      nextStep: null,
      isLoading: false,
      apiErrorMessage: '',
      labels: null,
      redeemKeyErrorMessage: '',
      defaultRedirectUrl: defaultRedirectUrl ? defaultRedirectUrl : '/dashboard/overview',
      loginAccessPageUrl: '/access',
      resendLoginLinkRef,
      resendResetPasswordLinkRef,
      otherData: { ...props },
      loadingTextForLoginModal: { text1: '', text2: '' }
    }
    return stateData
  },[defaultRedirectUrl, props])

  const { children } = props

  const [state, dispatch] = useReducer(reducer, initialState)

  useMemo(() => {
    if (isOkForHydration(state, initialState)) {
      environment.isNotPro &&
        // eslint-disable-next-line no-console
        console.error(
          'Attention, the initial state configured here and the one declared in' +
          ' ../contexts/module does not match. Remember to update and to add the explanation of what is, what for, ' +
          'and where is used, inside module context file.'
        )
    }
  }, [initialState, state])

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  )
}

export default UserAccessProvider
