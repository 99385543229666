import { PressAcc as PressAccService } from '../services'
import { isValidProfile } from '../components/PressAcc/utils'

const initialState = {
  data: null,
  isSubmitted: false
}

// ACTIONS
export const setSubmitApplication = (payload) => (dispatch) => dispatch({ type: 'SUBMIT_APPLICATION', payload })
export const clearPressAccr = () => (dispatch) => dispatch({ type: 'CLEAR_PRESS' })

export const getPressAccData = () => (dispatch) => {
  Promise.all([
    PressAccService.getAccreditationItems(),
    PressAccService.getCountries(),
    PressAccService.getProfessionalBranches(),
    PressAccService.getProfessionalFunctions(),
    PressAccService.getProfile(),
    PressAccService.getSalutations(),
    PressAccService.getAccreditationStatuses()
  ]).then((responses) => {
    const responseStatus = responses.map((item) => item.status === 200)
    if (responseStatus.every((item) => item === true)) {
      dispatch({ type: 'SET_SHOWS_DATA', response: {
        countries: responses[1].data,
        mediaFunctions: responses[3].data,
        mediaTypes: responses[2].data,
        profile: responses[4].data,
        salutations: responses[5].data,
        shows: responses[0].data,
        statuses: responses[6].data
      } })
    }
  })
}

// ACTION HANDLERS
const actionHandlers = {
  ['SET_SHOWS_DATA']: (state, action) => ({
      ...state,
      data: action.response,
      validProfile: isValidProfile(action.response.profile),
      permanent: action.response?.profile?.permanent
    }),
  ['SUBMIT_APPLICATION']: (state, action) => ({ ...state, isSubmitted: action.payload }),
  ['CLEAR_PRESS']: () => (initialState)
}

// REDUCER
const pressReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export default pressReducer
