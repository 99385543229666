import styled from 'styled-components'
import PropTypes from 'prop-types'

import { px2rem, mq } from '@utils'
import { Icon } from '@mch-group/uikit-components'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import { convertHexToRgba } from '@utils/cssHelpers'

const CardSelectionState = ({ entityId, entityType, className, subEntityId }) => {
  // @ts-ignore
  const [{ selectedItems }, dispatch] = useMyCollectionsPageContext()

  const isItemSelected = (arr) => subEntityId
    ? arr.findIndex(obj => obj.subEntityId === subEntityId)
    : arr.findIndex(obj => obj.entityId === entityId)

  const onSelectItem = () => {
    const currentSelection = [...selectedItems]
    const objIndex = currentSelection?.length > 0 ? isItemSelected(currentSelection) : -1
    if (objIndex === -1) {
      currentSelection.push({ entityId, entityType, subEntityId })
    } else {
      currentSelection.splice(objIndex, 1)
    }
    dispatch({ type: 'SET_SELECTED_ITEMS', payload: currentSelection })
  }

  const toggleClass = selectedItems?.length > 0 ? isItemSelected(selectedItems) : -1
  const isActiveClass = toggleClass > -1 ? 'is-active' : ''

  return (
    <StyledCardSelectedState onClick={onSelectItem} className={className}>
      <Circle className={isActiveClass}>
        {toggleClass > -1 ? <CheckItemWrapper><Icon name='checkmark-checkbox' color='var(--bs-mch-white)' /></CheckItemWrapper> : null}
      </Circle>
      <Line className={isActiveClass} />
    </StyledCardSelectedState>
  )
}

CardSelectionState.propTypes = {
  entityId: PropTypes.number,
  entityType: PropTypes.string,
  className: PropTypes.string,
  subEntityId: PropTypes.number
}

const Circle = styled.div`
  display: block;
  background: ${tokens.color.base.primary.white.value};
  border: 1.5px solid var(--bs-mch-black);
  border-radius: ${px2rem(50)};
  position: absolute;
  top: ${px2rem(20)};
  right: ${px2rem(20)};
  width: ${px2rem(20)};
  height: ${px2rem(20)};
  cursor: pointer;
  ${mq.lessThan('largestMobile')`
      top: ${px2rem(19)};
      right: ${px2rem(18)};
  `}

  ${mq.between('largestMobile', 'largestTablet')`
      top: ${px2rem(28)};
      right: ${px2rem(28)};
  `}

  &.is-active {
    background: var(--bs-mch-black) !important;
    border: none;
  }
`

const CheckItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${px2rem(20)};
  height: ${px2rem(20)};
`

const StyledCardSelectedState = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(${convertHexToRgba(tokens.color.light.base.neutrals[0].value, tokens.opacity[60].value)});
  cursor: pointer;
`

const Line = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${px2rem(2)};
  background: var(--bs-mch-black);
  display: none;

  &.is-active {
    display: block;
  }
`

export default CardSelectionState
