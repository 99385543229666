import { useEffect, useCallback } from 'react'
import { EVGCompactTable } from '../IndexedDB/config'
import { ICompactTable } from '../IndexedDB/types'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

/**
 * @description Custom hook to delete modules from IndexedDB on page refresh
 * @returns {Object} {}
 * @example
 * const {} = useOnWebsiteRefresh()
 */
const useOnWebsiteRefresh = () => {
  const router = useRouter()
  const reduxShared = useSelector((state) => state.shared)

  const deleteModuleFromDB = useCallback(async () => {
    const EVGCompactModulesInDB = await EVGCompactTable.toArray()
    const indexToDelete: number[] = []

    EVGCompactModulesInDB.forEach(async (i: ICompactTable) => {
      if (router && router?.asPath !== i.origin) {
        indexToDelete.push(i.id)
      }
    })

    if (indexToDelete.length > 0) {
      await EVGCompactTable.bulkDelete(indexToDelete)
    }
  }, [router])

  useEffect(() => {
    if (reduxShared && reduxShared.rxIsPageRefresh) {
      deleteModuleFromDB()
    }
  }, [deleteModuleFromDB, reduxShared])

  return {}

}

export default useOnWebsiteRefresh
