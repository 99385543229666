import UniversalCookie, { CookieSetOptions, Cookie } from 'universal-cookie'
import { NextIncomingMessage } from 'next/dist/server/request-meta'
import { AxiosResponse } from 'axios'
import { logger } from '.'
import { User as UserService } from '../services'
import { removeSessionSummary } from '../stores/user'
import { api } from '@services/_http'
import { ApiResponse } from 'apisauce'
import CookieKeys from '@constants/CookieKeys'

const Cookies = new UniversalCookie()

export const setCookie = (key: string, value: Cookie, options?: CookieSetOptions): void => {
  Cookies.set(key, value, { path: '/', ...options })
}

export const getCookie = (
  req: NextIncomingMessage | null = null,
  key,
  fallbackLanguage: Cookie = null
): Cookie => {
  let result = fallbackLanguage

  try {
    if (typeof window !== 'undefined') {
      result = Cookies.get(key) || fallbackLanguage
    } else {
      //@ts-ignore
      result = req && req.universalCookies ? req.universalCookies.get(key) : fallbackLanguage
    }
  } catch (e) {
    logger(`!!! CRITICAL: ${e}`)
  }

  return result
}

/** This function should be used only in posession of a request getSiteLanguage(req), when in SSR.
 * If it's called without request (getSiteLanguage()) in SSR, it will return just 'en', and ignore the Cookie.
 * This is possible to be happening in some corner of the site. If that is the case, it should be changed to
 * take the language from Redux. Redux is the source of truth for the language, not this function.
 */
export function getSiteLanguage(req: ICookieRequest | null = null): string {
  // Note: 3rd parameter here passed as a fallback language
  //@ts-ignore
  return getCookie(req, CookieKeys.LANGUAGE, 'en')
}

export const setSiteLanguage = (language: string): void => setCookie(CookieKeys.LANGUAGE, language)

/**
 * @param values
 */
export function setUserAccessTokens(
  values: ApiResponse<ABTypes.Login.LoginResponse>
): void {
  if (values.ok) {
    const serverSessionObject = { ...values.data }
    let sessionExpire: Date | null = null
    let refreshTokenSessionExpire: Date | null = null

    if (serverSessionObject && serverSessionObject.sessionSummary) {
      // Current time + expire time from API, in ms. Converted to date object.
      const expireInSeconds = serverSessionObject.sessionSummary.expireInSeconds * 1000
      const refreshExpireInSeconds = serverSessionObject.sessionSummary.expireRefreshInSeconds * 1000
      sessionExpire = new Date(new Date().getTime() + expireInSeconds)
      refreshTokenSessionExpire = new Date(new Date().getTime() + refreshExpireInSeconds)
      setCookie(CookieKeys.CSRF_TOKEN, serverSessionObject.sessionSummary?.csrfToken, {
        expires: sessionExpire, secure: true
      })
      setCookie(CookieKeys.ACCESS_TOKEN, serverSessionObject?.accessToken, { expires: sessionExpire, secure: true })
      setCookie(CookieKeys.REFRESH_TOKEN, serverSessionObject.sessionSummary?.refreshToken, {
        // httpOnly: true not working on our test env so marked this as a false for testing purpose
        expires: refreshTokenSessionExpire, secure: true, httpOnly: false, sameSite: 'lax'
      })
    }
  }
}

export function getAccessToken(req: ICookieRequest | null = null) {
  //@ts-ignore
  return getCookie(req, CookieKeys.ACCESS_TOKEN)
}

export function getRefreshToken(req: ICookieRequest | null = null): string {
  //@ts-ignore
  return getCookie(req, CookieKeys.REFRESH_TOKEN)
}

const getAccessTokenByRefreshTokenAPI = async (reFreshToken: string):
  Promise<AxiosResponse<ABTypes.Login.LoginResponse> & { ok: boolean } | null> => {
  try {
    const result = await UserService.reFreshToken(reFreshToken)
    if (result.status === 200) {
      //@ts-ignore
      return result
    }
  } catch (error) {
    logger(error)
  }
  return null
}

export async function sessionAccessToken(req: ICookieRequest | null = null): Promise<string | null> {
  const userAccessToken = getAccessToken(req)
  const reFreshToken = getRefreshToken()
  let token: string | null = null
  if (userAccessToken) {
    token = userAccessToken
  } else if (reFreshToken) {
    const response = await getAccessTokenByRefreshTokenAPI(reFreshToken)
    //@ts-ignore
    response && setUserAccessTokens(response)
    if (response) {
      token = response.data.accessToken
    }
  } else {
    removeSessionSummary()
  }
  return token
}

/**
 * Prepare User Accces Token
 * @param req
 */
export async function prepareUAT(req): Promise<void> {
  // CODE COPY/PASTED FROM withUserToken HOC
  if (req) { // server-only
    const accessToken = await sessionAccessToken(req)
    /**
     * Below, Authorization Header will be added for the call inside wrapped page requests.
     * "api" object could be initialized on server-side/globally
     * so, header should be cleaned-up before every request
     * */
    api.deleteHeader('Authorization')
    accessToken && (accessToken !== '-') && api.setHeader('Authorization', `Bearer ${accessToken}`)
  }
}


export const clearUserTokens = (req: ICookieRequest | null = null): void => {
  if (req && req.universalCookies) {
    // TS: to be improved in upcoming commit
    req.universalCookies.remove(CookieKeys.CSRF_TOKEN)
    req.universalCookies.remove(CookieKeys.ACCESS_TOKEN)
    req.universalCookies.remove(CookieKeys.REFRESH_TOKEN)
    req.universalCookies.remove('__INSIDE__ROOM____BACK__NAVIGATION__', { path: '/' })
    req.universalCookies.remove('__INSIDE__ARTWORK____BACK__NAVIGATION__', { path: '/' })
    req.universalCookies.remove('__INSIDE__SPECIAL__ROOM__BACK__NAVIGATION__', { path: '/' })
    req.universalCookies.remove('__INSIDE_MY_COLLECTION____BACK__NAVIGATION__', { path: '/' })
    req.universalCookies.remove('__INSIDE__OS__COMPACT__ROOM_____BACK__NAVIGATION__', { path: '/' })
    req.universalCookies.remove('__INSIDE__OS__COMPACT__SPECIAL__ROOM_____BACK__NAVIGATION__', { path: '/' })
    req.universalCookies.remove('__INSIDE_ARTWORK_RECOMMENDATION__BACK__NAVIGATION__', { path: '/' })
    req.universalCookies.remove('COLLECTION_ROOM_INDEX', { path: '/' })
    req.universalCookies.remove('OS_COMPACT_ROOM_INDEX', { path: '/' })
    req.universalCookies.remove('OS_COMPACT_SPECIAL_ROOM_INDEX', { path: '/' })
    req.universalCookies.remove('invitationkey', { path: '/' })
  } else {
    Cookies.remove(CookieKeys.CSRF_TOKEN, { path: '/' })
    Cookies.remove(CookieKeys.ACCESS_TOKEN, { path: '/' })
    Cookies.remove(CookieKeys.REFRESH_TOKEN, { path: '/' })
    Cookies.remove('__INSIDE__ROOM____BACK__NAVIGATION__', { path: '/' })
    Cookies.remove('__INSIDE__ARTWORK____BACK__NAVIGATION__', { path: '/' })
    Cookies.remove('__INSIDE__SPECIAL__ROOM__BACK__NAVIGATION__', { path: '/' })
    Cookies.remove('__INSIDE_MY_COLLECTION____BACK__NAVIGATION__', { path: '/' })
    Cookies.remove('__INSIDE__OS__COMPACT__ROOM_____BACK__NAVIGATION__', { path: '/' })
    Cookies.remove('__INSIDE__OS__COMPACT__SPECIAL__ROOM_____BACK__NAVIGATION__', { path: '/' })
    Cookies.remove('__INSIDE_ARTWORK_RECOMMENDATION__BACK__NAVIGATION__', { path: '/' })
    Cookies.remove('COLLECTION_ROOM_INDEX', { path: '/' })
    Cookies.remove('OS_COMPACT_ROOM_INDEX', { path: '/' })
    Cookies.remove('OS_COMPACT_SPECIAL_ROOM_INDEX', { path: '/' })
    Cookies.remove('invitationkey', { path: '/' })
  }
}

export interface ICookieRequest {
  universalCookies: UniversalCookie
}
