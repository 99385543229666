import React, { FC } from 'react'
import { Container, Divider } from '@mch-group/uikit-components'

const Separator:FC<SeparatorProps> = ({ className, cssClass, id, ...props }) => (
  <Container
    id={id}
    className={`
      ${props.noPadding ? '' : ' px-5 px-lg-8 px-md-7 '}
      ${props.noMarginBottom ? '' : ' mb-md-14 mb-sm-11 mb-9 '}
      ${className ? className : ''}
      `}
  >
    <Divider className={cssClass} color={props.slm_grp1_color || 'mch-gray-200'} size={props.slm_grp1_size || 's'} />
  </Container>
)

interface SeparatorProps {
  className?: string,
  isRow?: boolean,
  cssClass?: string,
  id?: number,
  slm_grp1_color?: React.ComponentProps<typeof Divider>['color'],
  slm_grp1_size?: React.ComponentProps<typeof Divider>['size'],
  noPadding?: boolean,
  noMarginBottom?: boolean
}

export default Separator
