import status from '@constants/galleryStatus'

export const formatGalleryLink = (id, cleanTitle) => `/catalog/gallery/${id}/${cleanTitle}`

// To find out exactly what the status code means
export const isGalleryStatus = {
  live: code => code === status.live,
  alumni: code => code === status.alumni,
  testing: code => code === status.testing,
  outOfBusiness: code => code === status.outOfBusiness,
  // Aliases
  active: code => code === status.live,
  passive: code => code === status.alumni || code === status.outOfBusiness
}

export const showNameToSlug = (galleryName, replacement = '-') =>
  galleryName
    .trim()
    .toLowerCase()
    .replace(/[-\s]+/g, replacement)

export default { formatGalleryLink, isGalleryStatus, showNameToSlug }
