import { useContext } from 'react'
import { useSelector } from 'react-redux'
import groupContent from './groupContent'
import { AppContext } from '@providers/AppContext'
import type ISiteHostName from 'types/ISiteHostName'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { getSiteLanguage } from '@utils'

const useFooter = (): IFooter => {
  const { labels } = useLabelsFromAPI(['Sponsors', 'AltTags'], getSiteLanguage())
  const {
    state: { siteHostName }
  } = useContext(AppContext)

  const globalModulesData = useSelector((state) => state.shared.globalModules)
  const { footerModule } = globalModulesData

  /*
  Footer Labels
  Art Basel from Global Footer Module
  */
  const key = ''
  //@ts-ignore
  const footerLabels = footerModule?.labels

  // if the server/be crashes, we dont crash the FE
  if (!footerLabels) {
    return {}
  }

  /*
  Footer Social Data
  7 slots
  */
  const socialDataEntries: Array<ISocialData> = []

  if (footerLabels[`ftr_${key}grp1_social1`]) {
    socialDataEntries.push({
      label: 'Instagram',
      icon: 'instagram',
      link: footerLabels[`ftr_${key}grp1_social1`],
      target: '_blank',
      title: `${labels?.AltTags?.footerInstagramLogo} ${labels?.AltTags?.generalOpensInNewWindow}`
    })
  }

  if (footerLabels[`ftr_${key}grp1_social2`]) {
    socialDataEntries.push({
      label: 'Facebook',
      icon: 'facebook',
      link: footerLabels[`ftr_${key}grp1_social2`],
      target: '_blank',
      title: `${labels?.AltTags?.footerFacebookLogo} ${
        labels?.AltTags?.generalOpensInNewWindow
      }`
    })
  }

  if (footerLabels[`ftr_${key}grp1_social3`]) {
    socialDataEntries.push({
      label: 'X',
      icon: 'twitter',
      link: footerLabels[`ftr_${key}grp1_social3`],
      target: '_blank',
      title: `${labels?.AltTags?.footerXLogo} ${labels?.AltTags?.generalOpensInNewWindow}`
    })
  }

  if (footerLabels[`ftr_${key}grp1_social4`]) {
    socialDataEntries.push({
      label: 'LinkedIn',
      icon: 'linkedin',
      link: footerLabels[`ftr_${key}grp1_social4`],
      target: '_blank',
      title: `${labels?.AltTags?.footerLinkedInLogo} ${
        labels?.AltTags?.generalOpensInNewWindow
      }`
    })
  }

  if (footerLabels[`ftr_${key}grp1_social6`]) {
    socialDataEntries.push({
      label: 'Youtube',
      icon: 'youtube',
      link: footerLabels[`ftr_${key}grp1_social6`],
      target: '_blank',
      title: `${labels?.AltTags?.footerYoutubeLogo} ${
        labels?.AltTags?.generalOpensInNewWindow
      }`
    })
  }

  if (footerLabels[`ftr_${key}grp1_social8`]) {
    socialDataEntries.push({
      label: 'Wechat',
      icon: 'wechat',
      link: footerLabels[`ftr_${key}grp1_social8`],
      target: '_blank',
      title: `${labels?.AltTags?.footerWeChatLogo} ${
        labels?.AltTags?.generalOpensInNewWindow
      }`
    })
  }

  if (footerLabels[`ftr_${key}grp1_social9`]) {
    socialDataEntries.push({
      label: 'Weebo',
      icon: 'weebo',
      link: footerLabels[`ftr_${key}grp1_social9`],
      target: '_blank',
      title: `${labels?.AltTags?.footerWeeboLogo} ${
        labels?.AltTags?.generalOpensInNewWindow
      }`
    })
  }

  if (footerLabels[`ftr_${key}grp1_social9_direct`]) {
    socialDataEntries.push({
      label: 'Xiaohongshu',
      icon: 'xiaohongshu',
      link: footerLabels[`ftr_${key}grp1_social9_direct`],
      target: '_blank',
      title: `${labels?.AltTags?.footerXiaohongshu} ${
        labels?.AltTags?.generalOpensInNewWindow
      }`
    })
  }

  /*
  Footer Data - 3 Columns
  */
  let footerColumnLinks
  const columns: Array<Array<IColumnGroup>> = []

  if (footerLabels) {
    footerColumnLinks = [...groupContent(footerLabels[`ftr_${key}grp1_options`])]
    if (footerColumnLinks?.length > 0) {
      footerColumnLinks.map((i) => i.shift())
    }
  }

  if (groupContent(footerLabels[`ftr_${key}grp1_options`])?.length > 0) {
    // Column group 1
    const columnsGroup1: Array<IColumnGroup> = []
    groupContent(footerLabels[`ftr_${key}grp1_options`])[0][0] &&
      columnsGroup1.push({
        title: groupContent(footerLabels[`ftr_${key}grp1_options`])[0][0]?.label,
        data: footerColumnLinks[0]
      })
    groupContent(footerLabels[`ftr_${key}grp1_options`])[1][0] &&
      columnsGroup1.push({
        title: groupContent(footerLabels[`ftr_${key}grp1_options`])[1][0]?.label,
        data: footerColumnLinks[1]
      })
    columnsGroup1.length > 0 && columns.push(columnsGroup1)

    // Column group 2
    const columnsGroup2: Array<IColumnGroup> = []
    groupContent(footerLabels[`ftr_${key}grp1_options`])[2] &&
      columnsGroup2.push({
        title: groupContent(footerLabels[`ftr_${key}grp1_options`])[2][0]?.label,
        data: footerColumnLinks[2]
      })
    groupContent(footerLabels[`ftr_${key}grp1_options`])[3] &&
      columnsGroup2.push({
        title: groupContent(footerLabels[`ftr_${key}grp1_options`])[3][0]?.label,
        data: footerColumnLinks[3]
      })
    columnsGroup2.length > 0 && columns.push(columnsGroup2)

    // Column group 3
    const columnsGroup3: Array<IColumnGroup> = []
    groupContent(footerLabels[`ftr_${key}grp1_options`])[4] &&
      columnsGroup3.push({
        title: groupContent(footerLabels[`ftr_${key}grp1_options`])[4][0]?.label,
        data: footerColumnLinks[4]
      })
    groupContent(footerLabels[`ftr_${key}grp1_options`])[5] &&
      columnsGroup3.push({
        title: groupContent(footerLabels[`ftr_${key}grp1_options`])[5][0]?.label,
        data: footerColumnLinks[5]
      })
    columnsGroup3.length > 0 && columns.push(columnsGroup3)
  }

  /*
  Footer Legal Data
  3 links
  */
  const footerLegalData: Array<ILegalData> = []

  if (footerLabels[`ftr_${key}grp1_legal_data_link_1`]) {
    footerLegalData.push({
      label: footerLabels[`ftr_${key}grp1_legal_data_tex_1`],
      link: footerLabels[`ftr_${key}grp1_legal_data_link_1`]
    })
  }

  if (footerLabels[`ftr_${key}grp1_legal_data_link_2`]) {
    footerLegalData.push({
      label: footerLabels[`ftr_${key}grp1_legal_data_tex_2`],
      link: footerLabels[`ftr_${key}grp1_legal_data_link_2`]
    })
  }

  if (footerLabels[`ftr_${key}grp1_legal_data_link_3`]) {
    footerLegalData.push({
      label: footerLabels[`ftr_${key}grp1_legal_data_tex_3`],
      link: footerLabels[`ftr_${key}grp1_legal_data_link_3`]
    })
  }

  return {
    footerLabels,
    labels,
    footerColumnLinks,
    columns,
    key,
    socialDataEntries,
    footerLegalData,
    siteHostName
  }
}

interface IFooter {
  footerLabels?: Array<string>
  labels?: { [key: string]: { [key: string]: string } }
  footerColumnLinks?: Array<string>
  columns?: IColumnGroup[][]
  key?: string
  socialDataEntries?: Array<ISocialData>
  footerLegalData?: Array<ILegalData>
  siteHostName?: ISiteHostName | undefined
}

export interface ILegalData {
  label: string
  link: string
}

export interface ISocialData {
  label: string
  icon: string
  link: string
  target: string
  title: string
}

export interface IColumnGroup {
  title: string
  data: Array<{
    label: string,
    link: string
  }>
}

export interface ISiteFooter {
  className?: string
  globalModulesData?: ABTypes.Pageviews.GlobalModule
}

export default useFooter
