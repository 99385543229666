import { FC, memo } from 'react'
import { Footer } from '@mch-group/uikit-components'
import { CustomLinkTag } from '@components'
import useFooter, { ISiteFooter } from './useFooter'
import useCookiesClickHandler from './useCookiesClickHandler'

const SiteFooter: FC<ISiteFooter> = () => {
  const {
    footerLabels,
    labels,
    footerColumnLinks,
    columns,
    key,
    socialDataEntries,
    footerLegalData,
    siteHostName
  } = useFooter()
  useCookiesClickHandler(footerColumnLinks)

  return footerLabels && footerColumnLinks && columns && footerColumnLinks?.length > 0 && columns?.length > 0 ? (
    <Footer
      footerData={{
        cols: columns
      }}
      socialData={
        socialDataEntries && socialDataEntries.length > 0
          ? {
              title: footerLabels[`ftr_${key}grp1_follow_us_lbl`],
              entries: socialDataEntries
            }
          : undefined
      }
      linkRenderer={(link, children, target, title, classNames) => (
        <CustomLinkTag url={link} title={title} className={classNames} noRel>
          {children}
        </CustomLinkTag>
      )}
      legalData={{
        entries: footerLegalData,
        copyright: footerLabels[`ftr_${key}grp1_copy_right`]
      }}
      variant={siteHostName?.variant}
      logoVariant={siteHostName?.variant}
      sponsorData={{
        name: 'ubs',
        link: footerLabels[`ftr_${key}grp1_ubs`],
        target: '_blank',
        label: labels?.Sponsors?.ubsLabel,
        title: `${labels?.Sponsors?.ubsLabel} ${labels?.AltTags?.generalOpensInNewWindow}`
      }}
    />
  ) : null
}

SiteFooter.displayName = 'Site Footer'

export default memo(SiteFooter)
