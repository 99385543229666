export const TabsData = [
  { id: 'artworks', name: 'artworksTabLabel', entityType: '2' },
  { id: 'rooms', name: 'viewingRoomsTabLabel', entityType: '4c' },
  { id: 'specialRooms', name: 'specialRoomTabLabel', entityType: '4d' },
  { id: 'galleries', name: 'galleriesTabLabel', entityType: '1' },
  { id: 'artists', name: 'artistsTabLabel', entityType: '3' },
  { id: 'events', name: 'eventsTabLabel', entityType: '4a,4b' }
  // { id: 'news', name: 'News Articles', entityType: '7' }
]

export const myCollectionsTabsData = [
  { id: 'myCollections', name: 'allCollectionsPageCTALabel' },
  { id: 'followingCollections', name: 'followingCollectionBtnLabel' }
]

export default {}
