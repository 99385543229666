import React, { FC } from 'react'

import { SolidTriangle } from '@dmi-mch/icons'
import styled from 'styled-components'
import { Icon } from '@mch-group/uikit-components'

interface TableSortProps {
  direction: string,
  text: string
}

const TableSort: FC<TableSortProps> = ({ direction, text }) => (
  <div className='d-flex justify-content-center align-items-center'>
    <p className='text-label-medium flex-grow-1'>{text}</p>
    {!direction && (
    <TableSortDefault>
      <Icon
        color='var(--bs-mch-gray-500)'
        name='sorting'
      />
    </TableSortDefault>)}
    {direction && (
    <TableSortTriangle className={direction.toLowerCase()}>
      <SolidTriangle
        color='var(--bs-mch-gray-500)'
        width={12}
        height={8}
      />
    </TableSortTriangle>)}
  </div>
)


const TableSortDefault = styled.div`
  svg {
    width: 32px;
    height: 32px;
  }
`

const TableSortTriangle = styled.div`
  &.desc svg {
    transform: rotate(180deg);
  }
`

export default TableSort
