const breakpoints = {
  default: 0, // neded or responsive modules will disappear below 320px screen width
  mobile: 413,
  tablet: 768,
  computer: 1024,
  largeScreen: 1280,
  widescreen: 1536,
  // Repeating the same, but using same naming than UIKit.
  // Ideally vars should be not hardcode, but coming from UIKit. Not sure it's possible yet.
  xs: 0,
  sm: 413,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536
}

export default breakpoints
