import styled from 'styled-components'
import { Table as SUITable } from 'semantic-ui-react'
import { px2rem } from '@utils'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const Table = styled(SUITable).withConfig({ componentId: 'dmi-ui-table' })`
  &.ui.table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    border: none;
    border-radius: 0;

    th > span {
      display: flex;
      cursor: pointer;
    }

    tbody > tr {
      & td {
        padding: ${px2rem(12)};

        &:nth-child(1) {
          width: 10%;
        }
      }
    }

    td,
    th {
      border-color: var(--bs-mch-galleries);
      background: ${tokens.color.base.primary.white.value};
    }

    a {
      color: ${(props) => props.theme.colorTableText};
    }

    td a {
      display: block;
    }

    .ellipsis,
    .ellipsis a {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .unread * {
      font-weight: ${tokens.fontWeights.artBaselHeadline0.value};
    }

    .pointer {
      cursor: pointer !important;
    }
  }
`

export default Table
