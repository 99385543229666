import styled from 'styled-components'
import Link from 'next/link'
import { object, string } from 'prop-types'
import { Icon } from '@mch-group/uikit-components'
import { mq, px2rem, hiDPIpx } from '@utils'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

/**
 * @param  {object} href
 * @param  {string} as
 */
const CloseIcon = ({ href, as }) => (
  <Link href={href} as={as} legacyBehavior>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a>
      <StyledCloseIcon>
        <Icon name='close' />
      </StyledCloseIcon>
    </a>
  </Link>
  )

CloseIcon.propTypes = {
  href: object,
  as: string
}

const StyledCloseIcon = styled.div`
  background: ${tokens.color.base.primary.white.value};
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: middle;
  width: ${px2rem(37)};
  height: ${px2rem(37)};
  top: ${px2rem(70)};
  right: ${px2rem(70)};
  ${mq.between('tablet', 'largestTablet')`
    width: ${hiDPIpx(37)};
    height: ${hiDPIpx(37)};
    top: ${hiDPIpx(16)};
    right: ${hiDPIpx(16)};
  `}

  ${mq.lessThan('largestMobile')`
    top: ${px2rem(16)};
    right: ${px2rem(16)};
  `}
`

export default CloseIcon
