import qs from 'query-string'

const getRouterQueryParam = (router, param) => {
  let paramValue = router.query && router.query[param]
  if (!paramValue && typeof window !== 'undefined') {
      paramValue = qs.parseUrl(window.location.href)
      if (paramValue.query && paramValue.query[param]) {
        paramValue = paramValue.query[param]
      }
  }
  return paramValue
}

export default getRouterQueryParam