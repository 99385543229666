import React from 'react'
import useSWR from 'swr'
import qs from 'query-string'

const useEditCollectionDetail = () => {
  const [id, setId] = React.useState(null)
  const [collectionDetail, setMyCollectionDetail] = React.useState(null)

  let {
    data: collectionDetailFromApi,
    isValidating: collectionDetailValidating,
    mutate: mutateCollectionDetail,
    error: collectionDetailError
  } = useSWR(id && `/msvc/v1/collections/collection/${id}?field=collectionType,contentsCount,description,id,itemsCount,name,shareUrl,ownerId,ownerName,ownership,shareUrl,contentsCount`, {
    revalidateOnFocus: false,
    shouldRetryOnError: false
  })

  React.useEffect(() => {
    setMyCollectionDetail(collectionDetailFromApi)

  }, [collectionDetailFromApi])

  const editCollection = (idParam) => {
    let collectionId = idParam
    // avoids not getting the ID from the url in client side
    if (collectionId) {
      setId(idParam)
    }
    if (!idParam && typeof window !== 'undefined') {
      collectionId = qs.parseUrl(window.location.href)
      if (collectionId.query && collectionId.query.id) {
        setId(collectionId.query.id)
      }
    }
  }

  const resetDataOnCloseModal = () => {
    setId(null)
    setMyCollectionDetail(null)
  }

  return {
    collectionDetail,
    collectionDetailError,
    collectionDetailValidating,
    mutateCollectionDetail,
    editCollection,
    resetDataOnCloseModal,
    setMyCollectionDetail
  }
}
export default useEditCollectionDetail
