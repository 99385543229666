import qs from 'query-string'
import { api } from './_http'
import { ApiResponse } from 'apisauce'

import galleryStatus from '@constants/galleryStatus'
import { getSiteLanguage } from '@utils'


const defaultGalleryStatuses = [galleryStatus.live, galleryStatus.alumni, galleryStatus.outOfBusiness]

const Artworks = {
  getArtworks: (params): Promise<ApiResponse<ABTypes.Monolith.ArtworkDetail>> => {
    const paramsString = qs.stringify({
      galleryStatuses: defaultGalleryStatuses,
      locale: getSiteLanguage(),
      ...params
    })
    return api.get(`/msvc/v1/artcatalog/artwork/itemDetails?${paramsString}`)
  },


  getArtworkMediums: () =>
  api.get('/msvc/v1/artcatalog/artwork/mediums'),

  getArtworkById: id =>
  api.get(`/msvc/v1/artcatalog/artwork/item/${id}`),

  getArtworkFavorites: (params) => {
    const paramsString = qs.stringify({ galleryStatuses: defaultGalleryStatuses, ...params })
    return api.get(`/msvc/v1/artcatalog/artwork/favorites?${paramsString}`)
  },

  getArtworkFilters: (params) => {
    const paramsString = qs.stringify({ galleryStatuses: defaultGalleryStatuses, ...params })
    return api.get(`/msvc/v1/artcatalog/artwork/filters?${paramsString}`)
  },

  getExhibitionArtworks: (id, accountId) => api.get('/msvc/v1/artcatalog/artwork/itemDetails', {
      accountId,
      eventId: id,
      includeEventArtworks: true,
      includeArtworksNotInShows: true,
      includeIncompleteArtworks: true,
      includeHiddenArtworks: true,
      sortBy: 'position',
      offset: 0,
      limit: 50,
      locale: getSiteLanguage()
    }, {
      headers: {
        'cache-control': 'no-cache'
      }
    }),

  getExhibitionDetailArtworks: (params) => api.get('/msvc/v1/artcatalog/artwork/itemDetails', params),

  getRoomArtworks: params => api.get('/msvc/v1/artcatalog/artwork/itemDetails', params),

  getArtworkDetail: params => api.get('/msvc/v1/artcatalog/artwork/itemDetails', params)

}

export default Artworks
