import React from 'react'
import useSWRInfinite from 'swr/infinite'

import { Collections as CollectionsService } from '@services'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import { NotificationsContext } from '@providers/Notifications'
import { logger } from '@dmi-mch/utils'

const pageSize = 36
const useSubscribeCollection = (on) => {
  const [{ labels }, dispatch] = useMyCollectionsPageContext()
  const [active, setActive] = React.useState(on)
  const { showNotification } = React.useContext(NotificationsContext)
  const {
    data: subscribedCollectionsList,
    error: subscribedCollectionsError,
    isValidating: subscribedCollectionsValidating,
    mutate: mutateSubscribedCollections,
    size,
    setSize
  } = useSWRInfinite(
    (index) =>
      active && `${CollectionsService.getSubscribedCollection}?limit=${pageSize}&offset=${index * pageSize}`
    , {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      revalidateAll: true
    })
  // subscribe collection by ID
  // store the state in context as isSubscribed
  // triggers a success toaster
  const subscribe = async (collectionId) => {
    try {
      const response = await CollectionsService.subscribeCollection(collectionId)
      if (response.ok) {
        dispatch({ type: 'SET_COLLECTION_SUBSCRIBED', payload: true })
        dispatch({ type: 'SET_BANNER_DISMISSED', payload: true })
        showNotification({
          content: labels.Collections.followingConfirmationMsgLabel
        })
      }
    } catch (err) {
      logger(err)
    }
  }

  // subscribe collection by ID
  // store the state in context as isSubscribed
  const unsubscribe = async (collectionId, ismyCollectionPage) => {
    try {
      const response = await CollectionsService.unsubscribeCollection(collectionId)
      if (response.ok) {
        dispatch({ type: 'SET_COLLECTION_SUBSCRIBED', payload: false })
        if (ismyCollectionPage) {
          setActive(true) // this will trigger the subscriptions/mine api call on demand
          mutateSubscribedCollections()
        }
      }
    } catch (err) {
      logger(err)
    }
  }

  const itemsLength = subscribedCollectionsList?.length
  const items = itemsLength ? subscribedCollectionsList?.map(i => i.items) : []
  const subscribedCollectionsItems = items ? [].concat(...items) : []
  const isLoadingInitialData = !subscribedCollectionsList && !subscribedCollectionsError
  const isEmpty = itemsLength ? !subscribedCollectionsList.slice(-1)[0].hasMore : true
  const isReachingEnd = isEmpty || (itemsLength ? !subscribedCollectionsList[0].hasMore : false)
  const isRefreshing = subscribedCollectionsList && itemsLength
    && subscribedCollectionsList[0].items?.length === size
  const isLoadingMore = isLoadingInitialData || (size > 0 && itemsLength && typeof subscribedCollectionsList[size - 1] === 'undefined')

  return {
    subscribe,
    unsubscribe,
    isLoadingInitialData,
    size,
    setSize,
    isReachingEnd,
    isRefreshing,
    isLoadingMore,
    isEmpty,
    subscribedCollectionsItems,
    subscribedCollectionsError,
    subscribedCollectionsValidating,
    mutateSubscribedCollections,
    setActive
  }
}

export default useSubscribeCollection
