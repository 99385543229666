// still in development/debugging process
const redirectToB2bAfterLogin = (redirect: string) => {
  if (process.env.NODE_ENV === 'development') {
    window.location.href = `http://localhost:3003/${redirect}`
  } else {
    if (window?.location?.href?.includes('site-test.artbasel.com')) {
      window.location.href = `https://site-test.artbasel.com/${redirect}`
    } else if (window?.location?.href?.includes('site-integration.artbasel.com')) {
      window.location.href = `https://site-integration.artbasel.com/${redirect}`
    } else if (window?.location?.href?.includes('site-stage.artbasel.com')) {
      window.location.href = `https://site-stage.artbasel.com/${redirect}`
    } else {
      window.location.href = `https://www.artbasel.com/${redirect}`
    }
  }
}

export default redirectToB2bAfterLogin