import PropTypes from 'prop-types'

const isDev = false //process.env.NODE_ENV === 'development'
const DefaultLoading = ({ componentName }) => (isDev ? <div>{componentName} Loading...</div> : null)

const ContentPlaceholder = ({ componentName = '' }) => <DefaultLoading componentName={componentName} />

export default ContentPlaceholder

ContentPlaceholder.propTypes = {
  componentName: PropTypes.string
}

DefaultLoading. propTypes = {
  componentName: PropTypes.string
}