/*
  WIP, to be optimized when more cases appear
  If you need to validate if a certain url bellows to a certain section
  e.g. does url X belongs to Catalog Detail Pages
*/
const findUrlContext = (url = '', context) => {
  if (context === 'isCatalogDetail') {
    return url.indexOf('catalog/gallery', 'catalog/artist', 'catalog/artwork') >= 0
  }
  return false
}

export default findUrlContext
