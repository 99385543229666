import { useContext, useEffect, useState } from 'react'
import { Container } from 'semantic-ui-react'
import styled from 'styled-components'
import { withRouter } from 'next/router'
import { object } from 'prop-types'
import { LoginContext } from '@providers/Login'
import { Media, MediaContextProvider } from '@responsive'
import CopyMoveToCollection from '@components/collections/CopyMoveToCollection'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import useEditCollectionDetail from '@modules/collections/hooks/useEditCollectionDetail'
import useRemoveCollection from '@modules/collections/hooks/useRemoveCollection'
import useSubscribeCollection from '@modules/collections/hooks/useSubscribeCollection'
import { getRouterQueryParam } from '@utils'
import { Loader, PromoBanner } from '@components'
import useRequestLabelsPage from '@modules/collections/hooks/useRequestLabelsPage'
import TABS from '@modules/collections/constants/TABS'
import UnavailableCollection from '@components/reusable-modules/UnavailableCollection'
import useEditPreviewCollectionData from '@modules/collections/hooks/useEditPreviewCollectionData'
import { Tabs, Navigation, Header, TabSelector, ShareButtons } from './edit-preview-collection'
import FloatingControl from './FloatingControl'
import removeAllCookies from '@utils/removeCookies'

const EditPreviewCollectionModule = ({ router }) => {
  useRequestLabelsPage()
  const [isRedirectionOngoing, setIsRedirectionOngoing] = useState(false)
  //@ts-ignore
  const [
    {
      labels,
      isSubscribed,
      activeTab,
      isOrganizeMode,
      selectedItems,
      isCollectionOwner,
      isBannerDismissed,
      user,
      isNavVisibleAfterUnfollowAndDismissBanner,
      CMSProps
    },
    dispatch
  ] = useMyCollectionsPageContext()
  const {
    collectionDetail,
    editCollection,
    collectionDetailValidating,
    mutateCollectionDetail,
    collectionDetailError
  } = useEditCollectionDetail()
  const { removeCollection } = useRemoveCollection()
  const { subscribe, unsubscribe } = useSubscribeCollection()
  const shouldDisplayLoading = isCollectionOwner === null || (!isCollectionOwner && isSubscribed === null)

  const isBackToMyCollectionsNavVisible =
    (!isCollectionOwner && isSubscribed) || isCollectionOwner || isNavVisibleAfterUnfollowAndDismissBanner

  const {
    editPreviewCollectionItems,
    isRefreshing,
    isEmpty,
    isLoadingInitialData,
    editPreviewCollectionItemsValidating,
    setSize,
    size,
    isLoadingMore,
    mutateEditPreviewCollectionItems
  } = useEditPreviewCollectionData()

  const ActiveTab = TabSelector[activeTab || 'artworks']?.component || null
  const entityType = TABS[activeTab]?.entityType
  //@ts-ignore
  const totalCount = entityType === '4a,4b' ? (collectionDetail?.contentsCount['4b'] || 0) + (collectionDetail?.contentsCount['4a'] || 0) : collectionDetail?.contentsCount[entityType]
  const { showLogin } = useContext(LoginContext)

  useEffect(()=>{
    mutateCollectionDetail()
  }, [isOrganizeMode,mutateCollectionDetail])

  useEffect(() => {
    /** if redirection to somewhere else has started, don't call again the showlogin,
     * because u don't need it anymore, otherwise it will keep reopening the popup
     */
    !isRedirectionOngoing && showLogin({
      onLoginSuccessCallback: (data) => {
        // This SET_LOGGED_IN is either unnecessary or incorrect
        // This line should be removed, and let this Cllections hook/context consume the global user
        dispatch({ type: 'SET_LOGGED_IN', payload: data.me })
        mutateEditPreviewCollectionItems()
      },
      onLoginModalCloseCallback: () => {
        setIsRedirectionOngoing(true)
        router.push('/')
      }
    })
  }, [dispatch, mutateEditPreviewCollectionItems, router, showLogin, isRedirectionOngoing])

  const submitCallback = () => {
    router.push({ pathname: '/' }, labels?.Collections.addToCollectionCallbackURL, { shallow: false, scroll: false })
  }

  // clear storage
  useEffect(() => {
    removeAllCookies()
  }, [])

  // Get the Collection Detail based on URL "id" parameter
  useEffect(() => {
    if (typeof collectionDetail === 'undefined') {
      editCollection(getRouterQueryParam(router, 'id'))
    }
    if (collectionDetail) {
      //@ts-ignore
      dispatch({ type: 'SET_COLLECTION_SUBSCRIBED', payload: collectionDetail.ownership?.subscribed })
      //@ts-ignore
      dispatch({ type: 'SET_BANNER_DISMISSED', payload: collectionDetail.ownership?.subscribed === true })
      if (!collectionDetailValidating && isCollectionOwner === null) {
        if (user) {
          //@ts-ignore
          dispatch({ type: 'SET_COLLECTION_OWNER', payload: user.crmGuid === collectionDetail.ownerId })
        }
        if (!user) {
          dispatch({ type: 'SET_COLLECTION_OWNER', payload: false })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionDetail, collectionDetailValidating, isCollectionOwner, user])

  // if the collection was deleted or its unavailable for this user
  if (collectionDetailError) {
    return <div className='no-margin-top'><UnavailableCollection /></div>
  }

  if (shouldDisplayLoading) {
    return (
      <LoaderContainer>
        {/* @ts-ignore */}
        <Loader centered active />
      </LoaderContainer>
    )
  }
  if (!labels) return null

  return (
    <>
      <StyledContainer className={!isCollectionOwner ? 'is-visitor' : ''}>
        {/* Display the promotional banner to follow this collection */}
        {!isCollectionOwner && collectionDetail && !isSubscribed && !isBannerDismissed && (
          <PromoBanner
            //@ts-ignore
            ownerName={collectionDetail.ownerName}
            //@ts-ignore
            collectionId={collectionDetail.id}
            followCollectionMessage={labels.CollectionNotifications.followCollectionMessage}
            followCollectionTitle={labels.CollectionNotifications.followCollectionTitle}
            followCollectionBtnLabel={labels.CollectionNotifications.followCollectionBtnLabel}
            dismissBtnLabel={labels.CollectionNotifications.dismissBtnLabel}
            subscribe={subscribe}
            dispatch={dispatch}
          />
        )}

        {/* Back navigation to visit My Collections (appears only if you are the owner, or you are a follower) */}
        {isBackToMyCollectionsNavVisible && (
          <NavigationAndShareButtonWrapper>
            <MediaContextProvider>
              <Navigation
                title={labels?.Collections.allCollectionsPageCTALabel}
                navigationUrl={labels?.Collections.addToCollectionCallbackURL}
              />
              <Media lessThan='computer' className='share-button-right-top'>
                {
                  //@ts-ignore
                  (collectionDetail && Object.keys(collectionDetail?.contentsCount).length !== 0) && (
                    <ShareButtons
                      collectionDetail={collectionDetail}
                      dispatch={dispatch}
                      labels={labels}
                      isSubscribed={isSubscribed}
                      removeCollection={removeCollection}
                      mutateCollectionDetail={mutateCollectionDetail}
                      submitCallback={submitCallback}
                      isOrganizeMode={isOrganizeMode}
                      myCollectionsReturnURL={labels?.Collections.addToCollectionCallbackURL}
                      isCollectionOwner={isCollectionOwner}
                      subscribe={subscribe}
                      unsubscribe={unsubscribe}
                      isBannerDismissed={isBannerDismissed}
                      isDisabledOrganizeMode={editPreviewCollectionItems?.length === 0}
                      CMSProps={CMSProps}
                    />
                  )
                }
              </Media>
            </MediaContextProvider>
          </NavigationAndShareButtonWrapper>
        )}

        {/* Collection Detail Infos (title, description, follow button, actions ) */}
        {collectionDetail && (
          <Header
            collectionDetail={collectionDetail}
            dispatch={dispatch}
            labels={labels}
            isSubscribed={isSubscribed}
            removeCollection={removeCollection}
            mutateCollectionDetail={mutateCollectionDetail}
            submitCallback={submitCallback}
            isOrganizeMode={isOrganizeMode}
            myCollectionsReturnURL={labels?.Collections.addToCollectionCallbackURL}
            isCollectionOwner={isCollectionOwner}
            subscribe={subscribe}
            unsubscribe={unsubscribe}
            isBannerDismissed={isBannerDismissed}
            isDisabledOrganizeMode={editPreviewCollectionItems?.length === 0}
            CMSProps={CMSProps}
          />
        )}

        <TabandShareButtonContainer>
          {/* Tabs Buttons */}
          <Tabs
            collectionDetail={collectionDetail}
            mutateCollectionDetail={mutateCollectionDetail}
            editPreviewCollectionItems={editPreviewCollectionItems}
          />
          <MediaContextProvider>
            <Media greaterThanOrEqual='computer'>
              {
                //@ts-ignore
                (collectionDetail && Object.keys(collectionDetail?.contentsCount).length !== 0) && (
                  <ShareButtons
                    collectionDetail={collectionDetail}
                    dispatch={dispatch}
                    labels={labels}
                    isSubscribed={isSubscribed}
                    removeCollection={removeCollection}
                    mutateCollectionDetail={mutateCollectionDetail}
                    submitCallback={submitCallback}
                    isOrganizeMode={isOrganizeMode}
                    myCollectionsReturnURL={labels?.Collections.addToCollectionCallbackURL}
                    isCollectionOwner={isCollectionOwner}
                    subscribe={subscribe}
                    unsubscribe={unsubscribe}
                    isBannerDismissed={isBannerDismissed}
                    isDisabledOrganizeMode={editPreviewCollectionItems?.length === 0}
                    CMSProps={CMSProps}
                    activeTab={activeTab}
                  />
                )
              }
            </Media>
          </MediaContextProvider>
        </TabandShareButtonContainer>

        {isLoadingInitialData ?
          //@ts-ignore
          ((!activeTab || Object.keys(collectionDetail?.contentsCount).length === 0) && (
            <NoCollectionItemsText as='h4' className='text-paragraph-2 color-darkgrey'>
              {labels?.Collections.noCollectionsResultText}
            </NoCollectionItemsText>
          )) : null}

        {/* Active Tab Panel */}
        {activeTab && (
          <ActiveTab
            editPreviewCollectionItems={editPreviewCollectionItems}
            isRefreshing={isRefreshing}
            isEmpty={isEmpty}
            isLoadingInitialData={isLoadingInitialData}
            editPreviewCollectionItemsValidating={editPreviewCollectionItemsValidating}
            setSize={setSize}
            size={size}
            isLoadingMore={isLoadingMore}
            totalItems={totalCount}
            //@ts-ignore
            isTabExist={Object.keys(collectionDetail?.contentsCount).length !== 0}
          />
        )}

        {/* Floating Controls to Organize the Panel */}
        {isOrganizeMode && (
          <FloatingControl
            dispatch={dispatch}
            selectedItems={selectedItems}
            mutateEditPreviewCollectionItems={mutateEditPreviewCollectionItems}
            editPreviewCollectionItems={editPreviewCollectionItems}
          />
        )}
      </StyledContainer>
      <CopyMoveToCollection
        //@ts-ignore
        editPreviewCollectionItems={editPreviewCollectionItems}
      />
    </>
  )
}

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`

const NoCollectionItemsText = styled.div`
  min-height: 500px;
`

const StyledContainer = styled(Container)`
  @media (min-width: 992px) {
    position: relative;
    margin-top: 20px !important;

    &.is-visitor {
      margin-top: 34px !important;
    }
  }

  @media (max-width: 991px) {
    position: relative;
  }
`

const TabandShareButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const NavigationAndShareButtonWrapper = styled.div`
  display: flex;

  a {
    height: 48px;
  }

  .share-button-right-top {
    margin-left: auto;

    @media (max-width: 767px) {
      margin-top: -25px;
    }
  }
`

EditPreviewCollectionModule.propTypes = {
  router: object
}

export default withRouter(EditPreviewCollectionModule)
