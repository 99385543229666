/* eslint-disable react/no-unused-state */
import React from 'react'
import PropTypes from 'prop-types'
import NextError from '../pages/_error'
import { track } from '../utils/logger'
import { AppContext } from 'next/app'
import type ISiteHostName from 'types/ISiteHostName'

const withErrorHandler = (App) =>
  (class extends React.Component {
    static displayName = 'withErrorHandler(PageErrorCatch)'

    static propTypes = {
      statusCode: PropTypes.number,
      siteHostname: PropTypes.object
    }

    state = {
      error: null,
      errorInfo: null
    }

    componentDidCatch(error, errorInfo) {
      track(error)
      this.setState({ error, errorInfo })
    }

    static async getInitialProps(ctx: AppContext) {
      const appProps = App.getInitialProps ? await App.getInitialProps(ctx) : {}
      let statusCode = 200
      const {
        ctx: { req }
      } = ctx

      let siteHostname: ISiteHostName = {}
      if (req?.headers?.host) {
        siteHostname.hostname = req?.headers?.host
      }
      if (!req?.headers?.host && typeof window !== 'undefined' && window?.location?.hostname) {
        siteHostname.hostname = window?.location?.hostname
      }

      siteHostname = {
        hostname: siteHostname?.hostname,
        brand: '',
        variant: 'artbasel',
        logoVariant: 'default'
      }

      try {
        // eslint-disable-next-line prefer-destructuring
        statusCode = appProps.pageProps.statusCode
      } catch (e) {
        statusCode = 500
      }

      return {
        ...appProps,
        siteHostname,
        statusCode
      }
    }

    render() {
      // @ts-ignore
      const { statusCode } = this.props
      const { error } = this.state

      if (statusCode !== 200 || error) {
        return <NextError />
      }

      return <App {...this.props} />
    }
  })

export default withErrorHandler
