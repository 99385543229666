import { createBrowserHistory } from 'history'
import qs from 'query-string'

/**
 * Use this function whenever you want to pass some query params to the URL, without
 * refreshing the page. It won't either call getInitialProps.
 */
const updateUrlParams = (params) => {
  const history = createBrowserHistory()
  const { location } = history
  history.replace({
    pathname: location.pathname,
    search: qs.stringify(params)
  })
}
export default updateUrlParams
