import React from 'react'
import { debounce, isElement, isEmpty } from 'lodash'
import { string, number, bool, oneOfType } from 'prop-types'
import { roundImageDimension } from '@utils'
import { uploadTransformationUrl } from '@mch-group/toolkit-utils'
import { cloudinary } from '@constants'

import { width as getWidth, transparentPixel } from './utils'

const transforms = {
  aspect_ratio: 'ar',
  quality: 'q',
  height: 'h',
  width: 'w',
  dpr: 'dpr',
  crop: 'c',
  flags: 'fl',
  effect: 'e',
  gravity: 'g',
  zoom: 'z',
  f: 'f'
}

const transformDefaults = {
  quality: 'auto',
  width: 'auto',
  dpr: 'auto',
  f: 'auto'
}

const lqipTransformDefaults = {
  width: '30',
  quality: 'auto',
  crop: 'fill',
  flags: 'progressive',
  f: 'auto'
}

const Cloudinary = (ImageComponent) =>
  // eslint-disable-next-line react/display-name
  (class extends React.PureComponent {
    static propTypes = {
      responsive: bool,
      publicId: string,
      aspect_ratio: oneOfType([number, string]), // ratio or percent (1.5 or 16:9)
      quality: oneOfType([number, string]), // percent (100, 20), percent[:chroma] (60:420), auto[:quality_level]
      width: oneOfType([number, string]), // pixels, percents or string
      height: oneOfType([number, string]), // pixels, percents or string
      dpr: string, // number (2.0) or auto
      crop: string,
      flags: string,
      effect: string,
      gravity: string,
      roundDimension: number,
      lqProgressive: bool,
      alt: string,
      preload: string,
      url: string,
      className: string,
      f: string,
      q: string,
      zoom: oneOfType([number, string]) // percent (1.2, 0.75)
    }

    static defaultProps = {
      publicId: transparentPixel,
      lqProgressive: true
    }

    imgRef: React.RefObject<unknown>

    element: any

    listener: any

    rqf: any

    constructor(props) {
      super(props)
      this.imgRef = React.createRef()
      this.handleResize = this.handleResize.bind(this)
      this.state = {
        url: transparentPixel,
        lqip: transparentPixel,
        ...this.prepareState()
      }
    }

    componentDidMount() {
      // @ts-ignore
      this.element = this.imgRef.current !== null ? this.imgRef.current.element : null

      // Now that we have a this.element, we need to calculate the URL
      this.handleResize()

      const wait = 1000
      if (this.listener) {
        this.window && this.window.removeEventListener('resize', this.listener)
      }
      this.listener = debounce(this.handleResize, wait)
      this.window && this.window.addEventListener('resize', this.listener)
    }

    componentWillUnmount() {
      this.element = undefined

      if (this.listener) {
        this.listener.cancel()
        this.window && this.window.removeEventListener('resize', this.listener)
      }
      this.listener = undefined

      if (this.rqf) {
        cancelAnimationFrame(this.rqf)
      }

      this.rqf = undefined
    }

    getUrl() {
      const { props } = this
      // @ts-ignore
      const { publicId } = props
      const transformString = this.urlTransformParams(props)
      return uploadTransformationUrl(publicId, transformString, { 'transformUrl': cloudinary.throughCloudfront })
    }

    getLqip() {
      const { props } = this
      // @ts-ignore
      const { publicId } = props
      const lqipTransformations = this.urlTransformParams({
        ...lqipTransformDefaults,
        // @ts-ignore
        ...('aspect_ratio' in props && { aspect_ratio: props.aspect_ratio })
      })
      let clLqip = uploadTransformationUrl(publicId, lqipTransformations, { 'transformUrl': cloudinary.throughCloudfront })
      clLqip = this.updateCloudinaryUrl(clLqip, true)

      return clLqip
    }

    // Retrieve the window or default view of the current element
    get window() {
      let windowRef = null
      if (typeof window !== 'undefined') {
        // @ts-ignore
        windowRef = window
      }

      return this.element && this.element.ownerDocument
        ? this.element.ownerDocument.defaultView || windowRef
        : windowRef
    }

    getElementWidth(element) {
      if (typeof window === 'undefined' || !element) return 0

      const style = this.window.getComputedStyle(element)
      if (!/^inline/.test(style.display)) {
        // @ts-ignore
        if (this.props.roundDimension) {
          return roundImageDimension(getWidth(element), 10)
        }
        return getWidth(element)
      }

      return 0
    }

    // Transform component props to Cloudinary URL params and set defaults
    urlTransformParams = props =>
      Object.keys(transforms)
        .map((t) => {
          const value = props[t] || transformDefaults[t]
          if (value) return { [transforms[t]]: value }
          return null
        })
        .filter(Boolean)
        .map((t) => {
          // @ts-ignore
          const key = Object.keys(t)[0]
          // @ts-ignore
          return `${key}_${t[key]}`
        })
        .join(',')

    handleResize() {
      if (this.rqf) return

      this.rqf = requestAnimationFrame(() => {
        cancelAnimationFrame(this.rqf)
        this.rqf = null

        const newState = this.prepareState()
        // @ts-ignore
        if (!isEmpty(newState.url)) {
          this.setState(newState)
        }
      })
    }

    prepareState() {
      const nextState = {}
      const currentState = this.state || {}
      let url = this.getUrl()
      // @ts-ignore
      const lqip = this.props.lqProgressive ? this.getLqip() : null

      // @ts-ignore
      if (this.props.responsive) {
        // @ts-ignore
        url = this.updateCloudinaryUrl(url)
      }
      // @ts-ignore
      if (!isEmpty(url) && url !== currentState.url) {
        // @ts-ignore
        nextState.url = url
      }

      // @ts-ignore
      if (!isEmpty(lqip) && lqip !== currentState.lqip) {
        // @ts-ignore
        nextState.lqip = lqip
      }

      return nextState
    }

    findContainerWidth() {
      if (typeof window === 'undefined' || !this.element) return 0

      let containerWidth = 0
      let { element } = this
      let style = ''

      // eslint-disable-next-line no-loops/no-loops
      while (isElement(element) && !containerWidth) {
        element = element != null ? element.parentNode : null
        style = this.window ? this.window.getComputedStyle(element) : ''
        // @ts-ignore
        if (!/^inline/.test(style.display)) {
          containerWidth = this.getElementWidth(element.parentNode)
        }
      }

      return containerWidth
    }

    devicePixelRatio(roundDpr = true) {
      let dpr = (typeof this.window !== 'undefined' && this.window !== null ? this.window.devicePixelRatio : 0) || 1
      let dprString

      if (roundDpr) {
        dpr = Math.ceil(dpr)
      }

      if (dpr <= 0 || Number.isNaN(dpr)) {
        dpr = 1
      }

      dprString = dpr.toString()
      if (dprString.match(/^\d+$/)) {
        dprString += '.0'
      }

      return dprString
    }

    isAutoWidth = clUrl => /w_auto(:(\d+))?/.exec(clUrl)

    updateCloudinaryUrl(url, isLqip) {
      let cloudinaryUrl = url
      let containerWidth = 0
      // eslint-disable-next-line no-useless-escape
      const urlAutoWidthParam = /w_auto[^,\/]*/g

      if (isLqip) return url

      // @ts-ignore
      if (this.props.responsive) {
        containerWidth = this.findContainerWidth()

        if (containerWidth !== 0 && this.isAutoWidth(cloudinaryUrl)) {
          // @ts-ignore
          cloudinaryUrl = cloudinaryUrl.replace(urlAutoWidthParam, `w_${parseInt(containerWidth, 10)}`)
        }
      }

      return cloudinaryUrl
    }

    render() {
      return <ImageComponent ref={this.imgRef} {...{ ...this.props, ...this.state }} />
    }
  })



export default Cloudinary
