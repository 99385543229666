import { css } from 'styled-components'
import { px2rem, hiDPIpx, mq } from '@utils'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const EnquiryModalWrapper = css`
  &.ui.modal {
    @media (min-width: 1200px) {
      width: ${px2rem(1054)};
    }

    ${mq.lessThan('largestTablet')`
      width: 100%;
    `}

    h2 {
      ${mq.lessThan('largestMobile')`
        font-size: ${tokens.fontSize[4].value};
      `}
    }

    padding-bottom: 0;

    .form {
      display: flex;
      ${mq.lessThan('largestTablet')`
        flex-direction: column;
      `}

      .left {
        width: 50%;
        padding: ${px2rem(36)} ${px2rem(40)} ${px2rem(36)} ${px2rem(33)};
        ${mq.lessThan('largestMobile')`
          width: 100%;
          padding: ${px2rem(26)} ${px2rem(16)} ${px2rem(16)};
        `}
        ${mq.between('tablet', 'largestTablet')`
          width: 100%;
          padding: ${hiDPIpx(20)};
        `}

        h2.ui.header {
          margin-bottom: ${px2rem(18)};
        }

        .preferred-option-wrapper {
          ${mq.lessThan('largestMobile')`
            margin-right: ${px2rem(-8)} !important;
          `}

          .preferred-contact {
            padding: ${px2rem(40)} 0;

            &.row {
              ${mq.lessThan('largestMobile')`
                display: block;
              `}

              .column {
                cursor: pointer;
                ${mq.lessThan('largestMobile')`
                  display: block;
                  width: 100% !important;
                  margin-bottom: ${px2rem(16)};
                `}
              }
            }

            .active span {
              border: ${px2rem(1.5)} solid var(--bs-mch-gray-400);
              color: var(--bs-mch-gray-400);
              font-weight: ${tokens.fontWeights.artBaselHeadline0.value};
            }

            span {
              border: ${px2rem(1.5)} solid var(--bs-mch-galleries);
              border-radius: ${px2rem(50)};
              padding: ${px2rem(7)};
              margin-right: ${px2rem(-12)};
              display: flex;
              justify-content: center;
            }
          }
        }

        .additional-info {
          .label-1 {
            margin-bottom: ${px2rem(20)};
          }

          .allow-job-title {
            margin-bottom: ${px2rem(20)};
            display: inline-block;
          }

          .userinvite-title {
            margin-bottom: ${px2rem(20)};
            display: flex;

            &--modifier {
              span {
                margin-left: ${px2rem(30)};
                margin-top: ${px2rem(-10)};
              }
            }
          }

          .ui.checkbox {
            margin-bottom: ${px2rem(-5)};
            display: inline-block;
          }

          span {
            margin-left: ${px2rem(10)};
          }
        }

        .item > .content > .item {
          label {
            color: ${tokens.color.light.base.neutrals['500'].value};
            margin-bottom: ${px2rem(12)};
          }

          .checkbox {
            max-width: 94%;
            ${mq.lessThan('largestMobile')`
              max-width: 82%;
            `}
            label {
              color: var(--bs-mch-black);
              white-space: pre-line;
            }
          }
        }

        .row.user-detail {
          ${mq.lessThan('largestTablet')`
            display: block;
          `}

          .column {
            ${mq.lessThan('largestTablet')`
              display: block;
              width: 100% !important;
            `}

            &.full-name {
              ${mq.lessThan('largestTablet')`
                margin-bottom: ${px2rem(16)};
              `}
            }
          }
        }

        .row.contact-detail {
          ${mq.lessThan('largestTablet')`
            padding: 0;
            margin-bottom: ${px2rem(24)};
          `}
        }
      }

      .seperator {
        border-left: ${px2rem(1.5)} solid var(--bs-mch-galleries);
        margin-top: 100px;
        margin-bottom: 45px;
        ${mq.lessThan('largestTablet')`
          display: none;
        `}
      }

      .right {
        width: 50%;
        padding: ${px2rem(90)} ${px2rem(32)} ${px2rem(64)} 0;
        ${mq.lessThan('largestMobile')`
          width: 100%;
          padding: ${px2rem(16)};
        `}

        ${mq.between('tablet', 'largestTablet')`
          width: 100%;
          padding: ${hiDPIpx(20)};
        `}

        .ui.grid {
          &.main-grid {
            ${mq.greaterThan('desktop')`
              margin-left: 0;
              margin-right: 0;
            `}
          }
        }

        .ui.grid > .row > .column {
          ${mq.greaterThan('desktop')`
            padding-left: ${px2rem(32)};
            padding-right: 0;
          `}
        }

        .inquiry-artwork-img-row {
          .column {
            &:first-child {
              width: 33% !important;
            }

            .artwork-image {
              width: 100%;
              padding-top: 100%;
              background-size: contain !important;
              background-color: var(--bs-mch-galleries);
            }
          }
        }

        .terms-condition-text {
          ${mq.greaterThan('desktop')`
            margin-left: ${px2rem(32)};
            padding-left: 0;
          `}

          ${mq.lessThan('largestTablet')`
            margin-bottom: ${px2rem(24)};
            padding-bottom: 0;
          `}
        }

        textarea {
          font-family: inherit;
          resize: none;
          padding: ${px2rem(10)};
          height: ${px2rem(146)};
          margin-top: ${px2rem(24)};
        }

        .textarea--large {
          textarea {
            height: ${px2rem(307)};
          }
        }

        .artist-detail {
          padding-left: 0 !important;
          ${mq.greaterThan('desktop')`
            margin-left: ${px2rem(16)};
          `}

          .name {
            ${mq.greaterThan('desktop')`
              position: absolute;
              bottom: 0;
            `}

            ${mq.lessThan('largestTablet')`
              margin-top: ${hiDPIpx(30)};
            `}

            ${mq.lessThan('largestMobile')`
              margin-top: ${px2rem(30)};
            `}
          }
        }

        .submit-button {
          margin-top: ${px2rem(16)};

          button {
            width: 100%;
            float: unset;
          }
        }

        .inquiring-artwork {
          margin-top: ${px2rem(24)};
          ${mq.greaterThan('desktop')`
            margin-left: ${px2rem(32)};
            padding-left: 0;
          `}
        }

        figcaption {
          font-size: ${tokens.fontSize[0].value};
        }
      }
    }

    .elipsis {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .edit-icon {
      opacity: 0.3;
      margin-left: ${px2rem(10)};
      position: absolute;
    }

    button {
      ${mq.lessThan('largestMobile')`
        width: 100%;
      `}
      float: right;
    }
  }
`

export default EnquiryModalWrapper