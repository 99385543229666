/* eslint-disable no-param-reassign */
import qs from 'query-string'

const cmsPageUrl = (slug, page = '/index', extraParams: any = null) => {
  // Most of the URLs that are being passed into this method has only the "slug" parameter. Doing so is causing the Next
  // router not being able to make the routing properly if there's any query parameters in them that are supposed to
  // affect requests (like in catalog or event browse).
  // Here, we're checking if there's any query parameters in the slug parameter, and if there is, we're extracting it
  // and sending it as extra params, so that the correct routing will be performed.
  if (slug && typeof slug === 'string' && slug.indexOf('?') > -1) {
    extraParams =
      extraParams === null
        ? qs.parse(`?${slug.split('?')[1]}`)
        : { ...extraParams, ...qs.parse(`?${slug.split('?')[1]}`) }
    slug = slug.split('?')[0]
  }

  let url = `${page}?slug=${slug}`
  const extras = qs.stringify(extraParams)
  if (extras) {
    url += `&${extras}`
  }
  return url
}

export default cmsPageUrl
