import React from 'react'
import Cookies from 'js-cookie'
import { FingerprintContext } from '@providers/Fingerprint'
import CookieKeys from '@constants/CookieKeys'

/**
 * Sugar envelop for async fetching the data needed for tracing the user.
 * This data is needed, to for example, fetch some data where its important the tracing of the user.
 */
function useTracing() {
  // @ts-ignore
  const { fingerprint } = React.useContext(FingerprintContext)
  const [agentKey, setAgentKey] = React.useState<string | null>(null)

  /**
   * As the cookie is requested in Layout.js we just have to wait for that fetch to complete
   * as it will assign the cookie to AGENT_KEY, we do not know when this fetch will finish, so we set an
   * interval until this fetch is done
   */
  React.useEffect(() => {
    const interval = setInterval(() => {
      let agentKeyForTracking: string | null = null
      let isTargetingCookiesTypeAccepted = false
      isTargetingCookiesTypeAccepted = typeof OnetrustActiveGroups !== 'undefined' &&
        OnetrustActiveGroups?.indexOf('C0004') > -1
      if (!isTargetingCookiesTypeAccepted) {
        // When agentKey cookie group is not accepted, put a hardcoded string
        agentKeyForTracking = 'NoAgentKey'
      } else {
        agentKeyForTracking = Cookies.get(CookieKeys.AGENT_KEY) ?? null
      }
      setAgentKey(agentKeyForTracking)
    }, 250)

    if (agentKey && OnetrustActiveGroups) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [agentKey])

  return {
    fingerprint,
    agentKey
  }
}

export default useTracing
