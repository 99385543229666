import { useCallback, useState, useEffect, cloneElement, useContext } from 'react'
import shortid from 'shortid'
import { Modal, Input, Form, Header, Checkbox, Grid } from 'semantic-ui-react'
import styled from 'styled-components'
import classNames from 'classnames'
import { withFormik, FormikProps, useFormikContext } from 'formik'
import * as Yup from 'yup'
import InputFeedback from '@components/Form/InputFeedback'
import { getSiteLanguage, returnEmptyStringFromNullOrUndefined } from '@utils'
import Text from '@components/Text'
import { LoginContext } from '@providers/Login'
import { logger, validateAxiosResponse, uploadTransformationUrl } from '@dmi-mch/utils'
import { CharCounter } from '@dmi-mch/components'
import { Contact as ContactService, VipInvitation as VipInvitationService } from '@services'
import { Icon, Button } from '@mch-group/uikit-components'
import validationConstants from '@constants/validation'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { cloudinary } from '@constants'
import preferredContactOption from './contactOptions'
import EnquiryGlobalStyles from './styles/EnquiryGlobalStyles'
import EnquiryStyles from './styles/EnquiryStyles'
import type { IUserRedux } from '../../types/IReduxStoreTypes'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const mID = 'modal-artwork-contact'
const maxCharsInText = 750

const EnquiryModalForm = ({
  className,
  contactBtnClass,
  imgAlt,
  image,
  title,
  subtitle,
  eventId,
  isSubmitting,
  showInRoom,
  setUserDataFromAPI,
  isShowArtworkDetail = true,
  // Default button, in case user doesn't enter any
  button = (
    <Button variant='primary' size='compact' className={contactBtnClass || ''}>
      Inquiry
    </Button>
  ),
  status = {
    isOpenContactForm: false
  },
  setStatus,
  onShowModal,
  showWhenCatalog,
  showWhenExhibition,
  showWhenShowroom,
  submitButtonAttributes,
  initialValues,
  buttonOnLoad,
  buttonOnLoadLabel,
  showPopupWithoutButton,
  closeCallback,
  // This is callback to decide what happens after Login modal is closed.
  closeLoginModalCallback
}: ArtworkContactFormProps & FormikProps<FormValues>) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue
  } = useFormikContext<FormValues>()
  // User
  const [user, setUser] = useState<IUserRedux | null>(null)
  const { showLogin } = useContext(LoginContext)

  // Labels
  const { labels } = useLabelsFromAPI(['ArtworkContactForm', 'FieldValidation', 'AltTags'], getSiteLanguage())
  const { ArtworkContactForm: artworkContactFormLabels } = labels || {}
  const contactOptions = preferredContactOption(artworkContactFormLabels)
  // Component can be enabled or disabled according to some logic in the CMS
  const [isComponentEnabled, setIsComponentEnabled] = useState(false)
  const [userInvitations, setUserInvitations] = useState<Array<ABTypes.Invitation.UserInvitation> | null>(null)
  const [isAllReady, setIsAllReady] = useState(false)

  //disable the phone number field when preferred option EMAIL is selected.
  const isPhoneNumberDisableAndBlank = initialValues.preferredContactOption === contactOptions[0].value

  const getUser = useCallback(async () => {
    try {
      setUserDataFromAPI()
    } catch (e) {
      logger(e)
    }
  }, [setUserDataFromAPI])

  useEffect(() => {
    if (user?.me && user.me.telephone && status.isOpenContactForm) {
      isPhoneNumberDisableAndBlank
        ? setFieldValue('contactNumber', '')
        : setFieldValue('contactNumber', user.me.telephone)
    }
  }, [setFieldValue, user, status.isOpenContactForm, isPhoneNumberDisableAndBlank])

  useEffect(() => {
    if (status.isOpenContactForm === false) {
      setIsAllReady(false)
    }
  }, [status.isOpenContactForm])

  const onClickCloseIcon = () => {
    if (showPopupWithoutButton) {
      typeof closeCallback === 'function' && closeCallback()
      setStatus({ isOpenContactForm: false })
    }
    else {
      setStatus({ isOpenContactForm: false })
    }
  }
  const guessIfIsEnabled = useCallback(async () => {
    try {
      if (eventId) {
        if (showInRoom) {
          setIsComponentEnabled(showWhenShowroom)
        } else {
          setIsComponentEnabled(showWhenExhibition)
        }
      } else if (showWhenCatalog) {
        setIsComponentEnabled(true)
      }
    } catch (error) {
      logger(error)
    }
  }, [eventId, showInRoom, showWhenShowroom, showWhenCatalog, showWhenExhibition])

  const getInvitationGEDs = useCallback(async () => {
    try {
      const response = await VipInvitationService.mineLatest({ limit: 3, mainEvent: true })
      if (validateAxiosResponse(response)) {
        // @ts-ignore
        const arrayInvitations = response.data.map(item => `${item.packageName}, ${item.showFullName}`)
        setUserInvitations(arrayInvitations)
      }
    } catch (error) {
      logger(error)
    }
  }, [])

  // What to do after click in the "Get in contact" button
  const onClickMainButton = useCallback(
    event => {
      event?.preventDefault()
      showLogin({
        onLoginSuccessCallback: (currentUser) => {
          setUser(currentUser)
          setStatus({ isOpenContactForm: true })
          if (typeof onShowModal === 'function') {
            onShowModal()
          }
        },
        onLoginModalCloseCallback: () => {
          if (typeof closeLoginModalCallback === 'function') {
            closeLoginModalCallback()
          }
        }
      })
    },
    [showLogin, onShowModal, setStatus, closeLoginModalCallback]
  )

  /**
   * @note This should be done with closures, the button prop should be a function
   * that contains the onClickMainButton, so when you call the component you could do a:
   * ``
   * <EnquiryModal
   *  button={(onClickMainButton) => <MyCustomButton onClick={onClickMainButton} />}
   *  />
   *  ``
   *  And inside the component the closure is done doing:
   *  ``
   *  const buttonWithVitamins = button(onClickMainButton)
   *  ``
   */
  // Add the onclick functionality to the button, which initially doesn't have functionality.
  const buttonWithVitamines = element => cloneElement(element, {
    onClick: (e) => { onClickMainButton(e) }
  })

  useEffect(() => {
    let isUnmounted = false
    !isUnmounted && guessIfIsEnabled()

    return () => {
      isUnmounted = true
    }
  }, [guessIfIsEnabled])

  useEffect(() => {
    if (showPopupWithoutButton) {
      // @ts-ignore
      typeof onClickMainButton === 'function' && onClickMainButton()
    }
    // Next line should document why this is necessary to "hack" react natural behavior
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopupWithoutButton])

  useEffect(() => {
    let isUnmounted = false
    // Calculate GEDS only when the contact button is clicked
    if (!isUnmounted && status.isOpenContactForm) {
      // Triggers a +1 right after modal is shown
      if (!isAllReady) {
        !user?.me && getUser()
        getInvitationGEDs()
        setIsAllReady(true)
      }
    }

    return () => {
      isUnmounted = true
    }
  }, [getInvitationGEDs, status.isOpenContactForm, onShowModal, getUser, isAllReady, user])

  if (!artworkContactFormLabels) return null

  if (isComponentEnabled) {
    return (
      <>
        {/* Modal Success Message */}
        <Modal
          open={status.isSuccessMessageOpened}
          size='tiny'
          closeIcon={
            <Icon
              name='close'
              data-is-closeicon-inside
              //@ts-ignore
              onClick={() => {
                setStatus({ isSuccessMessageOpened: false })
                typeof closeLoginModalCallback === 'function' && closeLoginModalCallback()
                typeof closeCallback === 'function' && closeCallback()
              }}
            />}
          className='success-modal'
          role='dialog'
          aria-modal='true'
          tabIndex={-1}
          aria-label={artworkContactFormLabels.submitSuccessLabel}
        >
          <Header as='h3' content={artworkContactFormLabels.submitSuccessLabel} />
          <Modal.Content>{artworkContactFormLabels.messageSentToGalleryLabel}</Modal.Content>
          <Modal.Actions className='success-ok'>
            <Button
              size='compact'
              variant='primary'
              onClick={() => {
                setStatus({ isSuccessMessageOpened: false })
                typeof closeCallback === 'function' && closeCallback()
                typeof closeLoginModalCallback === 'function' && closeLoginModalCallback()
              }}
            >
              {artworkContactFormLabels.okayLabel}
            </Button>
          </Modal.Actions>
        </Modal>
        {/* Modal for the contact form */}
        {user?.me && status.isOpenContactForm && (
          <Modal
            size='small'
            open={status.isOpenContactForm}
            closeIcon={<Icon
              name='close'
              data-is-closeicon-inside
              //@ts-ignore
              onClick={() => onClickCloseIcon()}
              />
            }
            closeOnDimmerClick={false}
            className={classNames(mID, className)}
            role='dialog'
            aria-modal='true'
            tabIndex={-1}
            aria-label={artworkContactFormLabels.interestedContactGalleryLabel}
          >
            <Form onSubmit={handleSubmit} loading={isSubmitting}>
              <div className='left'>
                <div>
                  <Header as='h2' className='color-darkgrey'>
                    {artworkContactFormLabels.interestedContactGalleryLabel}
                  </Header>
                  <Text className='main-text color-black'>
                    {artworkContactFormLabels.preferredContactMethod}
                  </Text>
                  <Grid className='preferred-option-wrapper'>
                    <Grid.Row columns={3} className='preferred-contact'>
                      {contactOptions.map((option) => (
                        <Grid.Column
                          className={values.preferredContactOption == option.value ? 'active' : ''}
                          key={shortid.generate()}
                          onClick={() => {
                            initialValues.preferredContactOption = option.value
                            setFieldValue('preferredContactOption', option.value)
                          }}
                        >
                          <span className='label label-2 color-darkgrey'>
                            {option.text}
                          </span>
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                  </Grid>
                  <Grid>
                    <span className='main-text'>
                      {artworkContactFormLabels.detailLabelText}
                    </span>
                    <Grid.Row columns={2} className='user-detail'>
                      <Grid.Column className='full-name'>
                        <div className='label label-1 color-black'>
                          {artworkContactFormLabels.nameLabel}
                        </div>
                        <Text className='main-text'>{user.me.fullName}</Text>
                      </Grid.Column>
                      <Grid.Column>
                        <div className='label label-1 color-black'>
                          {artworkContactFormLabels.contactEmailLabel}
                        </div>
                        <Text className='main-text elipsis'>{user.me.email}</Text>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className='contact-detail'>
                      <Grid.Column>
                        <Form.Field disabled={isPhoneNumberDisableAndBlank}>
                          <div className='label label-1 color-black'>
                            {artworkContactFormLabels.phoneFieldText} {' '}
                            <span className='label label-3 color-black'>
                              {artworkContactFormLabels.phoneFieldTooltip}
                            </span>
                          </div>
                          <Input
                            type='text'
                            name='contactNumber'
                            id='contactNumber'
                            value={values.contactNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // @ts-ignore
                            error={errors.contactNumber && touched.contactNumber}
                            placeholder='Enter phone number (+xx)'
                            aria-label={artworkContactFormLabels.phoneFieldText}
                          />
                          {errors.contactNumber && touched.contactNumber && (
                            <InputFeedback>{errors.contactNumber}</InputFeedback>
                          )}
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Field className='additional-info'>
                          {(user.me.jobTitle ||
                            user.me.companyName ||
                            (userInvitations && userInvitations.length > 0)) && (
                              <label htmlFor='allowJobTitle' className='label label-1 color-black'>
                                {artworkContactFormLabels.additionalInfoLabel}
                              </label>
                            )}
                          {returnEmptyStringFromNullOrUndefined(user.me.jobTitle || user.me.companyName) && (
                            <>
                              <div className='allow-job-title'>
                                <Checkbox
                                  id='allowJobTitle'
                                  name='allowJobTitle'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  checked={values.allowJobTitle}
                                  className='dark'
                                  aria-label='allowJobTitle'
                                />
                              </div>
                              <span>
                                {user.me.jobTitle} {user.me.companyName}
                                <a href={`/dashboard/overview?lang=${getSiteLanguage()}#29911`} className='edit-icon'>
                                  <Icon name='edit' />
                                </a>
                              </span>
                            </>
                          )}
                          {userInvitations && userInvitations?.length > 0 && userInvitations.map((data, index) => (
                            <div
                              className={`userinvite-title ${index > 0 && 'userinvite-title--modifier'}`}
                              key={shortid.generate()}
                            >
                              {index === 0 &&
                                <Checkbox
                                  id='allowShowHistory'
                                  name='allowShowHistory'
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  checked={values.allowShowHistory}
                                  className='dark'
                                  aria-label='allowShowHistory'
                                />
                              }
                              <span>{data}</span>
                            </div>
                          )
                          )}
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              </div>
              <div className='seperator' />
              <div className='right'>
                <Grid className='main-grid'>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field>
                        <Text className='main-text color-black' htmlFor='text' aria-label={artworkContactFormLabels.personalMessageText}>
                          {artworkContactFormLabels.personalMessageText}
                        </Text>
                        <Form.TextArea
                          name='text'
                          placeholder={artworkContactFormLabels.moreInformationLabel}
                          aria-label={artworkContactFormLabels.moreInformationLabel}
                          maxLength={maxCharsInText}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={!isShowArtworkDetail ? 'textarea--large' : ''}
                        />
                        <CharCounter
                          max={maxCharsInText}
                          current={values.text.length}
                          text={artworkContactFormLabels.charMaxLabel}
                        />
                        {errors.text && touched.text && <InputFeedback>{errors.text}</InputFeedback>}
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  {isShowArtworkDetail &&
                    <Grid>
                      <div className='label label-1 inquiring-artwork'>
                        {artworkContactFormLabels.inquiringArtworkText}
                      </div>
                      <Grid.Row columns={2} className='inquiry-artwork-img-row'>
                        <Grid.Column>
                          <div
                            role='img'
                            title={imgAlt}
                            aria-label={imgAlt}
                            className='artwork-image'
                            style={{
                              background: image && `url(${uploadTransformationUrl(image, 'w_100,q_auto,c_fill',
                                { 'transformUrl': cloudinary.throughCloudfront })}) ${tokens.color.light.base.neutrals[200].value} center center no-repeat`
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column className='artist-detail'>
                          <span>{title}</span>
                          <div className='name'>
                            {artworkContactFormLabels.byContactLabel} {subtitle}
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  }
                  <Grid.Row className='submit-button'>
                    <Grid.Column>
                      <Button
                        size='compact'
                        variant='primary'
                        type='submit'
                        {...submitButtonAttributes}
                      >
                        {artworkContactFormLabels.submitLabel}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Text isHtml forwardedAs='div' isSmall className='label label-3 terms-condition-text'>
                    {artworkContactFormLabels.termsAndConditionsMessage}
                  </Text>
                </Grid>
              </div>
            </Form>
          </Modal>
        )}
        {!showPopupWithoutButton && buttonWithVitamines(button)}
        <EnquiryGlobalStyles />
      </>
    )
  }
  if (buttonOnLoad) {
    return (
      <Button
        className='sales-inquiry-button'
        type='button'
      >
        {buttonOnLoadLabel}
      </Button>
    )
  }

  return null
}

interface FormValues {
  allowJobTitle: boolean,
  allowShowHistory: boolean,
  text: string,
  preferredContactOption: string,
  contactNumber: string
}

const FormRules = withFormik<ArtworkContactFormProps, FormValues>({
  mapPropsToValues: () => ({
    allowJobTitle: true,
    allowShowHistory: true,
    text: '',
    preferredContactOption: 'EMAIL',
    contactNumber: ''
  }),
  validationSchema: props =>
    Yup.object().shape({
      text: Yup.string().max(maxCharsInText),
      preferredContactOption: Yup.string()
        .required(props.labels.FieldValidation.RequiredFieldMessage),

      contactNumber: Yup.string()
        .when('preferredContactOption', {
          is: 'EMAIL',
          then: () => Yup.string(),
          otherwise: () => Yup.string().required(props.labels.FieldValidation.RequiredFieldMessage)
        })
        .matches(validationConstants.PHONE, 'Please use the following format: +41 43 123 45 67')
    }),

  handleSubmit: async (values, { setSubmitting, setStatus, props, resetForm }) => {
    let reqParams: any = {
      allowJobTitle: values.allowJobTitle,
      allowShowHistory: values.allowShowHistory,
      artworkId: props.id,
      text: values.text,
      preferredContactOption: values.preferredContactOption
    }
    if (values.contactNumber && values.preferredContactOption !== 'EMAIL') {
      reqParams.contactNumber = values.contactNumber
    }

    if (values.text) { reqParams.text = values.text }
    //if user is contacting with gallery.
    if (props.isConnectToGallery) {
      reqParams = {
        ...reqParams,
        eventId: props.eventId,
        accountId: props.accountId
      }
    }


    const contact = !props.isConnectToGallery
      ? await ContactService.contactFormArtwork(reqParams)
      : await ContactService.contactFormGallery(reqParams)

    if (contact.ok) {
      // tracking prop
      if (typeof props.onSubmitSuccess === 'function') {
        props.onSubmitSuccess()
      }
      // Close contact form modal
      setStatus({ isOpenContactForm: false })
      // Open success popup
      setStatus({ isSuccessMessageOpened: true })
    }

    setSubmitting(false)
    resetForm()
  },
  displayName: 'EnquiryModalForm'
})(EnquiryModalForm)

const WrapperForLabels = (props) => {
  const { labels } = useLabelsFromAPI(['FieldValidation'], getSiteLanguage())
  const newProps = { ...props }
  newProps.labels = labels
  if (!newProps.labels) return null
  return (<FormRules {...newProps} />)
}

const EnquiryModalWrapper = styled(WrapperForLabels)`
  ${EnquiryStyles}
`


type ArtworkContactFormProps = {
  id?: number,
  title?: string,
  isShowArtworkDetail?: boolean,
  isConnectToGallery?: boolean,
  subtitle?: string,
  className?: string,
  image?: string,
  imgAlt?: string,
  user?: IUserRedux | null,
  isSubmitting?: boolean,
  showWhenCatalog: boolean,
  showWhenExhibition: boolean,
  showWhenShowroom: boolean,
  showInRoom?: boolean,
  eventId?: number,
  submitBtnAttrs?: {
    'data-event': string,
    'data-category': string,
    'data-action': string,
    'data-label': string,
    'data-value': string
  },
  setFieldValue?: Function,
  initialValues?: any,
  accountId?: number,
  closeCallback?: Function,
  onSubmitSuccess?: Function,
  onSuccessMessageClickOK?: Function,
  visible?: boolean,
  isSuccessMessageShow?: boolean,
  labels?: { [labelKey: string]: string },
  onShowModal?: Function,
  contactBtnClass: string,
  button: any,
  submitButtonAttributes: any,
  loginData: any,
  setUserDataFromAPI: any,
  artworkContactFormLabels: any,
  buttonOnLoad: any,
  buttonOnLoadLabel: any,
  showPopupWithoutButton: any,
  closeLoginModalCallback: Function
}

export default EnquiryModalWrapper
