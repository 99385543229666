import { FC, memo } from 'react'

import { useIntersection } from '@utils'

const IntersectionCapture: FC<IINtersectionCapture> = memo(({
  active = true,
  onIntersect,
  params
}) => {
  const [loadMoreRef]: Array<any> = useIntersection(active ? onIntersect : undefined, params)

  return <div ref={loadMoreRef} style={{ height: '1px', width: '1px' }} />
})

IntersectionCapture.displayName = 'IntersectionCapture'

interface IINtersectionCapture {
  active: boolean,
  onIntersect: () => void,
  params: {
    rootMargin: string
  }
}

export default IntersectionCapture