const REDUCERNAME = 'designation'

const initialState = {
  designationDisclaimers: null
}

// ACTION TYPES
export const actionTypes = {
  SET_DESIGNATION_DISCLAIMERS: `${REDUCERNAME}__SET_DESIGNATION_DISCLAIMERS`
}

// ACTIONS
export const setDesignationDisclaimers = payload => dispatch =>
  dispatch({
    type: actionTypes.SET_DESIGNATION_DISCLAIMERS,
    payload
  })

// ACTION HANDLERS
const actionHandlers = {
  [actionTypes.SET_DESIGNATION_DISCLAIMERS]: (state, action) => ({
    ...state,
    designationDisclaimers: action.payload
  })
}

// REDUCER
const designationReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export default designationReducer
