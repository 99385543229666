import ArtworksTab from './edit-preview-collection/Tabs/ArtworksTab'
import RoomsTab from './edit-preview-collection/Tabs/Rooms'
import SpecialRoomTab from './edit-preview-collection/Tabs/SpecialRoomsTab'
import GalleriesTab from './edit-preview-collection/Tabs/GalleriesTab'
import ArtistsTab from './edit-preview-collection/Tabs/ArtistsTab'
import EventsTab from './edit-preview-collection/Tabs/EventsTab'
import NewsTab from './edit-preview-collection/Tabs/NewsTab'
import MyCollectionsTab from './my-collections/Tabs/MyCollectionsTab'
import SubscribedCollectionsTab from './my-collections/Tabs/SubscribedCollectionsTab'

const TabSelector = {
  artworks: {
    component: ArtworksTab
  },
  rooms: {
    component: RoomsTab
  },
  galleries: {
    component: GalleriesTab
  },
  specialRooms: {
   component: SpecialRoomTab
  },
  artists: {
    component: ArtistsTab
  },
  events: {
    component: EventsTab
  },
  news: {
    component: NewsTab
  },
  myCollections: {
    component: MyCollectionsTab
  },
  followingCollections: {
    component: SubscribedCollectionsTab
  }
}

export default TabSelector
