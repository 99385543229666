/*
Add here global styles needed in all pages, and are currently spread
e.g. Spacing, Grid System, Colors, Icons, Layout, Typography...

TODO: Remove global styles that are not needed anymore
*/

import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import React from 'react'
import { createGlobalStyle } from 'styled-components'

export const LayoutStyles = React.memo(createGlobalStyle`
  :root {
    scroll-behavior: auto;
  }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: none;
  }

  *:focus-visible {
    outline-color: #006fd6 !important;
    outline-style: auto !important;
    outline-width: 1px !important;
  }

  /* Chinese language exceptions */
  .mfp-site.zh_cn {
    h6,
    .h6,
    h5,
    .h5,
    h4,
    .h4,
    h3,
    .h3,
    h2,
    .h2,
    h1:not(.errorBackground),
    .card-title,
    .menu-mobile-section .accordion-button,
    .profileflyout-menu .language-header,
    .navlink-mobile-header .link-language-header,
    .link-card .headline {
      font-family: ${tokens.fontFamilies.artBaselText.value} !important;
    }

    .tag.type-premium .header-uppercase-2.h5,
    .tag.type-premium h5.header-uppercase-2 {
      top: 0;
    }

    .rm-secondary-header {
      h2 {
        padding-top: 0;
      }
    }
  }

  @font-face {
    font-family: 'FontAwesome';
    src: url('/static/fonts/FontAwesome/fa-solid-900.woff2') format('woff2'),
      url('/static/fonts/FontAwesome/fa-solid-900.woff') format('woff'),
      url('/static/fonts/FontAwesome/fa-solid-900.ttf') format('truetype');
    font-weight: ${tokens.fontWeights.artBaselText2.value};
    font-style: normal;
    font-display: block;
  }

  .site-modules {
    overflow: hidden !important;
  }

  .site-modules:not(.event-detail):first-of-type {
    margin-top: 0;
  }

  .site-modules:not(.is-mobile-web-view):first-of-type {
    margin-top: 0 !important;
  }

  .is-mobile-web-view .navbar-parent-container,
  .is-mobile-web-view #prio > footer {
    @media (max-width: 1023px) {
      display: none !important;
    }
  }

  .prio i.icon,
  body i.icon {
    font-family: Icons, FontAwesome, sans-serif;
    font-style: normal;
    font-weight: ${tokens.fontWeights.artBaselText2.value};

    &.chevron {
      &.left::before {
        content: "\f053";
      }

      &.right::before {
        content: "\f054";
      }
    }
  }

  /* UI Kit patch - remove when https://bitbucket.org/mchappsrvcs/artbasel-ui-kit/pipelines/results/3964 is published and pipeline working */
  .media-placeholder-animation {
    animation: none !important;
    background-color: inherit !important;
  }

  /* Hide image alt when its loading, and also when img src returns error */
  img[alt]::after,
  img[src=''] {
    display: inherit;
    text-indent: -99999px;
    visibility: hidden;
  }

  .bg-color-light-base-0 {
    background-color: ${tokens.color.light.base.neutrals['100'].value};
  }
  .font-color-light-base-0 {
    color: ${tokens.color.light.base.neutrals['0'].value};
  }
  .font-color-light-base-500 {
    color: ${tokens.color.light.base.neutrals['500'].value};
  }
  .font-color-light-base-700 {
    color: ${tokens.color.light.base.neutrals['700'].value};
  }
  .font-color-light-base-1000 {
    color: ${tokens.color.light.base.neutrals['1000'].value};
  }
`)

export { }
