import { FC } from 'react'
import { FormikConsumer } from 'formik'
import { Col, Text, Button } from '@mch-group/uikit-components'
import { useUserAccessContext } from '../context/useUserAccessContext'

const InitialStep: FC = () => {
  const { state: {
    apiErrorMessage,
    isLoading,
    labels: userAccessLabels,
    otherData
  }, dispatch } = useUserAccessContext()

  return(
    <FormikConsumer >
      {({ handleBlur, values, errors, touched, handleChange, setFieldValue }) => (
        <Col className={!otherData.isModal ? 'mt-0' : ''}>
          {!otherData.isModal && <h4 className='pb-10'>{userAccessLabels.Access.logInLabel}</h4>}
          <p className='text-medium'>
            {userAccessLabels.Access.textBelowLogin}
          </p>
          <Text
            label={userAccessLabels.Access.emailAddressLabel}
            type='email'
            value={values.email}
            onChange={(e) => {
                  handleChange(e)
                  dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
                }}
            onBlur={handleBlur}
            id='email'
            helpText={(touched.email && errors.email) ? errors.email as string : apiErrorMessage}
            helpTextType={(errors.email && touched.email || apiErrorMessage) ? 'danger' : 'muted'}
          />
          <div className='d-grid mt-8'>
            <Button
              type='submit'
              className='next-button'
              onClick={() => {
                      setFieldValue('submitBtn', 'type 1')
                    }}
              isLoading={isLoading}
            >
              {userAccessLabels.Access.loginOrRegisterBtnLabel}
            </Button>
          </div>
        </Col>
      )}
    </FormikConsumer >
  )
}

export default InitialStep
