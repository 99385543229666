export const date = {
  COUNTDOWN_END: '0d 0h 0min',
  BE_DATE_FORMAT: 'YYYY-MM-DD hh:mm', // MCHGB-10359
  FE_DATE_FORMAT: 'MMM D, YYYY', // MCHGB-10359
  FR_FE_DATE_FORMAT: 'DD MMM YYYY', // MCHGB-12153
  CH_FE_DATE_FORMAT: 'YYYY年M月D日', // MCHGB-12153
  FE_WEEK_DATE_FORMAT: 'ddd, MMM D, YYYY', // AB-97
  FR_WEEK_DATE_FORMAT: 'ddd, DD MMM, YYYY',// AB-97
  CH_WEEK_DATE_FORMAT: 'ddd, YYYY年M月D日',// AB-97
  FE_WEEK_MIN_DATE_FORMAT: 'ddd MMM D, YYYY HH:mm', // MCHGB-10359
  FE_HOUR_MIN_FORMAT: 'HH:mm', // MCHGB-10359
  FE_HOUR_FORMAT_12_hours: 'hh:mm a', // AB-121
  CH_FE_YEAR_DATE_FORMAT: 'YYYY,年 MMMM DD日, ddd'
}

export const dateFormatInLocaleLanguage = {
  en: date.FE_DATE_FORMAT,
  fr: date.FR_FE_DATE_FORMAT,
  zh_CN: date.CH_FE_DATE_FORMAT,
  de: date.FE_DATE_FORMAT, // AB-3750
  es: date.FE_DATE_FORMAT // AB-3750
}

// the below are done because date format are different for invitations timeslot date
export const dateFormatForInvitations = {
  en: date.FE_WEEK_DATE_FORMAT,
  fr: date.FR_WEEK_DATE_FORMAT,
  zh_CN: date.CH_WEEK_DATE_FORMAT,
  de: date.FE_WEEK_DATE_FORMAT, // AB-3742
  es: date.FE_WEEK_DATE_FORMAT // AB-3742
}

// the below are done because date format are different for  magezine and category landing pages Chub
export const dateFormatForContentHub= {
  en: date.FE_WEEK_DATE_FORMAT,
  fr: date.FR_WEEK_DATE_FORMAT,
  zh_CN: date.CH_FE_YEAR_DATE_FORMAT,
  de: date.FR_WEEK_DATE_FORMAT, // date format is same for Fr, de, es
  es: date.FR_WEEK_DATE_FORMAT
}

export default {}
