import React from 'react'
import propTypes from 'prop-types'
import { Col, Container, Icon, Row } from '@mch-group/uikit-components'

import { track } from '../utils/logger'
import cssClasses from '../constants/cssClasses'

class ErrorBoundary extends React.Component {
  state = { error: null }

  componentDidCatch(error, info) {
    if (info && info.componentStack) {
      // The component stack is sometimes useful in development mode
      track(info.componentStack, false)
    }

    track(error)
    this.setState({ error })
  }

  render() {
    if (this.state.error) {
      return (
        <Container className={cssClasses.CMS_MODULE}>
          <Row className='text-center'>
            <Col style={{ width: '130px' }}><Icon name='warning' size={50} /></Col>
          </Row>
          <Row className='text-center'>
            <h2>Something went wrong! Please excuse the inconvenience</h2>
          </Row>
          <Row className='text-center'>
            <h3>We are working on getting this fixed immediately. Please try again later.</h3>
          </Row>
        </Container>
      )
    }
    return this.props.children
  }
}
//@ts-ignore
ErrorBoundary.propTypes = {
  children: propTypes.node
}

export default ErrorBoundary
