import PropTypes from 'prop-types'

import ContentPlaceholder from './ContentPlaceholder'

const isDev = process.env.NODE_ENV === 'development'

const DefaultLoadingComponent = (props) => {
  const { error, retry, timedOut, pastDelay, componentName, isLoading } = props
  const ContentLoader = () => <ContentPlaceholder componentName={componentName} {...props} />

  if (error) {
    // When the loader has errored
    // eslint-disable-next-line no-console
    console.error(error)

    return (
      <div>
        Error! <button onClick={retry}>Retry</button>
        {'message' in error && isDev ? <div>{error.message}</div> : null}
      </div>
    )
  } else if (timedOut) {
    // When the loader has taken longer than the timeout
    return (
      <div>
        Taking a long time... <button onClick={retry}>Retry</button>
      </div>
    )
  } else if (pastDelay || isLoading) {
    // When the loader has taken longer than the delay
    return <ContentLoader />
  }
  // When the loader has just started
  return null
}

DefaultLoadingComponent.propTypes = {
  error: PropTypes.object,
  retry: PropTypes.func,
  timedOut: PropTypes.bool,
  pastDelay: PropTypes.bool,
  componentName: PropTypes.string,
  isLoading: PropTypes.bool
}

export default DefaultLoadingComponent
