export default {
  // eslint-disable-next-line no-control-regex
  ROMAN_ACCENTED: /^[\u0000-\u007F\s]*$/, // Please only use Roman unaccented characters.
  NON_ROMAN: /^[\u0020-\u024F\s]*$/, // Please only use Roman characters.
  PASSWORD: /^(?=.*[A-Z]).{7,}$/, // Your password must be at least 7 characters and at least one uppercase character.
  AT_LEAST_8CHAR_ONE_UPPERCASE_ONE_NUMBER: /^(?=.*\d)(?=.*[A-Z]).{8,}$/, // Regex for 8 characters one uppercase one number.
  PHONE: /^[+ 0-9]*$/, // Please use the following format: +41 43 123 45 67
  // eslint-disable-next-line max-len
  URL: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i, // Please enter a valid URL.
  CHINESE: /^[\u4E00-\uFA29,\uE7C7-\uE7F3]*$/, // Accept only chinese
  CHINESE_CHARACTERS: /[\u4E00-\u9FFF]/ // Accept only chinese characters
}

/*
(?=.*[a-z])        // use positive look ahead to see if at least one lower case letter exists
(?=.*[A-Z])        // use positive look ahead to see if at least one upper case letter exists
(?=.*\d)           // use positive look ahead to see if at least one digit exists
(?=.*\W)           // use positive look ahead to see if at least one non-word character exists
*/
