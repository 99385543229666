import qs from 'query-string'

import galleryStatus from '@constants/galleryStatus'
import { api } from './_http'
import { ApiResponse } from 'apisauce'

const defaultGalleryStatuses = [
  galleryStatus.live,
  galleryStatus.alumni,
  galleryStatus.outOfBusiness
]

const Artist = {
  getArtists: (params) => {
    const paramsString = qs.stringify({ galleryStatuses: defaultGalleryStatuses, ...params })
    return api.get(`/msvc/v1/artcatalog/artist/items?${paramsString}`)
  },

  getFavorites: (params) => {
    const paramsString = qs.stringify({ galleryStatuses: defaultGalleryStatuses, ...params })
    return api.get(`/msvc/v1/artcatalog/artist/favorites?${paramsString}`)
  },

  getFilters: (params) => {
    const paramsString = qs.stringify({ galleryStatuses: defaultGalleryStatuses, ...params })
    return api.get(`/msvc/v1/artcatalog/artist/filters?${paramsString}`)
  },

  getArtistById: id =>
    api.get(`/msvc/v1/artcatalog/artist/item/${id}`),

  getArtistByDetails: (params): Promise<ApiResponse<ABTypes.Monolith.ArtistDetail>> => {
    if (params.cancelToken) {
      return api.get('/msvc/v1/artcatalog/artist/itemDetails', params.params, { cancelToken: params.cancelToken })
    }
    return api.get('/msvc/v1/artcatalog/artist/itemDetails', params.params)
  },
  getArtworksByArtist: (params): Promise<ApiResponse<ABTypes.Monolith.ResponseListArtworkDetail>> => {
    if (params.cancelToken) {
      return api.get('/msvc/v1/artcatalog/artwork/itemDetails', params, { cancelToken: params.cancelToken })
    }
    return api.get('/msvc/v1/artcatalog/artwork/itemDetails', params)
  }
}

export default Artist