import App from 'next/app'
import type { AppProps } from 'next/app'
import React from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { SWRConfig } from 'swr'
import PageProvider from '@providers/PageContext'
import SpecialRoomProvider from '@modules/SpecialRoom/SpecialRoomModule/SpecialRoomProvider'
import WrappedContexts from '@utils/WrappedContexts'

// import '@researchgate/react-intersection-observer'
import 'intersection-observer'

// design tokens
import '@mch-group/uikit-themes/dist/artbasel/design-tokens/css/design_tokens.css'

// Plain CSS imports
import '../styles/css/imports'

// css root variables
import '../public/static/styles/root.css'

// Some global styles for dashboard to appear grey background, etc.
import '../public/static/styles/dashboard.css'

// Some styles to allow Semantic UI and UIKit live together
import '../public/static/styles/adaptSemanticUIToUIKit.css'

// global sass
import '../styles/Global.scss'

// UIKIT vars to be converted to JS
import uikitVars from '../styles/variables.module.scss'

// SWR Default configuration for all swr requests
import swrContextConfig from '../services/swr/context.config.gb'

// System HoC's
import withReduxStore from '../hocs/withReduxStore'
import withUserAuth from '../hocs/withUserAuth'
import withCoreData from '../hocs/withCoreData'
import withErrorHandler from '../hocs/withErrorHandler'

import Layout from '../components/Layout'
import IAppProps from '../types/IAppProps'
import AppProvider from '@providers/AppContext'
import withQSActionHandler from '@hocs/withQSActionHandler'

/* This is similar to the "Window" object, no state, nothing, just a variable with a global scope. */
global.uikitVars = uikitVars

function MCHAppContent(props: AppProps<IAppProps>) {
  // @ts-ignore
  const { Component, pageProps, reduxStore } = props

  return (
    <PageProvider state={{ ...pageProps }}>
      <AppProvider>
        <ThemeProvider
          theme={{
            name: getEnvConfig.fe.theme,
            var: MFP.vars
          }}
        >
          <Provider store={reduxStore}>
            <SWRConfig value={swrContextConfig}>
              <WrappedContexts>
                <SpecialRoomProvider pageMetaInfo={pageProps?.pageData}>
                  <Layout {...props}>
                    <Component {...pageProps} />
                  </Layout>
                </SpecialRoomProvider>
              </WrappedContexts>
            </SWRConfig>
          </Provider>
        </ThemeProvider>
      </AppProvider>
    </PageProvider>
  )
}

class MCHApp extends App {
  render() {
    const { props } = this
    // @ts-ignore
    return <MCHAppContent {...props} />
  }
}

export default withErrorHandler(withReduxStore(withUserAuth(withQSActionHandler(withCoreData(MCHApp)))))
