/**
 * Requests all the required data for the module
 */
 export const randomSeed = (agentKey) => {
    let randomNumber = Math.abs(
    Array.from(agentKey ?? 0).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0) + new Date().getDate()
    ) % 10000
    return randomNumber
 }

 export default { randomSeed }