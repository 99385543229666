import { memo, useRef, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import * as RV from 'react-virtualized'
import styled, { createGlobalStyle } from 'styled-components'

import { jsMq, px2rem } from '@utils'

const GridStyles = createGlobalStyle`
  .ReactVirtualized__Masonry {
    outline: none !important;
    overflow: unset !important;
  }

  .ReactVirtualized__Masonry__innerScrollContainer {
    overflow: unset !important;
  }
`

const Grid = memo(
  ({
    items,
    total,
    cols = {
      mobile: 1,
      tablet: 2,
      computer: 4,
      largeScreen: 4
    },
    gap = {
      mobile: 16,
      tablet: 24,
      computer: 40,
      largeScreen: 40
    },
    windowScroller = true,
    renderItem,
    overscanByPixels = 1000,
    style: gridStyle,
    rightPadding = 0,
    keyProp = 'id',
    stopRedraw,
    ...props
  }) => {
    const [colWidth, setColWidth] = useState(false)
    const measures = useRef()
    const itemsMemo = useRef()
    const scrollerRef = useRef()
    const masonryRef = useRef()
    const wrapWidth = useRef()
    const cache = useRef()
    const pos = useRef()
    const [scrollContainer, setScrollContainer] = useState(0)

    // This recalculates scroll position from the top of page.
    const refreshScroller = () => {
      setTimeout(() => {
        scrollerRef.current && scrollerRef.current.updatePosition()
      }, 0)
    }

    useEffect(() => {
      refreshScroller()
    }, [total])

    const measure = useCallback(
      (newWrapWidth) => {
        const spacer = jsMq.calcResponsiveValue(gap)
        const columnCount = jsMq.calcResponsiveValue(cols)
        const columnWidth = Math.round((newWrapWidth - (columnCount - 1) * spacer) / columnCount)
        measures.current = { columnCount, columnWidth, spacer }
        wrapWidth.current = newWrapWidth
        setColWidth(columnWidth)
        return measures.current
      },
      [cols, gap]
    )

    const draw = (newWrapWidth) => {
      if (!measures.current) {
        const { columnCount, columnWidth, spacer } = measure(newWrapWidth)
        const cellMeasurerCache = new RV.CellMeasurerCache({ defaultWidth: columnWidth, fixedWidth: true })
        cache.current = cellMeasurerCache
        pos.current = RV.createMasonryCellPositioner({ cellMeasurerCache, columnCount, columnWidth, spacer })
      }
    }

    const reDraw = useCallback(
      (newWrapWidth) => {
        if (!stopRedraw && (!newWrapWidth || newWrapWidth !== wrapWidth.current)) {
          const { columnCount, columnWidth, spacer } = !newWrapWidth ? measures.current : measure(newWrapWidth)
          cache.current.clearAll()
          pos.current.reset({ columnCount, columnWidth, spacer })
          masonryRef.current && masonryRef.current.clearCellPositions()
          refreshScroller()
        }
      },
      [stopRedraw, measure]
    )

    // For preparing the caches before react-virtualized elements.
    const onWrapLoad = (el) => el && draw(el.offsetWidth)

    const onResize = ({ width }) => reDraw(width)

    // For refreshing react-virtualized on set different data.
    useEffect(() => {
      if (items && items.length) {
        if (itemsMemo.current) {
          const changedIndex = itemsMemo.current.findIndex((item, index) => item !== items[index])
          if (changedIndex > -1) reDraw()
        }
        itemsMemo.current = [...items]
      }
    }, [items, reDraw])

    const keyMapper = (index) => (items[index] ? items[index][keyProp] : index)

    const debounce = (fn, ms) => {
      let timer
      return () => {
        clearTimeout(timer)
        timer = setTimeout(() => {
          timer = null
          fn.apply(this, arguments) // eslint-disable-line no-undef
        }, ms)
      }
    }

    const debouncedHandleResize = debounce(() => {
      const el = document.querySelector('.masonry')
      el && setScrollContainer(document.querySelector('.masonry').clientHeight)
    }, 50)

    React.useEffect(() => {
      if (!windowScroller) {
        const el = document.querySelector('.masonry')
        if (el) {
          setScrollContainer(document.querySelector('.masonry').clientHeight)
          window.addEventListener('resize', debouncedHandleResize)
        }
      }
      return () => {
        !windowScroller && window.removeEventListener('resize', debouncedHandleResize)
      }
    }, [debouncedHandleResize, windowScroller])

    // For cell height calculation.
    // Also it will be cache this values for better performance.
    // If you don't set with style, it can't be change widths on resize.
    // eslint-disable-next-line react/prop-types
    const renderCell = ({ index, style, key: indexKey, ...rest }) => (
      <RV.CellMeasurer key={keyMapper(indexKey)} cache={cache.current} index={index} {...rest}>
        <StyledOverlay
          role='row'
          position={style.position}
          top={style.top}
          left={style.left}
          width={colWidth}
          height={style.height}
        >
          <div
            role='gridcell'
            style={{
              ...style,
              width: colWidth,
              position: 'unset',
              margin: '0 auto'
            }}
          >
            {items[index] && renderItem(items[index])}
          </div>
        </StyledOverlay>
      </RV.CellMeasurer>
    )

    if (items && items.length) {
      // on window scroll
      if (windowScroller) {

        return (
          <div ref={onWrapLoad}>
            {cache.current && pos.current && (
              <RV.WindowScroller ref={scrollerRef} {...{ overscanByPixels }}>
                {({ height, scrollTop, registerChild, isScrolling, onChildScroll }) => (
                  <RV.AutoSizer disableHeight {...{ height: '100%', scrollTop, overscanByPixels, onResize }}>
                    {({ width }) => (
                      <div ref={registerChild}>
                        <RV.Masonry
                          autoHeight
                          ref={masonryRef}
                          cellCount={items.length}
                          cellRenderer={renderCell}
                          cellMeasurerCache={cache.current}
                          cellPositioner={pos.current}
                          onScroll={onChildScroll}
                          style={{ paddingBottom: px2rem(40), ...(props?.gridStyle || {}) }}
                          {...{ width, height, scrollTop, isScrolling, overscanByPixels }}
                        />
                      </div>
                    )}
                  </RV.AutoSizer>
                )}
              </RV.WindowScroller>
            )}
            <GridStyles />
          </div>
        )
      }

      // on div scroll
      if (!windowScroller) {
        return (
          <div ref={onWrapLoad}>
            {cache.current && pos.current && (
              <RV.AutoSizer disableHeight height={scrollContainer} {...{ overscanByPixels, onResize }}>
                {({ width }) => (
                  <RV.Masonry
                    height={scrollContainer}
                    ref={masonryRef}
                    cellCount={items.length}
                    cellRenderer={renderCell}
                    cellMeasurerCache={cache.current}
                    cellPositioner={pos.current}
                    width={width + rightPadding}
                    overscanByPixels={overscanByPixels}
                    style={{ paddingBottom: px2rem(40), ...(gridStyle || {}) }}
                  />
                )}
              </RV.AutoSizer>
            )}
            <GridStyles />
          </div>
        )
      }
    }
  }
)
Grid.displayName = 'Grid'

const StyledOverlay = styled.div.attrs(props => ({
  style: {
    position: props.position ? props.position : '',
    left: props.left ? `${props.left}px` : '',
    top: props.top ? `${props.top}px` : '',
    width: props.towidthp ? `${props.width}px` : ''
  }
}))``

export const GridPropTypes = {
  items: PropTypes.arrayOf(PropTypes.shape),
  total: PropTypes.number,
  renderItem: PropTypes.func.isRequired,
  overscanByPixels: PropTypes.number,
  style: PropTypes.object,
  gridStyle: PropTypes.object,
  keyProp: PropTypes.string,
  windowScroller: PropTypes.bool,
  rightPadding: PropTypes.number,
  stopRedraw: PropTypes.bool,
  cols: PropTypes.shape({
    mobile: PropTypes.number,
    tablet: PropTypes.number,
    computer: PropTypes.number,
    largeScreen: PropTypes.number
  }),
  gap: PropTypes.shape({
    mobile: PropTypes.number,
    tablet: PropTypes.number,
    computer: PropTypes.number,
    largeScreen: PropTypes.number
  })
}

Grid.propTypes = GridPropTypes

export default Grid
