export const unformattedChineseCountries = [
  'China',
  'df07c4c0-d242-e211-9367-00155d350208',
  'Hong Kong, SAR',
  '3908c4c0-d242-e211-9367-00155d350208',
  'Macau',
  '7308c4c0-d242-e211-9367-00155d350208',
  'Taiwan',
  '1b09c4c0-d242-e211-9367-00155d350208'
]
export const formattedChineseCountries = [
  { value:'df07c4c0-d242-e211-9367-00155d350208', text:'China' },
  { value:'3908c4c0-d242-e211-9367-00155d350208', text:'Hong Kong, SAR' },
  { value:'7308c4c0-d242-e211-9367-00155d350208', text:'Macau' },
  { value:'1b09c4c0-d242-e211-9367-00155d350208', text:'Taiwan' }
]
