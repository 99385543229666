const countdown = (secondsLeft, d = 'd', h = 'h', m = 'm') => {
  let seconds = parseInt(secondsLeft, 10)
  const days = Math.floor(seconds / (3600 * 24))
  seconds -= days * 3600 * 24
  const hrs = Math.floor(seconds / 3600)
  seconds -= hrs * 3600
  const mnts = Math.floor(seconds / 60)
  seconds -= mnts * 60
  return secondsLeft <= 0 ? `0${d} 0${h} 0${m}` : `${days}${d} ${hrs}${h} ${mnts}${m}`
}

export default countdown
