import React from 'react'
import PropTypes from 'prop-types'

//@ts-ignore
const DefererContext = React.createContext()

/**
 * This Provider creates a context to store info that can be shared between components
 */
const DefererProvider = ({ children }) => {
  const [deferersList, setDeferersList] = React.useState({})

  return (
    <DefererContext.Provider value={{ deferersList, setDeferersList }}>
      {children}
    </DefererContext.Provider>
  )
}

DefererProvider.propTypes = {
  children: PropTypes.node
}

export default DefererProvider

export { DefererContext }
