import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const processEnv = publicRuntimeConfig || process.env || {}

const { lessVars, mch, ...restVars } = processEnv
const injectedVars = {
  ...(mch || { fe: { cloudinary: {} } }),
  ...restVars
}

export default injectedVars || {}
