import { ApiResponse } from 'apisauce'
import http, { api } from './_http'

const Countries = {
  getCountries: () =>
    // @ts-ignore
    http.get('/msvc/v1/countries/'),
  getCountry: (guid): Promise<ApiResponse<ABTypes.Countries.Country>> =>
    api.get(`/msvc/v1/countries/crmguid=${guid}`)
}
export default Countries
