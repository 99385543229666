import qs from 'query-string'

import { filterEntityTypes } from '@constants/entityTypes'
import { dimensions } from '@constants/catalog'
import { cmsPageUrl } from '@utils'

export const updateComponent = (newSelectedIds: any, components: any, previousSelectedIds: any, onChange: any) => {
  let newSelects = []

  if (newSelectedIds.length) {
    const selectedShows = components.filter(l => newSelectedIds.includes(l.code))
    newSelects = selectedShows.map(l => l.code)
  }

  const newSelectsString = newSelects.join(',')
  if (previousSelectedIds !== (newSelectsString || undefined)) {
    onChange(newSelectsString)
  }
}

export const getShowFromFilters = (filters = [], showIds) => {
  //@ts-ignore
  const showEntity = showIds && filters && filters.find(entity => entity.entityType === filterEntityTypes.show)
  if (showEntity && showIds) {
    const firstShow = showIds.split(',')[0]
    return showEntity.options.find(entity => entity.showIds.split(',').includes(firstShow))
  }
  return undefined
}

const mapArtworkWithSponsorGalleries = (item) => {
  if (item.sponsorGalleries && item.sponsorGalleries.length > 0) {
    return { ...item, ...item.sponsorGalleries[0] }
  }
  return item
}

export const remapArtworkWithSponsorGalleries = items => items.map(item => mapArtworkWithSponsorGalleries(item))

export const remapArtworkWithArtistsGalleries = (items, artistId) => items.map((item) => {
  const newItem = mapArtworkWithSponsorGalleries(item)
  const sponsorArtist = newItem.sponsorArtists && newItem.sponsorArtists.find(element => element.artistId === artistId)
  if (sponsorArtist) {
    return { ...newItem, ...sponsorArtist }
  }
  return newItem
})

export const remapArtworkWithGalleries = (items, galleryId) => items.map((item) => {
  const newItem = mapArtworkWithSponsorGalleries(item)
  const sponsorGallery = newItem.sponsorGalleries &&
    newItem.sponsorGalleries.find(element => element.galleryId === galleryId)
  if (sponsorGallery) {
    return { ...newItem, ...sponsorGallery }
  }
  return newItem
})

export const getFormatValue = (value) => {
  if (value) {
    return value.toFixed(1)
  }
  return undefined
}

const displayDepth = (value) => {
  if (value) {
    return `x ${getFormatValue(value)}`
  }
  return ''
}

export const getDimensions = (height, width, depth = null, from, to, label) => {
  const inchesCm = 2.54
  if (from && from.toUpperCase() === dimensions.cm) {
    if (to && to.toUpperCase() === dimensions.cm) {
      return `${getFormatValue(height)} x ${getFormatValue(width)} ${displayDepth(depth)} ${label}`
    } else if (to && to.toUpperCase() === dimensions.inches) {
      const heightToInches = height / inchesCm
      const widthToInches = width / inchesCm
      const depthToInches = !depth ? '' : depth / inchesCm
      // eslint-disable-next-line max-len
      return `${getFormatValue(heightToInches)} x ${getFormatValue(widthToInches)} ${displayDepth(depthToInches)} ${label}`
    }
  } else if (from && from.toUpperCase() === dimensions.inches) {
    if (to && to.toUpperCase() === dimensions.cm) {
      const heightToInches = height * inchesCm
      const widthToInches = width * inchesCm
      const depthToInches = !depth ? '' : depth * inchesCm
      // eslint-disable-next-line max-len
      return `${getFormatValue(heightToInches)} x ${getFormatValue(widthToInches)} ${displayDepth(depthToInches)} ${label}`
    } else if (to && to.toUpperCase() === dimensions.inches) {
      return `${getFormatValue(height)} x ${getFormatValue(width)} ${displayDepth(depth)} ${label}`
    }
  }
  return ''
}

export const getArtistsArray = (artistId, artistCleanTitle, artistName, sponsorArtists) => {
  if (sponsorArtists && sponsorArtists.length > 0) {
    return sponsorArtists
  }
  return [{ artistId, artistCleanTitle, artistName }]
}

export const getGalleriesArray = (galleryId, galleryCleanTitle, galleryName, sponsorGalleries) => {
  if (sponsorGalleries && sponsorGalleries.length > 0) {
    return sponsorGalleries
  }
  return [{ galleryId, galleryCleanTitle, galleryName }]
}

export const getCatalogFiltersRouteParams = (slug, params = {}) => {
  const queryParams = qs.stringify(params)
  const as = `${slug + (queryParams ? `?${qs.stringify(params)}` : '')}`
  return [cmsPageUrl(slug, '/', params), as]
}

export default { updateComponent, getShowFromFilters }
