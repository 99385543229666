import qs from 'query-string'
import { api } from './_http'
import { ApiResponse } from 'apisauce'

const Gallery = {
  getInvitations: () => api.get('/msvc/v1/exhibitorprofile/invitations/mine'),

  acceptInvitation: exhibitorId =>
    api.post(
      `/msvc/v1/exhibitorprofile/invitations/exhibitorId=${exhibitorId}`,
      { responseStatus: 'ACCEPT' }
    ),

  declineInvitation: exhibitorId =>
    api.post(
      `/msvc/v1/exhibitorprofile/invitations/exhibitorId=${exhibitorId}`,
      { responseStatus: 'REJECT' }
    ),


  getGalleries: (params): Promise<ApiResponse<ABTypes.Monolith.ResponseListGalleryDetail>> => {
    const paramsString = qs.stringify(params)
    return api.get(`/msvc/v1/artcatalog/gallery/items?${paramsString}`)
  },

  getFavorites: (params) => {
    const paramsString = qs.stringify(params)
    return api.get(`/msvc/v1/artcatalog/gallery/favorites?${paramsString}`)
  },

  getFilters: params => api.get('/msvc/v1/artcatalog/gallery/filters', params),

  getGalleryById: id =>
    api.get(`/msvc/v1/artcatalog/gallery/item/${id}`),

  getGalleryRelationships: params =>
    api.get('/msvc/v1/relationships/items', params),

  getGalleryByDetails: params =>
    api.get('/msvc/v1/artcatalog/gallery/itemDetails', params)
}

export default Gallery
