/** DEPRECATED */
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { px2rem } from '@utils'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

/**
 * This Component works with an overcomplex "show" param.
 * This might be ready to be replaced in UIKIT.
 */
const ShowCircleIcon = ({ show }) => (
  <Icon
    className='show-icon'
    // @ts-ignore
    show={{
      miamibeach: tokens.color.light.brands.miami.value,
      basel: tokens.color.light.brands.basel.value,
      hongkong: tokens.color.light.brands.hongKong.value,
      paris: tokens.color.light.brands.paris.value
    }[show]}
  />
)

const Icon = styled.div<any>`
  display: inline-block;
  width: ${px2rem(10)};
  height: ${px2rem(10)};
  border-radius: ${px2rem(5)};
  background-color: ${({ show }) => show};

  & + & {
    margin-left: ${px2rem(10)};
  }
`

ShowCircleIcon.propTypes = {
  show: PropTypes.string.isRequired
}

export default ShowCircleIcon
