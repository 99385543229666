const getOneTrustScripts = `
  function OptanonWrapper() {
    // Get initial OnetrustActiveGroups ids
    if (typeof OptanonWrapperCount == "undefined") {
      // console.log('OptanonWrapperCount')
      otGetInitialGrps();
    }

    otDeleteCookie(otIniGrps);

    // Assign OnetrustActiveGroups to custom variable
    function otGetInitialGrps() {
      OptanonWrapperCount = '';
      otIniGrps = OnetrustActiveGroups;
    }

    function otDeleteCookie(iniOptGrpId) {
      var otDomainGrps = JSON.parse(JSON.stringify(Optanon.GetDomainData().Groups));
      var otDeletedGrpIds = otGetInactiveId(iniOptGrpId, OnetrustActiveGroups);
      if (otDeletedGrpIds.length != 0 && otDomainGrps.length != 0) {
        for (var i = 0; i < otDomainGrps.length; i++) {
          //Check if CustomGroupId matches
          if (otDomainGrps[i]['CustomGroupId'] != '' && otDeletedGrpIds.includes(otDomainGrps[i]['CustomGroupId'])) {
            for (var j = 0; j < otDomainGrps[i]['Cookies'].length; j++) {
              // put the pattern key in a variable
              var patternKey = otDomainGrps[i]['Cookies'][j]['patternKey']
              // put the name in a variable
              var name = otDomainGrps[i]['Cookies'][j]['Name']
              // put it in a new field
              otDomainGrps[i]['Cookies'][j]['patternKeyWithoutXchars'] = patternKey
              // find if pattern key has 'xxx' string
              if (patternKey && patternKey.search('xxx') > -1) {
                // if yes, find index 
                const indexOfXxx = patternKey.search('xxx')
                // cut the string just before the 'xxx'.
                const newPatternKey = patternKey.substring(0, indexOfXxx)
                // put in new fancy field
                otDomainGrps[i]['Cookies'][j]['patternKeyWithoutXchars'] = newPatternKey
              }
              // find if name has 'xxx' string
              if (name && name.search('xxx') > -1) {
                // if yes, find index 
                const indexOfXxx = name.search('xxx')
                // cut the string just before the 'xxx'.
                const newPatternKey = name.substring(0, indexOfXxx)
                // put in new fancy field
                otDomainGrps[i]['Cookies'][j]['patternKeyWithoutXchars'] = newPatternKey
              }
              // put all cookie names in current cookies, in an array
              var arrayKeys = Object.keys(Object.fromEntries(document.cookie.split('; ').map(c => c.split('='))))
              // find the one that starts the same than the content in patternKey
              const newPatternKeyWithoutXchars = otDomainGrps[i]['Cookies'][j]['patternKeyWithoutXchars']
              const foundCookieName = arrayKeys.find((item) => item.startsWith(newPatternKeyWithoutXchars, 0))
              // Delete from specific Cookie Name
              eraseCookie(foundCookieName);
              // Delete from more generic name
              eraseCookie(otDomainGrps[i]['Cookies'][j]['Name']);
            }
          }

          //Check if Hostid matches
          if (otDomainGrps[i]['Hosts'].length != 0) {
            for (var j = 0; j < otDomainGrps[i]['Hosts'].length; j++) {
              //Check if HostId presents in the deleted list and cookie array is not blank
              if (otDeletedGrpIds.includes(otDomainGrps[i]['Hosts'][j]['HostId']) && otDomainGrps[i]['Hosts'][j]['Cookies'].length != 0) {
                for (var k = 0; k < otDomainGrps[i]['Hosts'][j]['Cookies'].length; k++) {
                  //Delete cookie
                  // console.log('DeleteCookie Hosts', otDomainGrps[i]['Hosts'][j]['Cookies'][k]['Name'])
                  eraseCookie(otDomainGrps[i]['Hosts'][j]['Cookies'][k]['Name']);
                }
              }
            }
          }

        }
      }
      otGetInitialGrps(); //Reassign new group ids
    }

    //Get inactive ids
    function otGetInactiveId(customIniId, otActiveGrp) {
      //Initial OnetrustActiveGroups
      // console.log("otGetInactiveId",customIniId)
      customIniId = customIniId.split(",");
      customIniId = customIniId.filter(Boolean);

      //After action OnetrustActiveGroups
      otActiveGrp = otActiveGrp.split(",");
      otActiveGrp = otActiveGrp.filter(Boolean);

      var result = [];
      for (var i = 0; i < customIniId.length; i++) {
        if (otActiveGrp.indexOf(customIniId[i]) <= -1) {
          result.push(customIniId[i]);
        }
      }
      return result;
    }

    //Delete cookie
    function eraseCookie(name) {
      //Delete root path cookies
      domainName = window.location.hostname;
      domainNameWithoutSubdomain = '.' + window.location.hostname.replace(/^[^.]+\./g, "");
      document.cookie = name + '=; Max-Age=-99999999; Path=/;Domain=' + domainName;
      document.cookie = name + '=; Max-Age=-99999999; Path=/;Domain=' + domainNameWithoutSubdomain;
      document.cookie = name + '=; Max-Age=-99999999; Path=/;';

      //Delete LSO incase LSO being used, cna be commented out.
      // console.log('Cookie name', name)
      localStorage.removeItem(name);

      //Check for the current path of the page
      pathArray = window.location.pathname.split('/');
      //Loop through path hierarchy and delete potential cookies at each path.
      for (var i = 0; i < pathArray.length; i++) {
        if (pathArray[i]) {
          //Build the path string from the Path Array e.g /site/login
          var currentPath = pathArray.slice(0, i + 1).join('/');
          // console.log('Cookie name', name, 'Current Path', currentPath)
          document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + ';Domain=' + domainName;
          document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + ';Domain=' + domainNameWithoutSubdomain;
          document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + ';';
          //Maybe path has a trailing slash!
          document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + '/;Domain=' + domainName;
          document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + '/;Domain=' + domainNameWithoutSubdomain;
          document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + '/;';


        }
      }

    }
    var event = new Event('OptanonReady');
    window.dispatchEvent(event);
  }
`
export default getOneTrustScripts