import { createGlobalStyle } from 'styled-components'
import { mq } from '@utils'

const RemoveTopMarginsMobile = createGlobalStyle`
  ${mq.lessThan('largestTablet')`
    .site-modules:not(.event-detail):first-of-type {
      margin-top: 0;
    }
    .site-modules:not(.event-detail)
      >:first-child:not(
        [class^=Signpost]):not(
          [class^=VideoPlayer]):not(
            [class^=News]):not(
              [class^=Promotional]):not(
                [class^=rm-secondary-header]):not(
                  [class^=PersonalDetail]).${props => props.mID} {
      margin-top: 0 !important;
    }
  `}
`

export default RemoveTopMarginsMobile