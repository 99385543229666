import Cookies from 'js-cookie'

/**
 * Extends the specified key so there are no collisions with other page keys
 * @type {string}
 */
const KEY_EXTENSION = '__BACK__NAVIGATION__'

/**
 * Default set of generic keys to decrease the chances of error when using this hook
 * @type {{OVR_INTRO: {INSIDE_ARTWORK: string, INSIDE_ROOM: string}}}
 */
export const GENERIC_KEYS = {
  OVR_INTRO: {
    INSIDE_ROOM: '__INSIDE__ROOM__',
    INSIDE_SPECIAL_ROOM: '__INSIDE__SPECIAL__ROOM',
    INSIDE_ARTWORK: '__INSIDE__ARTWORK__',
    INSIDE_ROOM_TO_ARTWORK: '__INSIDE___ROOM__TO__ARTWORK',
    INSIDE_ARTWORK_RECOMMENDATION: '__INSIDE_ARTWORK_RECOMMENDATION'
  },
  COLLECTION: {
    INSIDE_MY_COLLECTION: '__INSIDE_MY_COLLECTION__'
  },
  EVENTS: {
    INSIDE_EVENTS: '__INSIDE__EVENTS__'
  },
  OS_COMPACT_INTRO: {
    INSIDE_ROOM: '__INSIDE__OS__COMPACT__ROOM__',
    INSIDE_SPECIAL_ROOM: '__INSIDE__OS__COMPACT__SPECIAL__ROOM__',
    INSIDE_ARTWORK: '__INSIDE__OS__COMPACT__ARTWORK__',
    INSIDE_ROOM_TO_ARTWORK: '__INSIDE__OS__COMPACT__ROOM__TO__ARTWORK'
  }
}
/**
 * This hook exposes API's that should be used to store the current page for later usage.
 * This necessity came when implementing the OVR INTRO, Rooms and Artworks internal navigation, when there where
 * a lot of problems that arise with different origins and paths and the used implementation (referred in router)
 * became a source of bugs an problem, so we took a slimmer and clear approach.
 * The functionality is simple and clear, the ``set`` method once called with a key stores the current page with
 * its params in local storage. Then this same key can be retrieved whenever it has to be used using
 * the method ``get``.
 * It also exposes some generic keys under ``keys``, and then ``remove`` in case we want to remove some key.
 * It can be used together with the component/utils/LinkWithBackNavigation for easier and smoother implementation.
 * Be aware that sometimes could be interesting to trigger the remove key when the user makes uses of some link that
 * came from the ``get`` functionality, as we dont want to keep them stored, most of the times will not matter.
 */
function useBackNavigation() {
  const isSSR = typeof window === 'undefined'
  return {
    /**
     * Generic keys to avoid errors when using this hook
     */
    keys: GENERIC_KEYS,
    /**
     *
     * @param {*} key
     * @returns {string} The stored value for the specified key
     */
    get: (key) => (isSSR ? null : Cookies.get(`${key}${KEY_EXTENSION}`)),
    /**
     *
     * @param {*} key
     * @returns {string} The stored value for the specified key
     */
    set: (key) =>
      isSSR ? null : Cookies.set(`${key}${KEY_EXTENSION}`, `${window?.location?.pathname}${location?.search}`, {
        path: '/'
      }),
    /**
     *
     * @param {*} key
     * @returns {string} The stored value for the specified key
     */
    remove: (key) => (isSSR ? null : Cookies.remove((`${key}${KEY_EXTENSION}`), { path: '/' }))
  }
}

export default useBackNavigation
