import { ITabTypes } from '../types/ITabs'
import { useEffect } from 'react'
import { DispatchProp } from 'react-redux'
import {
  A_INITIALIZE,
  A_MODIFY_PAGE,
  A_ADD_ITEMS,
  A_ADD_CUSTOM_PROPS,
  list_initial_state,
  useListsProvider,
  A_ADD_SCROLL_POSITION
} from '../stores/lists'

const getPropertiesWithoutCrashing = list => list ?? list_initial_state

/**
 * This exposes the lists provider that is page level.
 * You must specify the listId which will generate a prop where all the data will be stored
 * +++++
 * You can also specify an array of strings, that will be used to dump the data when the current path does not match
 * the defined strings
 * -- Note that its not a 1 === 1 match, if you specify just events, all the urls with events will
 * match, so better be the more specific you can, this means that its not bulletproof, but its the best we could do
 * when the structure is not common for most the pages, so the provider needs to be way up.
 * -- Note also that this has to be set in the first hook that is called, as the list is only instantiated once,
 * so if you use it multiple times for the same list, you must specify it where it will be called first.
 * @param listId {string} Under this name the property will hold all the data
 * @param config {{initialize: boolean, dumpWhenOutsideOf: string[]}}
 * {array} dumpWhenOutsideOf : Here you can specify under which urls the data must not dump, by default its and empty
 * array and will never be dumped. Also if set, it will include the current url where this is set, so its not dumped
 * at the same moment is set :)
 * {boolean} initialize : By default its false, if set to true it will try to initialize the list
 */
function useList(
  listId: ITabTypes,
  config: { initialize: boolean, dumpWhenOutsideOf: Array<string> } = {
    dumpWhenOutsideOf: [],
    initialize: false
  }
) {
  //@ts-ignore
  const [lists, dispatch] = useListsProvider()
  const { totalNumberOfElements, page, elementVisited, items } = getPropertiesWithoutCrashing(lists[listId])
  const allProps = getPropertiesWithoutCrashing(lists[listId])

  /**
   * Its effects are only executed if the list does not exist, and the prop in config initialize is set to true
   */
  useEffect(() => {
    if (!lists[listId] && config.initialize) {
      /* We always the slug of where we are to the routes that we do not want to dump the data **/
      if (config.dumpWhenOutsideOf.length) {
        config.dumpWhenOutsideOf.push(window.location.pathname)
      }
      dispatch({ type: A_INITIALIZE, payload: { listId, dumpWhenOutsideOf: config.dumpWhenOutsideOf } })
    }
  }, [config.dumpWhenOutsideOf, config.initialize, dispatch, listId, lists])

  /* Sets the page number passed **/
  const modifyPage = pageNumber => dispatch({ type: A_MODIFY_PAGE, listId: listId, payload: pageNumber })
  /**
   * Adds the item to the list, its all or nothing, it recreates the list, so its not an "append" function
   * @param {array} itemsToAdd
   **/
  const addItems = itemsToAdd => dispatch({ type: A_ADD_ITEMS, listId: listId, payload: itemsToAdd })
  /**
   * Use this function to store custom data that you could need for your list
   * Be aware that there are reserved props like : scrollPosition, pathToApplyScrollPosition etc...
   * @param {object} propsToAdd
   **/
  const addCustomProps = propsToAdd => dispatch({ type: A_ADD_CUSTOM_PROPS, listId: listId, payload: propsToAdd })
  /**
   * Stores the scroll position so you can restore the position when user is back to the list, you can also
   * store custom props, like the path to the OVR Show
   **/
  const addScrollPosition = (extraPropsToAdd: { [key: string]: any } = {}): DispatchProp =>
    dispatch({
      type: A_ADD_SCROLL_POSITION,
      listId: listId,
      payload: {
        scrollPosition: { x: window.scrollX, y: window.scrollY },
        pathToApplyScrollPosition: window.location.pathname,
        ...extraPropsToAdd
      }
    })
  /* Is the list not empty :) **/
  const hasItems = !!items.length

  return {
    allProps,
    totalNumberOfElements,
    page,
    elementVisited,
    items,
    hasItems,
    modifyPage,
    addItems,
    addCustomProps,
    addScrollPosition
  }
}

export default useList
