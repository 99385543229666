/* eslint-disable max-len */
import { string, number, oneOfType, bool } from 'prop-types'
import styled from 'styled-components'

import { getAspectRatioPercent } from '@utils'

const calcHeightPercent = ({ width, height, aspectRatio = 0 }) =>
  width && height ? Math.floor((height * 100) / width) : 100 * getAspectRatioPercent(aspectRatio)

// This component calculates and reserves the dimensions of the image before the image load.
// Also add a background color which one is show before the image load.
const ImageRect = styled.div`
  &&& {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: ${({ width, height, aspectRatio }) => calcHeightPercent({ width, height, aspectRatio })}%;
    background-color:
      ${({ placeholderColor: color, transparent, transparentBackground }) =>
      transparent ? `${transparentBackground}` : !!color && `rgb(${color})`};

    > img {
      position: absolute !important;
      left: 0;
      top: ${({ outer }) => (outer ? undefined : 0)};
      bottom: ${({ outer }) => (outer ? 0 : undefined)};
      width: ${({ outer }) => (outer ? 'auto' : '100%')};
      /* For fixing to the dpr issue */
      max-width: 100%;
      max-height: 100%;
    }
  }
`

ImageRect.propTypes = {
  width: number,
  height: number,
  aspectRatio: oneOfType([string, number]),
  placeholderColor: string,
  outer: bool,
  transparent: bool
}

export default ImageRect