import React from 'react'
import PropTypes from 'prop-types'


import { formatGalleryLink } from '@utils'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { CustomLinkTag } from '@components'
import { ArtistGalleryMiniCard } from '@mch-group/uikit-components'
import { AddToCollectionContext } from '@components/collections/AddToCollection'
import { cloudinary } from '@constants'

const GalleryItem = ({
  showNames,
  isSelectedInCollections,
  id,
  cleanTitle,
  displayName,
  imageUrl,
  withAddToCollection,
  isOrganizeMode,
  showShowsData,
  onSelectCard,
  labels
}) => {
  const { showAddToCollectionModal } = React.useContext(AddToCollectionContext)
  return <>
    <ArtistGalleryMiniCard
      collectionLink={formatGalleryLink(id, cleanTitle)}
      image={uploadTransformationUrl(
        imageUrl && imageUrl.replace(/ /g, '%20'), 'c_fill,ar_1:1,q_auto:good,w_400',
        { 'transformUrl': cloudinary.throughCloudfront }
      )}
      collection={{
        active: withAddToCollection,
        onClick: () => {
          showAddToCollectionModal(id, '1')
        }
      }}
      variant='gallery'
      linkRenderer={(link, children, linkTitle, ariaLabel) => (
        <CustomLinkTag title={linkTitle} url={link} ariaLabel={ariaLabel}>
          {children}
        </CustomLinkTag>
      )}
      a11yData={{
        addBtn: labels?.addBtn || 'Add item to collection',
        ariaLabel: labels?.ariaLabel || 'Read more about'
      }}
      alt={`${labels?.imageAlt} ${displayName}`}
      title={displayName}
      fallbackImage={`${cloudinary.UPLOAD}/c_fill,ar_1:1,q_auto:good,w_400/v1599746309/gray-placeholder_wkdex3.jpg`}
      tagCities={showShowsData ? showNames : []}
      selectMode={{
        active: isOrganizeMode,
        checked: isSelectedInCollections,
        onChange: () => {
          isOrganizeMode && onSelectCard(id, '1')
        }
      }}
    />
  </>
}

GalleryItem.propTypes = {
  isSelectedInCollections: PropTypes.bool,
  showNames: PropTypes.array,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cleanTitle: PropTypes.string,
  imageUrl: PropTypes.string,
  displayName: PropTypes.string,
  isOrganizeMode: PropTypes.bool,
  withAddToCollection: PropTypes.bool,
  showShowsData: PropTypes.bool,
  onSelectCard: PropTypes.func
}

export default GalleryItem
