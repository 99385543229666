import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import { environment } from '@dmi-mch/utils'
import { initialState, reducer, StateContext } from '@modules/collections/contexts/useCollectionsContext'

const CollectionsProvider = (props) => {
  const { children, router } = props
  const [hydratedInitialState] = React.useState({
    router,
    isUserLogged: props.isUserLogged,
    list: [],
    dynamicCollection: null,
    labels: null
  })

  React.useMemo(() => {
    if (JSON.stringify(Object.keys(hydratedInitialState).sort()) !== JSON.stringify(Object.keys(initialState).sort())) {
      environment.isNotPro && console.error('Initial state vs context doesnt match!')
    }
  }, [hydratedInitialState])

  return (
    <StateContext.Provider value={React.useReducer(reducer, hydratedInitialState)}>
      {children}
    </StateContext.Provider>
  )
}

CollectionsProvider.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object,
  router: PropTypes.object,
  CMSProps: PropTypes.object,
  isUserLogged: PropTypes.bool,
  collectionsPageSize: PropTypes.number
}

export default withRouter(CollectionsProvider)
