import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Charcounter = ({
  max = 255,
  current = 0,
  text = 'char. max',
  ...props
}) => <figcaption {...props}>{`${current}/${max} ${text}`}</figcaption>

Charcounter.propTypes = {
  text: PropTypes.string,
  max: PropTypes.number,
  current: PropTypes.number
}

const StyledCharcounter = styled(Charcounter)`
  color: ${props =>
    //@ts-ignore
    (props.current < props.max ? tokens.color.light.base.neutrals['500'].value : tokens.color.light.base.alerts.red[700].value)};
`

export default StyledCharcounter
