import { FC } from 'react'
import { Spinner } from '@mch-group/uikit-components'
import styles from './styles/index.module.scss'


/**
 * Component that renders a full size overlay, with a spinner and 2 texts. All params are optional.
 * @param showSpinner
 * @param text1
 * @param text2
 * @param theme
 * @returns JSX.Element
 */
const Loadingscreen: FC<ILoadingscreen> = ({ showSpinner = true, text1, text2, theme = 'light' }) => (
  <div className={styles.fullDocumentOverlay}>
    <div className={styles.container}>
      <div className={styles.centerPositioning}>
        {showSpinner && <Spinner theme={theme} variant='page' />}
      </div>
      <div>
        <div className='text-large text-center'>
          {text1 && <div>{text1}</div>}
          {text2 && <div>{text2}</div>}
        </div>
      </div>
    </div>
  </div>
)

export interface ILoadingscreen {
  showSpinner?: boolean,
  text1?: string,
  text2?: string,
  theme?: 'light' | 'primary' | 'secondary' | 'light' | 'dark' | 'gray'
}

export default Loadingscreen