// @ts-nocheck
/* eslint-disable no-unused-expressions */
import { getCookie, setCookie } from '@utils/storage'
import getProperty from '@utils/getProperty'
import { NextIncomingMessage } from 'next/dist/server/request-meta'
import CookieKeys from '@constants/CookieKeys'

const localeOptions = ['en', 'zh_CN', 'fr', 'es', 'de']

export const standardizeLocale = (reqData) => {
  let currentLanguage = null
  if (reqData?.includes('zh')) {
    currentLanguage = 'zh_CN'
  } else if (reqData?.includes('fr')) {
    currentLanguage = 'fr'
  } else if (reqData?.includes('en')) {
    currentLanguage = 'en'
  } else if (reqData?.includes('de')) {
    currentLanguage = 'de'
  } else if (reqData?.includes('es')) {
    currentLanguage = 'es'
  }
  return currentLanguage
}

export const detectBrowserLocaleByHostname = (req) => {
  let acceptLanguage = null
  let language = null
  let regex = null
  const isServer = req?.headers && req.headers['accept-language']
  const isClient = typeof window !== 'undefined'

  // SSR
  if (isServer) {
    regex = /([^-;]*)(?:-([^;]*))?(?:;q=([0-9]\.[0-9]))?/
    acceptLanguage = regex.exec(req.headers['accept-language'])
    acceptLanguage = acceptLanguage?.length > 0 ? acceptLanguage[1].split(',')[0] : null
    language = standardizeLocale(acceptLanguage)
    if (!localeOptions?.includes(language)) {
      language = 'en'
    }
  }

  // CSR
  if (isClient) {
    const requestFromCSR = req?.split('-')[0]
    language = standardizeLocale(requestFromCSR)
    if (!localeOptions.includes(language)) {
      language = 'en'
    }
  }

  return language
}

interface ABLocale {
  cookie: string
  url: string
  siteLanguage: string
}

export const detectSiteLanguage = (req: NextIncomingMessage | undefined): ABLocale => {
  let locale = {
    cookie:
      req?.universalCookies?.get(CookieKeys.LANGUAGE)?.replace('?utm_source=newsletter', '') ||
      getCookie(null, CookieKeys.LANGUAGE)?.replace('?utm_source=newsletter', ''),
    url: getProperty(req, 'query.lang')?.replace('?utm_source=newsletter', ''),
    siteLanguage: null
  }

  // SSR vs CSR
  if (locale.url) {
    if (!localeOptions.includes(locale.url)) {
      locale.url = locale.cookie
    }

    if (locale.cookie) {
      req?.universalCookies?.remove(CookieKeys.LANGUAGE)
      req?.universalCookies?.set(CookieKeys.LANGUAGE, locale.url)
      locale.siteLanguage = locale.url
      locale.locale = locale.cookie
    } else {
      // first visit ever, on SSR, without mfpLocale cookie
      req?.universalCookies?.set(CookieKeys.LANGUAGE, locale.url)
      locale = {
        cookie: locale.url,
        url: locale.url,
        siteLanguage: locale.url
      }
    }
  } else if (!locale.url && req) {
    if (locale.cookie) {
      locale.siteLanguage = locale.cookie
    } else {
      locale.siteLanguage = detectBrowserLocaleByHostname(req)
      req.universalCookies?.set(CookieKeys.LANGUAGE, locale.siteLanguage)
    }
  } else {
    if (!getCookie(null, CookieKeys.LANGUAGE)) {
      locale.siteLanguage = detectBrowserLocaleByHostname(window.navigator.userLanguage || window.navigator.language)
      setCookie(CookieKeys.LANGUAGE, locale.siteLanguage, { secure: true })
    } else {
      locale.siteLanguage = getCookie(null, CookieKeys.LANGUAGE)
    }
  }

  return locale
}

export const hostProtocol = (host) => {
  if (host?.includes('localhost')) {
    return `http://${host}`
  }
  return `https://${host}`
}
