import styled from 'styled-components'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import tokensRenamed from '@constants/tokensRenamed'

import { px2rem } from '@utils'

const GDBlockWrapper = styled.div`
  & + .container {
    margin-top: -${px2rem(30)};
  }

  margin-bottom: ${px2rem(24)};

  .ui.container {
    padding-left: 0;
    padding-right: 0;
    background: var(--bs-mch-white);

    .ui.grid.block-header {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .ui.grid.gd-block-content {
      margin-top: 0;
    }
  }

  .gd-block-header {
    h3.ui.header {
      padding: ${px2rem(40)} 0 ${px2rem(30)} ${px2rem(40)};
      margin: 0;
      position: relative;
    }

    .first-row {
      padding-bottom: ${px2rem(0)} !important;
    }

    .last-row {
      h3.ui.header {
        padding-top: ${px2rem(35)};
      }

      .column-align {
        display: inline-flex;
        padding-bottom: ${px2rem(24)};
      }
    }

    .with-tabs {
      display: flex !important;
      align-items: center;
      justify-content: flex-start;

      .tab-button {
        margin-left: ${px2rem(70)};
      }

      button {
        padding-left: ${px2rem(15)};
        padding-right: ${px2rem(15)};
        border-radius: ${px2rem(4)} !important;
        font-weight: ${tokens.fontWeights.artBaselText1.value};
        border-color: var(--bs-mch-gray-400) !important;

        &.ui.primary {
          background: ${tokens.color.light.base.neutrals['500'].value} !important;
          border-color: ${tokens.color.light.base.neutrals['500'].value} !important;
          font-weight: ${tokens.fontWeights.artBaselHeadline0.value};
          color: var(--bs-mch-black) !important;
        }
      }
    }

    .input-height-align {
      padding-right: ${px2rem(3)};

      & > input {
        border-radius: ${px2rem(4)} !important;
        height: ${px2rem(40)} !important;
        background: ${tokens.color.light.base.neutrals['100'].value};
        border: 0.5px solid ${tokens.color.light.base.neutrals['600'].value} !important;
        font-size: ${tokens.fontSize[3].value} !important;
        padding: ${px2rem(13)} 0 ${px2rem(13)} ${px2rem(13)};

        &::placeholder {
          color: ${({ theme }) => theme.colorPlaceholder};
          opacity: 1;
        }

        &::-ms-clear {
          display: none;
        }
      }
    }

    .archive-delete-label {
      padding: ${px2rem(8)} ${px2rem(24)} ${px2rem(8)} ${px2rem(182)};
    }
  }

  .gd-block-header.ui.grid > .row {
    padding-top: 0;

    & > .column:last-child {
      display: flex;
      align-items: center;

      .controls {
        margin-left: auto;
        margin-right: ${px2rem(40)};

        button:not(:first-of-type) {
          margin-left: ${px2rem(40)};
        }
      }
    }
  }

  .gd-block-nav h4.ui.header {
    padding: ${px2rem(30)} 0 ${px2rem(30)} ${px2rem(42)};
    margin: 0;
    position: relative;
  }

  .gd-block-nav {
    &.ui.grid {
      margin-bottom: 0;

      & > .row {
        padding: 0;

        & > .column:last-child {
          display: flex;
          align-items: center;

          div {
            margin-left: auto;
            margin-right: ${px2rem(40)};
          }

          button:not(:first-of-type) {
            margin-left: ${px2rem(40)};
          }
        }
      }
    }
  }

  .gd-block-content > .row > .column {
    padding: ${props => props.gdBlockMarginTop ? px2rem(props.gdBlockMarginTop) : 0} ${px2rem(70)} 0;

    & > .ui.grid > .row:first-child h3 {
      margin: ${px2rem(3)} 0 ${px2rem(47)} 0;
      color: var(--bs-mch-gray-500);
    }
  }

  .gd-block-content.clear-last-row {
    & > .row {
      padding: 0;

      & > .column .ui.grid .row {
        padding: 0;
      }
    }
  }

  .gd-block-controls {
    & > .ui.grid > .row > .column {
      &:first-child > button.ui.button {
        margin-left: -${px2rem(6)};

        &:active {
          background: none;
        }
      }

      &:last-child > button.ui.button {
        margin-right: -${px2rem(4)};
      }
    }
  }

  .gd-block-title {
    & > .ui.grid > .row > .column {
      .ui.button.preview {
        padding: 0;
        margin: 0 0 0 ${px2rem(35)};

        svg {
          width: ${px2rem(24)};
        }
      }

      &:last-child > button.ui.button {
        margin-right: -${px2rem(4)};

        &:active {
          background: none;
        }
      }
    }
  }

  .gd-block-controls,
  .gd-block-title {
    padding: ${px2rem(20)} ${px2rem(48)};
    border-bottom: 1px solid ${tokens.color.light.base.neutrals['500'].value};

    .row {
      align-items: center;

      .column:last-child {
        text-align: right;
      }
    }

    .ui.button:not(.primary) {
      display: inline-flex;
      align-items: center;
      padding: ${px2rem(8)} 0;
      font-weight: ${tokens.fontWeights.artBaselText1.value};
      border: none;
      font-size: ${tokens.fontSize[3].value};

      & + .ui.button {
        margin-left: ${px2rem(32)};
      }

      &.loading {
        svg {
          visibility: hidden;
        }
      }

      svg {
        margin-right: ${px2rem(11)};
      }
    }
  }

  .rows-per-page {
    margin-left: auto;
    margin-right: ${px2rem(31)};
    position: relative;

    .dropdown.icon {
      display: none !important;
    }

    .field {
      display: flex;
      margin: 0 !important;
      align-items: center;
      font-size: ${tokens.fontSize[3].value};
      line-height: ${tokensRenamed.lineHeights[7].value};
    }

    label {
      display: block;
      white-space: normal;
      flex-shrink: 0;
    }

    .ui.selection.active.dropdown .menu {
      border: 1px solid ${tokens.color.light.base.neutrals['300'].value} !important;
    }

    .ui.fluid.dropdown {
      background: none !important;
      width: ${px2rem(50)} !important;
      flex-shrink: 0;
      margin-right: 0 !important;
      margin-left: ${px2rem(15)} !important;
      border: none !important;
      position: relative;
      z-index: 1;

      .text {
        text-align: center;
        width: ${px2rem(50)};
        font-weight: ${tokens.fontWeights.artBaselHeadline0.value};

        &:not(.item) {
          text-indent: -${px2rem(7)};
        }
      }

      .menu > .item {
        width: ${px2rem(50)};
        text-align: center;
        display: flex;
        justify-content: center;
      }
    }

    svg {
      position: absolute;
      top: ${px2rem(19)};
      right: 0;
    }
  }
`

export default GDBlockWrapper
