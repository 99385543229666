import { Checkbox, Image } from 'semantic-ui-react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { px2rem } from '@utils'
import { cloudinary } from '@constants'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const GridItem = ({ text, image, onChange, checked, className, onClick }) => (
  <div className={className}>
    <div
      role='button'
      tabIndex={0}
      onClick={(e) => {
        if (e.code !== 'Tab') {
          onClick()
        }
      }}
      onKeyUp={(e) => {
        if (e.code !== 'Tab') {
          onClick()
        }
      }}
    >
      <Image
        src={uploadTransformationUrl(image ||
          '/static/images/placeholder-no-image.png',
          'w_400,q_auto:good,dpr_1.0,ar_1.6,c_crop,c_fill,g_auto',
          { 'transformUrl': cloudinary.throughCloudfront }
        )}
        alt='' // button title comes from the inner text as this all a single button
      />
      <div className='content'>
        <span className={`label ${checked ? 'label-1' : 'label-2'}`}>{text}</span>
        <Checkbox
          name='items'
          checked={checked}
          onChange={onChange}
          className='dark'
          aria-label={text}
        />
      </div>
    </div>
  </div>)

GridItem.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  className: PropTypes.string,
  image: PropTypes.string,
  mID: PropTypes.string
}

const GridItemWrapper = styled(GridItem)`
  height: 100%;
  /* No need variable for now */
  ${props => props.checked ?
    'box-shadow: 0 0 0 1px var(--bs-mch-gray-500);'
    :
    `box-shadow: 0 1px 0 0 ${tokens.color.light.base.neutrals[400].value};`
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: ${px2rem(10)} ${px2rem(8)} ${px2rem(10)} ${px2rem(8)};
    }
  }
`

export default GridItemWrapper
