import { FC } from 'react'
import styled from 'styled-components'

interface ORLabelProps {
  orLabel: string,
  className: string
}

const ORLabel: FC<ORLabelProps> = ({ orLabel, className }) => (
  <StyledHeading className={`header-uppercase-2 ${className}`}>
    {orLabel}
  </StyledHeading>
)

const StyledHeading = styled.h1`
  margin: 100px auto;
  text-align: center;
  position: relative;
  color: var(--bs-mch-gray-400) !important;

  &::before,
  &::after {
    content: "";
    display: block;
    width: 88px;
    height: 1px;
    background: var(--bs-mch-gray-200);
    top: 35%;
    position: absolute;
  }

  @media (max-width: 767px) {
    &::before,
    &::after {
      width: 50px;
    }
  }

  &::before {
    left: 78px;
  }

  &::after {
    right: 78px;
  }
`

export default ORLabel
