import queryString from 'query-string'
import type { ParsedUrlQuery } from 'querystring'
import { cmsSkeletonModules } from '@constants/cms'
import type IModule from 'types/IModule'

const REDUCERNAME = 'page'

const initialState = {
  componentCmsData: null,
  isFirstModuleVideoPlayer: false,
  pageHasMenu: false,
  query: {
    slug: ''
  },
  userAgent: ''
}

// ACTION TYPES
export const actionTypes = {
  SET_PAGE_DATA: `${REDUCERNAME}__SET_PAGE_DATA`
}

// ACTIONS
export const setPageData = (payload: {
  componentCmsData?: IModule[],
  pageMetaInfo: any, // This "any" is a FE unconsistency. We have to check the metadata.
  query: ParsedUrlQuery,
  userAgent: string
}): any => (dispatch): void => {
  dispatch({
    type: actionTypes.SET_PAGE_DATA,
    payload
  })
}

// ACTION HANDLERS
const actionHandlers = {
  [actionTypes.SET_PAGE_DATA]: (state, action) => {
    const {
      componentCmsData,
      pageMetaInfo,
      query,
      userAgent
    } = action.payload

    const pageFirstSecondaryHeader =
      componentCmsData.length > 0
        ? componentCmsData.filter(item => item.moduleName === cmsSkeletonModules.secondaryHeader)[0]
        : null

    const secondaryHeaderHasMenu =
      pageFirstSecondaryHeader &&
      'secom_secondary_menu_from' in pageFirstSecondaryHeader.componentProps &&
      pageFirstSecondaryHeader.componentProps.secom_secondary_menu_from !== ''

    const pageHasMenuModule =
      componentCmsData.length > 0
        ? componentCmsData.filter(item => item.moduleName === cmsSkeletonModules.pageMenu)[0]
        : null

    const pageHasMenu = secondaryHeaderHasMenu || pageHasMenuModule

    const isFirstModuleVideoPlayer =
      componentCmsData.length > 0 && componentCmsData[0].moduleName === cmsSkeletonModules.headerVideo

    let queryFinal = query
    // fix bug in query.slug not correct sometimes
    if (queryFinal && queryFinal.slug && queryFinal.slug.includes('?')) {
      const parseQuery = queryString.parseUrl(queryFinal.slug)
      queryFinal.slug = parseQuery.url
      queryFinal = { ...queryFinal, ...parseQuery.query }
    }

    return {
      ...state,
      ...{
        componentCmsData,
        pageMetaInfo,
        pageHasMenu,
        isFirstModuleVideoPlayer,
        query: queryFinal,
        userAgent
      }
    }
  }
}

// REDUCER
const pageReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export default pageReducer
