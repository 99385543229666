import React from 'react'
import PropTypes from 'prop-types'

const DefaultToggle = ({ isOpen }) => (
  <span className='AnimatedShowMore__DefaultToggle'>
    { isOpen ? 'Show less' : 'Show more' }
  </span>
)

DefaultToggle.propTypes = {
  isOpen: PropTypes.bool
}

export default DefaultToggle