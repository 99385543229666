const prepareSectorData = (sectorsData, additionalSectorsData) => {
  const Sectors = []
  const sectorDataIsString = typeof sectorsData == 'string'
  const extraSectorDataIsString = typeof additionalSectorsData == 'string'
  const sectorDataArray = sectorDataIsString ? sectorsData.split(' ') : sectorsData
  const extraSectorDataArray = extraSectorDataIsString ? additionalSectorsData.split(',') : []
  const allSectorDataArray = extraSectorDataIsString ? [...sectorDataArray, ...extraSectorDataArray] : sectorDataArray

  allSectorDataArray &&
    allSectorDataArray.forEach((itemData, index) => {
      const obj = {
        id: sectorDataIsString ? index : itemData?.id,
        name: sectorDataIsString ? itemData : itemData?.name
      }
      //@ts-ignore
      Sectors.push(obj)
    })

  return Sectors
}

export default prepareSectorData
