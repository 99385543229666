// @ts-nocheck
import http from './_http'

const Cities = {
  getList: ({ offset, limit, search, order, direction }) =>
    http.get('/msvc/v1/cities', { offset, limit, search, order, direction }),
  getAll: () =>
    http.get('/msvc/v1/cities/all/'),
  getClosest: ({ latitude, longitude }) =>
    http.get('/msvc/v1/cities/closest', { latitude, longitude }),
  getSuggestions: ({ search, count }) =>
    http.get('/msvc/v1/cities/', { search, count }),
  getTheseCities: ids =>
    http.get(`/msvc/v1/cities/${ids}`),
  getCity: id =>
    http.get(`/msvc/v1/cities/city/${id}`)
}

export default Cities
