import { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getSiteLanguage } from '@utils/storage'
import { debounce, isScrolledIntoView } from '@utils'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { Icon } from '@mch-group/uikit-components'
import { PageContext } from '@providers/PageContext'
import useRemoveCollectionDetail from '@modules/collections/hooks/useRemoveCollectionDetail'
import useCopyMoveCollectionDetail from '@modules/collections/hooks/useCopyMoveCollectionDetail'
import tokensRenamed from '@constants/tokensRenamed'

const FloatingControl = ({ selectedItems, mutateEditPreviewCollectionItems, editPreviewCollectionItems }) => {
  const { labels: apiLabels = {} } = useLabelsFromAPI(['AltTags'], getSiteLanguage())
  const { AltTags } = apiLabels
  const { removeCollectionItems, visible } = useRemoveCollectionDetail()
  const { copyCollectionItems, moveCollectionItems, defaultState } = useCopyMoveCollectionDetail()
  const { page: { query } } = useContext(PageContext)
  const promptDelete = async () => {
    removeCollectionItems(query?.id, selectedItems, mutateEditPreviewCollectionItems, editPreviewCollectionItems)
  }
  const promptMove = async () => {
    const [{ entityType }] = selectedItems
    moveCollectionItems(query?.id, entityType,
    // @ts-ignore
      selectedItems, mutateEditPreviewCollectionItems, editPreviewCollectionItems)
  }
  const promptCopy = async () => {
    const [{ entityType }] = selectedItems
    copyCollectionItems(query?.id, entityType, selectedItems)
  }
  const isActive = selectedItems?.length > 0 && !visible
  const [controlsPosition, setControlsPosition] = useState('fixed')

  const calculateControlsPosition = useCallback(() => {
    let footer = document.querySelector('.site-footer')
    if (footer) {
      if (isScrolledIntoView(footer) && controlsPosition !== 'initial') {
        setControlsPosition('initial')
      }
      if (!isScrolledIntoView(footer) && controlsPosition !== 'fixed') {
        setControlsPosition('fixed')
      }
    }
  }, [controlsPosition])

  const handleScroll = debounce(
    useCallback(() => {
      calculateControlsPosition()
    }, [calculateControlsPosition]),
    10
  )

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return function () {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    calculateControlsPosition()
  }, []) /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <StyledIcon className={`icon-bar ${controlsPosition}`}>
      <IconWrapper>
        <div
          className={`${isActive ? 'is-active' : ''} floating-icon`}
          onClick={() => (isActive ? promptDelete() : {})}
          onKeyUp={() => (isActive ? promptDelete() : {})}
          role='button'
          tabIndex={0}
          title={AltTags?.deleteItemFromCollection}
        >
          <Icon name='delete' color={isActive ? 'var(--bs-mch-white)' : 'var(--bs-mch-gray-400)'} />
        </div>
        <div
          className={`${isActive ? 'is-active' : ''} floating-icon`}
          onClick={() => (isActive ? promptMove() : {})}
          onKeyUp={() => (isActive ? promptMove() : {})}
          role='button'
          tabIndex={0}
          title={AltTags?.moveItemToCollection}
        >
          <Icon name='move' color={isActive ? 'var(--bs-mch-white)' : 'var(--bs-mch-gray-400)'} />
        </div>
        <div
          className={`${isActive ? 'is-active' : ''} floating-icon`}
          onClick={() => (isActive ? promptCopy() : {})}
          onKeyUp={() => (isActive ? promptCopy() : {})}
          role='button'
          tabIndex={0}
          title={AltTags?.copyItemToCollection}
        >
          <Icon name='copy' color={isActive ? 'var(--bs-mch-white)' : 'var(--bs-mch-gray-400)'} />
        </div>
        <Separator />
        <div
          className='floating-icon'
          // @ts-ignore
          onClick={() => { defaultState(selectedItems, editPreviewCollectionItems) }}
          // @ts-ignore
          onKeyUp={() => { defaultState(selectedItems, editPreviewCollectionItems) }}
          role='button'
          tabIndex={0}
          title={AltTags?.closeOrganizeCollection}
        >
          <Icon name='close' color='var(--bs-mch-gray-100)' />
        </div>
      </IconWrapper>
    </StyledIcon>
  )
}

FloatingControl.propTypes = {
  dispatch: PropTypes.func,
  selectedItems: PropTypes.array,
  mutateEditPreviewCollectionItems: PropTypes.func,
  editPreviewCollectionItems: PropTypes.array
}

const Separator = styled.div`
  border-right: 1px solid var(--bs-mch-gray-400);
  margin: 0 8px;
  height: 55%;
`

const IconWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  text-align: center;
  margin-left: 10px;

  .floating-icon {
    cursor: pointer;
    outline: none;
    flex-basis: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
`

const StyledIcon = styled.div`
  background-color: var(--bs-mch-black);
  width: 241px;
  height: 60px;
  border-radius: 50px;
  box-shadow: ${tokensRenamed.shadows[3]};
  z-index: 9;

  &.fixed {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 19px auto 12px auto;
  }

  &.initial {
    position: absolute;
    text-align: center;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
`

export default FloatingControl
