import React from 'react'

import { ConfirmContext } from '@providers/Confirm'
import { NotificationsContext } from '@providers/Notifications'
import { Collections as CollectionsService } from '@services'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'

const useRemoveCollectionDetail = () => {
  const { showConfirm, setVisible, setButtonStates, visible } = React.useContext(ConfirmContext)
  const { showNotification } = React.useContext(NotificationsContext)
  const [{ labels, isOrganizeMode }, dispatch] = useMyCollectionsPageContext()
  const { Collections } = labels || {}

  const remove = async (id, selecteditems, updateListCallback, editPreviewCollectionItems) => {
    setButtonStates({
      disabled: true,
      isLoading: true
    })
    if (selecteditems.length) {
      const reqParams = {
        'items': {
          'toDelete': selecteditems
        }
      }
      try {
        const response = await CollectionsService.edit(id, reqParams)
        if (response.ok) {
          const itemsLabel =
            selecteditems.length === 1 ? Collections.singleItemDeleteMsgLabel : Collections.multipleItemsDeleteMsgLabel
          setVisible(false)
          showNotification({
            content: `<b>${selecteditems.length} ${itemsLabel}</b> <br>${Collections.successfulDeleteCollection}`
          })
          updateListCallback()
          //this if condition will set the organizeMode false when we are not deleting all the items from a tab
          // if the user will delete all the items from the a tab this will be handled inside /edit-preview-collections/Tabs
          if (selecteditems.length !== editPreviewCollectionItems.length) {
            dispatch({ type: 'SET_ORGANIZE_MODE', payload: !isOrganizeMode })
          }
          dispatch({ type: 'SET_SELECTED_ITEMS', payload: [] })
        }
      } catch (e) {
        setVisible(true)
      }
    }
  }

  const removeCollectionItems = (id, selecteditems, updateListCallback, editPreviewCollectionItems) => {
    showConfirm({
      labels: {
        title: Collections.deleteButtonLabel,
        message: Collections.deleteItemsAlertMessageLabel,
        ok: Collections.deleteButtonLabel,
        cancel: 'Cancel'
      },
      actions: {
        onOK: () => {
          remove(id, selecteditems, updateListCallback, editPreviewCollectionItems)
        },
        onCancel: () => setVisible(false)
      }
    })
  }

  return {
    removeCollectionItems,
    visible
  }
}

export default useRemoveCollectionDetail
