import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import { mq, px2rem } from '@utils'
import { createGlobalStyle } from 'styled-components'

/**
 * Makes the modal look full screen width and height in mobile, eventually also for desktop
 */
const ModalFullscreen = createGlobalStyle`
  body {
    .ui {
      &.modals {
        &.dimmer {
          ${mq.lessThan('largestTablet')`
            padding: 0;
          `}

          .ui.modal {
            ${mq.lessThan('largestTablet')`
              width: 100%;
              top: 0;
              height: 100%;
              display: flex !important;
              flex-direction: column;
              justify-content: space-between;
              margin-top: 0 !important;
            `}

            .scrolling {
              &.content {
                ${mq.lessThan('largestTablet')`
                  max-height: inherit;
                `}
              }
            }

            .actions {
              ${mq.lessThan('largestTablet')`
                padding: ${px2rem(16)} !important;
                border-top: 1px solid ${tokens.color.light.base.neutrals['500'].value};
              `}
            }
          }
        }
      }
    }
  }
`

export default ModalFullscreen
