import { ApiResponse } from 'apisauce'
import type INews from 'types/INews'
import { api } from './_http'

const News = {
  // Extending the response type with "items", because BE data model is incomplete
  search: (params, axiosConfig?: any): Promise<ApiResponse<INews>> => api.get('/msvc/v1/newsviews/search', params, axiosConfig),
  get: (id): Promise<ApiResponse<ABTypes.Monolith.NewSearchResult>> => api.get(`/msvc/v1/newsviews/${id}`)
}


export default News