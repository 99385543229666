import styled from 'styled-components'
import PropTypes from 'prop-types'

import { px2rem, mq } from '@utils'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import { cssClasses } from '@constants'
import { Loader } from '@components'
import IntersectionCapture from '@reusable-modules/IntersectionCapture'
import useSubscribeCollection from '@modules/collections/hooks/useSubscribeCollection'
import { NotificationsContext } from '@providers/Notifications'
import { ConfirmContext } from '@providers/Confirm'
import CollectionAlbumCard from '../CollectionAlbumCard'

const SubscribedCollectionsTab = ({
  subscribedCollectionsItems,
  isLoadingInitialData,
  subscribedCollectionsValidating,
  isRefreshing,
  isLoadingMore,
  size,
  setSize,
  isEmpty
}) => {
  const [{ CMSProps, labels }] = useMyCollectionsPageContext()
  const { unsubscribe } = useSubscribeCollection()
  const { moduleName, id } = CMSProps
  const { showConfirm , setVisible } = React.useContext(ConfirmContext)
  const { showNotification } = React.useContext(NotificationsContext)

  const isIntersectionActive = subscribedCollectionsItems
    && !subscribedCollectionsValidating && !isRefreshing && !isEmpty && !isLoadingInitialData && !isLoadingMore

  const handleUnfollowCollection = (i) => {
    showConfirm({
    labels: {
      title: labels.CollectionNotifications.unfollowCollectionPopupTitle,
      message: labels.CollectionNotifications.unfollowCollectionPopupMessage,
      ok: labels.CollectionNotifications.unfollowCollectionPopupBtnLabel,
      cancel: labels.CollectionNotifications.cancelPopupBtnLabel
    },
    actions: {
      onOK : () => {
        showNotification({
          content: labels.Collections.unfollowingConfirmationMsgLabel,
          time: 3500
      })
      setVisible(false)
      unsubscribe(i.id, true)
      },
      onCancel : () => {
        setVisible(false)
      }
    }
    })
  }

  return (
    <>
      <Cards id={`${moduleName}-${id}`} className={cssClasses.CMS_MODULE}>
        {subscribedCollectionsItems && subscribedCollectionsItems.length > 0 &&
          subscribedCollectionsItems.map(i => (
            <Card key={i.id}>
              <CollectionAlbumCard
                item={i}
                isCreator={false}
                handleUnfollowClick={() => handleUnfollowCollection(i)}
              />
            </Card>
          ))}
      </Cards>
      <IntersectionCapture
        onIntersect={() => setSize(size + 1)}
        active={isIntersectionActive}
        params={{ rootMargin: '400px' }}
      />
      {(isLoadingInitialData || isLoadingMore) ?
        (<LoaderContainer><Loader centered active /></LoaderContainer>) : null
      }
    </>
  )
}

SubscribedCollectionsTab.propTypes = {
  subscribedCollectionsItems: PropTypes.array,
  isLoadingInitialData: PropTypes.bool,
  subscribedCollectionsValidating: PropTypes.bool,
  isRefreshing: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
  isLoadingMore: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
  size: PropTypes.number,
  setSize: PropTypes.func,
  isEmpty: PropTypes.bool
}

const Cards = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 2.5%;
  grid-template-columns: repeat(4, 23.125%);
  grid-row-gap: inherit;
  ${mq.lessThan('largestTablet')`
    grid-gap: 0%;
    grid-template-columns: repeat(1, 100%);
  `}
`

const Card = styled.div`
  margin-bottom: ${px2rem(30)};
`
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
`

export default SubscribedCollectionsTab