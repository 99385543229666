import React from 'react'
import Router from 'next/router'
import { logger } from '@dmi-mch/utils'

import { User as UserService } from '../services'
import { clearUserTokens } from '../utils/storage'
import { clearAllUserData } from './shared'

const REDUCERNAME = 'user'

const initialState = {
  me: null,
  meIsLoading: false,
  profiles: null,
  isRedirectionAfterLogin: false,
  haveVipRepresentative: true,
  loginData: null,
  accessToken: null,
  profileDataLoading: false,
  accountType: null
}

// ACTION TYPES
export const actionTypes = {
  SET_LOGIN_DATA: `${REDUCERNAME}__SET_LOGIN_DATA`,
  SET_ME_DATA: `${REDUCERNAME}__SET_ME_DATA`,
  SET_ME_DATA_LOADING: `${REDUCERNAME}__SET_ME_DATA_LOADING`,
  SET_ME_PROFILES: `${REDUCERNAME}__SET_ME_PROFILES`,
  SET_ME_ACCOUNT_TYPE: `${REDUCERNAME}__SET_ME_ACCOUNT_TYPE`,
  SET_HAVE_VIP_REPRESENTATIVE: `${REDUCERNAME}__SET_HAVE_VIP_REPRESENTATIVE`,
  SET_USER_COOKIES: `${REDUCERNAME}__SET_USER_COOKIES`,
  SIGNOUT_REQUEST: `${REDUCERNAME}__SIGNOUT_REQUEST`,
  SIGNOUT_SUCCESS: `${REDUCERNAME}__SIGNOUT_SUCCESS`,
  SET_SESSION_SUMMARY: `${REDUCERNAME}__SET_SESSION_SUMMARY`,
  REMOVE_SESSION_SUMMARY: `${REDUCERNAME}__REMOVE_SESSION_SUMMARY`,
  SET_REDIRECT_AFTER_LOGIN: `${REDUCERNAME}__SET_REDIRECT_AFTER_LOGIN`,
  SET_ACCESS_TOKEN: `${REDUCERNAME}__SET_ACCESS_TOKEN`,
  SET_REFRESH_TOKEN: `${REDUCERNAME}__SET_REFRESH_TOKEN`,
  SET_USER_PROFILE_DATA_LOADING: `${REDUCERNAME}__SET_USER_PROFILE_DATA_LOADING`
}

// ACTIONS
export const setLoginData = (payload: ABTypes.Login.SessionSummary) => dispatch =>
  dispatch({
    type: actionTypes.SET_LOGIN_DATA,
    payload
  })

export const setMe = (payload: ABTypes.Userprofiles.User) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_ME_DATA,
    payload
  })

export const setAccessToken = (payload: string) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_ACCESS_TOKEN,
    payload
  })

export const setRefreshToken = (payload: string) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_REFRESH_TOKEN,
    payload
  })

// Same than the method above, but including API call
export const setUserDataFromAPI = (params: { forceCall?: boolean, freshData?: boolean } = {}) =>
  async (dispatch, getState) => {
    // Avoid redundant API calls. If it's called (maybe by another module) and it's already loading, don't call it again.
    if (!getState().user.meIsLoading || params.forceCall) {
      dispatch({
        type: actionTypes.SET_ME_DATA_LOADING
      })
      try {
        const getMe = await UserService.getMe(params.freshData)
        if (getMe.data) {
          dispatch(setMe(getMe.data))
        }
      } catch (e) {
        logger(e)
      }
    }
  }

// ACTIONS
export const setUserProfiles = payload => dispatch =>
  dispatch({
    type: actionTypes.SET_ME_PROFILES,
    payload
  })

export const setUserAccountType = payload => dispatch =>
  dispatch({
    type: actionTypes.SET_ME_ACCOUNT_TYPE,
    payload
  })


// This action is very similar to setUserProfiles, but it includes the API call
export const setUserProfilesFromAPI = () => async (dispatch, getState) => {
  // Avoid redundant API calls. If it's called (maybe by another module) and it's already loading, don't call it again.
  if (!getState().user.profileDataLoading) {
    dispatch({
      type: actionTypes.SET_USER_PROFILE_DATA_LOADING
    })
  }
  try {
    const meProfile = await UserService.getProfiles()
    if (meProfile.data && meProfile.ok) {
      dispatch(setUserProfiles(meProfile.data))
    }
  } catch (e) {
    logger(e)
  }
}

export const setUserAccountTypeFromAPI = () => async (dispatch, getState) => {
  if (!getState().user.profileDataLoading) {
    dispatch({
      type: actionTypes.SET_USER_PROFILE_DATA_LOADING
    })
  }
  try {
    const meAccountType = await UserService.getMyAccount('')
    if (meAccountType.data) {
      dispatch(setUserAccountType(meAccountType.data))
    }
  } catch (e) {
    logger(e)
  }
}

export const signOut = (url?: string) => async (dispatch: React.Dispatch<any>) => {
  dispatch({
    type: actionTypes.SIGNOUT_REQUEST
  })

  const isAccountDeleted = url === 'userAccountDelete'
  const signout = await UserService.signOut()

  if (isAccountDeleted || signout.status === 200) {
    clearUserTokens()
    if (url) {
      Router.push({ pathname: '/' }, isAccountDeleted ? '/' : url)
    }
    dispatch({
      type: actionTypes.SIGNOUT_SUCCESS
    })
    dispatch(clearAllUserData())
  }
}

// Not clear how this sessionSummary works.
// If someone finds out please comment this function
export const setSessionSummary = (payload: ABTypes.Login.SessionSummary) => dispatch =>
  dispatch({
    type: actionTypes.SET_SESSION_SUMMARY,
    payload
  })

export const setHaveVipRepresentative = (payload: boolean) => dispatch =>
  dispatch({
    type: actionTypes.SET_HAVE_VIP_REPRESENTATIVE,
    payload
  })

// Not clear how this sessionSummary works.
// If someone finds out please comment this function
export const removeSessionSummary = () =>
  ({ type: actionTypes.REMOVE_SESSION_SUMMARY })

// ACTION HANDLERS
const actionHandlers = {
  [actionTypes.SET_LOGIN_DATA]: (state, action) => ({
    ...state,
    loginData: action.payload
  }),
  [actionTypes.SET_ME_DATA_LOADING]: (state) => ({
    ...state,
    meIsLoading: true
  }),
  [actionTypes.SET_USER_PROFILE_DATA_LOADING]: (state) => ({
    ...state,
    profileDataLoading: true
  }),
  [actionTypes.SET_ME_DATA]: (state, action) => ({
    ...state,
    me: action.payload,
    meIsLoading: false
  }),
  [actionTypes.SET_ME_PROFILES]: (state, action) => ({
    ...state,
    profileDataLoading: false,
    profiles: action.payload
  }),
  [actionTypes.SET_ME_ACCOUNT_TYPE]: (state, action) => ({
    ...state,
    profileDataLoading: false,
    accountType: action.payload
  }),
  [actionTypes.SIGNOUT_SUCCESS]: state => ({
    ...state,
    me: null,
    profiles: null,
    loginData: null,
    accessToken: null,
    accountType: null
  }),
  [actionTypes.SET_SESSION_SUMMARY]: (state, action) => ({
    ...state,
    sessionSummary: action.payload
  }),
  [actionTypes.SET_REDIRECT_AFTER_LOGIN]: (state, action) => ({
    ...state,
    isRedirectionAfterLogin: action.payload
  }),
  [actionTypes.SET_HAVE_VIP_REPRESENTATIVE]: (state, action) => ({
    ...state,
    haveVipRepresentative: action.payload
  }),
  [actionTypes.REMOVE_SESSION_SUMMARY]: (state) => {
    const newState = { ...state }
    delete newState.sessionSummary
    return {
      ...newState
    }
  },
  [actionTypes.SET_ACCESS_TOKEN]: (state, action) => ({
    ...state,
    accessToken: action.payload
  }),
  [actionTypes.SET_REFRESH_TOKEN]: (state, action) => ({
    ...state,
    accessToken: action.payload
  })
}

// REDUCER
const userReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export default userReducer
