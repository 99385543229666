import { useContext, useEffect } from 'react'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { PageContext } from '@providers/PageContext'

/**
 * This function is thought to be reusable, but itassumes some things.
 * Will always dispatch type "SET_LABELS", but could be improved
 * @param labelEntitiesArray
 * @param useModuleContext
 * @returns
 */
const useRequestLabels = (labelEntitiesArray: Array<string>, useModuleContext: Function): void => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const { state: { allLabels }, dispatch } = useModuleContext()
  const { labels } = useLabelsFromAPI(labelEntitiesArray, siteLanguage)

  useEffect(() => {
    !allLabels && labels && dispatch({ type: 'SET_LABELS', payload: labels })
  }, [allLabels, dispatch, labels])
}
export default useRequestLabels