/**
 * Usage:
 * import { getLinkRel } from '@utils'
 * rel={getLinkRel(url)}
 *
 */

export const getLinkRel = (url: string) => {
  if (!url.includes('artbasel.com')) {
    return 'nofollow noopener'
  }
  return 'external'
}

/*
rel='noopener'
Willing to protect your site users from potentially malicious external links

rel='noreferrer'
Unwilling to appear in a website’s analytics data as referred traffic

rel='nofollow'
unwilling to endorse the content or creator you’re linking to
*/

export default {}