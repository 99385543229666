// @ts-nocheck
import { http } from '@services'
import { Onlineshow } from '@dmi-mch/services'

const headers = { 'cache-control': 'no-cache' }

const EventsExhibition = {
  getAccountEvents: params => http.get('/msvc/v1/events/events', params, headers),
  // TODO: This should be moved into a "class" in the monorepo.
  getEventById: id => http.get(`/msvc/v1/events/event/${id}`, null, headers),
  getEventByIdWithOnlineShow: async (id) => {
    const event = await http.get(`/msvc/v1/events/event/${id}`, null, headers)
    if (event.data.onlineShowId) {
      const onlineshowService = new Onlineshow({ http })
      const onlineShow = await onlineshowService.getOnlineShowViews(event.data.onlineShowId)
      event.data.onlineShow = onlineShow.data
    }
    return event
  },
  deleteEventByID: id => http.del(`/msvc/v1/events/event/${id}`),
  updateEventStatus: (id, status) => http.put(`/msvc/v1/events/event/${id}/status`, { status }, null)
}

export default EventsExhibition
