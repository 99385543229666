import styled from 'styled-components'
import { Button } from '@mch-group/uikit-components'
import { px2rem, mq } from '@utils'
import { FC } from 'react'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const SelectorControls: FC<ISelectorControls> = ({ className, onClickReset, onChange, labels,
  resetBtnActive, buttonType = 'button', isLoading = false, disabled, fullWidthButton }) => (
    <div className={`${className} controls`}>
      {labels?.reset &&
      <span
        role='button'
        tabIndex={0}
        onClick={onClickReset}
        onKeyUp={(e) => {
          if (e?.code === 'Tab') {
            e.preventDefault()
            e.stopPropagation()
          } else {
            onClickReset(e)
          }
        }}
      >{labels.reset}
      </span>}
      <Button
        size='dynamic'
        onClick={onChange}
        type={buttonType}
        isLoading={isLoading}
        disabled={disabled}
        className={`js-trigger ${fullWidthButton ? 'fullWidthButton' : ''}`}
      >
        <span className={`label label-1 ${resetBtnActive ? 'enabled' : 'disabled'}`}>{labels.apply}</span>
      </Button>
    </div>
)

const SelectorControlsStyled = styled(SelectorControls)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  > span {
    text-transform: initial;
    font-weight: ${tokens.fontWeights.artBaselHeadline0.value};
    font-size: initial;
    ${props => props.resetBtnActive ? `
      outline: none;
      cursor: pointer;
      color: ${tokens.color.base.secondary.grey400.value};
      ` : `
      color: ${tokens.color.light.base.neutrals['800'].value};
      `}
  }

  .fullWidthButton {
    width: 100%;
  }

  button {
    > * {
      pointer-events: none;
    }

    margin-left: auto !important;
  }

  ${mq.lessThan('largestMobile')`
    padding-left: ${px2rem(16)};
    padding-right: ${px2rem(16)};
  `}
`

interface ISelectorControls {
  className?: string,
  classNameForResetButton?: string,
  value?: Array<any>,
  labels: { [labelKey: string]: string },
  onClickReset: (e) => void,
  onChange: () => void,
  resetBtnActive: boolean,
  buttonType?: 'button' | 'submit' | 'reset',
  isLoading?: boolean,
  disabled?: boolean,
  fullWidthButton?: boolean
}

export default SelectorControlsStyled
