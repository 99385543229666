import styled, { createGlobalStyle } from 'styled-components'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { cloudinary } from '@constants'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const cdnURL = (height, url) => `${uploadTransformationUrl(url, `q_auto,h_${height},dpr_auto,c_fit`, { 'transformUrl': cloudinary.throughCloudfront })}`

const getResponsiveBackground = (url) => `
  @media screen and (min-width: 992px) {
    background-image: url('${cdnURL(1800, url)}');
  }

  @media screen and (max-width: 991px) and (min-width: 768px) {
    background-image: url('${cdnURL(717, url)}');
  }

  @media screen and (max-width: 767px) and (min-width: 375px) {
    background-image: url('${cdnURL(555, url)}');
  }

  @media screen and (max-width: 374px) {
    background-image: url('${cdnURL(430, url)}');
  }
  `

export const GlobalStyle = createGlobalStyle`
  .site-modules:not(.event-detail):first-of-type {
    margin-top: 0 !important;

    section {
      margin-top: 0 !important;
    }
  }

  .site-footer {
    display: none !important;
  }
`

export const Wrapper = styled.section`
  &&& {
    margin: 0 !important;
    padding: 0 !important;
  }
`

export const GridContainer = styled.div`
  height: 100vh;
  display: grid;
  padding: 0;
  overflow: hidden;
`

export const GridItem = styled.div`
  padding: 0;
  display: flex;
  position: relative;
  background-color: ${tokens.color.light.base.neutrals['400'].value};
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  ${({ roomType }) => {
    if (roomType === 'STANDARD') {
      return `${getResponsiveBackground(`${cloudinary.UPLOAD}/v1604333265/WallView_Large_bllfxx.jpg`)}`
    }
    if (roomType === 'SMALL') {
      return `${getResponsiveBackground(`${cloudinary.UPLOAD}/v1604333265/WallView_Small_vtosq2.jpg`)};`
    }
  }}
`

export const Wall = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  top: 0;
  ${({ roomType }) => {
    if (roomType === 'STANDARD') {
      return 'height: 74.4%;'
    }
    if (roomType === 'SMALL') {
      return 'height: 81.4%;'
    }
  }}
`

export const Layer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const Layer2 = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
`

export const Layer3 = styled.div`
  text-align: center;
  display: contents;
  justify-content: center;
  align-items: center;
  height: 100%;

  a {
    display: contents;
    cursor: zoom-in;
  }
`