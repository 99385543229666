import styled, { createGlobalStyle } from 'styled-components'
import { Container, Row, Spinner } from '@mch-group/uikit-components'
import { FC } from 'react'
import { useUserAccessContext } from '@components/UserAccess/context/useUserAccessContext'

const GlobalStyle = createGlobalStyle`
  .ui.mini.modal {
    .close-icon {
      display: none;
    }
  }
`

const ModalLoadingScreen: FC = () => {
  const { state: { loadingTextForLoginModal: { text1, text2 } } } = useUserAccessContext()
  return (
    <div
      className='d-flex justify-content-center flex-nowrap'
    >
      <ModuleLayoutWrapper className='bg-white'>
        <Container>
          <Row>
            <div >
              <div
                style={{
                height: '342px'
              }}
                className='d-flex align-items-center justify-content-center mb-5 loading-wrapper'
              >
                <Spinner variant='page' theme='light' />
              </div>
              <div className='text-large text-center mt-10'>
                {text1 && <div>{text1}</div>}
                {text2 && <div>{text2}</div>}
              </div>
            </div>
          </Row>
        </Container>
      </ModuleLayoutWrapper>
      <GlobalStyle />
    </div>
  )
}

const ModuleLayoutWrapper = styled.div`
  border-radius: 4px;
  margin-top: -5px;
  height: 342px;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-wrapper {
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 767px) {
    width: 342px;
  }
`

export default ModalLoadingScreen