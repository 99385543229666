import classNames from 'classnames'
import dynamic from 'next/dynamic'

import DefaultLoadingComponent from './Loading'
import cmsModuleComponents from './_moduleMap'
import ErrorBoundary from '../ErrorBoundary'
import NoComponent from '../NoComponent'
import { FC, ReactNode } from 'react'

// Comes from app/src/components/CmsPage.tsx
const CMSModuleList: FC<ICMSModuleList> = ({ components, className, pageMetaInfo = {} }) => {
  if (!components || components.length === 0) return null
  const ComponentsList: ReactNode = components.map((module) => {
    let Component: any = null
    let ComponentProps = {}
    // If the module is not found in moduleMap, and found in the folder, use it
    Component = !cmsModuleComponents[module.moduleName] &&
      dynamic(() => import(`@modules/${module.displayPartial}`), {
        loading: DefaultLoadingComponent
      })

    // Component is empty after checking if it's in the folder, then pick from moduleMap
    if (!Component) {
      ({ Component } = cmsModuleComponents[module.moduleName])
    }

    // Component is still empty after checking all the above, just put the red box
    if (!Component) {
      Component = NoComponent
    }

    ComponentProps = {
      moduleName: module.moduleName,
      id: module.id,
      pageMetaInfo,
      // Adds the ssrData fetched in pages/index.tsx, obtained for each module, for now only News
      ssrData: module.ssrData ? module.ssrData : {},
      ...module.componentProps
    }
    return (
      <ErrorBoundary key={`err-${module.id}-${module.moduleId}`}>
        <Component {...ComponentProps} key={`${module.id}-${module.moduleId}`} />
      </ErrorBoundary >
    )
  })
  return (
    <main role='main' id='content' className={classNames('site-modules', className)}>
      {ComponentsList}
    </main>
  )
}

interface ICMSModuleList {
  components: any,
  className?: string,
  pageMetaInfo?: any
}

export default CMSModuleList
