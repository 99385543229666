import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { mq } from '@utils/'

/**
 * Main content area with animating frame
 */
const MainContent = ({ height, animationSpeed = 300, children, isOpen, isMaskText, shouldShowToggle }) => (
  <StyledMainContent
    className='AnimatedShowMore__MainContent'
    style={{
      maxHeight: `${height}px`,
      transition: `max-height ${animationSpeed}ms ease`
    }}
    isMaskText={isMaskText && !isOpen && shouldShowToggle}
  >
    {children}
  </StyledMainContent>
)

const StyledMainContent = styled.div`
  ${(props) => (props.isMaskText ? '-webkit-mask-image: linear-gradient(black 70%, transparent 100%);' : '')}
  ${mq.greaterThan('desktop')`
    overflow: 'hidden';
    transform-origin: 'center top';
  `}
`
MainContent.propTypes = {
  height: PropTypes.number,
  animationSpeed: PropTypes.number,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  isMaskText: PropTypes.bool,
  shouldShowToggle: PropTypes.bool
}

export default MainContent