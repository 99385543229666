// @ts-nocheck
import http from './_http'

// TODO: For OVR use the one from monorepo, not this one.
const Accounts = {
  getAccountById: id =>
    http.get(`/msvc/v1/accounts/items/${id}`),
  getAccounts: params => {
    if (params.cancelToken) {
      return http.get('/msvc/v1/accounts/items', params.params, { cancelToken: params.cancelToken })
    }
    return http.get('/msvc/v1/accounts/items', params)
  }
}

export default Accounts
