import React, { FC, useContext } from 'react'
import styled from 'styled-components'
import { MediaCaption, Container, Row, Col } from '@mch-group/uikit-components'
import { Media, MediaContextProvider } from '@responsive'
import { Text } from '@components'
import { useEditorialModule } from '@hooks'
import IEImageText from '@modules/EMImageText/types'
import { PageContext } from '@providers/PageContext'
import classNames from 'classnames'

const EMImageText: FC<IEImageText> = ({
  id,
  emit_image_upload,
  emit_image_caption,
  emit_image_title,
  emit_image_description
}) => {
  const { getImageUrl, ar } = useEditorialModule()
  const { page: { siteLanguage } } = useContext(PageContext)
  return (
    <section
      className={classNames('em-image-text', siteLanguage === 'zh_CN' ?
        'mb-7 mb-md-8' : 'mb-9 mb-md-12')}
      id={id}>
      {/* @ts-ignore */}
      <MediaContextProvider>
        {/* @ts-ignore */}
        <Media at='xs'>
          <Row>
            <Col xs={{ span: 12, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 0 }}>
              {emit_image_upload && (
                <StyledMediaCaption ar={ar(emit_image_upload)}>
                  <MediaCaption
                    urlImage={getImageUrl(emit_image_upload, 397, '')}
                    caption={!!emit_image_caption}
                    captionText={emit_image_caption}
                    imageAltText={emit_image_title || emit_image_caption}
                    className='mb-0'
                    fullWidth
                    media='image'
                  />
                </StyledMediaCaption>
              )}
            </Col>
          </Row>
          <Container className='px-5 px-lg-8 px-md-7 mt-9'>
            <Row>
              <Col xs={{ span: 12, offset: 0 }}>
                <h3 className='editorial-heading-h4 mb-3'>
                  {emit_image_title}
                </h3>
                <Text isHtml>{emit_image_description}</Text>
              </Col>
            </Row>
          </Container>
        </Media>
        {/* @ts-ignore */}
        <Media at='sm'>
          <Row>
            <Col xs={{ span: 12, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 0 }}>
              {emit_image_upload && (
                <StyledMediaCaption ar={ar(emit_image_upload)}>
                  <MediaCaption
                    urlImage={getImageUrl(emit_image_upload, 752, '')}
                    caption={!!emit_image_caption}
                    captionText={emit_image_caption}
                    imageAltText={emit_image_title || emit_image_caption}
                    className='mb-0'
                    fullWidth
                    media='image'
                  />
                </StyledMediaCaption>
              )}
            </Col>
          </Row>
          <Container className='px-5 px-lg-8 px-md-7 mt-9'>
            <Row>
              <Col
                xs={{ span: 12, offset: 0 }}
                md={{ span: 8, offset: 2 }}
                lg={{ span: 4, offset: 0 }}
                className='d-flex flex-column justify-content-center mt-0'
              >
                <h3 className='editorial-heading-h4 mb-3'>
                  {emit_image_title}
                </h3>
                <Text isHtml>{emit_image_description}</Text>
              </Col>
            </Row>
          </Container>
        </Media>
        <Media greaterThanOrEqual='md'>
          <Container className='px-5 px-lg-7 px-xl-8 px-md-7 mt-0'>
            <Row gutter='g-lg-7 g-xl-7 g-xxl-8'>
              <Col xs={{ span: 12, offset: 0 }} md={{ span: 11, offset: 0 }} lg={{ span: 7, offset: 0 }}>
                {emit_image_upload && (
                  <StyledMediaCaption ar={ar(emit_image_upload)}>
                    <MediaCaption
                      urlImage={getImageUrl(emit_image_upload, 878, '')}
                      caption={!!emit_image_caption}
                      captionText={emit_image_caption}
                      imageAltText={emit_image_title || emit_image_caption}
                      className='mb-0'
                      fullWidth={false}
                      media='image'
                    />
                  </StyledMediaCaption>
                )}
              </Col>
              <Col
                xs={{ span: 12, offset: 0 }}
                md={{ span: 8, offset: 2 }}
                lg={{ span: 4, offset: 0 }}
                className='d-flex flex-column justify-content-center'
              >
                <h3 className='editorial-heading-h4 mt-9 mt-0 mb-3'>
                  {emit_image_title}
                </h3>
                <Text isHtml>{emit_image_description}</Text>
              </Col>
            </Row>
          </Container>
        </Media>
      </MediaContextProvider>
    </section>
  )
}

interface Props {
  ar: string | null
}

const StyledMediaCaption = styled.div`
  figure,
  img {
    aspect-ratio: ${(props: Props) => (props.ar ? props.ar : 'inherit')};
  }

  img {
    display: flex;
  }
`

export default EMImageText
