import { createGlobalStyle } from 'styled-components'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const Colors = createGlobalStyle`
    /* -------------------
  Site Colors
  https://www.figma.com/file/L3mQZ4aIzMPDu4DW9hjaXY/MCH-Design-System?node-id=0%3A4289
-------------------- */

    .color-black {
      color: var(--bs-mch-black) !important;
    }

    .bg-black {
      background-color: var(--bs-mch-black) !important;
    }

    .color-darkgrey {
      color: var(--bs-mch-gray-500) !important;
    }

    .bg-darkgrey {
      background-color: var(--bs-mch-gray-500) !important;
    }

    .color-textgrey {
      color: var(--bs-mch-gray-400) !important;
    }

    .bg-textgrey {
      background-color: var(--bs-mch-gray-400) !important;
    }

    .color-grey {
      color: ${tokens.color.light.base.neutrals['500'].value} !important;
    }

    .bg-grey {
      background-color: ${tokens.color.light.base.neutrals['500'].value} !important;
    }

    .color-artworkgrey {
      color: ${tokens.color.light.base.neutrals['500'].value} !important;
    }

    .bg-artworkgrey {
      background-color: ${tokens.color.light.base.neutrals['500'].value} !important;
    }

    .color-artworklightgrey {
      color: var(--bs-mch-galleries) !important;
    }

    .bg-artworklightgrey {
      background-color: var(--bs-mch-galleries) !important;
    }

    .color-artworkverylightgrey {
      color: var(--bs-mch-gray-100) !important;
    }

    .bg-artworkverylightgrey {
      background-color: var(--bs-mch-gray-100) !important;
    }

    .color-hkmagenta {
      color: var(--bs-mch-hongkong) !important;
    }

    .bg-hkmagenta {
      background-color: var(--bs-mch-hongkong) !important;
    }

    .color-baselblue {
      color: var(--bs-mch-basel) !important;
    }

    .bg-baselblue {
      background-color: var(--bs-mch-basel) !important;
    }

    .color-miamigreen {
      color: var(--bs-mch-miami) !important;
    }

    .bg-miamigreen {
      background-color: var(--bs-mch-miami) !important;
    }

    .color-orange {
      color: var(--bs-mch-orange) !important;
    }

    .bg-orange {
      background-color: var(--bs-mch-orange) !important;
    }

    .color-red {
      color: var(--bs-mch-text-red) !important;
    }

    .bg-red {
      background-color: var(--bs-mch-text-red) !important;
    }

    .color-icongreen {
      color: var(--bs-mch-green) !important;
    }

    .bg-icongreen {
      background-color: var(--bs-mch-green) !important;
    }

    .color-textorange {
      color: var(--bs-mch-text-orange) !important;
    }

    .bg-textorange {
      background-color: var(--bs-mch-text-orange) !important;
    }

    .color-textred {
      color: var(--bs-mch-text-red) !important;
    }

    .bg-textred {
      background-color: var(--bs-mch-text-red) !important;
    }

    .color-textgreen {
      color: var(--bs-mch-light-success) !important;
    }

    .bg-textgreen {
      background-color: var(--bs-mch-light-success) !important;
    }

    .color-textwhite {
      color: var(--bs-mch-white) !important;
    }

    .bg-textwhite {
      background-color: var(--bs-mch-white) !important;
    }

    /* sectors */
    .color-dialogues {
      color: var(--bs-mch-dialoges) !important;
    }

    .bg-dialogues {
      background-color: var(--bs-mch-dialoges) !important;
    }

    .color-discoveries {
      color: var(--bs-mch-discoveries) !important;
    }

    .bg-discoveries {
      background-color: var(--bs-mch-discoveries) !important;
    }

    .color-edition {
      color: var(--bs-mch-edition) !important;
    }

    .bg-edition {
      background-color: var(--bs-mch-edition) !important;
    }

    .color-encounters {
      color: var(--bs-mch-encounters) !important;
    }

    .bg-encounters {
      background-color: var(--bs-mch-encounters) !important;
    }

    .color-feature {
      color: var(--bs-mch-feature) !important;
    }

    .bg-feature {
      background-color: var(--bs-mch-feature) !important;
    }

    .color-film {
      color: var(--bs-mch-film) !important;
    }

    .bg-film {
      background-color: var(--bs-mch-film) !important;
    }

    .color-galleries {
      color: var(--bs-mch-galleries) !important;
    }

    .bg-galleries {
      background-color: var(--bs-mch-galleries) !important;
    }

    .color-insights {
      color: var(--bs-mch-insights) !important;
    }

    .bg-insights {
      background-color: var(--bs-mch-insights) !important;
    }

    .color-kabinett {
      color: var(--bs-mch-kabinett) !important;
    }

    .bg-kabinett {
      background-color: var(--bs-mch-kabinett) !important;
    }

    .color-meridians {
      color: var(--bs-mch-meridians) !important;
    }

    .bg-meridians {
      background-color: var(--bs-mch-meridians) !important;
    }

    .color-nova {
      color: var(--bs-mch-nova) !important;
    }

    .bg-nova {
      background-color: var(--bs-mch-nova) !important;
    }

    .color-parcours {
      color: var(--bs-mch-parcour) !important;
    }

    .bg-parcours {
      background-color: var(--bs-mch-parcour) !important;
    }

    .color-positions {
      color: var(--bs-mch-positions) !important;
    }

    .bg-positions {
      background-color: var(--bs-mch-positions) !important;
    }

    .color-public {
      color: var(--bs-mch-public) !important;
    }

    .bg-public {
      background-color: var(--bs-mch-public) !important;
    }

    .color-statements {
      color: var(-bs-mch-statements) !important;
    }

    .bg-statements {
      background-color: var(-bs-mch-statements) !important;
    }

    .color-survey {
      color: var(--bs-mch-survey) !important;
    }

    .bg-survey {
      background-color: var(--bs-mch-survey) !important;
    }

    .color-unlimited {
      color: var (--bs-mch-unlimited) !important;
    }

    .bg-unlimited {
      background-color: var(--bs-mch-unlimited) !important;
    }
  `

export default Colors
