import React, { FC, useState } from 'react'
import UserAccessModule from '@components/UserAccess/UserAccessModule'
import { Modal } from '@mch-group/uikit-components'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { getSiteLanguage } from '@utils/storage'

import styled from 'styled-components'
import UserAccessProvider from '@components/UserAccess/provider/UserAccessProvider'
interface ILoginModal {
  onCloseModal: () => void,
  loginFormCallback: (me: ABTypes.Userprofiles.User) => void,
  isHideCloseIcon: boolean
}

const LoginModal: FC<ILoginModal> = props => {
  const { labels } = useLabelsFromAPI(['Access'], getSiteLanguage())
  const [modalData, setModalData] = useState({ title: '', subTitle: '', backText: '', handleBackBtnClick: () => {} })

  React.useEffect(() => {
    if(labels) {
      setModalData({ ...modalData, title: labels?.Access.logInLabel })
    }
    // TODO: Remove array dependencies here, React behavior shouldn't be hacked .
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[labels])

  if(!labels) return null

  return (
    <>
      <StyledModal
        show
        wizard
        onHide={() => {props.onCloseModal()}}
        className='mt-6 mt-md-0'
        backdrop='static'
      >
        {modalData.title &&
          <Modal.Header
            wizard
            title={modalData.title}
            subline={modalData?.subTitle || ''}
            backButton={!!modalData.backText}
            backButtonText={modalData.backText}
            onBackButtonClick={()=>{modalData.handleBackBtnClick()}}
            onHide={props.onCloseModal}
          />
        }
        <Modal.Body>
          <UserAccessProvider
            isModal
            callback={props.loginFormCallback}
            onCloseLoginModal={props.onCloseModal}
            setModalData={setModalData}
            {...props}
          >
            <div className='no-margin-top pb-1'>
              <UserAccessModule />
            </div>
          </UserAccessProvider>
        </Modal.Body>
      </StyledModal>
    </>
  )
}

const StyledModal = styled(Modal)<any>`
  &.modal.show {
    overflow-y: scroll !important;
  }

  &.loading-screen-modal {
    .modal-content {
      padding: 0 !important;
    }
  }
`

export default LoginModal