/*
1. IMPORTNT: ONLY PUBLIC DATA CAN GO HERE
2. TODO: assets for Paris+ will be provided in MCHGB-12010
*/

export const metadata = {
  artbasel: {
    general: {
      host: 'artbasel.com',
      slug: 'artbasel'
    },
    og: {
      sitename: 'Art Basel',
      title: 'Art Basel',
      image: 'https://res.cloudinary.com/dqzqcuqf9/image/upload/v1546932493/ab-og-image.png'
    },
    twitter: {
      title: 'Artbasel',
      site: '@artbasel',
      image: 'https://res.cloudinary.com/dqzqcuqf9/image/upload/v1546932493/ab-og-image.png'
    },
    google: {
      siteVerification: getEnvConfig?.fe?.verificationMetaTag?.google?.id,
      gtm: getEnvConfig?.fe?.analytics ? getEnvConfig?.fe?.analytics['google-gtm']?.id : ''
    },
    microsoft: {
      bing: getEnvConfig?.fe?.verificationMetaTag?.bing?.id,
      tileImage: 'mstile-150x150.png'
    },
    facebook: {
      appId: getEnvConfig?.fe?.verificationMetaTag?.facebook?.id,
      pixelId: getEnvConfig?.fe?.analytics ? getEnvConfig?.fe?.analytics['facebook-fbPixel']?.id : '',
      domainVerification: null
    },
    favicon: {
      ico: 'favicon.ico',
      size144: 'apple-touch-icon-144x144.png',
      size128: 'favicon-128.png',
      size114: 'apple-touch-icon-114x114.png',
      size96: 'favicon-96x96.png',
      size72: 'apple-touch-icon-72x72.png',
      size57: 'apple-touch-icon-57x57.png',
      appleTouchIcon: 'smartBannerIcon.png',
      androidTouchIcon: 'smartBannerIcon.png'
    }
  }
}

export default {}