import qs from 'qs'

export const getAspectRatioPercent = (aspectRatio) =>
  typeof aspectRatio === 'number' ? aspectRatio : aspectRatio.split(':').reduce((a, b) => b / a)

export const detectDpr = (removeStr) => {
  let dpr = typeof window !== 'undefined' ? window.devicePixelRatio : 1
  if (dpr <= 1) {
    dpr = 'dpr_1.0'
  } else if (dpr > 1 && dpr <= 2) {
    dpr = 'dpr_2.0'
  } else if (dpr > 2 && dpr <= 3) {
    dpr = 'dpr_3.0'
  } else {
    dpr = 'dpr_auto'
  }
  return removeStr ? dpr.replace(removeStr, '') : dpr
}

export const detectAspectRatio = (emsm_image_upload = '', input = 'qs', output = 'inline') => {
  let imageUrl = ''
  let ar = ''
  let delimiter = ''
  // Greatest Common Divisor
  const gcd = (a, b) => (b == 0 ? a : gcd(b, a % b))

  if (emsm_image_upload) {
    // If input is a URL with "?w=1800&h=1000" format
    if (input === 'qs') {
      imageUrl = emsm_image_upload.split('?')[1]
      imageUrl = qs.parse(imageUrl)
      const w = imageUrl.w
      const h = imageUrl.h
      const r = w && h && gcd(w, h)

      // if you want to output aspect ratio for inline styles
      if (output === 'inline') {
        delimiter = '/'
      }

      // if you want to output the aspect ratio in cloudinary format
      if (output === 'cloudinary') {
        delimiter = ':'
      }

      ar = `${w / r}${delimiter}${h / r}` // e.g. 16:9
    }
  }
  return ar
}

export const roundImageDimension = (imageSize, roundNumber) => {
  let defaultRound = Math.ceil(imageSize / roundNumber) * roundNumber
  if (defaultRound <= roundNumber) {
    defaultRound = roundNumber
  }
  return defaultRound
}

export default { getAspectRatioPercent }
