const getFingerprint = async () => {
  let key = null
  if (typeof FingerprintJS === 'object') {
    return FingerprintJS.load().then(fp => fp.get().then(result => {
      if (result && result.visitorId) {
        return result.visitorId
      }
    }))
  }
  return key
}

export default getFingerprint