import React from 'react'
import classNames from 'classnames'
import NProgressBar from '../NProgressBar'
import Header from '../headers/DefaultHeader/Megamenu'
import Footer from '../Footer'
import Overlay from '../Overlay'
import Head from '../Head'
import ErrorBoundary from '../ErrorBoundary'
import ScrollToHash from '../ScrollToHash'
import PageNotAvailableForMobile from '../Error/PageNotAvailableForMobile'
import { LayoutStyles } from '../../styles/Global.styles'
import useLayout from './useLayout'
import { AppProps } from 'next/app'
import type IAppProps from 'types/IAppProps'

export default function MyApp({ Component, pageProps }: AppProps<IAppProps>) {
  // NO LOGIC HERE PLEASE, PURE VIEW. ALL LOGIC IN ./useLayout
  // Importing vars from hook
  const {
    siteLanguage,
    fromWebView,
    showInRoom,
    onlineShowId,
    isFirstModuleVideoPlayer,
    isCatalogDetail,
    pageHasMenu,
    onlyForLargeScreens,
    hideHeader,
    hideFooter,
    layout,
    SmartBanner,
    ColorsStyledComponent,
    SpacingsStyledComponent
  } = useLayout()

  return (
    <>
      <div
        className={classNames(`mfp-site ${siteLanguage ? siteLanguage?.toLowerCase() : ''}`, {
          'is-mobile-web-view': fromWebView
        })}
      >
        {!showInRoom && !onlineShowId && !hideHeader && (
          <ErrorBoundary key='site-main-header'>
            <Header />
          </ErrorBoundary>
        )}
        <NProgressBar />
        <div
          id='prio'
          className={classNames('prio', 'mfp-app-layout', {
            'page-has-video-as-title': isFirstModuleVideoPlayer,
            /* TODO REVIEW THIS LINE COMMENTED, GUESS IT'S CORRECT ISCATALOGDETAIL */
            'page-has-menu-component': isCatalogDetail || pageHasMenu,
            'from-web-view': fromWebView,
            'page-only-desktop': onlyForLargeScreens,
            'no-header': hideHeader,
            'no-footer': hideFooter,
            'is-dashboard-page': layout === 'DASHBOARD'
          })}
        >
          {!fromWebView && <SmartBanner />}
          <Head />
          <ColorsStyledComponent />
          <SpacingsStyledComponent />
          <ScrollToHash />
          {/**
           * This element here can be used as an anchor, for example,
           * sticky elements in the DOM with ReactDom.createPortal()
           * And then it can be styled with styled-components createGlobalStyle
           * */}
          <Component {...pageProps} />
          {onlyForLargeScreens && <PageNotAvailableForMobile />}

          {!hideFooter && (
            <ErrorBoundary key='site-main-footer'>
              <Footer />
            </ErrorBoundary>
          )}

          <Overlay />
        </div>
      </div>
      <LayoutStyles />
      <div id='portal-target' />
    </>
  )
}