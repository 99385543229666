import { Tag } from '@mch-group/uikit-components'

export const findListItemByKeyValue = (id, list, key, value) => {
  let res = null
  if (list && list.length > 0) {
    res = list.find((i) => i[key] === id?.toString())
    if (res) {
      res = res[value] || null
    }
  }
  return res
}

export const isApproved = status => [102900003, 102900006].includes(status)
export const isSubmitted = status => status === 102900002
export const isOpen = status => status === 102900000
export const isInProgress = status => status === 102900001
export const isIncomplete = status => [102900004, 102900007, 102900008].includes(status)
export const isNotSuccessfull = status => [102900005, 102900009].includes(status)

export const isFormVisible = (permanent, status) => {
  if (permanent) {
    if (isNotSuccessfull(status)) {
      return false
    }
    return true
  } else if (!permanent) {
    if (isSubmitted(status) || isNotSuccessfull(status)) {
      return false
    } else if (isOpen(status) || isInProgress(status) || isIncomplete(status)) {
      return true
    }
  }
  return false
}

export const isDocsSubmittedEnabled = (status, permanent) =>
  !permanent && (isSubmitted(status) || isNotSuccessfull(status) || isApproved(status))

export const isValidProfile = (data) => {
  if (!data?.email || !data?.telephone || !data?.companyName || !data?.mediaFunction
  || !data?.mediaType) {
    return false
  }
  return true
}

export const getLabelStatus = (value): { type: React.ComponentProps<typeof Tag>['type'], label: string } => {
  if (value === 102900000) {
    return {
      type: 'neutral',
      label: 'mpaa_open'
    }
  } else if (value === 102900001) {
    return {
      type: 'neutral',
      label: 'mpaa_in_progress'
    }
  } else if (value === 102900002) {
    return {
      type: 'neutral',
      label: 'mpaa_submitted'
    }
  } else if ([102900003, 102900006].includes(value)) {
    return {
      type: 'success',
      label: 'mpaa_approved'
    }
  } else if ([102900004, 102900007, 102900008].includes(value)) {
    return {
      type: 'alert',
      label: 'mpaa_incomplete'
    }
  } else if ([102900005, 102900009].includes(value)) {
    return {
      type: 'error',
      label: 'mpaa_not_successful'
    }
  } else {
    return {
      type: 'alert',
      label: 'mpaa_incomplete'
    }
  }
}

export const labelProps = (props) => Object.keys({ ...props }).filter((prop) => prop.includes('mpaa_'))

export const getShowPdf = (show: string, document:string, type: string) => {
  let showKey = ''
  if (show.indexOf('Paris') > -1) {
    showKey = 'paris'
  } else if (show.indexOf('Miami Beach') > -1) {
    showKey = 'miami_beach'
  } else if (show.indexOf('Hong Kong') > -1) {
    showKey = 'hongkong'
  } else {
    showKey = 'basel'
  }

  if (document === 'termsconditions') {
    if (type === 'url') {
      return `mpaa_terms_and_conditions_pdf_${showKey}`
    }
    return 'mpaa_terms_and_conditions_pdf_title'
  } else {
    if (type === 'url') {
      return `mpaa_acc_guidelines_pdf_${showKey}`
    }
    return 'mpaa_acc_guidelines_pdf_title'
  }
}

export {
}