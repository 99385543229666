import getProperty from './getProperty'

export const getTranslationByName = (labels, language, entityId, fieldName) => {
  if (Array.isArray(labels)) { // old format. An array of objects with entityType, entityId, value
    const filteredObject = labels.filter(label =>
      (language && label.key.localeCode.toLowerCase() === language.toLowerCase()) &&
      label.key.fieldName === fieldName &&
      label.key.entityId === entityId)
    return filteredObject.length > 0 ? filteredObject[0].value : null
  } else { // New format. Direct tree object with the keys->value.
    return getProperty(labels, ['Label', entityId, fieldName])
  }
}

export default getTranslationByName
