import * as Yup from 'yup'

const castDefaults = (currentValue, originalValue, schema) =>
  ((typeof originalValue !== 'object' || originalValue === null) ? schema.cast({}) : currentValue)

const cmsModuleDataSchema = Yup.object({
  id: Yup.number(),
  accessGroups: Yup.array().strip(),
  displayPartial: Yup.string().strip(),
  name: Yup.string(),
  moduleId: Yup.number(),
  moduleName: Yup.string(),
  globalFlag: Yup.bool().strip(),
  offlineFlag: Yup.string().strip(),
  templateFlag: Yup.string().strip(),
  fieldsList: Yup.array()
    .of(Yup.object({
      key: Yup.string(),
      order: Yup.number().default(0),
      values: Yup.array().default([])
    })).default([])
})

/* /msvc/v1/pages */
const pageDataSchema = Yup.object().shape({
  id: Yup.number(),
  pageType: Yup.string()
    .strip(),
  internalTitle: Yup.string()
    .strip(),
  externalTitle: Yup.string()
    .default(''),
  dateCreated: Yup.string()
    .strip(),
  dateUpdated: Yup.string()
    .strip(),
  schedule: Yup.string()
    .strip(),
  description: Yup.string(),
  imageUrl: Yup.string(),
  openGraphData: Yup.object({
    title: Yup.string().default(null),
    description: Yup.string(),
    imageUrl: Yup.string().default(null)
  }),
  accessGroups: Yup.array()
    .strip(),
  displayTemplate: Yup.string()
    .strip(),
  information: Yup.string()
    .strip(),
  modules: Yup.array()
    .of(cmsModuleDataSchema)
    .default([])
})

export const pageSchema = Yup.object({
  data: pageDataSchema.transform((current, original) => castDefaults(current, original, pageDataSchema))
})

/* /msvc/v1/pages/globalModules */
const globalModulesDataSchema = Yup.object().shape({
  footerModule: cmsModuleDataSchema,
  validationMessageModule: cmsModuleDataSchema,
  labelModule: cmsModuleDataSchema,
  generalNotificationModule: cmsModuleDataSchema,
  newsletterModule: cmsModuleDataSchema
})

export const globalModulesSchema = Yup.object({
  data: globalModulesDataSchema.transform((current, original) =>
    castDefaults(current, original, globalModulesDataSchema))
})

const navigationItemSchema = Yup.object({
  name: Yup.string(),
  url: Yup.string()
})

export const primaryNavigationSchema = Yup.object({
  data: navigationItemSchema.transform((current, original) =>
    castDefaults(current, original, navigationItemSchema))
})

/* =============================== */

export default {
  pageSchema
}
