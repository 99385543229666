import { createContext, useContext } from 'react'

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_NEXT_STEP':
      return {
        ...state,
        nextStep: action.payload
      }
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload
    }
    case 'SET_API_ERROR_MESSAGE':
      return {
        ...state,
        apiErrorMessage: action.payload
    }
    case 'SET_REDEEM_KEY_ERROR_MESSAGE':
      return {
        ...state,
        redeemKeyErrorMessage: action.payload
    }
    case 'SET_LABELS':
      return {
        ...state,
        labels: action.payload
    }
    case 'SET_LOADING_TEXT_FOR_LOGIN_MODAL':
      return {
        ...state,
        loadingTextForLoginModal: action.payload
    }
    default:
      return { ...state }
  }
}

interface IOtherData {
  isModal?: boolean,
  callback?: Function,
  onCloseLoginModal?: Function,
  setModalData?: React.Dispatch<React.SetStateAction<{
    title: string;
    subTitle?: string;
    backText: string;
    handleBackBtnClick?: Function
}>>
}

interface IloadingTextForLoginModal {
  text1?: string,
  text2?: string
}
interface InitialStateProps {
  nextStep: any,
  isLoading: boolean,
  apiErrorMessage: string,
  labels: any,
  redeemKeyErrorMessage: string,
  defaultRedirectUrl: string,
  loginAccessPageUrl: string,
  isDisableFullPageLoadingSpinner: boolean,
  resendLoginLinkRef: any,
  resendResetPasswordLinkRef: any,
  otherData: IOtherData,
  loadingTextForLoginModal: IloadingTextForLoginModal
}

export const StateContext = createContext<{
  state: InitialStateProps;
  dispatch: React.Dispatch<any>;
}>({
  state: {
    nextStep: null,
    isLoading: false,
    apiErrorMessage: '',
    labels: null,
    redeemKeyErrorMessage: '',
    resendLoginLinkRef: null,
    resendResetPasswordLinkRef: null,
    defaultRedirectUrl: '',
    loginAccessPageUrl: '',
    isDisableFullPageLoadingSpinner: false,
    otherData: {},
    loadingTextForLoginModal: {}
  },
  dispatch: () => null
})

export const useUserAccessContext = () => useContext(StateContext)
