import { useContext } from 'react'
import PropTypes from 'prop-types'
import { getSiteLanguage } from '@utils/storage'
import { RoomCard } from '@mch-group/uikit-components'
import { formatRoomLink, getEventImage } from '@utils'
import { LinkWithBackNavigation } from '@components'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { AddToCollectionContext } from '@components/collections/AddToCollection'
import prepareSectorData from '../../../utils/prepareSectorData'

const RoomCardItem = ({
  item,
  isSelectedInCollections,
  openingSoon,
  callbackToReloadShow,
  isOrganizeMode,
  isCollectionCard,
  onSelectCard,
  theme,
  onRoomCardClick,
  unavailableToView,
  entityId,
  isRecommendationCard,
  navigationKey
}) => {
  const {
    hybridShow,
    eventImage,
    alreadyVisited,
    mainSectors,
    additionalSectors,
    id,
    cleanTitle,
    openingDateTimes,
    curator,
    ownerAccount,
    shortParagraphText,
    onlineShow,
    title
  } = item || {}
  const sectors = mainSectors && additionalSectors && [...mainSectors, ...additionalSectors]
  const { showAddToCollectionModal } = useContext(AddToCollectionContext)
  const { labels: filteredLabels } = useLabelsFromAPI(['OnlineShowRoom', 'Collections', 'AltTags'], getSiteLanguage())

  return (
    <div className='mb-5 mb-lg-0 mb-xl-3 d-flex w-100'>
      <RoomCard
        type={hybridShow || item?.extraInfo2 ? 'hybrid' : 'freestanding'}
        imageUrl={getEventImage({
          hybridShow,
          eventImage: eventImage ?? item?.image,
          images: item?.images,
          imageType: 'main-mobile',
          size: 'square'
        })}
        title={title ?? item?.name}
        visited={{
          active: alreadyVisited,
          label: filteredLabels?.OnlineShowRoom?.ovrVisitedRoomLabel
        }}
        theme={theme}
        roomLink={formatRoomLink(id, cleanTitle, openingDateTimes?.length ? openingDateTimes[0].id : null)}
        a11yData={{
          addBtn: `${filteredLabels?.AltTags?.addToCollection || 'Add to a collection'}`,
          linkTitle: `${filteredLabels?.AltTags?.generalViewRoom || 'View the Room'}`,
          ariaLabel: `${`${filteredLabels?.AltTags?.generalViewRoom}:` || 'View the Room:'}`
        }}
        linkRenderer={(link, children, linkTitle, ariaLabel) => (
          <LinkWithBackNavigation
            passHref
            as={link}
            href={`/?slug=${link}`}
            navigationKey={isRecommendationCard ? '' : navigationKey}
            extraAction={onRoomCardClick}
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a aria-label={ariaLabel} title={linkTitle}>{children}</a>
          </LinkWithBackNavigation>
        )}
        sectorsData={prepareSectorData(sectors || item?.extraInfo2, item?.extraInfo8)}
        openingSoon={{
          active: openingSoon,
          label: filteredLabels?.OnlineShowRoom?.showComingSoonLabel
        }}
        curator={{
          active: curator,
          label: `${filteredLabels?.OnlineShowRoom?.curatorPickLabel} | ${curator?.fullName}`
        }}
        ownerAccount={ownerAccount?.name || item?.extraInfo1}
        selectMode={{
          active: isOrganizeMode,
          checked: isSelectedInCollections,
          onChange: () => {
            isOrganizeMode && onSelectCard(id ? id : entityId, '4c')
          },
          disabled: false
        }}
        collection={{
          active: isCollectionCard,
          onClick: () => {
            showAddToCollectionModal(id, '4c', undefined, callbackToReloadShow)
          }
        }}
        unavailableToView={{
          active: unavailableToView,
          label: filteredLabels?.Collections?.notAvailableForViewLabel
        }}
        show={{
          active: isCollectionCard,
          label: onlineShow ? `${onlineShow?.name} ${onlineShow?.year ? `(${onlineShow?.year})` : ''}`
            : `${item?.extraInfo9} ${item?.extraInfo16 ? `(${item?.extraInfo16})` : ''}`
        }}
        // the name is misleading but the prop means the teaser description of room
        collaboratedAccounts={shortParagraphText || item?.description}
      />
    </div>
  )
}
RoomCardItem.propTypes = {
  isSelectedInCollections: PropTypes.bool,
  item: PropTypes.object,
  openingSoon: PropTypes.bool,
  callbackToReloadShow: PropTypes.func,
  isOrganizeMode: PropTypes.bool,
  showAddToCollection: PropTypes.bool,
  theme: PropTypes.string,
  isCollectionCard: PropTypes.bool,
  onSelectCard: PropTypes.func,
  onRoomCardClick: PropTypes.func,
  unavailableToView: PropTypes.bool,
  entityId: PropTypes.string,
  isRecommendationCard: PropTypes.bool
}

export default RoomCardItem
