import TwoImages from './TwoImages'
import ThreeImagesDynamic from './ThreeImagesDynamic'
import ThreeImagesStructured from './ThreeImagesStructured'
import FourImages from './FourImages'

export {
  TwoImages,
  ThreeImagesDynamic,
  ThreeImagesStructured,
  FourImages
}
