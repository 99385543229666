import React from 'react'

import { Cms as CmsService, Translations as TranslationsService } from '../services'
import { setGlobalModules, setServerDate, setDomain, setPageRefresh, setUrl, setLabels } from '../stores/shared'
import { globalModulesSchema } from '../constants/schemas'
import { logger } from '../utils'
import { detectSiteLanguage } from '@utils'

const withCoreData = App =>
  (class extends React.Component {
    static displayName = 'withCoreData(CoreData)'

    static async getInitialProps(ctx) {
      const {
        ctx: { req, res, reduxStore }
      } = ctx
      const { siteLanguage } = detectSiteLanguage(req)
      let existLabels = ctx.ctx.reduxStore.getState().shared.labels
      let existGlobalModules = !ctx.ctx.reduxStore.getState().shared.globalModules.data

      let globalModuleData = {}

      try {
        const [getGlobals, getLabels] = await Promise.all([
          !existGlobalModules && CmsService.getGlobalModules(siteLanguage),
          !existLabels && TranslationsService.getWebsiteLabelValues(siteLanguage)
        ])
        /* Validate API JSON data and filter (on API fail it returns safe object) */
        /* Header, Secondary Menu and Footer data */
        if (!existGlobalModules) {
          globalModuleData = globalModulesSchema.cast(getGlobals)
          // Send API responses to Redux reduxStore
          // @ts-ignore
          if ('data' in globalModuleData && globalModuleData.ok) {
            try {
              // @ts-ignore
              reduxStore.dispatch(setGlobalModules(globalModuleData.data))
              // @ts-ignore
              if (globalModuleData && 'headers' in globalModuleData && 'date' in globalModuleData.headers) {
                // @ts-ignore
                reduxStore.dispatch(setServerDate(globalModuleData.headers.date))
              }

            } catch (e) {
              logger('CRITICAL! GLOBAL MODULE FETCH FAILED!')
            }
          }
        }

        if (req && 'headers' in req && 'host' in req.headers) {
          reduxStore.dispatch(setDomain(req ? req.headers.host : ''))
        }

        if (req && 'url' in req) {
          reduxStore.dispatch(setUrl(req.url))
        }
        // @ts-ignore
        if (!existLabels && 'data' in getLabels && getLabels.ok) {
          try {
            reduxStore.dispatch(setLabels(getLabels.data))
            reduxStore.dispatch(setPageRefresh(true))
          } catch (e) {
            logger('CRITICAL! LABELS FETCH FAILED!')
            logger(e)
          }
        } else {
          reduxStore.dispatch(setPageRefresh(false))
        }
      } catch (e) {
        logger(e)
        if (res) {
          res.statusCode = e.response ? e.response.status : 500
        }
      }

      return {
        ...(App.getInitialProps ? await App.getInitialProps(ctx) : {})
      }
    }

    render() {
      return <App {...this.props} />
    }
  })

  export default withCoreData
