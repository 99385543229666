import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'
import { Loader } from '@components'

import { px2rem, mq } from '@utils'
import CardVisibleState from '@components/collections/CardVisibleState'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import useCollectionUtils from '@modules/collections/hooks/useCollectionUtils'
import IntersectionCapture from '@reusable-modules/IntersectionCapture'
import TabContentHeader from '@components/collections/edit-preview-collection/Tabs/TabContentHeader'
import GalleryItem from '@modules/Catalog/Galleries/GalleryItem'

const GalleriesTab = (props) => {
  const {
    editPreviewCollectionItems,
    totalItems,
    isRefreshing,
    isEmpty,
    isLoadingInitialData,
    editPreviewCollectionItemsValidating,
    setSize,
    isLoadingMore,
    size,
    isTabExist
  } = props

  const [{ labels, isOrganizeMode, selectedItems }] = useMyCollectionsPageContext()
  const { onSelectCard } = useCollectionUtils()
  const isIntersectionActive =
    editPreviewCollectionItems &&
    !editPreviewCollectionItemsValidating &&
    !isRefreshing &&
    !isEmpty &&
    !isLoadingInitialData &&
    !isLoadingMore

  return (
    <div>
      <TabContentHeader
        total={totalItems}
        counterSubText={labels?.Collections.galleriesSubHeadingLabel}
        isTabExist={isTabExist}
      />
      {editPreviewCollectionItems.length > 0 ? (
        <>
          <Grid padded='vertically'>
            <GridRowStyled>
              {editPreviewCollectionItems.map((event) => {
                const hasGalleryNotAvailable = Object.values(event.status).some((item) => item === true)
                return (
                  <GridColumnStyled mobile={6} computer={3} key={event.id}>
                    {!hasGalleryNotAvailable ? (
                      <Grid.Row>
                        <GalleryItem
                          {...event.item}
                          isOrganizeMode={isOrganizeMode}
                          showShowsData={false}
                          onSelectCard={onSelectCard}
                          labels={{
                            imageAlt: labels?.AltTags?.generalViewGallery,
                            addBtn: labels?.AltTags?.addToCollection,
                            ariaLabel: labels?.AltTags?.generalReadMoreAbout
                          }}
                          isSelectedInCollections={
                            typeof selectedItems?.find(
                              (x) => x.entityId?.toString() === (event?.id?.toString() || event?.entityId?.toString())
                            ) !== 'undefined'
                          }
                        />
                      </Grid.Row>
                    ) : (
                      <div>
                        <CardVisibleState
                          isOrganizeMode={isOrganizeMode}
                          notAvailableLabel={labels?.Collections.notAvailableForViewLabel}
                          entityType='1'
                          tipMessage={labels?.Collections.unavailableGalleriesHelperText}
                          className='not-visible-card'
                          organizeIcon='organize-icon'
                        />
                      </div>
                    )}
                  </GridColumnStyled>
                )
              })}
            </GridRowStyled>
            <IntersectionCapture
              onIntersect={() => setSize(size + 1)}
              active={isIntersectionActive}
              params={{ rootMargin: '400px' }}
            />
          </Grid>
        </>
      ) : null}
      {(isLoadingInitialData || isLoadingMore) && (
        <LoaderContainer>
          <Loader centered active />
        </LoaderContainer>
      )}
    </div>
  )
}

const GridRowStyled = styled(Grid.Row)`
  &&&&& {
    padding-top: 0 !important;
  }
`
const GridColumnStyled = styled(Grid.Column)`
  margin-bottom: ${px2rem(64)} !important;
  ${mq.between('tablet', 'largestTablet')`
    margin-bottom: ${px2rem(64)} !important;
    .ui.grid {
    .ui.image {
      max-width: ${px2rem(335)};
    }
    }

    .gallery-item-content__wrapper {
      position: absolute;
      bottom: -${px2rem(40)};
      left: -${px2rem(62)};
      margin-top: ${px2rem(15)} !important
    }
      margin-bottom: ${px2rem(24)} !important;
  `}
  ${mq.lessThan('largestMobile')`
      margin-bottom: ${px2rem(64)} !important;
      .ui.grid {
        .ui.image {
          max-width: ${px2rem(160)};
        }

        .gallery-item-content__wrapper {
          position: absolute;
          top: ${px2rem(98)};
          left: -${px2rem(27)};
        }
      }
  `}

  .ui.grid {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 200px) and (max-width: 380px) {
    .ui.grid {
      .ui.image {
        max-width: ${px2rem(144)};
      }
    }
  }

  .not-visible-card {
    height: ${px2rem(173)};
    ${mq.lessThan('largestDesktop')`
      height : ${px2rem(72)};
      padding-top: ${px2rem(14)};
   `}

    ${mq.lessThan('largestMobile')`
      height : ${px2rem(64)};
      padding-top: 0;
    `}

    ${mq.between('tablet', 'largestTablet')`
      height: ${px2rem(90)};
      padding-top: ${px2rem(11)};
    `}
  }

  .styled-organize-mode {
    ${mq.between('tablet', 'largestTablet')`
      width: ${px2rem(335)};
      position: absolute;
      left: 0;
    `}

    ${mq.lessThan('largestMobile')`
      width: ${px2rem(160)};
      position: absolute;
      left: 0;
    `}
  }

  .organize-icon {
    ${mq.greaterThan('largestTablet')`
      position: absolute;
      right: ${px2rem(19)};
      width: ${px2rem(370)};
    `}
    ${mq.between('tablet', 'largestTablet')`
      width: ${px2rem(344)};
      position: absolute;
      left: ${px2rem(19)};
   `}

    ${mq.lessThan('largestMobile')`
      width: ${px2rem(170)};
      position: absolute;
      left: ${px2rem(19)};
   `}
  }
`

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: ${px2rem(100)};
`

GalleriesTab.propTypes = {
  editPreviewCollectionItems: PropTypes.array,
  isOrganizeMode: PropTypes.bool,
  totalItems: PropTypes.number,
  setSize: PropTypes.func,
  size: PropTypes.number,
  isRefreshing: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isEmpty: PropTypes.bool,
  isLoadingInitialData: PropTypes.bool,
  editPreviewCollectionItemsValidating: PropTypes.bool,
  isLoadingMore: PropTypes.bool,
  isTabExist: PropTypes.bool
}
export default GalleriesTab
