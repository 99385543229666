import { useEffect } from 'react'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import { getSiteLanguage } from '@utils/storage'

const useRequestLabels = () => {
  const [{ labels: contextLabels }, dispatch] = useMyCollectionsPageContext()
  const { labels: allLabels } = useLabelsFromAPI(['Collections','CollectionForms', 'CollectionNotifications', 'AltTags', 'OnlineShowRoom'], getSiteLanguage())

  useEffect(() => {
    !contextLabels && allLabels && dispatch({ type: 'SET_LABELS', payload: {
      Collections: allLabels?.Collections,
      CollectionForms: allLabels?.CollectionForms,
      CollectionNotifications: allLabels?.CollectionNotifications,
      AltTags: allLabels?.AltTags,
      OnlineShowRoom: allLabels?.OnlineShowRoom
    } })
  }, [allLabels, contextLabels, dispatch])

  return contextLabels
}

export default useRequestLabels
