import { useContext } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'semantic-ui-react'
import styled from 'styled-components'

import { px2rem, mq, StyleModalBottom } from '@utils'
import ModalTopBar from '@components/ModalTopBar'
import { NotificationsContext } from '@providers/Notifications'
import CollectionFormFormik from './Form'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const EditCollectionForm = (props) => {
  const { showNotification } = useContext(NotificationsContext)
  const mID = 'create-collection'

  return (
    <ModalStyled
      size='small'
      open
      className={mID}
      centered={false}
      role='dialog'
      aria-label={
        props.collectionDetail ?
        props.labels?.Collections.editCollectionPopupLabel : props.labels?.Collections.createNewCollectionLabel
      }
    >
      <div>
        <ModalTopBar
          title={
            props.collectionDetail ?
            props.labels?.Collections.editCollectionPopupLabel : props.labels?.Collections.createNewCollectionLabel
          }
          onClick={() => props.setEditCollectionModalOpen(false)}
          className={`${mID}__title`}
        />
        <Modal.Content className={`${mID}__content`}>
          <CollectionFormFormik
            collectionFormLabels={props.labels?.CollectionForms}
            collectionLabels={props.labels?.Collections}
            setVisible={() => { props.setEditCollectionModalOpen(false) }}
            submitFormCallback={(data, values) => {
              props.mutateCollectionDetail({
                ...props.collectionDetail,
                name: values.name,
                description: values.description
              }, false)
              props.setEditCollectionModalOpen(false)
              showNotification({
                content: `${values.name} ${props.labels?.Collections.successfulUpdateLabel}`
              })
            }}
            collectionDelete={props.removeCollection}
            collectionDetail={props.collectionDetail}
            submitCallback={props.submitCallback}
          />
        </Modal.Content>
      </div>
    </ModalStyled>
  )
}

const ModalStyled = styled(Modal)`
  /** This modal was copy/pasted from components/collections/collection-form/index.js
  but it missed this CSS. This is necessary for modals:
  1. aligned to the bottom
  2. With a scrolled content
  */
  &&&&&& {
    &.scrolling {
      @media screen and (max-height: 577px) {
        height: 100%;

        > div {
          background: ${tokens.color.base.primary.white.value};
        }
      }
    }

    margin-bottom: 0 !important;
  }

  ${mq.lessThan('largestTablet')`
    ${StyleModalBottom}
    margin: 0 !important;
  `}
  width: ${px2rem(502)} !important;
  padding-bottom: ${px2rem(28)} !important;

  &.ui {
    .create-collection {
      &__title.header {
        border-bottom: ${px2rem(1)} solid var(--bs-mch-galleries);
        ${mq.greaterThan('desktop')`
          margin-bottom: ${px2rem(10)};
        `}

        ${mq.lessThan('largestTablet')`
          padding: ${px2rem(16)} !important;
        `}
      }

      &__content.content {
        padding: 0 !important;
      }
    }
  }
`

EditCollectionForm.propTypes = {
  collectionDetail: PropTypes.object,
  labels: PropTypes.object,
  setEditCollectionModalOpen: PropTypes.func,
  removeCollection: PropTypes.func,
  mutateCollectionDetail: PropTypes.func,
  submitCallback: PropTypes.func
}

export default EditCollectionForm
