import qs from 'query-string'
import { Onlineshow } from '@dmi-mch/services'
import { logger, validateAxiosResponse } from '@dmi-mch/utils'
import type IEvent from 'types/IEvent'

import http, { api } from './_http'
import { ApiResponse } from 'apisauce'

const Events = {
  getUpcomingEvents: (params): Promise<ApiResponse<IEvent>> => {
    if (params.cancelToken) {
      return api.get('/msvc/v1/eventviews/upcoming', params.params, { cancelToken: params.cancelToken })
    } else {
      return api.get('/msvc/v1/eventviews/upcoming', params)
    }
  },
  getArchivedEvents: (params): Promise<ApiResponse<IEvent>> => api.get('/msvc/v1/eventviews/items/archive', params),
  getUpcomingEventsFilters: (data): Promise<ApiResponse<ABTypes.Monolith.AvailableFilters>> => api.get('/msvc/v1/eventviews/upcoming/filters', data.params),
  getEventById: (id, locale) => api.get(`/msvc/v1/eventviews/detail/${id}`, { locale }),
  getRSVP: (id): Promise<ApiResponse<ABTypes.Crmsubevent.RsvpUserInfo>> => api.get(`/msvc/v1/eventviews/rsvp/${id}`),
  postRSVP: (id, plusOne = null) => api.post(`/msvc/v1/eventviews/rsvp/${id}`, { plusOne }),
  delRSVP: id => http.del(`/msvc/v1/eventviews/rsvp/${id}`),
  postRSVPOneClick: params =>
  api.post(`/msvc/v1/eventviews/rsvp/${params.i}/oneclick?${qs.stringify({ token: params.token })}`),
  delRSVPOneClick: params =>
  http.del(`/msvc/v1/eventviews/rsvp/${params.i}/oneclick?${qs.stringify({ token: params.token })}`),
  viewRSVPOneClick: params =>
    api.get(`/msvc/v1/eventviews/rsvp/${params.i}/oneclick?${qs.stringify({ token: params.token })}`),
  getRoomsWithShow(params) {
    const mergeAll = async (promises, items) => {
      const events = [...items]
      const shows = []
      try {
        await Promise.all(promises).then(showRequest => {
          showRequest.forEach(res => {
            if (res.ok && res.data) {
              //@ts-ignore
              shows.push(res.data)
            }
          })
        })
        events.forEach(event => {
          if (event.onlineShowId) {
            //@ts-ignore
            event.onlineShowData = shows.length > 0 ? shows.find(i => i.id === event.onlineShowId) : null
          }
        })
        return events
      } catch (e) {
        logger(e)
      }
      return []
    }

    const fetchShowByRoomId = async onlineShowId => {
      let req = null
      try {
        const onlineshowService = new Onlineshow({ http })
        req = await onlineshowService.getOnlineShowViews(onlineShowId)
        if (validateAxiosResponse(req)) {
          return req
        }
      } catch (e) {
        logger(e)
      }
      return req
    }

    const fetchShow = async data => {
      const events = [...data]
      const promises = []
      const showsObj = []
      events.forEach(event => {
        if (event.onlineShowId) {
          //@ts-ignore
          const findShowInObj = showsObj.find(o => o.onlineShowId === event.onlineShowId)
          if (typeof findShowInObj === 'object') {
            promises.push(findShowInObj)
          } else {
            const req = fetchShowByRoomId(event.onlineShowId)
            //@ts-ignore
            showsObj.push({ onlineShowId: event.onlineShowId, data: req })
            //@ts-ignore
            promises.push(req)
          }
        }
      })
      return mergeAll(promises, events)
    }

    const fetchEvents = async () => {
      let req = null
      try {
        //@ts-ignore
        req = await api.get('/msvc/v1/eventviews/upcoming', params)
        if (validateAxiosResponse(req)) {
          //@ts-ignore
          const clonedEvents = { ...req.data }
          //@ts-ignore
          clonedEvents.events.items = await fetchShow(req.data.events.items)
          return clonedEvents
        }
      } catch (e) {
        logger(e)
      }
      return req
    }

    return fetchEvents()
  }
}

export default Events
