import { ApiResponse } from 'apisauce'
import http, { api } from './_http'

const Cms = {
  getContentByUrl: (uri, locale: string | null = 'en'): Promise<ApiResponse<ABTypes.Pageviews.Pageview>> =>
    api.get(Cms.endpoints.landingContent, {
      uri,
      pageFormat: 'COMPACT', // Save half of the bandwidth and CPU processing using COMPACT page format
      locale
    }),
  getPageByUrl: (url, previewPageDraftId) =>
    http.get('/msvc/v1/pageviews', {
      url,
      pageDraftId: previewPageDraftId
    }),
  getGlobalModules: (locale: string): Promise<ApiResponse<ABTypes.Pageviews.GlobalModule>> =>
    api.get('/msvc/v1/pageviews/globalModules', {
      locale,
      excludedFieldKeys: 'ftr_grp1_link2_desc'
    }),
  getPrimaryNavigation: locale =>
    http.get('/msvc/v1/pageviews/menus/primary', {
      locale
    }),
  getSecondaryNavigation: (id, locale) =>
    http.get(`/msvc/v1/pageviews/menus/secondary/${id}/`, {
      locale
    }),
  getProducts: locale =>
    http.get('/msvc/v1/cmsproducts', {
      locale
    }),
  getCategories: (locale: string): Promise<ApiResponse<ABTypes.Cmscategories.CategoryDTO>> =>
    api.get('/msvc/v1/cmscategories', {
      locale
    }),
  endpoints: {
    landingContent: '/msvc/v1/landing/content'
  }
}

export default Cms
