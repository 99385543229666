import { useRef, useEffect, useCallback } from 'react'

const useSwipe = ({ disabled }) => {
  const elRef = useRef()
  const start = useRef()
  const move = useRef()
  const onUpdateFunc = useRef()
  const onEndFunc = useRef()

  const onUpdate = useCallback(() => onUpdateFunc.current && onUpdateFunc.current(), [])
  const onEnd = useCallback(() => onEndFunc.current && onEndFunc.current(), [])

  const touchStart = (event) => {
    const touch = event.targetTouches[0] || event.changedTouches[0]
    start.current = { x: touch.pageX, y: touch.pageY }
    elRef.current.setAttribute('on-swipe', '')
  }

  const touchMove = useCallback(
    (event) => {
      const touch = event.targetTouches[0] || event.changedTouches[0]
      move.current = {
        x: touch.pageX - (start.current && start.current.x || 0),
        y: touch.pageY - (start.current && start.current.y || 0)
      }
      onUpdate()
    },
    [onUpdate]
  )

  const touchEnd = useCallback(() => {
    onEnd()
    start.current = null
    move.current = null
    onUpdate()
    elRef.current.removeAttribute('on-swipe')
  }, [onUpdate, onEnd])

  useEffect(() => {
    const el = elRef.current
    if (el && !disabled) {
      el.addEventListener('touchstart', touchStart)
      el.addEventListener('touchmove', touchMove)
      el.addEventListener('touchend', touchEnd)
    }
    return () => {
      if (el && !disabled) {
        el.removeEventListener('touchstart', touchStart)
        el.removeEventListener('touchmove', touchMove)
        el.removeEventListener('touchend', touchEnd)
      }
    }
  }, [disabled, touchMove, touchEnd])

  const setCallbacks = ({ update, end }) => {
    onUpdateFunc.current = update
    onEndFunc.current = end
  }

  return { elRef, move, setCallbacks }
}

export default useSwipe
