import urlMainEventColors from '../constants/urlMainEventColors'

const findColorSeparator = (routerAsPath: string): 'default' | 'basel' | 'miami' | 'hongkong' | 'cities' | 'paris' => {
  const url = routerAsPath
  const urlWithoutQueryparams = url?.split('?')[0]
  const firstPath: string = urlWithoutQueryparams?.split('/')[1] || ''

  return firstPath in urlMainEventColors ? urlMainEventColors[firstPath] : 'default'
}

export default findColorSeparator