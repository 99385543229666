import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import spacetime from 'spacetime'
import { date as dateConstant } from '@constants'

import { formatDate, logger } from '.'

dayjs.extend(customParseFormat)

// converts Thu Jul 19 2018 12:00:00 GMT+0100 (British Summer Time) in YYYY-MM-DD
/* IMPORTANT used in datepicker of Events Browse, and format is strict */
export const formatEventDate = (date) => dayjs(date).format('YYYY-MM-DD')

// converts Thu Jul 19 2018 12:00:00 GMT+0100 (British Summer Time) in YYYY-MM-DDTHH:mm:ss
export const formatEventDateRange = (date) => dayjs(date, 'ddd MMM DD YYYY').format('YYYY-MM-DDTHH:mm:ss')

// return an array of event days for the datepicker
export const buildEventDays = (filter, field) => {
  try {
    const { options } = filter.find((obj) => obj.entityType === field)
    return options.map((item) => new Date(item.code))
  } catch (e) {
    logger(e)
    return []
  }
}

// build each filter for events browse
export const buildEventsFilter = (arr, keyMap, all, addOptionForAll = true) => {
  try {
    const filter = arr.map((item) => ({
      key: item[keyMap.key],
      value: item[keyMap.value],
      label: item[keyMap.text],
      id: item[keyMap.id],
      name: item[keyMap.name],
      disabled: item[keyMap.disabled] === 0 || false
    }))
    if (addOptionForAll) {
      // prepends all option based on cms translation
      filter.unshift({
        key: all,
        value: 'all',
        text: all,
        id: all,
        name: all
      })
    }
    return filter
  } catch (e) {
    logger(e)
    return []
  }
}

// converts date in 12 noon or 7am format
export const formatHour = (
  date,
  incomingFormat = 'YYYY-MM-DD hh:mm',
  labels = {
    las_grp1_noon: 'noon',
    las_grp1_midnight: 'midnight'
  }
) => {
  let hour = dayjs(date, incomingFormat).format('h:mma')
  if (hour === '12:00pm') {
    hour = `12 ${labels.las_grp1_noon ? labels.las_grp1_noon : 'noon'}`
  }
  if (hour === '12:00am') {
    hour = `12 ${labels.las_grp1_midnight ? labels.las_grp1_midnight : 'midnight'}`
  }
  return hour.replace(':00', '')
}

// format the event detail link
export const formatEventLink = (id, cleanTitle, timeId) =>
  `/events/detail/${id}/${cleanTitle}${timeId ? `/${timeId}` : ''}`

// format the room detail link
export const formatRoomLink = (id, cleanTitle, timeId) =>
  `/rooms/detail/${id}/${cleanTitle}${timeId ? `/${timeId}` : ''}`

export const formatSpecialRoomLink = (id, cleanTitle, timeId) =>
  `/rooms/specialsector/${id}/${cleanTitle}${timeId ? `/${timeId}` : ''}`

export const calcIsCapacityReached = (capacity, used) => capacity === used && capacity !== 0

export const prepareRSVPText = (rsvp, { isRSVPDatePassed, RSVPDateDeadline }, labels) => {
  const isRSVPConfirmed = rsvp && rsvp.rsvpStatus === 'Confirmed'
  const isCapacityReached = rsvp && calcIsCapacityReached(rsvp.capacity, rsvp.used)

  let text = ''
  /* Default info text. For ex: There are limited places available for this event. Please confirm your attendance */
  text = labels.evd_grp1_rsvp_info_label || 'Please confirm your attendance with RSVP.'

  /* Info text with date */
  if (rsvp && rsvp.subEvent.rsvpEndDate && !isCapacityReached && !isRSVPDatePassed && !isRSVPConfirmed) {
    text =
      `${labels.evd_grp1_rsvp_info_with_date_label}
      ${dayjs(RSVPDateDeadline).format('ddd, MMM D, YYYY')}` ||
      'There are limited places available for this event, or Please confirm your attendance by XXXXXXX'
  }

  // confirmed with plus one
  if (rsvp && rsvp.rsvpStatus === 'Confirmed' && rsvp.plusOne) {
    text = `${labels.evd_grp1_rsvp_attendingwith_label} ${rsvp.plusOne}` || 'Confirmed plus one'
  }

  // Confirmed without plus one
  if (isRSVPConfirmed && !rsvp.plusOne) {
    text = labels.evd_grp1_rsvp_attending_label || 'Confirmed'
  }

  // If Capacity reached (and it's not confirmed), show corresponding text
  if (isCapacityReached && !isRSVPDatePassed && !isRSVPConfirmed) {
    // ??????
    text = `${labels.evd_grp1_rsvp_event_full_label}` || 'Capacity is reached'
  }

  /* Label for passed events */
  if (isRSVPDatePassed && !isRSVPConfirmed) {
    text =
      `${labels.evd_grp1_rsvp_event_passed_label} ${dayjs(rsvp.subEvent.rsvpEndDate).format('ddd, MMM D, YYYY')}` ||
      'Unfortunately the RSVP deadline has elapsed on...'
  }
  return text
}

export const getHourAndMinutes = (time) => {
  const openingTime = time.split(':')
  const hour = Number(openingTime[0])
  const minutes = Number(openingTime[1])
  return { hour, minutes }
}

export const calculateExpiryDate = (openingDate, timezone) => {
  let date = spacetime(openingDate.date)
  if (timezone) {
    date = spacetime(openingDate.date, timezone)
  }
  if (!openingDate.endTime) {
    const hourAndMinutes = getHourAndMinutes(openingDate.startTime)
    date = date.hour(hourAndMinutes.hour)
    date = date.minute(hourAndMinutes.minutes)
    date = date.add(4, 'hour')
  } else {
    const hourAndMinutes = getHourAndMinutes(openingDate.endTime)
    date = date.hour(hourAndMinutes.hour)
    date = date.minute(hourAndMinutes.minutes)
  }
  return date
}

export const findMaxOpeningDate = (openingDateTimes) => {
  let maxDate
  let maxIndex = -1
  openingDateTimes.forEach((element, index) => {
    //@ts-ignore
    const date = calculateExpiryDate(element)
    if (!maxDate || (maxDate && maxDate.isBefore(date))) {
      maxDate = date
      maxIndex = index
    }
  })
  return maxIndex !== -1 ? openingDateTimes[maxIndex] : null
}

export const findOpeningTime = (openingDateTimes, opentime = null) => {
  //@ts-ignore
  let openingTime = openingDateTimes && openingDateTimes.find((item) => item.id === parseInt(opentime, 0))
  if (!openingTime && openingDateTimes && openingDateTimes.length > 0) {
    openingTime = findMaxOpeningDate(openingDateTimes)
  }
  return openingTime
}

export const getDatesExhibition = (startDate, endDate) => {
  let dateString = formatDate(startDate, dateConstant.BE_DATE_FORMAT, 'MMM D, YYYY')
  if (endDate) {
    dateString += ` - ${formatDate(endDate, dateConstant.BE_DATE_FORMAT, 'MMM D, YYYY')}`
  }
  return dateString
}

/* IMPORTANT: Used only in Datepickers of Events Browse */
export const getDateRangeDisplayInputValue = (from, to, all) => {
  if (from && to) {
    if (new Date(from).getTime() === new Date(to).getTime()) {
      return dayjs(from).format(dateConstant.FE_DATE_FORMAT)
    }
    return `${dayjs(from).format(dateConstant.FE_DATE_FORMAT)} - ${dayjs(to).format(dateConstant.FE_DATE_FORMAT)}`
  } else if (from && !to) {
    return dayjs(from).format(dateConstant.FE_DATE_FORMAT)
  }
  return all
}

export const getExpiryDate = (openingDate, timezone) => {
  const date = calculateExpiryDate(openingDate, timezone)
  return `${date.unixFmt('dd-MMM-yyyy HH:mm:ss')} ${date.timezone().display}`
}

export const getLocationMapLink = ({ venue: { mapUrl, street, street2, city, postCode, country }, hall }) => {
  let link = mapUrl
  if (!link) {
    link = `http://www.google.com/maps/place/?q=${[hall, street, street2, city, postCode, country]
      .filter(Boolean)
      .join(' ')}`
  }
  return link
}

// These to helper methods are being used for passing into react-day-picker library, because using the built-in methods
// of the library is adding 'moment' back to the bundle.
export const formatDateDayPicker = (date, format) => dayjs(date).format(format)
export const parseDateDayPicker = (date, format) => {
  const result = dayjs(date, format)

  if (result.isValid()) {
    return result.toDate()
  }

  return undefined
}

export const slideLink = (orientation, pageMetaInfo) => {
  let slideItem = null
  const currentIndex = pageMetaInfo.roomArtworks
    ? pageMetaInfo.roomArtworks.items.findIndex((obj) => obj.id === pageMetaInfo.id)
    : 0

  if (pageMetaInfo.roomArtworks) {
    if (orientation === 'prev') {
      //@ts-ignore
      const findPrevSlide = pageMetaInfo.roomArtworks.items[parseInt(currentIndex - 1, 10)]
      if (findPrevSlide) {
        slideItem = findPrevSlide
      } else {
        slideItem = pageMetaInfo.roomArtworks.items[pageMetaInfo.roomArtworks.items.length - 1]
      }
    }
    if (orientation === 'next') {
      const findNextSlide = pageMetaInfo.roomArtworks.items[parseInt(currentIndex + 1, 10)]
      if (findNextSlide) {
        slideItem = findNextSlide
      } else {
        slideItem = pageMetaInfo.roomArtworks.items[0]
      }
    }
  }
  return slideItem
}

export const randomSeed = (agentKey) =>
  Math.abs(
    //@ts-ignore
    Array.from(agentKey ?? 0).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0) + new Date().getDate()
  ) % 10000

/**
 * @TODO Create a hook of this a think a better abstraction that takes into account different scenarios
 * CMS Options, Note: BE sent the options with blank spaces, not the best idea.
 * CMS options are passed as default values in case of no custom sort by selection on website
 */
export const sortbyQueryRelation = (agentKey, fingerprint) => ({
  'most visited': 'uvisits(7)+DESC,id+DESC',
  'least visited': 'uvisits(7)+ASC,id+ASC',
  'latest artwork added': 'freshContentAddedDate+DESC,id',
  random: `random(${randomSeed(agentKey)}),id`,
  'ml logic': `rscore(:${agentKey}::${fingerprint})+DESC`,
  'random with featured': `isFeaturedEvent+DESC,random(${randomSeed(agentKey)}),id`,
  alphabetical: 'accountName,id',
  'alphabetical artist': 'artist,id',
  'alphabetical artwork title': 'name,id',
  'price ascending': 'price+ASC',
  'price descending': 'price+DESC',
  'year ascending': 'year+ASC',
  'year descending': 'year+DESC',
  'year created ascending': 'year+ASC',
  'year created descending': 'year+DESC',
  'newly published updated': 'visibilityDate+DESC',
  'gallery name': 'accountName,id',
  'room title': 'title+ASC',
  // The rest of options, that show in the dropdown
  alphabeticalOrder: 'accountName,id',
  recentlyUpdated: `freshContentAddedDate+DESC,random(${randomSeed(agentKey)}),id`,
  lastVisitedAscending: `vscore(0:${agentKey}::${fingerprint})+DESC,random(${randomSeed(agentKey)}),id`,
  // same purpose than "random" above. Just to differentiate the one in CMS and the one in dropdown
  randomDropdown: `random(${randomSeed(agentKey)}),id`
})
