import { FC } from 'react'
import Image from 'next/image'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

// e.g. params: "q_auto:good,dpr_1.0,ar_16:10,c_crop,c_fill,g_center,f_auto" output is 16:/10
const findAspectRatio = (params) => {
  let myArray = params.split(',')
  let aspectRatio = 'auto'
  myArray.map(property => {
    if (property.includes('ar_')) {
      let propertyAarray = property.split('_')[1]
      aspectRatio = propertyAarray?.replace(':', '/')
    }
  })
  return aspectRatio
}

const ImageNext: FC<IImageNext> = (props) => (
  <div style={{ aspectRatio: findAspectRatio(props.params), background: tokens.color.light.base.neutrals['200'].value }}>
    <Image alt={props.alt} src={uploadTransformationUrl(props.src, props?.params)} />
  </div>
)

interface IImageNext {
  src: string // required
  params: string // required
  alt: string // optional
}

export default ImageNext
