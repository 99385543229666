import PropTypes from 'prop-types'
import { CollectionsAlbumCard } from '@mch-group/uikit-components'
import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'

const CollectionAlbumCardCreate = ({
  onClick,
  myCollections
}) => {
  const [{ labels }] = useMyCollectionsPageContext()
  return (
    <div role='button' tabIndex={0} onClick={onClick} onKeyDown={onClick}>
      <CollectionsAlbumCard variant='new' noCollectionText={myCollections?.length === 0 ? labels?.Collections.createCollectionLabel : ''} />
    </div>
  )
}

CollectionAlbumCardCreate.propTypes = {
  myCollections: PropTypes.array,
  onClick: PropTypes.func
}

export default CollectionAlbumCardCreate
