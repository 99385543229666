import { useEffect } from 'react'

/**
 * This Hook looks for an anchor with #CookieSettings, and adds in some JS magic to make it open the cookies popup
 * @param footerLinks: This is the param that makes the addEventListener not to "fail", quite random, but it works.
 */
const useCookiesClickHandler = (footerLinks) => {
  const handleClickCookies = event => {
    if (typeof OneTrust !== 'undefined') {
      OneTrust.ToggleInfoDisplay()
      event.preventDefault()
    }
  }

  useEffect(() => {
    const elms: NodeListOf<Element> | null = document.querySelectorAll('a[href*="#CookieSettings"]')
    elms.forEach(elm => {
      elm.addEventListener('click', handleClickCookies)
    })

    return () => {
      elms.forEach(elm => {
        elm.removeEventListener('click', handleClickCookies)
      })
    }
  }, [footerLinks])
}

export default useCookiesClickHandler