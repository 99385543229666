import styled from 'styled-components'
import PropTypes from 'prop-types'
import { px2rem } from '@utils'
import tokensRenamed from '@constants/tokensRenamed'

const InputFeedback = props => (<small className={props.className}>{props.children}</small>)

InputFeedback.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

/**
 * $wrapText: if true, will make line breaks
 */
const InputFeedbackStyled = styled(InputFeedback)`
  ${props => props.$wrapText ? 'white-space: normal;' : ''}
  display: block;
  color: var(--bs-mch-light-error);
  font-size: ${MFP.vars.errorTextFontSize};
  line-height: ${tokensRenamed.lineHeights[0].value};
  margin-top: ${px2rem(2)};
`

export default InputFeedbackStyled
