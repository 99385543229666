import styled from 'styled-components'
import { Input } from 'semantic-ui-react'
import { px2rem } from '@utils'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const StyledInput = styled(Input).withConfig({ componentId: 'dmi-ui-input' })`
  &.ui.input {
    width: 100%;

    & > input {
      border-radius: 0;
      height: ${px2rem(45)};

      background: ${tokens.color.light.base.neutrals['100'].value};
      border: 0.5px solid ${tokens.color.light.base.neutrals['600'].value} !important;
      font-size: ${tokens.fontSize[3].value} !important;
      padding: ${px2rem(13)} 0 ${px2rem(13)} ${px2rem(13)};

      &::placeholder {
        color: ${({ theme }) => theme.colorPlaceholder};
        opacity: 1;
      }

      &::-ms-clear {
        display: none;
      }
    }

    &.disabled {
      opacity: unset;

      & > input {
        font-size: ${tokens.fontSize[3].value} !important;
        background: ${tokens.color.light.base.neutrals['100'].value};
        border: 0.5px solid ${tokens.color.light.base.neutrals['600'].value} !important;
        color: ${tokens.color.light.base.neutrals['800'].value};
        opacity: unset;
      }
    }
  }
`

export default StyledInput