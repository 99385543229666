// @ts-nocheck
import http from './_http'

const Favorites = {
  set: (entityType, entityId, on) => {
    const url = `/msvc/v1/favorites/${entityType}/${entityId}`
    if (on) {
      return http.post(url)
    }
    return http.del(url)
  },

  getMine: (entityType) => {
    const url = `/msvc/v1/favorites/mine/${entityType}`
    return http.get(url)
  },

  getMineAll: () => http.get('/msvc/v1/favorites/mine')
}

export default Favorites
