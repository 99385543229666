import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Dimmer } from 'semantic-ui-react'
import { convertHexToRgba } from '@utils/cssHelpers'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

//@ts-ignore
const OverlayContext = React.createContext()

const OverlayProvider = ({ children }) => {
  const transitionTime = 420
  const [inDOM, setInDOM] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const [fullVisible, setFullVisible] = React.useState(false)
  const [transparent, setTransparent] = React.useState(false)

  React.useEffect(() => {
    // Wake from the dead
    if (visible && !inDOM && !fullVisible) {
      setInDOM(true)
    }

    // Already awake, but not showing
    if (visible && inDOM && !fullVisible) {
      setFullVisible(true)
    }

    // First step for hiding
    if (!visible && inDOM && fullVisible) {
      setFullVisible(false)
    }

    // Before removing from DOM for full hide, letting a bit of time margin to let the CSS transition complete
    if (!visible && inDOM && !fullVisible) {
      let timeout = setTimeout(() => {
        setInDOM(false)
      }, transitionTime)
      return () => clearTimeout(timeout)
    }
  }, [inDOM, visible, fullVisible])

  const showOverlay = async (isVisible) => {
    setVisible(isVisible)
  }

  const showTransparentOverlay = async (isVisible) => {
    setTransparent(isVisible)
  }

  return (
    <OverlayContext.Provider value={{ showOverlay, showTransparentOverlay, visible, transparent }}>
      {inDOM && (<DimmerStyled active $fullVisible={fullVisible} $transitionTime={transitionTime} />)}
      {transparent && (<DimmerStyled active={transparent} $isTransparent={transparent} />)}
      {children}
    </OverlayContext.Provider>
  )
}

const DimmerStyled = styled(Dimmer)`
  position: fixed !important;
  z-index: 3 !important;
  background-color: rgba(${convertHexToRgba(tokens.color.light.base.neutrals[1000].value, tokens.opacity[0].value)}) !important;
  ${(props) => props.$isTransparent && 'background: none !important; z-index: 1000 !important;'}
  ${(props) => props.$fullVisible && `background-color: rgba(${convertHexToRgba(tokens.color.light.base.neutrals[1000].value, tokens.opacity[30].value)}) !important;`}
  ${(props) => props.$transitionTime && `transition: background-color ${props.$transitionTime}ms ease !important;`}
`

OverlayProvider.propTypes = {
  children: PropTypes.node,
  isTransparent: PropTypes.bool
}

export default OverlayProvider

export { OverlayContext }
