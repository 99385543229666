import PropTypes from 'prop-types'
import { ArtistGalleryMiniCard } from '@mch-group/uikit-components'
import { cloudinary } from '@constants'
import { formatArtistLink } from '@utils'
import { uploadTransformationUrl } from '@dmi-mch/utils'
import { CustomLinkTag } from '@components'
import { AddToCollectionContext } from '@components/collections/AddToCollection'

const ArtistItem = ({
  id,
  cleanTitle,
  name,
  imageUrl,
  withAddToCollection,
  isOrganizeMode,
  isSelectedInCollections,
  onSelectCard,
  labels
}) => {
  const { showAddToCollectionModal } = React.useContext(AddToCollectionContext)
  return (
    <ArtistGalleryMiniCard
      collectionLink={formatArtistLink(id, cleanTitle)}
      image={uploadTransformationUrl(
        imageUrl, 'c_fill,ar_1:1,q_auto:good,w_400',
        { 'transformUrl': cloudinary.throughCloudfront }
      )}
      collection={{
        active: withAddToCollection,
        onClick: () => {
          showAddToCollectionModal(id, '3')
        }
      }}
      variant='artist'
      linkRenderer={(link, children, linkTitle, ariaLabel) => (
        <CustomLinkTag title={linkTitle} url={link} ariaLabel={ariaLabel}>
          {children}
        </CustomLinkTag>
      )}
      a11yData={{
        addBtn: labels?.addBtn || 'Add item to collection',
        ariaLabel: labels?.ariaLabel || 'Read more about'
      }}
      alt={`${labels?.imageAlt} ${name}`}
      title={name}
      selectMode={{
        active: isOrganizeMode,
        checked: isSelectedInCollections,
        onChange: () => {
          isOrganizeMode && onSelectCard(id, '3')
        }
      }}
      fallbackImage={`${cloudinary.UPLOAD}/c_fill,ar_1:1,q_auto:good,w_400/v1599746309/gray-placeholder_wkdex3.jpg`}
    />
  )
}

ArtistItem.propTypes = {
  isSelectedInCollections: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cleanTitle: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  withAddToCollection: PropTypes.bool,
  isOrganizeMode: PropTypes.bool,
  onSelectCard: PropTypes.func
}

export default ArtistItem
