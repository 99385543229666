import React from 'react'
import NProgress from 'nprogress'
import { createGlobalStyle } from 'styled-components'

NProgress.configure()

// NOTE: Extracted from https://github.com/rstacruz/nprogress/blob/master/nprogress.css
const NProgressStyles = createGlobalStyle`
  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: var(--bs-mch-orange);
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 100px;
    height: 100%;
    box-shadow: var(--bs-mch-orange) 1px 0 6px 1px;
    opacity: 1;
    transform: rotate(3deg) translate(0, -4px);
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }
`

function NProgressBar({ children = null }) {
  return (
    <>
      <NProgressStyles />
      {children}
    </>
  )
}

export default NProgressBar
