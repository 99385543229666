import Cookies from 'js-cookie'

const removeAllCookies = () => {
  Cookies.remove('COLLECTION_ROOM_INDEX', { path: '/' })
  Cookies.remove('OS_COMPACT_ROOM_INDEX', { path: '/' })
  Cookies.remove('OS_COMPACT_SPECIAL_ROOM_INDEX', { path: '/' })
  Cookies.remove('__INSIDE_MY_COLLECTION____BACK__NAVIGATION__', { path: '/' })
  Cookies.remove('__INSIDE__ROOM____BACK__NAVIGATION__', { path: '/' })
  Cookies.remove('__INSIDE__ARTWORK____BACK__NAVIGATION__', { path: '/' })
  Cookies.remove('__INSIDE__SPECIAL__ROOM__BACK__NAVIGATION__', { path: '/' })
  Cookies.remove('__INSIDE_ARTWORK_RECOMMENDATION__BACK__NAVIGATION__', { path: '/' })
  Cookies.remove('__INSIDE__OS__COMPACT__ROOM____BACK__NAVIGATION__', { path: '/' })
  Cookies.remove('__INSIDE__OS__COMPACT__SPECIAL__ROOM____BACK__NAVIGATION__', { path: '/' })
}

export default removeAllCookies