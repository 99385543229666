import cmsPageUrl from './cmsPageUrl'

const isDetailLink = (link, entity, page) => {
  let output = ''
  if (link.indexOf(`/${entity}/`) > -1) {
    output = link.replace(`/${entity}/`, '')
    return cmsPageUrl(output, page)
  }
  return cmsPageUrl(link)
}

export default isDetailLink
