/**
 * Cookie keys used in the application
 */
const CookieKeys = {
  LANGUAGE: 'mfpLocale',
  CSRF_TOKEN: 'csrfToken',
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  IS_MOBILE_WEBVIEW: 'mfpIsMobileWebview',
  AGENT_KEY: 'agentKey',
  COOKIES_AND_DATA_ANALYTICS: 'acceptedCookies'
}

export default CookieKeys
