const legacyPaths = (getEnvConfig.fe && 'legacyPaths' in getEnvConfig.fe && getEnvConfig.fe.legacyPaths) || []
const legacyRegexps = (getEnvConfig.fe && 'legacyRegexps' in getEnvConfig.fe && getEnvConfig.fe.legacyRegexps) || []

const isLegacyPath = (url) => {
  if (typeof url !== 'string') return false
  // eslint-disable-next-line no-loops/no-loops
  for (let i = 0; i < legacyPaths.length; i+=1) {
    if (url.indexOf(legacyPaths[i]) === 0) {
      return true // There is a legacy path which my URL starts with it exactly.
    }
  }

  // eslint-disable-next-line no-loops/no-loops
  for (let i = 0; i < legacyRegexps.length; i+=1) {
    if (url.match(legacyRegexps[i])) {
      return true // There is a regexp path which my URL matches.
    }
  }
  return false
}

export default isLegacyPath
