import shortid from 'shortid'

import { entityTypes, filterEntityTypes } from '@constants/entityTypes'
import {
  Gallery as GalleriesService,
  Artists as ArtistsService,
  Favorites as FavoritesService,
  Artworks as ArtworksService
} from '@services'
import { getSiteLanguage } from '@utils/storage'
import { logger } from '@utils'
import { catalogEntityUrl, catalogSortByValue } from '@constants/catalog'
import galleryStatus from '@constants/galleryStatus'

const REDUCERNAME = 'catalog'
interface IInitialState {
  catalogData: any,
  defaultParams: any,
  currentParams: any,
  filters: any,
  secondaryMenus: any,
  cityFilters: any,
  myFavorites: any,
  currentShowTab: any,
  isLoading: boolean,
  isChangingSort: boolean,
  currentFavItemParams: any
}
const initialState: IInitialState = {
  catalogData: null, // to store all the catalog browse filters/list data
  defaultParams: {
    localeCode: getSiteLanguage(),
    limit: 36,
    offset: 0,
    sortBy: 'sortingName',
    atozLetter: 'all',
    locations: undefined,
    showIds: undefined,
    sectorIds: undefined,
    medium: undefined,
    startYear: undefined,
    endYear: undefined
  },
  currentParams: {}, // to store the catalog browse params
  filters: [], // filters of the page
  secondaryMenus: [],
  cityFilters: [],
  myFavorites: [],
  currentShowTab: '',
  isLoading: false,
  isChangingSort: false,
  currentFavItemParams: {}
}

// ACTION TYPES
const actionTypes: any = {
  INIT_SORT_BY: `${REDUCERNAME}__INIT_SORT_BY`,
  SET_IS_LOADING: `${REDUCERNAME}__SET_IS_LOADING`,
  SET_CHANGING_SORT: `${REDUCERNAME}__SET_CHANGING_SORT`,
  SET_CATALOG_DATA: `${REDUCERNAME}__SET_CATALOG_DATA`,
  RESET_CATALOG_MODULE: `${REDUCERNAME}__RESET_CATALOG_MODULE`,
  SET_FILTERS: `${REDUCERNAME}__SET_FILTERS`,
  SET_QUERY_PARAMS: `${REDUCERNAME}__SET_QUERY_PARAMS`,
  SET_SECONDARY_MENUS: `${REDUCERNAME}__SET_SECONDARY_MENUS`,
  SET_CITY_FILTERS: `${REDUCERNAME}__SET_CITY_FILTERS`,
  SET_MY_FAVORITES: `${REDUCERNAME}__SET_MY_FAVORITES`,
  UPDATE_MY_FAVORITES: `${REDUCERNAME}__UPDATE_MY_FAVORITES`,
  SET_CURRENT_SHOW_TAB: `${REDUCERNAME}__SET_CURRENT_SHOW_TAB`
}

const allGalleryStatuses = [
  galleryStatus.live,
  galleryStatus.alumni,
  galleryStatus.outOfBusiness
]

// get entityType of favorites based on url
const getEntityTypeFromUrl = (entity) => {
  if (entity === catalogEntityUrl.galleries) {
    return entityTypes.gallery
  } else if (entity === catalogEntityUrl.artists) {
    return entityTypes.artist
  } else if (entity === catalogEntityUrl.artworks) {
    return entityTypes.artwork
  }
  return entityTypes.gallery
}

// ACTIONS
export const setFavorite = (entity, id, isFavorite) => async (dispatch) => {
  const catalogParams = { ...{ id, isFavorite }, ...initialState.defaultParams }
  initialState.currentFavItemParams = catalogParams
  const entityType = getEntityTypeFromUrl(entity)

  let setFavoritePayload: null | {} = null
  try {
    // Post/Delete favorite
    await FavoritesService.set(entityType, id, isFavorite)
    setFavoritePayload = { type: entityType, id, isFavorite }
  } catch (e) {
    logger(e)
  }
  return dispatch({
    type: actionTypes.SET_FAVORITE,
    action: setFavoritePayload
  })
}

const getFiltersBasedOnURL = async (catalogEntity, filtersParams) => {
  let getCatalogResponse = {}
  if (catalogEntity === catalogEntityUrl.galleries) {
    getCatalogResponse = await GalleriesService.getFilters(filtersParams)
  }

  if (catalogEntity === catalogEntityUrl.artists) {
    getCatalogResponse = await ArtistsService.getFilters(filtersParams)
  }

  if (catalogEntity === catalogEntityUrl.artworks) {
    getCatalogResponse = await ArtworksService.getArtworkFilters(filtersParams)
  }
  return { getCatalogResponse }
}

const checkAddAlumniAndOutOfBusiness = async (showIds, catalogEntity) => {
  let entityShow: any = []
  if (showIds) {
    if (initialState.filters.length === 0) {
      // need to check for the corner case scenario where filters have not been loaded yet from other module
      const getFilterResponse: any = await getFiltersBasedOnURL(catalogEntity, { showIds })
      if (getFilterResponse.data && getFilterResponse.ok) {
        entityShow = getFilterResponse.data.filters.filter(entity => entity.entityType === filterEntityTypes.show)
      }
    } else {
      entityShow = initialState.filters.filter((entity: any) => entity.entityType === filterEntityTypes.show)
    }
    if (entityShow.length > 0) {
      let countHaveShow = 0
      let currentShow: any = null
      const showIdsArray = showIds.split(',')

      // check if showIds are in all years
      // eslint-disable-next-line no-loops/no-loops
      for (let i = 0; i < showIdsArray.length; i++) {
        const showId = showIdsArray[i]
        // eslint-disable-next-line no-loops/no-loops
        for (let optionIndex = 0; optionIndex < entityShow[0].options.length; optionIndex++) {
          const show = entityShow[0].options[optionIndex]

          // if we have find already a corresponding show, can skip
          if (currentShow && currentShow.value !== show.value) {
            continue
          }

          const matchShow = show.options.filter(showYear => showYear.code === showId)
          if (matchShow.length > 0) {
            countHaveShow += 1
            currentShow = show
          }
        }
      }
      // if showIds parameter is not in all years of the show, return true to get alumni and out of business
      if (currentShow && countHaveShow !== currentShow.options.length) {
        return true
      }
    }
  }
  return false
}

// sortBy value is different in galleries, artists, artworks
const getSortByValue = (entity, catalogParams) => {
  if (catalogParams.sortBy === 'sortingName') {
    if (entity === catalogEntityUrl.galleries) {
      return catalogSortByValue.galleries
    } else if (entity === catalogEntityUrl.artists) {
      return catalogSortByValue.artists
    } else if (entity === catalogEntityUrl.artworks) {
      return catalogSortByValue.artworks
    }
  }
  return catalogParams.sortBy
}

const getCatalog = async (entity, catalogParams, currentList: any = {}, append = false) => {
  let requestCatalogPayload: any = null
  try {
    if (entity === catalogEntityUrl.galleries) {
      catalogParams.statuses = (await checkAddAlumniAndOutOfBusiness(catalogParams.showIds, entity))
        ? allGalleryStatuses : '102900000'
    } else {
      catalogParams.galleryStatuses = (await checkAddAlumniAndOutOfBusiness(catalogParams.showIds, entity))
        ? allGalleryStatuses : '102900000'
    }
    const isFavorite = !!catalogParams.favorite
    if (catalogParams.showIds === 'all') {
      delete catalogParams.showIds
    }
    if (catalogParams.sectorIds === 'all') {
      delete catalogParams.sectorIds
    }
    if (catalogParams.locations === 'all') {
      delete catalogParams.locations
    }

    catalogParams.sortBy = getSortByValue(entity, catalogParams)

    let getCatalogResponse: any = null
    if (entity === catalogEntityUrl.galleries) {
      getCatalogResponse = isFavorite ?
        await GalleriesService.getFavorites(catalogParams) :
        await GalleriesService.getGalleries(catalogParams)
    }

    if (entity === catalogEntityUrl.artists) {
      getCatalogResponse = isFavorite ?
        await ArtistsService.getFavorites(catalogParams) :
        await ArtistsService.getArtists(catalogParams)
    }

    if (entity === catalogEntityUrl.artworks) {
      getCatalogResponse = isFavorite ?
        await ArtworksService.getArtworkFavorites(catalogParams) :
        await ArtworksService.getArtworks(catalogParams)
    }

    // ok
    if (getCatalogResponse && getCatalogResponse.data && getCatalogResponse.ok) {
      requestCatalogPayload = getCatalogResponse.data
      if (append) {
        requestCatalogPayload.items = currentList.items ?
        [...currentList.items, ...getCatalogResponse.data.items] : [...getCatalogResponse.data.items]
      }
    }
    // fixing weird bug where it can be null
    if (!getCatalogResponse) {
      requestCatalogPayload = {
        items: [],
        hasMore: false
      }
    } else {
      // error
      if (!getCatalogResponse.ok && getCatalogResponse.problem === 'CLIENT_ERROR') {
        requestCatalogPayload = 'error'
      }
    }
  } catch (e) {
    logger(e)
  }
  return requestCatalogPayload
}

export const requestCatalog = (entity, params, limit = 36) => async (dispatch) => {
  // handle params
  const catalogParams = { ...initialState.defaultParams, ...params, limit }
  if (catalogParams.sortBy === 'random' || catalogParams.atozLetter === 'all') {
    delete catalogParams.atozLetter
  }
  if (catalogParams.sortBy === 'random') {
    catalogParams.randomStr = initialState.currentParams.randomStr
  }

  initialState.currentParams = catalogParams

  const requestCatalogPayload = await getCatalog(entity, catalogParams)

  return dispatch({
    type: actionTypes.SET_CATALOG_DATA,
    payload: requestCatalogPayload
  })
}

export const loadMoreCatalog = (entity, currentList) => async (dispatch) => {
  // handle params
  const catalogParams: any = initialState.currentParams
  catalogParams.offset = initialState.currentParams.offset + 36
  catalogParams.pageToken = currentList.pageToken
  initialState.currentParams = catalogParams

  const requestCatalogPayload = await getCatalog(entity, catalogParams, currentList, true)
  return dispatch({
    type: actionTypes.SET_CATALOG_DATA,
    payload: requestCatalogPayload
  })
}

export const resetCatalogModule = payload => (dispatch) => {
  initialState.catalogData = null
  initialState.currentParams = {}
  initialState.currentFavItemParams = {}
  initialState.myFavorites = []
  initialState.currentShowTab = ''
  dispatch({ type: actionTypes.RESET_CATALOG_MODULE, payload })
}

export const requestFilters = (catalogEntity, params) => async (dispatch) => {
  // handle params
  const filtersParams = { ...params }
  let requestFiltersPayload: any = []
  try {
    const { getCatalogResponse }: any = await getFiltersBasedOnURL(catalogEntity, {
        showIds: filtersParams.showIds
      })
    // ok
    if (getCatalogResponse.data && getCatalogResponse.ok && getCatalogResponse.data.filters.length > 0) {
      requestFiltersPayload = getCatalogResponse.data.filters
      initialState.filters = requestFiltersPayload
      const entityShow: any =
      requestFiltersPayload.filter((entity: any) => entity.entityType === filterEntityTypes.show)
      if (entityShow.length > 0) {
        entityShow[0].options.forEach((show) => {
          const concatShowIds = show.options.map(showYear => showYear.code).join(',')
          // eslint-disable-next-line no-param-reassign
          show.showIds = concatShowIds
          show.options.reverse()
        })
      }
    }
    // error
    if (!getCatalogResponse.ok && getCatalogResponse.problem === 'CLIENT_ERROR') {
      requestFiltersPayload = 'error'
    }
  } catch (e) {
    logger(e)
  }
  const result = {
    filters: requestFiltersPayload,
    showIds: filtersParams.showIds
  }
  return dispatch({
    type: actionTypes.SET_FILTERS,
    payload: result
  })
}

export const setQueryParams = (params, resetParams = false) => async (dispatch) => {
  initialState.currentParams = resetParams ? { ...initialState.defaultParams, ...params } : params
  if (initialState.currentParams.sortBy === 'random') {
    initialState.currentParams.randomStr = shortid.generate()
  } else {
    initialState.currentParams.randomStr = undefined
  }
  dispatch({ type: actionTypes.SET_QUERY_PARAMS, payload: { params: initialState.currentParams, resetParams } })
}

export const setSecondaryMenus = value => dispatch =>
  dispatch({ type: actionTypes.SET_SECONDARY_MENUS, value })

export const setCityFilters = value => dispatch =>
  dispatch({ type: actionTypes.SET_CITY_FILTERS, value })

export const setChangingSort = value => dispatch =>
  dispatch({ type: actionTypes.SET_CHANGING_SORT, payload: value })

export const setMyFavorites = entity => async (dispatch) => {
  const entityType = getEntityTypeFromUrl(entity)
  const myFavorites = await FavoritesService.getMine(entityType)
  let result: any = []
  if (myFavorites.ok && myFavorites.data) {
    result = myFavorites.data
    initialState.myFavorites = result
  }
  dispatch({ type: actionTypes.SET_MY_FAVORITES, result })
}

export const updateMyFavorites = (type, id, isFavorite) => (dispatch) => {
  const favorites: any = [...initialState.myFavorites]
  if (isFavorite) {
    favorites.push({
      entityType: type.toString(),
      entityId: id.toString()
    })
  } else {
    const indexInSource = initialState.myFavorites.findIndex((favorite: any) => favorite.entityId === id.toString())
    if (indexInSource !== -1) {
      favorites.slice(indexInSource, 1)
    }
  }
  dispatch({ type: actionTypes.UPDATE_MY_FAVORITES, favorites })
}

export const setCurrentShowTab = show => (dispatch) => {
  initialState.currentShowTab = show
  dispatch({ type: actionTypes.SET_CURRENT_SHOW_TAB, show })
}

export const setIsLoading = value => (dispatch) => {
  initialState.isLoading = value
  dispatch({ type: actionTypes.SET_IS_LOADING, value })
}

export const initSortBy = url => (dispatch) => {
  let sortBy = 'sortingName'
  if (url === catalogEntityUrl.artworks) {
    sortBy = 'random'
  }
  initialState.defaultParams.sortBy = sortBy
  dispatch({ type: actionTypes.INIT_SORT_BY, sortBy })
}

// ACTION HANDLERS
const actionHandlers = {
  [actionTypes.SET_CATALOG_DATA]: (state, action) => ({
    ...state,
    catalogData: action.payload
  }),
  [actionTypes.RESET_CATALOG_MODULE]: () => initialState,
  [actionTypes.SET_CHANGING_SORT]: (state, action) => ({ ...state, isChangingSort: action.payload }),
  [actionTypes.SET_FILTERS]: (state, action) => {
    const newState = { ...state }
    const currentObject = newState.currentParams
    currentObject.showIds = action.payload.showIds
    return { ...state,
      filters: action.payload.filters,
      currentParams: currentObject
    }
  },
  [actionTypes.SET_QUERY_PARAMS]: (state, action) => {
    const currentObject = action.payload.params
    return { ...state,
      currentParams: action.payload.resetParams ?
        { ...currentObject } : { ...state.currentParams, ...currentObject }
    }
  },
  [actionTypes.SET_SECONDARY_MENUS]: (state, action) => ({ ...state, secondaryMenus: action.value }),
  [actionTypes.SET_CITY_FILTERS]: (state, action) => ({ ...state, cityFilters: action.value }),
  [actionTypes.SET_MY_FAVORITES]: (state, action) => ({ ...state, myFavorites: action.result }),
  [actionTypes.UPDATE_MY_FAVORITES]: (state, action) => ({ ...state, myFavorites: action.favorites }),
  [actionTypes.SET_CURRENT_SHOW_TAB]: (state, action) => ({ ...state, currentShowTab: action.show }),
  [actionTypes.SET_IS_LOADING]: (state, action) => ({ ...state, isLoading: action.value }),
  [actionTypes.INIT_SORT_BY]: (state, action) => {
    const newState = { ...state }
    const currentObject = newState.defaultParams
    currentObject.sortBy = action.sortBy
    return { ...state,
      defaultParams: currentObject
    }
  }
}

// REDUCER
const catalogReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export default catalogReducer