import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'

const useCopyMoveCollectionDetail = () => {
  const [{ showModal, modalData, isOrganizeMode }, dispatch] = useMyCollectionsPageContext()
  // const { Collections } = labels;
  const copyCollectionItems = (id, type, selecteditems) => {
    dispatch({ type: 'SHOW_COPY_MODAL', payload: { id, type, selecteditems } })
  }
  const moveCollectionItems = (id, type, selecteditems, updateItems) => {
    dispatch({ type: 'SHOW_MOVE_MODAL', payload: { id, type, selecteditems, updateItems } })
  }
  const hideModal = () => {
    dispatch({ type: 'HIDE_MODAL' })
  }
  const defaultState = () => {
    dispatch({ type: 'SET_ORGANIZE_MODE', payload: !isOrganizeMode })
    dispatch({ type: 'SET_SELECTED_ITEMS', payload: [] })
    dispatch({ type: 'HIDE_MODAL' })
  }
  return {
    showModal,
    modalData,
    copyCollectionItems,
    moveCollectionItems,
    hideModal,
    defaultState
  }
}

export default useCopyMoveCollectionDetail
