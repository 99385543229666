import { FC, useContext, useState } from 'react'
import { FormikConsumer } from 'formik'

import { Row, Col, Text, Dropdown, Checkbox, Button } from '@mch-group/uikit-components'
import { buildSelectOptionsForUiKitDropdown, handleNoAutocomplete } from '@utils/dropdown'
import { Translations as TranslationsService } from '@services'
import { PasswordValidationCheck } from '@components'

import StepLabel from './common/StepLabel'
import { useUserAccessContext } from '../context/useUserAccessContext'
import { PageContext } from '@providers/PageContext'

interface IRegisterUserFirstStep {
  countries: Object
}

const RegisterUserFirstStep: FC<IRegisterUserFirstStep> = ({
  countries
}) => {
  const { page: { siteLanguage } } = useContext(PageContext)
  const {
    state: { apiErrorMessage, isLoading, labels: userAccessLabels, otherData },
    dispatch
  } = useUserAccessContext()
  const [ passwordType, setPasswordType ] = useState('text')

  return (
    <FormikConsumer>
      {({ handleBlur, values, errors, touched, handleChange, setFieldValue }) => (
        // @ts-ignore
        <Col className={!otherData.isModal && 'mt-0'}>
          {!otherData.isModal && <h4 className='pb-10'>{userAccessLabels.Access.createAccountHeadingTextLabel}</h4>}
          <StepLabel step='Step 1' />
          <h5 className='mb-7'>{userAccessLabels.Access.createAccountExtraInfoTextLabel}</h5>
          <Row>
            <Col className='mt-7'>
              <Text
                label={userAccessLabels.Access.emailAddressLabel}
                type='email'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                id='email'
                // @ts-ignore
                helpText={touched.email && errors.email}
                helpTextType={errors.email && touched.email ? 'danger' : 'muted'}
                disabled
              />
            </Col>
          </Row>
          <Row className='gx-0'>
            <Col className='col-12 col-md-6'>
              <Text
                label={userAccessLabels.Access.firstNameLabel}
                type='text'
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                id='firstName'
                // @ts-ignore
                helpText={touched.firstName && errors.firstName}
                helpTextType={errors.firstName && touched.firstName ? 'danger' : 'muted'}
                className='mt-3 mt-md-5'
              />
            </Col>
            <Col className='col-md-6'>
              <Text
                label={userAccessLabels.Access.lastNameLabel}
                type='text'
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                id='lastName'
                // @ts-ignore
                helpText={touched.lastName && errors.lastName}
                helpTextType={errors.lastName && touched.lastName ? 'danger' : 'muted'}
                className='mt-3 mt-md-5'
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Dropdown
                label={userAccessLabels.Access.countryLabel}
                options={buildSelectOptionsForUiKitDropdown(countries, {
                      value: 'name',
                      label: 'name',
                      id: 'crmGuid'
                    })}
                name='country'
                a11yData={{
                  searchBtn: userAccessLabels.AltTags.megamenuSearch,
                  toggleBtn: '',
                  infoBtn: ''
                }}
                placeholder={userAccessLabels.Access.countryLabel}
                // @ts-ignore
                helpText={touched.country && errors.country}
                helpTextType={errors.country && touched.country ? 'danger' : 'muted'}
                variant='search'
                // @ts-ignore
                onChange={(e) => setFieldValue('country', e)}
                onFocus={handleNoAutocomplete}
                className='mt-3 mt-md-5'
                value={values.country}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Text
                label={userAccessLabels.Access.passwordWithOptionalText}
                // @ts-ignore
                type={values.password ? 'password' : passwordType}
                value={values.password}
                onChange={(e) => {
                  setPasswordType('password')
                  handleChange(e)
                }}
                onBlur={(e) => {
                  setPasswordType('password')
                  handleBlur(e)
                }}
                name='password'
                // @ts-ignore
                helpText={touched.password && errors.password}
                helpTextType={errors.password && touched.password ? 'danger' : 'muted'}
                className='mt-3 mt-md-5'
              />
            </Col>
          </Row>
          {values.password &&
            <Row>
              <Col>
                <PasswordValidationCheck
                  passwordHeadingLabel={userAccessLabels.Access.passwordValidationHeaderLabel}
                  passwordString={values.password}
                  minCharLabel={userAccessLabels.Access.validateLengthFieldLabel}
                  upperCaseLabel={userAccessLabels.Access.validateUpperCaseFieldLabel}
                  numericalCharLabel={userAccessLabels.Access.validateNumericFieldLabel}
                  className='mt-7'
                  isPasswordValidated={!!(touched.password && errors.password)}
                />
              </Col>
            </Row>
              }
          <Row>
            <Col>
              <Checkbox
                id='termsCondition'
                label={userAccessLabels.Access.agreeLabel}
                onChange={handleChange}
                checked={values.termsCondition}
                // @ts-ignore
                onBlur={handleBlur}
                className={`d-inline-block ${values.password ? '' : 'mt-7'} mb-4`}
                // @ts-ignore --> REVIEW @Lata, can empty string be moved to 'default'? because empty doesn't exist in UIKIT
                status={errors.termsCondition && touched.termsCondition ? 'error' : ''}
              />
              <a href='/terms-of-use' target='_blank'>
                <strong>{' '}{userAccessLabels.Access.termsAndConditionLinkLabel}</strong>
              </a>
            </Col>
            {errors.termsCondition && touched.termsCondition && (
              <span className='text-danger form-text mb-4'>{errors.termsCondition}</span>
                )}
          </Row>
          <Row>
            <Col>
              <Checkbox
                label={userAccessLabels.Access.newsLetterCheckbox}
                id='newsletter'
                checked={values.newsletter}
                // @ts-ignore
                onChange={handleChange}
                // @ts-ignore
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='d-grid mt-8'>
                {apiErrorMessage && <span className='text-danger form-text mb-4'>{apiErrorMessage}</span>}
                <Button
                  type='submit'
                  isLoading={isLoading}
                  onClick={async() => {
                        dispatch({ type: 'SET_API_ERROR_MESSAGE', payload: '' })
                        const emailSentLabel = await TranslationsService.getDynamicLabelValue(
                          'Access',
                          'verifyRegistrationEmailTextLabel',
                          siteLanguage,
                          `${values.email},2`
                        )
                        // @ts-ignore
                        setFieldValue('emailSentInviteText', emailSentLabel?.data.value)
                      }}
                >
                  {userAccessLabels.Access.registerButtonLabel}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      )}
    </FormikConsumer >
  )
}

export default RegisterUserFirstStep
