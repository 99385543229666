import { useContext, useEffect, useState } from 'react'
import { object, bool, string } from 'prop-types'
import { withRouter } from 'next/router'
import ViewRoomArtwork from './ViewRoomArtwork'
import CloseIcon from './CloseIcon'
import { Wrapper, GridContainer, GridItem, Wall, Layer1, Layer2, Layer3, GlobalStyle } from './Styles'
import { PageContext } from '@providers/PageContext'

/**
 * @param {object} router
 */
const ViewRoom = ({ router }) => {
  const { page: { pageData: pageMetaInfo } } = useContext(PageContext)
  const {
    images,
    unitMeasure,
    roomType,
    height,
    framedHeight,
    framedUnitMeasure,
    displayName,
    verticalOffsetCm,
    id,
    cleanTitle
  } = pageMetaInfo
  const [loaded, setLoaded] = useState(false)
  const isComingFromOVRRoom = router?.query?.comingFromOVRRoom === 'true'

  // artwork measures calculation
  const isInches = framedHeight ? framedUnitMeasure.toLowerCase() === 'inches' : unitMeasure.toLowerCase() === 'inches'
  //@ts-ignore
  const calcHeight = (h) => parseInt(h * (isInches ? 2.54 : 1))
  const heightCm = !framedHeight ? calcHeight(height) : calcHeight(framedHeight)

  // scenario
  const types = ['SMALL', 'STANDARD']
  const supportedType = types && roomType ? types.indexOf(roomType) >= 0 : false

  useEffect(() => {
    !loaded && setLoaded(true)
  }, [loaded])

  if (loaded && supportedType && images && images.length > 0) {
    return (
      //@ts-ignore
      <Wrapper roomType={roomType} className='no-margin-top'>
        <GridContainer>
          {/** @ts-ignore */}
          <GridItem roomType={roomType}>
            {/** @ts-ignore */}
            <Wall roomType={roomType} className='wall'>
              <Layer1>
                <Layer2>
                  <Layer3>
                    {/** @ts-ignore */}
                    <ViewRoomArtwork
                      img={images[0]}
                      height={heightCm}
                      title={displayName}
                      roomType={roomType}
                      verticalOffset={verticalOffsetCm}
                    />
                  </Layer3>
                </Layer2>
              </Layer1>
            </Wall>
            <CloseIcon
              href={{ pathname: '/artwork', query: { comingFromOVRRoom: isComingFromOVRRoom ? 'true' : false } }}
              as={`/catalog/artwork/${id}/${cleanTitle}?${isComingFromOVRRoom ? '?comingFromOVRRoom=true' : ''}`}
            />
          </GridItem>
        </GridContainer>
        <GlobalStyle />
      </Wrapper>
    )
  }
  return null
}

ViewRoom.propTypes = {
  router: object,
  roomInfo: bool,
  direction: string,
  type: string
}

export default withRouter(ViewRoom)
