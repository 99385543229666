import React from 'react'
import useSWRInfinite from 'swr/infinite'
import qs from 'query-string'

import { useMyCollectionsPageContext } from '@modules/collections/contexts/useMyCollectionsPageContext'
import TABS, { ARTWORKS_TAB, ARTISTS_TAB } from '@modules/collections/constants/TABS'
import { Collections as CollectionsService } from '@services'
import { getSiteLanguage } from '@utils'

const useEditPreviewCollectionData = () => {
  const [{ detailList: collectionDetailItemsList,
    router, entityType, activeTab }, dispatch] = useMyCollectionsPageContext()
  const fields = TABS[activeTab || ARTWORKS_TAB].fields


  // Collections
  const {
    data: editPreviewCollectionItems,
    error: editPreviewCollectionItemsError,
    isValidating: editPreviewCollectionItemsValidating,
    mutate: mutateEditPreviewCollectionItems,
    size,
    setSize
  } = useSWRInfinite(
    (index) => {
      let collectionId = router.query && router.query.id || null
      if (!collectionId && typeof window !== 'undefined') {
        collectionId = qs.parseUrl(window.location.href)
        if (collectionId.query && collectionId.query.id) {
          collectionId = collectionId.query.id
        }
      }
      if (index === 0) {
        return collectionId && entityType && `${CollectionsService.getCollectionEndpoint}/${collectionId}/items?limit=34&offset=0&entityType=${entityType}&fields=${fields}&locale=${getSiteLanguage()}`
      }
      return collectionId && entityType && `${CollectionsService.getCollectionEndpoint}/${collectionId}/items?limit=36&offset=${(36 * index) - 2}&entityType=${entityType}&fields=${fields}&locale=${getSiteLanguage()}`
    },
    {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
      revalidateAll: true
    }
  )

  React.useEffect(() => {
    if (editPreviewCollectionItems) {
      //store the collection items list in reducer
      dispatch({ type: 'SET_EDIT_PREVIEW_LIST', payload: editPreviewCollectionItems })
    }
  }, [dispatch, editPreviewCollectionItems])

  const itemsLength = collectionDetailItemsList.length
  const items = itemsLength ? collectionDetailItemsList.map(i => i.items) : []
  const posts = items ? [].concat(...items) : []
  const filterDataIfArtistsTab = activeTab === ARTISTS_TAB ? posts?.filter(item => item.status.hidden !== true) : posts
  // this is a temporary solution as we have to hide the artist without artworks because of page crash

  //id key is required in virtual grid and it's coming with the different entity from the api
  //So we will add a new property "id" in the exisitng object.
  const editPreviewCollectionItemsWithId = filterDataIfArtistsTab ?
    filterDataIfArtistsTab.map(i => ({ ...i, id: i.entityId }))
    : []

  const isLoadingInitialData = !itemsLength && !editPreviewCollectionItemsError
  const isEmpty = itemsLength ? !collectionDetailItemsList.slice(-1)[0].hasMore : true
  const isReachingEnd = isEmpty || (itemsLength ? !collectionDetailItemsList[0].hasMore : false)
  const isRefreshing =
    editPreviewCollectionItemsValidating && itemsLength && collectionDetailItemsList[0].items.length === size
  const isLoadingMore =
    isLoadingInitialData || (size > 0 && itemsLength && typeof collectionDetailItemsList[size - 1] === 'undefined')
  const totalItems = itemsLength && filterDataIfArtistsTab.length

  return {
    // Collections
    isLoadingInitialData,
    size,
    setSize,
    isReachingEnd,
    isRefreshing,
    isLoadingMore,
    isEmpty,
    editPreviewCollectionItems: editPreviewCollectionItemsWithId,
    editPreviewCollectionItemsPages: items,
    editPreviewCollectionItemsError,
    editPreviewCollectionItemsValidating,
    mutateEditPreviewCollectionItems,
    totalItems
  }
}

export default useEditPreviewCollectionData
