import { createContext, useRef, FC } from 'react'
import { NotificationMessage } from '@dmi-mch/components'
import styled from 'styled-components'
import { px2rem } from '@utils'
import useVisibilityTransition from '@hooks/useVisibilityTransition'
import { useLabelsFromAPI } from '@dmi-mch/hooks'
import { getSiteLanguage } from '@utils/storage'
import { INotificationsContext, INotificationsProvider } from './types'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

// PARAMS IT CAN RECEIVE:
// header, content, ctaAction, ctaLabel, defaultCtaLabel, time

// EXAMPLE OF USE:
// Import the Context feature and the Notifications context:
// import { useContext } from 'react'
// import { NotificationsContext } from 'context/Notifications'

// Import the Context
/*
const context = useContext(NotificationsContext)
const { showNotification } = context
*/

// Use in your JSX
/*
<button
  type='button'
  onClick={() => showNotification({
    header: 'Event Saved',
    content: 'You can continue with your tasks',
    time: 3000
  })}
>Trigger notification
</button>
*/

const NotificationsContext = createContext<INotificationsContext>({} as INotificationsContext)

// How long (in seconds) will the CSS transition take. Not to confuse with the time the Notification stays in display
const transitionTimeInSeconds = 1

const NotificationsProvider: FC<INotificationsProvider> = ({ children }) => {
  const { show, hide, state } = useVisibilityTransition(transitionTimeInSeconds * 1000)
  const { labels } = useLabelsFromAPI(['Notifications'], getSiteLanguage())
  const { Notifications } = labels || {}
  const defaultParams = {
    time: 3000,
    color: tokens.color.light.base.alerts.green[500].value
  }

  const header = useRef(null)
  const content = useRef(null)
  const ctaAction = useRef(null)
  const ctaLabel = useRef<string | null>(null)
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const showNotification = (params) => {
    timeout.current && clearTimeout(timeout.current)
    // Merges the default params with those coming from the request
    const newParams = { ...defaultParams, ...params }
    // Sets the new message
    header.current = newParams.header
    content.current = newParams.content
    // Makes it visible
    show()
    // sets callback action
    ctaAction.current = params.ctaAction
    // sets callback action label
    ctaLabel.current = params.ctaLabel
    // There is default label for the "cta", that usually has value "View".
    if (params.defaultCtaLabel) {
      ctaLabel.current = Notifications.viewLabel
    }
    // Hide it in the specified time
    timeout.current = setTimeout(() => {
      hide()
    }, newParams.time)
  }

  const closeNotification = () => {
    hide()
    timeout.current && clearTimeout(timeout.current)
  }

  return (
    <NotificationsContext.Provider value={{ showNotification }}>
      {
        //@ts-ignore
        state.exists ? (
          //@ts-ignore
          <NotificationsProviderStyled $visibility={state}>
            <NotificationMessageStyled
              show
              header={header.current}
              content={content.current}
              color='black'
              ctaLabel={ctaLabel.current}
              ctaAction={ctaAction.current}
              closeClickCallback={closeNotification}
              showCloseButton
            />
          </NotificationsProviderStyled>
        ) : null
      }
      {children}
    </NotificationsContext.Provider>
  )
}

const NotificationsProviderStyled = styled.div<{ $visibility: { visible: boolean }}>`
  > div {
    transition: all ${transitionTimeInSeconds}s !important;
    opacity: ${({ $visibility }) => !$visibility.visible ? 0 : 1 };
    right: ${({ $visibility }) => !$visibility.visible && `-${px2rem(450)}`};
  }
`

const NotificationMessageStyled = styled(NotificationMessage)`
  background-color: ${tokens.color.base.primary.black.value};
`

export default NotificationsProvider

export { NotificationsContext }
