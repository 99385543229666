import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'
import { px2rem } from '@utils'
import { Icon } from '@mch-group/uikit-components'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'
import tokensRenamed from '@constants/tokensRenamed'

const Pagination = ({ total, params, doFetch, onPageClickCallback }) => {
  //@ts-ignore
  const doPageClick = React.useCallback((data) => {
    const filterParams = { ...params }
    filterParams.offset = Math.ceil(data.selected * params.limit)
    doFetch(filterParams)
    typeof onPageClickCallback() === 'function' && onPageClickCallback()
  }, [doFetch, onPageClickCallback, params])

  return (
    <PaginationWrapper>
      <ReactPaginate
        previousLabel={<Icon name='chevron-left' />}
        nextLabel={<Icon name='chevron-right' />}
        breakLabel='...'
        breakClassName='break-me'
        pageCount={Math.ceil(total / params.limit)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={doPageClick}
        containerClassName='pagination'
        subContainerClassName='pages pagination'
        activeClassName='active'
        forcePage={params.offset === 0 ? 0 : params.offset / params.limit}
      />
    </PaginationWrapper>
  )
}

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  params: PropTypes.object.isRequired,
  doFetch: PropTypes.func.isRequired,
  onPageClickCallback: PropTypes.func
}

const PaginationWrapper = styled.div`
  .pagination {
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin: ${px2rem(40)} 0 0;
    border-radius: ${px2rem(4)};
    overflow: hidden;

    li {
      display: inline;

      a {
        outline: 0;
        color: var(--bs-mch-gray-500);
        font-weight: ${tokens.fontWeights.artBaselText1.value};
        cursor: pointer;
        position: relative;
        float: left;
        padding: ${px2rem(8)} ${px2rem(15)};
        line-height: ${tokensRenamed.lineHeights[7].value};
        text-decoration: none;
        background-color: var(--bs-mch-white);
        border: 1px solid var(--bs-mch-galleries);
        margin-left: 6px;
      }

      &.active {
        a {
          background-color: var(--bs-mch-gray-500);
          border-color: var(--bs-mch-gray-500);
        }
      }

      &.next,
      &.previous {
        a {
          border: unset !important;
        }
      }
    }

    & > .active > a,
    & > .active > a:hover,
    & > .active > span,
    & > .active > span:hover {
      z-index: 2;
      color: ${tokens.color.base.primary.white.value};
      cursor: default;
      background-color: var(--bs-mch-gray-500);
      border-color: var(--bs-mch-gray-500);
    }

    & > li:first-child > a,
    & > li:first-child > span {
      margin-left: 0;
      border-top-left-radius: ${px2rem(4)};
      border-bottom-left-radius: ${px2rem(4)};
    }

    & > li:last-child > a,
    & > li:last-child > span {
      border-top-right-radius: ${px2rem(4)};
      border-bottom-right-radius: ${px2rem(4)};
    }

    & > li > a:hover,
    & > li > span:hover {
      background-color: var(--bs-mch-gray-100);
      border: 1px solid ${tokens.color.light.base.neutrals['400'].value};
    }
  }

  .disabled {
    display: none !important;
  }
`

export default Pagination