const preferredContactOption = (artworkContactFormLabels) => {
  const contactOption = [
    {
      name: 'email',
      text: artworkContactFormLabels?.preferedContactEmail,
      value: 'EMAIL'
    },
    {
      name: 'phone',
      text: artworkContactFormLabels?.preferedContactPhone,
      value: 'PHONE_CALL'
    },
    {
      name: 'text',
      text: artworkContactFormLabels?.preferedContactTextMessage,
      value: 'TEXT_MESSAGE'
    }
  ]
  return contactOption
}
export default preferredContactOption
