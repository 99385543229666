import { useEffect, useCallback } from 'react'
import { EVGCompactTable, OSCompactRoomsTable, OSCompactArtworksTable } from '../IndexedDB/config'
import { ICompactTable } from '../IndexedDB/types'

// WIP
const useDeleteListIfOutsidePath = (router) => {
  const loopAndDeleteModule = useCallback(async () => {
    const EVGCompactModulesInDB = await EVGCompactTable.toArray()
    const indexToDelete: number[] = []

    EVGCompactModulesInDB.forEach(async (i: ICompactTable) => {
      const isCurrentUrlInModulePaths = i?.paths?.findIndex((x) => router?.asPath?.includes(x)) === -1 ? false : true
      if (!isCurrentUrlInModulePaths && router?.asPath !== i.origin) {
        indexToDelete.push(i.id)
      }
    })
    const osCompactRoomModulesInDB = await OSCompactRoomsTable.toArray()
    const indexToDeleteOSCompactRooms: number[] = []

    osCompactRoomModulesInDB.forEach(async (i: ICompactTable) => {
      const isCurrentUrlInModulePaths = i?.paths?.findIndex((x) => router?.asPath?.includes(x)) === -1 ? false : true
      if (!isCurrentUrlInModulePaths && router?.asPath !== i.origin) {
        indexToDeleteOSCompactRooms.push(i.id)
      }
    })

    const osCompactArtworksModuleInDB = await OSCompactArtworksTable.toArray()
    const indexToDeleteOSCompactArtworks: number[] = []

    osCompactArtworksModuleInDB.forEach(async (i: ICompactTable) => {
      const isCurrentUrlInModulePaths = i?.paths?.findIndex((x) => router?.asPath?.includes(x)) === -1 ? false : true
      if (!isCurrentUrlInModulePaths && router?.asPath !== i.origin) {
        indexToDeleteOSCompactArtworks.push(i.id)
      }
    })

    if (indexToDelete.length > 0) {
      EVGCompactTable.bulkDelete(indexToDelete)
    }

    if(indexToDeleteOSCompactRooms) {
      OSCompactRoomsTable.bulkDelete(indexToDeleteOSCompactRooms)
    }

    if(indexToDeleteOSCompactArtworks) {
      OSCompactArtworksTable.bulkDelete(indexToDeleteOSCompactArtworks)
    }
  }, [router])

  useEffect(() => {
    loopAndDeleteModule()
  }, [loopAndDeleteModule])

  return {}
}

export default useDeleteListIfOutsidePath
