import React from 'react'

import { debounce } from '@utils'

/**
 * @param  {func} trigger
 * @param  {number} ms
 */
const useDebouncedResize = (trigger, ms) => {
  React.useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      typeof trigger === 'function' && trigger()
    }, ms)
    window.addEventListener('resize', debouncedHandleResize)
    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [ms, trigger])
}

export default useDebouncedResize