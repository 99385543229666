import { NextRouter } from 'next/router'
import isExternalLink from './isExternalLink'
import qs from 'query-string'

const redirectOnCtaUrl = (ctaLink: string, router: NextRouter) => {
  if (isExternalLink(ctaLink)) {
    window.location.href = ctaLink
  } else {
    router.push({
      pathname: '/',
      query: {
        ...qs.parseUrl(ctaLink).query
      }
    },
      ctaLink)
  }
}

export default redirectOnCtaUrl
