import cloudinary2cloudfront from './cloudinary2cloudfront'

/**
 * This function makes the images in the Megamenu JSON to be smaller
 * @param {*} object
 * @param {*} pxNum
 */

const parseMegamenuJSONToAdaptImageWidths = (obj) => {
  let stringified = JSON.stringify(obj)
  const regex = /\b(https?:\/\/\S*?\.(?:png|jpe?g|gif)(?:\?(?:(?:(?:[\w_-]+=[\w_-]+)(?:&[\w_-]+=\w_-]+)*)|(?:[\w_-]+)))?)\b/igm
  const match = stringified.match(regex)

  if (match) {
    stringified = stringified.replace(regex, (matchText) => cloudinary2cloudfront(matchText, 'q_auto,c_limit,w_600'))
  }
  return JSON.parse(stringified)
}

export default parseMegamenuJSONToAdaptImageWidths
