const REDUCERNAME = 'favorites'

const initialState = {
  isInitialized: false,
  favoritesList: []
}

// ACTION TYPES
const actionTypes = {
  SET_FAVORITES: `${REDUCERNAME}__SET_FAVORITES`,
  UPDATE_FAVORITES: `${REDUCERNAME}__UPDATE_FAVORITES`,
  CLEAR_FAVORITES: `${REDUCERNAME}__CLEAR_FAVORITES`
}

// ACTIONS
export const setFavorites = favorites => dispatch => dispatch({
  type: actionTypes.SET_FAVORITES,
  favorites
})

export const updateFavorites = (type, id, isFavorite) => dispatch =>
  dispatch({ type: actionTypes.UPDATE_FAVORITES, payload: { type, id, isFavorite } })

export const clearFavorites = () => dispatch => dispatch({ type: actionTypes.CLEAR_FAVORITES })

// ACTION HANDLERS
const actionHandlers = {
  [actionTypes.SET_FAVORITES]: (state, action) =>
    ({ isInitialized: true, favoritesList: [...state.favoritesList, ...action.favorites] }),
  [actionTypes.UPDATE_FAVORITES]: (state, action) => {
    const favorites = [...state.favoritesList]
    const { type, id, isFavorite } = action.payload

    if (isFavorite) {
      favorites.push({
        entityType: type.toString(),
        entityId: id.toString()
      })
    } else {
      const indexInSource = favorites.findIndex(favorite =>
        (favorite.entityId.toString() === id.toString() && favorite.entityType.toString() === type.toString()))
      if (indexInSource !== -1) {
        favorites.splice(indexInSource, 1)
      }
    }

    return { ...state, favoritesList: favorites }
  },
  [actionTypes.CLEAR_FAVORITES]: () => ({ ...initialState })
}

// REDUCER
const favoritesReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export default favoritesReducer
