import React from 'react'
import PropTypes from 'prop-types'

//@ts-ignore
const FingerprintContext = React.createContext()

/**
 * This Provider creates a context with the fingerprint
 */
const FingerprintProvider = ({ children }) => {
  const [isLibraryImported, setIsLibraryImported] = React.useState(false)
  const [fingerprint, setFingerprint] = React.useState(null)

  React.useEffect(() => {
    async function importLibrary() {
      // Importing the library dynamically, because since import time, there are calls to window object
    // If not done like this, when SSR, there would be errors
      const importedLibrary = await import('@fingerprintjs/fingerprintjs')
      const fpPromise = importedLibrary.load()
      setIsLibraryImported(true)
      const fp = await fpPromise
      const result = await fp.get()

      // This is the visitor identifier:
      const visitorId = result.visitorId
      //@ts-ignore
      setFingerprint(visitorId)

    }
    !isLibraryImported && importLibrary()
  }, [isLibraryImported])

  return (
    <FingerprintContext.Provider value={{ fingerprint }}>
      {children}
    </FingerprintContext.Provider>
  )
}

FingerprintProvider.propTypes = {
  children: PropTypes.node
}

export default FingerprintProvider

export { FingerprintContext }
