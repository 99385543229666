import { generateMedia } from 'styled-media-query'

/**
 * All the variables pre-fixed as "largest" are "major" breakpoints minus 1px,
 * because they are the "roof" of a range, before the next range starts.
 */
export const breakpoints = {
  quadHD: MFP.vars.quadHDBreakpoint,
  large4K: MFP.vars.large4KBreakpoint,
  large8K: MFP.vars.largest8K,

  wide: MFP.vars.widescreenMonitorBreakpoint, /* 1920 */
  large: MFP.vars.largeMonitorBreakpoint, /* 1200 */
  largestLarge: MFP.vars.largestLargeMonitor, /* 1919 */
  largestMedium: MFP.vars.largestMediumMonitor, /* 1365 */
  breakpoint1366: MFP.vars.largeMediumMonitorBreakpoint, /* 1366 */

  // Introduce any device name here
  breakpoint1679: '1679px',
  breakpoint1680: '1680px',

  // Introduce any device name here
  breakpoint1479: '1479px',
  breakpoint1480: '1480px',
  breakpoint1280: '1280px',
  desktop: MFP.vars.computerBreakpoint, /* 992 */
  largestDesktop: MFP.vars.largestSmallMonitor, /* 1199 */

  tablet: MFP.vars.tabletBreakpoint, // 767px
  largestTablet: MFP.vars.largestTabletScreen, // 991px

  mobile: MFP.vars.mobileBreakpoint,
  largestMobile: MFP.vars.largestMobileScreen,
  largestMobilePortrait: MFP.vars.largestMobilePortrait
}

const customMedia = generateMedia(breakpoints)

export default customMedia
