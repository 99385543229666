import Notifications from '@providers/Notifications'
import SnackBarNotification from '@providers/SnackBarNotification'
import Confirm from '@providers/Confirm'
import CollectionsProvider from '@modules/collections/providers/CollectionsProvider'
import AddToCollectionProvider from '@components/collections/AddToCollection'
import StopRedrawProvider from '@providers/StopRedraw'
import LoginProvider from '@providers/Login'
import CollectionForm from '@components/collections/collection-form'
import OverlayProvider from '@providers/Overlay'
import FingerprintProvider from '@providers/Fingerprint'
import DefererProvider from '@providers/Deferer'
import Loadingscreen from '@providers/Loadingscreen'
import ListsProviderWithDataDump from '../stores/lists'

interface Props {
  components: any // ?
  children: React.ReactNode
}

// Function that combines React components, mostly used for Contexts
function Compose(props: Props) {
  const { components = [], children } = props

  return components.reduceRight((acc, Comp) => <Comp>{acc}</Comp>, children)
}

const providers = [
  StopRedrawProvider,
  DefererProvider,
  FingerprintProvider,
  ListsProviderWithDataDump,
  OverlayProvider,
  Loadingscreen,
  Confirm,
  Notifications,
  SnackBarNotification,
  LoginProvider,
  CollectionsProvider,
  CollectionForm,
  AddToCollectionProvider
]

const Wrappedhelpers = ({ children }) => (
  <Compose components={providers}>
    {children}
  </Compose>
)

export default Wrappedhelpers