// Adding exception to stylelint because this is old code,
// All errors were fixed except this no-duplicate-selectors
/* stylelint-disable no-duplicate-selectors */
import { createGlobalStyle } from 'styled-components'
import { px2rem } from '@dmi-mch/utils'

const Spacings = createGlobalStyle`
    --MarginH2ToH3: 15px;
    --MarginH2ToH3Mobile: 10px;
    --MarginH3SubtToP: 10px;
    --MarginH3SubtToPMobile: 10px;
    --MarginH3ToH3SubtMobile: 5px;
    --MarginH5ToH2: 5px;

    .prio {
      /* -------------------
      headings
      -------------------- */
      h2.ui.header {
        margin: 0;
        /* Distance H2 <---> H3 */
        & + h3.ui.header {
          margin-top: var(--MarginH2ToH3);

          @media (max-width: 767px) {
            margin-top: var(--MarginH2ToH3Mobile);
          }
        }
      }

      h3.ui.header {
        margin: 0;

        &.subtitle {
          /* Distance H3.subtitle <---> P */
          & + p {
            margin-bottom: 0;
            margin-top: var(--MarginH3SubtToP);

            @media (min-width: 768px) and (max-width: 991px) {
              margin-top: var(--MarginH3SubtToPMobile) * 1.5;
            }

            @media (max-width: 767px) {
              margin-top: var(--MarginH3SubtToPMobile);
            }
          }
        }

        & + h3.header.ui.subtitle {
          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: var(--MarginH3ToH3SubtMobile) * 1.5;
          }

          @media (max-width: 767px) {
            margin-top: var(--MarginH3ToH3SubtMobile);
          }
        }
      }

      h5.ui.header {
        margin: 0;

        & + h2.ui.header {
          @media (min-width: 992px) {
            margin-top: var(--MarginH5ToH2);
          }

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: var(--MarginH5ToH2Mobile) * 1.5;
          }

          @media (max-width: 767px) {
            margin-top: var(--MarginH5ToH2Mobile);
          }
        }
      }

      /* Text Component followed by a Footer element, will add some margin
      top in the footer */
      div[class^='Text__'] {
        & + footer {
          margin-top: 30px;
        }
      }

      /* Not sure this "header" is used */

      &.page-has-menu-component {
        .site-modules {
          &:first-of-type {
            @media (min-width: 768px) and (max-width: 991px) {
              margin-top: ${px2rem(75 * 1.5)};
            }

            @media (max-width: 767px) {
              margin-top: ${px2rem(100)};
            }
          }
        }
      }
    }

    /* ==========================================================================
    Module Spacings
    ========================================================================== */
    /* General rule: all modules have margin bottom except Signpost */
    .cms-module:not([class^='Signpost']) {
      @media (min-width: 992px) {
        margin-bottom: 80px;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        margin-bottom: calc(40px * 1.5);
      }

      @media (max-width: 767px) {
        margin-bottom: 40px;
      }

      &.small-spacing-top {
        @media (min-width: 992px) {
          margin-top: -(calc(80px / 2));
        }

        @media (min-width: 768px) and (max-width: 991px) {
          margin-top: -(calc(40px / 2)) * 1.5;
        }

        @media (max-width: 767px) {
          margin-top: -(40px / 2);
        }
      }
    }

    /* Signpost followed by any other module. The following module should have
    some spacing on top, simulating the margin bottom. */
    section[class^='Signpost'] {
      & + section:not([class^='Promotional']) {
        @media (min-width: 992px) {
          margin-top: 80px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
          margin-top: calc(40px * 1.5);
        }

        @media (max-width: 767px) {
          margin-top: 40px;
        }
      }
      /* Signpost followed by Signpost, the second Signpost should be
      closer to the first. */
      & + section[class^='Signpost'] {
        @media (min-width: 992px) {
          margin-top: -${px2rem(85)};
        }

        @media (min-width: 768px) and (max-width: 991px) {
          margin-top: -60px;
        }

        @media (max-width: 767px) {
          margin-top: -40px;
        }
      }
    }

    /* fix spacing top issues with different fields in promotional module */
    .site-modules:not(.event-detail) {
      & > section:first-of-type[class^='Promotional'] .rm-promotional--spacing {
        &-title {
          margin-top: 60px;

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: 6px;
          }

          @media (max-width: 767px) {
            margin-top: 4px;
          }
        }

        &-label {
          margin-top: 63px;

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: 9px;
          }

          @media (max-width: 767px) {
            margin-top: 6px;
          }
        }

        &-author {
          margin-top: 60px;

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: 5px;
          }

          @media (max-width: 767px) {
            margin-top: 3px;
          }
        }

        &-description {
          margin-top: 45px;

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: 8px;
          }

          @media (max-width: 767px) {
            margin-top: 5px;
          }
        }

        &-subtitle {
          margin-top: 63px;

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: 6px;
          }

          @media (max-width: 767px) {
            margin-top: 4px;
          }
        }
      }
      /* The first module in the list of modules, should have some space to separate
      from the secondary navigation or the Title module,
      except these modules below (the ones in :not), that don't need separation */
      > *:first-child:not([class^='artworks-detail']):not([class^='user-access']):not([class^='Signpost']):not([class^='VideoPlayer']):not([class^='News']):not([class^='Promotional']):not([class^='rm-online-show-teaser']):not([class^='rm-secondary-header']):not([class^='PersonalDetail']):not([class^='no-margin-top']):not([class^='secom-module']):not(.mt-0) {
        margin-top: ${px2rem(75)};

        @media (min-width: 768px) and (max-width: 991px) {
          margin-top: ${px2rem(104)} !important;
        }

        @media (max-width: 767px) {
          margin-top: ${px2rem(104)} !important;
        }
      }

      /* A Text module followed by a carousel should be closer,
      to indicate they belong to the same block of content */
      .rm-text.cms-module {
        & + section[class*='Carousel'] {
          margin-top: -60px;

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: -${px2rem(30 * 1.5)};
          }

          @media (max-width: 767px) {
            margin-top: -30px;
          }
        }
      }

      /* Headline Title H3 followed by text, remove margin */
      .rm-headlineTitle {
        & + .rm-text.cms-module {
          .ui.grid > .row {
            padding-top: 0;

            ul {
              margin-top: 0;
            }
          }
        }

        &--h3.cms-module {
          & + .rm-text.cms-module {
            margin-top: -${px2rem(70)};

            @media (min-width: 768px) and (max-width: 991px) {
              margin-top: -${px2rem(30 * 1.5)};
            }

            @media (max-width: 767px) {
              margin-top: -30px;
            }
          }
        }
      }

      /* text followed by title, remove margin */
      .rm-text.cms-module {
        & + .rm-headlineTitle.cms-module {
          .ui.grid > .row {
            padding-top: 0;

            ul {
              margin-top: 0;
            }
          }

          margin-top: -60px;

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: -${px2rem(30 * 1.5)};
          }

          @media (max-width: 767px) {
            margin-top: -30px;
          }
        }
      }

      /* Text followed by text, remove margin */
      .rm-text.cms-module {
        & + .rm-text.cms-module {
          .ui.grid > .row {
            padding-top: 0;

            ul {
              margin-top: 0;
            }
          }

          margin-top: -60px;

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: calc(-30px * 1.5);
          }

          @media (max-width: 767px) {
            margin-top: -30px;
          }
        }
      }

      /* Text module */
      .rm-text.cms-module {
        .ui.grid > .row {
          padding-bottom: ${px2rem(3)};

          ul {
            margin-top: 0;
          }
        }
      }

      /* Headline followed by Information */
      .rm-headlineTitle.cms-module {
        & + section[class*='Information'] {
          margin-top: -25px;

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: -${px2rem(15 * 1.5)};
          }

          @media (max-width: 767px) {
            margin-top: -15px;
          }
        }
      }

      /* Information followed by Information */
      section.cms-module[class*='Information'] {
        & + section.cms-module[class*='Information'] {
          margin-top: -${px2rem(50)};

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: -${px2rem(14 * 1.5)};
          }

          @media (max-width: 767px) {
            margin-top: -${px2rem(14)};
          }
        }
      }

      /* Promotional followed by Information */
      section.cms-module[class*='Promotional'] {
        & + section.cms-module[class*='Information'] {
          margin-top: -${px2rem(5)};

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: -${px2rem(6 * 1.5)};
          }

          @media (max-width: 767px) {
            margin-top: -${px2rem(6)};
          }
        }
      }

      /* Signpost followed by text, add margin */
      section.cms-module[class^='Signpost'] {
        & + .rm-text.cms-module {
          margin-top: ${px2rem(60)};

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: ${px2rem(40 * 1.5)};
          }

          @media (max-width: 767px) {
            margin-top: ${px2rem(40)};
          }
        }
      }

      /* Information followed by Information */
      .rm-text.cms-module {
        & + section.cms-module[class*='Information'] {
          margin-top: -${px2rem(55)};

          @media (min-width: 768px) and (max-width: 991px) {
            margin-top: -${px2rem(14 * 1.5)};
          }

          @media (max-width: 767px) {
            margin-top: -${px2rem(14)};
          }
        }
      }
    }

    .spacing-bottom {
      margin-bottom: 50px;
    }

    /* In AB, when the secondary header module is followed by a Promotional,
    it should have some separation. */
    .site-modules {
      .rm-secondary-header + * {
        margin-top: ${px2rem(75)};

        @media (min-width: 992px) and (max-width: 991px) {
          margin-top: ${px2rem(48 * 1.5)} !important;
        }

        @media (max-width: 767px) {
          margin-top: ${px2rem(48)} !important;
        }
      }

      .rm-secondary-header.has-label + * {
        @media (min-width: 992px) and (max-width: 991px) {
          margin-top: ${px2rem(64 * 1.5)} !important;
        }

        @media (max-width: 767px) {
          margin-top: ${px2rem(64)} !important;
        }
      }
    }

    /** Minimal Header has currently a position fixed. So it occupies no real space.
    So, first content that appears after the Header needs a margin-top forced. */
    /* .minimal-header-mt {
      margin-top: 113px;
    } */
  `

export default Spacings
/* stylelint-enable */
