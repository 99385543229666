import qs from 'query-string'
import http, { api } from './_http'
import { ApiResponse } from 'apisauce'
import { IVouchersResponse } from '@components/ownerVouchersList/types/IVoucherMetaData'

const Vouchers = {
  validateCode: (code) => http.post('/msvc/v1/vouchers/validate', { code }),
  redeemCode: (code) => http.post('/msvc/v1/vouchers/redeem', { code }),
  deleteVoucher: (code) => http.del(`/msvc/v1/vouchers/mine/delete/${code}`),
  getOwnersVoucher: (params): Promise<ApiResponse<IVouchersResponse>> => api.get(`/msvc/v1/vouchers/mine?${qs.stringify(params)}`),
  voucherSentToCustomer: (code, distributeToVip): Promise<ApiResponse<any>> => api.put(`/msvc/v1/vouchers/mine/${code}/vipdistribution/${distributeToVip}`)
}

export default Vouchers
