import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const ArtworkCounter = ({ className, displayOfLabel, artworksAvailableLabel, displayed, available }) => (
  <span className={className}>
    <span className='bold-text'>
      {displayed}
    </span> {displayOfLabel || 'Displayed of'}&nbsp;
    <span className='bold-text'>{available}</span> {artworksAvailableLabel || 'artworks available.'}
  </span>
)

ArtworkCounter.propTypes = {
  available: PropTypes.number,
  displayed: PropTypes.number,
  className: PropTypes.string,
  displayOfLabel: PropTypes.string,
  artworksAvailableLabel: PropTypes.string
}

export default styled(ArtworkCounter)`
  color: var(--bs-mch-gray-500);
  font-size: ${tokens.fontSize[1].value};
  opacity: 0.5;
  font-weight: ${tokens.fontWeights.artBaselText1.value};

  .bold-text {
    font-weight: ${tokens.fontWeights.artBaselHeadline0.value};
    color: var(--bs-mch-gray-500);
  }
`
