import React, { FC } from 'react'
import { MediaCaption, Row, Col } from '@mch-group/uikit-components'
import { Media, MediaContextProvider } from '@responsive'
import { useEditorialModule } from '@hooks'
import IEMImageMosaic from '@modules/EMImageMosaic/types'
import StyledMediaCaption from './StyledMediaCaption'

const ThreeImagesDynamic: FC<IEMImageMosaic> = ({
  emim_image_upload_1,
  emim_image_upload_2,
  emim_image_upload_3,
  emim_image_caption_1,
  emim_image_caption_2,
  emim_image_caption_3
}) => {
  const { getImageUrl, ar } = useEditorialModule()
  return (
    <Row gutter='g-5 g-md-6 g-xl-7'>
      {emim_image_upload_1 && (
        <Col xs={12} md={7} lg={6} xl={6} xxl={6} className='mb-7 mb-md-0'>
          <MediaContextProvider>
            <Media at='xs'>
              <StyledMediaCaption ar={ar(emim_image_upload_1)}>
                <MediaCaption
                  caption={!!emim_image_caption_1}
                  captionText={emim_image_caption_1}
                  imageAltText={emim_image_caption_1}
                  urlImage={getImageUrl(emim_image_upload_1, 365, '')}
                  fullWidth={false}
                  media='image'
                />
              </StyledMediaCaption>
            </Media>
            <Media at='sm'>
              <StyledMediaCaption ar={ar(emim_image_upload_1)}>
                <MediaCaption
                  caption={!!emim_image_caption_1}
                  captionText={emim_image_caption_1}
                  imageAltText={emim_image_caption_1}
                  urlImage={getImageUrl(emim_image_upload_1, 720, '')}
                  fullWidth={false}
                  media='image'
                />
              </StyledMediaCaption>
            </Media>
            <Media greaterThanOrEqual='tablet'>
              <StyledMediaCaption ar={ar(emim_image_upload_1)}>
                <MediaCaption
                  caption={!!emim_image_caption_1}
                  captionText={emim_image_caption_1}
                  imageAltText={emim_image_caption_1}
                  urlImage={getImageUrl(emim_image_upload_1, 724, '')}
                  fullWidth={false}
                  media='image'
                />
              </StyledMediaCaption>
            </Media>
          </MediaContextProvider>
        </Col>
      )}
      {emim_image_upload_2 && (
        <Col xs={12} md={5} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} xxl={{ span: 5, offset: 1 }} className='mb-md-0'>
          <MediaContextProvider>
            <Media at='xs'>
              <StyledMediaCaption ar={ar(emim_image_upload_2)}>
                <MediaCaption
                  caption={!!emim_image_caption_2}
                  captionText={emim_image_caption_2}
                  imageAltText={emim_image_caption_2}
                  urlImage={getImageUrl(emim_image_upload_2, 365, '')}
                  fullWidth={false}
                  media='image'
                />
              </StyledMediaCaption>
            </Media>
            <Media at='sm'>
              <StyledMediaCaption ar={ar(emim_image_upload_2)}>
                <MediaCaption
                  caption={!!emim_image_caption_2}
                  captionText={emim_image_caption_2}
                  imageAltText={emim_image_caption_2}
                  urlImage={getImageUrl(emim_image_upload_2, 720, '')}
                  fullWidth={false}
                  media='image'
                />
              </StyledMediaCaption>
            </Media>
            <Media greaterThanOrEqual='tablet'>
              <StyledMediaCaption ar={ar(emim_image_upload_2)} className='dynamic'>
                <MediaCaption
                  caption={!!emim_image_caption_2}
                  captionText={emim_image_caption_2}
                  imageAltText={emim_image_caption_2}
                  urlImage={getImageUrl(emim_image_upload_2, 724, '')}
                  className='dynamic'
                  fullWidth={false}
                  media='image'
                />
              </StyledMediaCaption>
            </Media>
          </MediaContextProvider>
        </Col>
      )}
      {emim_image_upload_3 && (
        <Col
          className='mt-9 mt-lg-12'
          xs={12}
          md={{ span: 10, offset: 1 }}
          lg={{ span: 8, offset: 2 }}
          xl={{ span: 8, offset: 2 }}
          xxl={{ span: 8, offset: 2 }}
        >
          <MediaContextProvider>
            <Media at='xs'>
              <StyledMediaCaption ar={ar(emim_image_upload_3)}>
                <MediaCaption
                  caption={!!emim_image_caption_3}
                  captionText={emim_image_caption_3}
                  imageAltText={emim_image_caption_3}
                  urlImage={getImageUrl(emim_image_upload_3, 380, '')}
                  fullWidth={false}
                  media='image'
                />
              </StyledMediaCaption>
            </Media>
            <Media at='sm'>
              <StyledMediaCaption ar={ar(emim_image_upload_3)}>
                <MediaCaption
                  caption={!!emim_image_caption_3}
                  captionText={emim_image_caption_3}
                  imageAltText={emim_image_caption_3}
                  urlImage={getImageUrl(emim_image_upload_3, 735, '')}
                  fullWidth={false}
                  media='image'
                />
              </StyledMediaCaption>
            </Media>
            <Media greaterThanOrEqual='tablet'>
              <StyledMediaCaption ar={ar(emim_image_upload_3)}>
                <MediaCaption
                  caption={!!emim_image_caption_3}
                  captionText={emim_image_caption_3}
                  imageAltText={emim_image_caption_3}
                  urlImage={getImageUrl(emim_image_upload_3, 973, '')}
                  fullWidth={false}
                  media='image'
                />
              </StyledMediaCaption>
            </Media>
          </MediaContextProvider>
        </Col>
      )}
    </Row>
  )
}

export default ThreeImagesDynamic