const getReferrer = (documentReferrer: string, prevPath) => {
  if (!prevPath.current) return documentReferrer
  try {
    const referrerUrl = new URL(documentReferrer)
    if (!referrerUrl.pathname || referrerUrl.pathname === '/') {
      return referrerUrl.origin + prevPath.current
    }
  } catch (e) {}
  return documentReferrer
}

export default getReferrer