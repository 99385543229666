import React from 'react'
import Observer from '@researchgate/react-intersection-observer'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Head from 'next/head'

import { transparentPixel } from './utils'
import { DOMTokenListSupports } from '../../utils/browser'

class LazyImage extends React.Component {
  static propTypes = {
    url: PropTypes.string,
    lqip: PropTypes.string,
    preload: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    setSrc: PropTypes.func,
    rootMargin: PropTypes.string,
    imageObserverRootMargin: PropTypes.string,
    responsive: PropTypes.bool,
    alt: PropTypes.string,
    className: PropTypes.string,
    refrence: PropTypes.object
  }

  static defaultProps = {
    preload: false,
    lqip: '',
    rootMargin: '-100px',
    imageObserverRootMargin: '2000px'
  }

  constructor(props) {
    super(props)
    // @ts-ignore
    this.imgRef = React.createRef()
    // @ts-ignore
    this.element = this.imgRef.current !== null ? this.imgRef.current : null
    // @ts-ignore
    this.browserCanPreload = true
  }

  state = {
    loadState: null,
    seen: false,
    lqipPreloaded: false,
    addPreloadHeadLink: false,
    // @ts-ignore
    rootMargin: this.props.rootMargin,
    // @ts-ignore
    imageObserverRootMargin: this.props.imageObserverRootMargin
  }

  componentDidMount() {
    // @ts-ignore
    this.element = this.imgRef.current !== null ? this.imgRef.current : null
    // @ts-ignore
    this.browserCanPreload = DOMTokenListSupports(document.createElement('link').relList, 'preload')

    this.preloadLQIP()
  }

  shouldComponentUpdate(nextState) {
    if (this.state.loadState === nextState.loadState) {
      return false
    }

    return true
  }

  componentWillUnmount() {
    // @ts-ignore
    this.element = undefined
    // @ts-ignore
    this.browserCanPreload = undefined
  }

  preloadLQIP() {
    // @ts-ignore
    if (this.props.preload !== 'lqip') return

    /**
     * Before preload, check some considitions to ensure the image is not hidden. e.g. mobile/desktop only
     * The below conditions will be "true" in case the element/or its parent uses media query to hide it.
     */

    // If the "offsetParent" of the image is "null" do not preload the LQIP
    // @ts-ignore
    if (this.element && 'offsetParent' in this.element && this.element.offsetParent === null) return

    // If the "offsetHeight" of the image is "0" do not preload the LQIP
    // @ts-ignore
    if (this.element && 'offsetHeight' in this.element && this.element.offsetHeight === 0) return

    // Now, all requirements provided. Lets define preload strategy and execute
    // @ts-ignore
    if (!this.browserCanPreload) {
      const img = new Image()
      // @ts-ignore
      img.src = this.props.lqip
      img.onload = () => {
        this.setState({ lqipPreloaded: true })
      }
    } else {
      this.setState({ lqipPreloaded: true, addPreloadHeadLink: true })
    }
  }

  loadStateChange = (loadState) => {
    this.setState({ loadState })
  }

  imageLoader = (src) => {
    const img = new Image()
    const result = new Promise((resolve, reject) => {
      img.onload = resolve
      // eslint-disable-next-line no-multi-assign
      img.onabort = img.onerror = () => reject(new Error('Image loading error'))
      img.src = src
    })

    return result
  }

  load = () => {
    const { loadState } = this.state
    // @ts-ignore
    const { url } = this.props
    if (typeof window === 'undefined' || loadState === 'loaded' || loadState === 'loading') return

    this.loadStateChange('loading')

    const loader = this.imageLoader(url)
    loader
      .then(() => {
        this.loadStateChange('loaded')
      })
      .catch(() => {
        this.loadStateChange('error')
      })

    // @ts-ignore
    this.loader = loader
  }

  handleChange = (event, unobserve) => {
    if (this.state.seen) return

    if (event.isIntersecting) {
      unobserve()
      this.setState({ seen: true })
      this.load()
    }
  }

  render() {
    const { props, state } = this
    const { loadState, seen, addPreloadHeadLink, rootMargin, imageObserverRootMargin } = state
    // @ts-ignore
    const { lqip, url, responsive, alt, className, preload, refrence } = props
    let src = transparentPixel

    // During the loading state of original image, show Low-Quality-Image-Placeholder version
    if (loadState === 'loading') src = lqip

    // Use the LQIP by default instead transparent pixel
    if (this.state.lqipPreloaded) src = lqip

    // Original image has loaded
    if (loadState === 'loaded') src = url
    // @ts-ignore
    this.props.setSrc && this.props.setSrc(src)

    return (
      <>
        {!seen && (
          <>
            {preload === 'lqip' && addPreloadHeadLink && loadState !== 'loaded' && (
              <Head>
                <link rel='preload' as='image' href={lqip} crossOrigin='crossorigin' />
              </Head>
            )}
            <Observer onChange={this.handleChange} threshold={0.01} disabled={seen}>
              {/* If the image gets within 100px above, start the download.
               * The is a cheap hack to detect distance to image since "rootMargin" doesn't work */}
              <div style={{ width: '100%', height: '5px', position: 'absolute', top: rootMargin }} />
            </Observer>
          </>
        )}
        <Observer onChange={this.handleChange} threshold={0.01} rootMargin={imageObserverRootMargin} disabled={seen}>
          <StyledImage
            src={src}
            ref={node => {
              // @ts-ignore
              this.imgRef.current = node
              if (refrence) {
                refrence.current = node
              }
            }}
            alt={alt}
            className={className}
            // @ts-ignore
            loaded={loadState === 'loaded'}
            responsive={responsive}
          />
        </Observer>
      </>
    )
  }
}

const StyledImage = styled.img<any>`
  transition: opacity 0.5s ease-in-out;
  opacity: ${({ loaded }) => (loaded ? 1 : 0.5)};
  ${({ responsive }) => (responsive ? 'width: 100%' : '')};
`

export default LazyImage
