import PropTypes from 'prop-types'
import { Header } from 'semantic-ui-react'
import styled from 'styled-components'

import { px2rem, mq, hiDPIpx } from '@utils'
import { FollowCta } from '@components'
import tokens from '@mch-group/design-tokens/build/js/design_tokens-module'

const EditCollectionHeader = ({
  collectionDetail,
  dispatch,
  labels,
  isSubscribed,
  isCollectionOwner,
  subscribe,
  unsubscribe,
  isBannerDismissed
}) => {
  const isFavorite = collectionDetail && collectionDetail.collectionType === 'FAVORITES'
  return (
    <HeaderWrapper
      className={`${!isCollectionOwner ? 'is-visitor' : ''} ${!isCollectionOwner && isSubscribed ? 'is-follower' : ''
        } ${!isCollectionOwner && !isSubscribed && isBannerDismissed ? 'is-unfollower' : ''}`}
    >
      {collectionDetail && (
        <div className='left-sec'>
          {!isCollectionOwner && (
            <FollowCta
              collectionId={collectionDetail.id}
              isCollectionOwner={isCollectionOwner}
              subscribe={subscribe}
              unsubscribe={unsubscribe}
              isSubscribed={isSubscribed}
              followCollectionBtnLabel={labels?.Collections.followCollectionBtnLabel}
              followingCollectionBtnLabel={labels?.Collections.followingCollectionBtnLabel}
              unfollowCollectionBtnLabel={labels?.Collections.unfollowCollectionBtnLabel}
              unfollowCollectionPopupTitle={labels?.CollectionNotifications.unfollowCollectionPopupTitle}
              unfollowCollectionPopupMessage={labels?.CollectionNotifications.unfollowCollectionPopupMessage}
              unfollowCollectionPopupBtnLabel={labels?.CollectionNotifications.unfollowCollectionPopupBtnLabel}
              cancelPopupBtnLabel={labels?.CollectionNotifications.cancelPopupBtnLabel}
              dispatch={dispatch}
              isBannerDismissed={isBannerDismissed}
            />
          )}
          <StyledHeader as='h1'>
            {isFavorite ? labels?.Collections.favoriteCollectionTitle : collectionDetail.name}
          </StyledHeader>
          {!isCollectionOwner && (
            <CollectionAuthor>
              {labels?.Collections.collectionByLabel} {collectionDetail?.ownerName}
            </CollectionAuthor>
          )}
          <Description>
            {isFavorite ? labels?.Collections.favoriteCollectionDescription : collectionDetail.description}
          </Description>
        </div>
      )}
    </HeaderWrapper>
  )
}

EditCollectionHeader.propTypes = {
  collectionDetail: PropTypes.object,
  showForm: PropTypes.func,
  dispatch: PropTypes.func,
  labels: PropTypes.object,
  removeCollection: PropTypes.func,
  mutateCollectionDetail: PropTypes.func,
  myCollectionsReturnURL: PropTypes.string,
  submitCallback: PropTypes.func,
  isOrganizeMode: PropTypes.bool,
  isCollectionOwner: PropTypes.bool,
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func,
  isSubscribed: PropTypes.bool,
  isBannerDismissed: PropTypes.bool,
  CMSProps: PropTypes.object,
  isDisabledOrganizeMode: PropTypes.bool
}

const HeaderWrapper = styled.div`
  display: flex;

  &.is-visitor {
    margin-top: ${px2rem(88)};
    ${mq.between('tablet', 'largestTablet')`
      margin-top: -${hiDPIpx(5)};
    `}
    ${mq.lessThan('largestMobile')`
      margin-top: -${px2rem(5)};
    `}
    &.is-follower,
    &.is-unfollower {
      margin-top: ${px2rem(11)} !important;
    }
  }

  .left-sec {
    flex: 1;
  }
`

const StyledHeader = styled(Header)`
  text-transform: ${tokens.textCase.none};
  margin-bottom: ${px2rem(36)};
  margin-top: 0;
  ${mq.lessThan('largestTablet')`
    margin-bottom: ${px2rem(12)};
  `}
`

const Description = styled.p`
  ${mq.greaterThan('desktop')`
    /* Grid would provide more precise control of the column than this. */
    width: 53.7%;
    margin-bottom: ${px2rem(60)} !important;
  `}

  ${mq.lessThan('largestTablet')`
    margin-bottom: ${px2rem(40)} !important;
  `}
  width: 100%;
`

const CollectionAuthor = styled.div`
  margin: -${px2rem(5)} 0 ${px2rem(17)} 0;
  padding: 0;
  ${mq.between('tablet', 'largestTablet')`
    margin-top: -${hiDPIpx(8)};
  `}
  ${mq.lessThan('largestMobile')`
    margin-top: -${px2rem(8)};
  `}
`

export default EditCollectionHeader
