export const catalogEntityUrl = {
  galleries: '/galleries',
  artists: '/artists',
  artworks: '/artworks'
}

export const catalogSortByValue = {
  galleries: 'sortingName',
  artists: 'name',
  artworks: 'title'
}

export const dimensions = {
  cm: 'CM',
  inches: 'INCHES'
}

export default catalogEntityUrl