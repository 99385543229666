import { useEffect } from 'react'

const ScrollToHash = () => {
  const scrollToHashLink = () => {
    const hashId = window.location.hash.split('#')[1]
    hashId && setTimeout(() => {
      const hashElm = document.getElementById(hashId)
      hashElm && 'scrollIntoView' in hashElm && hashElm.scrollIntoView()
    }, 3000)
  }

  useEffect(() => {
    scrollToHashLink()
  }, [])
  return (<>{}</>)
}

export default ScrollToHash
