// @ts-nocheck
import http from './_http'

const PressAcc = {
  getProfile: () =>
    http.get('/msvc/v2/userprofiles/user/me'),
  updateProfile: (body) =>
    http.put('/msvc/v2/userprofiles/user/me', JSON.stringify(body)),
  getCountries: () =>
    http.get('/msvc/v1/countries/'),
  getProfessionalBranches: () =>
    http.get('/msvc/v1/optionsets/contact/dl_mediatype'),
  getProfessionalFunctions: () =>
    http.get('/msvc/v1/optionsets/contact/dl_mediafunction'),
  getSalutations: () =>
    http.get('/msvc/v1/optionsets/contact/dl_mailingsalutationoptions'),
  getAccreditationItems: () =>
    http.get('/msvc/v1/mediaaccreditations/items'),
  postAccreditationItems: (body) =>
    http.post('/msvc/v1/mediaaccreditations/items', JSON.stringify(body)),
  putAccreditationItems: (body, id) =>
    http.put(`/msvc/v1/mediaaccreditations/items/${id}`, JSON.stringify(body)),
  getAccreditationStatuses: () =>
    http.get('/msvc/v1/optionsets/dl_mediaaccreditation/dl_status')
}

export default PressAcc

