import { OptionSet, Countries } from '../services'
import { formatCMSLabels, logger } from '../utils'
import { globalModulesSchema } from '../constants/schemas'
import { clearPasses } from './passes'
import { clearFavorites } from './favorites'
import { clearPressAccr } from './pressAcc'

const REDUCERNAME = 'shared'

const initialState = {
  globalModules: globalModulesSchema.cast({}),
  serverDate: null,
  domain: null,
  siteLanguage: null,
  url: null,
  actionModuleData: null,
  labels: null
}

// ACTION TYPES
export const actionTypes = {
  SET_GLOBAL_MODULES: `${REDUCERNAME}__SET_GLOBAL_MODULES`,
  UPDATE_GLOBAL_MODULES: `${REDUCERNAME}__UPDATE_GLOBAL_MODULES`,
  SET_SALUTATIONS: `${REDUCERNAME}__SET_SALUTATIONS`,
  SET_COUNTRIES: `${REDUCERNAME}__SET_COUNTRIES`,
  SET_MAILING_SALUTATIONS: `${REDUCERNAME}__SET_MAILING_SALUTATIONS`,
  SET_DESIGNATIONS_SALUTATIONS: `${REDUCERNAME}__SET_DESIGNATIONS_SALUTATIONS`,
  SET_SERVER_DATE: `${REDUCERNAME}__SET_SERVER_DATE`,
  SET_DOMAIN: `${REDUCERNAME}__SET_DOMAIN`,
  SET_PAGE_REFRESH: `${REDUCERNAME}__SET_PAGE_REFRESH`,
  SET_URL: `${REDUCERNAME}__SET_URL`,
  SET_ACTION_MODULE_DATA: `${REDUCERNAME}__SET_ACTION_MODULE_DATA`,
  SET_LABELS: `${REDUCERNAME}__SET_LABELS`,
  SET_ACCEPT_LANGUAGE: `${REDUCERNAME}__SET_ACCEPT_LANGUAGE`
}

// ACTIONS
export const requestSalutations = () => async (dispatch) => {
  const paramsSalutation = {
    type: 'dl_salutation',
    entityName: 'contact'
  }
  const salutations = await OptionSet.getOptionSet(paramsSalutation) // TODO: needs error handling
  return dispatch({
    type: actionTypes.SET_SALUTATIONS,
    payload: salutations.data
  })
}

export const requestDesignationsSalutations = () => async (dispatch) => {
  const paramsSalutation = {
    type: 'dl_salutation',
    entityName: 'dl_designation'
  }
  const salutations = await OptionSet.getOptionSet(paramsSalutation)
  return dispatch({
    type: actionTypes.SET_DESIGNATIONS_SALUTATIONS,
    payload: salutations.data
  })
}

export const requestCountries = () => async (dispatch) => {
  const countries = await Countries.getCountries()
  return dispatch({
    type: actionTypes.SET_COUNTRIES,
    payload: countries.data
  })
}

export const requestMailingSalutations = () => async (dispatch, state) => {
  const paramsSalutation = {
    type: 'dl_mailingsalutationoptions',
    entityName: 'contact'
  }
  const salutations = !state().mailing_salutations ?
    await OptionSet.getOptionSet(paramsSalutation) :
    state().mailing_salutations
  return dispatch({
    type: actionTypes.SET_MAILING_SALUTATIONS,
    payload: salutations.data
  })
}

export const setAcceptLanguage = payload => dispatch => dispatch({
    type: actionTypes.SET_ACCEPT_LANGUAGE,
    payload
  })

export const setGlobalModules = globalModulesRaw => (dispatch) => {
  const globalModuleKeys = Object.entries(globalModulesRaw || {})
  const globalModules = globalModuleKeys.reduce((prev, pair) => {
    const [key, module] = pair
    let formattedModule = {}
    try {
      const { fieldsList, ...moduleMetaData } = module

      if (Array.isArray(module)) { // Array of modules e.g. Secondary Modules
        formattedModule = {
          [key]: module.map((childModule) => {
            const { fieldsList: childFieldList, ...childModuleMetaData } = childModule

            return {
              ...childModuleMetaData,
              labels: formatCMSLabels(childFieldList)
            }
          })
        }
      } else {
        formattedModule = {
          [key]: {
            ...moduleMetaData,
            labels: formatCMSLabels(fieldsList)
          }
        }
      }
    } catch (e) {
      logger('CRITICAL: Module build failed', e)
      formattedModule = {}
    }

    return {
      ...prev,
      ...formattedModule
    }
  }, {})

  dispatch({
    type: actionTypes.SET_GLOBAL_MODULES,
    payload: {
      globalModules
    }
  })
}

export const setServerDate = payload => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SERVER_DATE,
    payload
  })
}

export const setDomain = payload => (dispatch) => {
  dispatch({
    type: actionTypes.SET_DOMAIN,
    payload
  })
}

export const setPageRefresh = payload => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PAGE_REFRESH,
    payload
  })
}

export const setUrl = payload => (dispatch) => {
  dispatch({
    type: actionTypes.SET_URL,
    payload
  })
}

export const setLabels = payload => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LABELS,
    payload
  })
}

// Any other stores to be cleared for signout, should be done here
export const clearAllUserData = () => dispatch => {
  dispatch(clearPasses())
  dispatch(clearFavorites())
  dispatch(clearPressAccr())
}

export const setActionModuleData = payload => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ACTION_MODULE_DATA,
    payload
  })
}

// ACTION HANDLERS
const actionHandlers = {
  [actionTypes.SET_GLOBAL_MODULES]: (state, action) => ({
    ...state,
    ...action.payload
  }),
  [actionTypes.SET_SALUTATIONS]: (state, action) => ({
    ...state,
    salutations: action.payload
  }),
  [actionTypes.SET_COUNTRIES]: (state, action) => ({
    ...state,
    countries: action.payload
  }),
  [actionTypes.SET_SERVER_DATE]: (state, action) => ({
    ...state,
    serverDate: action.payload
  }),
  [actionTypes.SET_MAILING_SALUTATIONS]: (state, action) => ({
    ...state,
    mailing_salutations: action.payload
  }),
  [actionTypes.SET_DESIGNATIONS_SALUTATIONS]: (state, action) => ({
    ...state,
    designationsSalutations: action.payload
  }),
  [actionTypes.SET_DOMAIN]: (state, action) => ({
    ...state,
    domain: action.payload
  }),
  [actionTypes.SET_PAGE_REFRESH]: (state, action) => ({
    ...state,
    rxIsPageRefresh: action.payload
  }),
  [actionTypes.SET_ACCEPT_LANGUAGE]: (state, action) => ({
      ...state,
      siteLanguage: action.payload
    }),
  [actionTypes.SET_URL]: (state, action) => ({
    ...state,
    url: action.payload
  }),
  [actionTypes.SET_ACTION_MODULE_DATA]: (state, action) => ({
    ...state,
    actionModuleData: action.payload
  }),
  [actionTypes.SET_LABELS]: (state, action) => ({
    ...state,
    labels: action.payload
  })
}

// REDUCER
const sharedReducer = (state = initialState, action) => {
  const handler = actionHandlers[action.type]
  return handler ? handler(state, action) : state
}

export default sharedReducer