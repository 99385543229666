import { getAgentKey, getFingerprint } from '@utils'
import { ApiResponse } from 'apisauce'
import http, { api } from './_http'

const Tracking = {
  trackAction: async (action, id, otherInfo: { measure?: string | number } = {}): Promise<any> => {
    let agentAndFingerprint = { 'agentKey': await getAgentKey(), 'fingerprint': await getFingerprint() }
    const params = { ...otherInfo, ...agentAndFingerprint }
    return http.post(`/msvc/v1/tracking/${action}/${id}`, params)
  },
  trackMultipleActions: async (action, otherInfo: { itemIds?: Array<string> } = {}): Promise<any> => {
    let agentAndFingerprint = { 'agentKey': await getAgentKey(), 'fingerprint': await getFingerprint() }
    const params = { ...otherInfo, ...agentAndFingerprint }
    return http.post(`/msvc/v1/tracking/items/${action}`, params)
  },

  // @ts-ignore
  getEventReport: (itemId) => http.get(`/msvc/v1/tracking/reports/event/${itemId}`),
  getAgentKey: (): Promise<ApiResponse<ABTypes.Tracking.AgentKey>> => api.get('/msvc/v1/tracking/agentKey'),

  /**
   * Given an id and a step (ActionType) returns the views statistics.
   * Example artwork views(actionType=5) or artwork detail views(actionType=2) into endpoints
   * @param id
   * @param actionType
   * @returns {Promise<*>}
   */
  getArtworkReports: (id, actionType) => http.get(`/msvc/v1/tracking/reports/artwork/${id}`, {
    actionType
    // @ts-ignore
  }, {
    headers: {
      'cache-control': 'no-cache'
    }
  }),

  /**
   * Given an array of ids and a step (ActionType) returns the views statistics of each of the artworks.
   * Example artwork views(actionType=5) or artwork detail views(actionType=2) into endpoints
   * @param itemIds
   * @param actionType
   * @returns {Promise<*>}
   */
  getArtworksReports: (itemIds, actionType) => http.get('/msvc/v1/tracking/reports/artworks/views', {
    itemIds,
    actionType
    // @ts-ignore
  }, {
    headers: {
      'cache-control': 'no-cache'
    }
  }),

  getViews: ({
    uniqueVisitors = false,
    actionType,
    offset = 0,
    limit = 100,
    itemId
  }) => http.get('/msvc/v1/tracking/count', {
    uniqueVisitors,
    actionType,
    offset,
    limit,
    itemId
  })
}

export default Tracking
