import PropTypes from 'prop-types'
import React, { createContext, useContext, useReducer } from 'react'
import { withRouter } from 'next/router'

export const A_INITIALIZE = '__INITIALIZE__'
export const A_DUMB = '__DUMP__'
export const A_MODIFY_PAGE = '__MODIFY__PAGE__'
export const A_ADD_ITEMS = '__ADD__ITEMS__'
export const A_ADD_CUSTOM_PROPS = '__ADD__CUSTOM__PROPS__'
export const A_ADD_SCROLL_POSITION = '__ADD__SCROLL__POSITION__'
export const A_REMOVE_SCROLL_POSITION = '__REMOVE__SCROLL__POSITION__'
export const A_SET_TOTAL_NUMBER_OF_ELEMENTS = '__SET__TOTAL__NUMBER__OF__ELEMENTS__'
export const A_SET_ELEMENT_VISITED = '__SET__ELEMENT__VISITED__'

/************************************************************************************************
/ This provider here can be used to store information about lists that we want to keep in memory.
/ And all the related logic that can come handy, like focus back to an item in an infinite list
/ ----------------------------------------------------------------------------------------------
/ It has some build-in functionality to dump the data when the current path is not one of the dumpWhenOutsideOf
/************************************************************************************************

/**
 * Initial state for each of the lists used inside the provider
 */
export const list_initial_state = {
  totalNumberOfElements: null,
  page: 1,
  elementVisited: null,
  items: [],
  dumpWhenOutsideOf: []
}

/**
 * @param {object} state
 * @param {any} action
 */
export const listsReducer = (state, action) => {
  if (![A_DUMB, A_INITIALIZE].includes(action.type) && !action.listId) {
    // eslint-disable-next-line no-console
    console.error('You must specify the listId to dispatch an action')
    return { ...state }
  }

  if (![A_INITIALIZE, A_DUMB].includes(action.type) && !state[action.listId]) {
    // console.error('The listId specified does not exit!')
    return { ...state }
  }

  switch (action.type) {
    case A_INITIALIZE:
      return {
        ...state,
        [action.payload.listId]: {
          ...list_initial_state,
          dumpWhenOutsideOf: action.payload.dumpWhenOutsideOf
        }
      }
    case A_DUMB:
      return {
        ...action.payload
      }
    case A_MODIFY_PAGE:
      return {
        ...state,
        [action.listId]: {
          ...state[action.listId],
          page: action.payload
        }
      }
    case A_SET_TOTAL_NUMBER_OF_ELEMENTS:
      return {
        ...state,
        [action.listId]: {
          ...state[action.listId],
          totalNumberOfElements: action.payload
        }
      }
    case A_SET_ELEMENT_VISITED:
      return {
        ...state,
        [action.listId]: {
          ...state[action.listId],
          elementVisited: action.payload
        }
      }
    case A_ADD_ITEMS:
      return {
        ...state,
        [action.listId]: {
          ...state[action.listId],
          items: action.payload
        }
      }
    case A_ADD_CUSTOM_PROPS:
      return {
        ...state,
        [action.listId]: {
          ...state[action.listId],
          ...action.payload
        }
      }
    case A_ADD_SCROLL_POSITION:
      return {
        ...state,
        [action.listId]: {
          ...state[action.listId],
          ...action.payload
        }
      }
    case A_REMOVE_SCROLL_POSITION:
      return {
        ...state,
        [action.listId]: {
          ...state[action.listId],
          ...action.payload
        }
      }
    default:
      return {
        ...state
      }
  }
}

/** Initial state is empty, as it works per list **/
export const listsInitialState = {}

export const StateContext = createContext(listsInitialState)

/**
 * Provider
 * @returns {*}
 * @constructor
 */
const ListsProvider = ({ children }) => (
  <StateContext.Provider value={useReducer(listsReducer, listsInitialState)}>{children}</StateContext.Provider>
)

ListsProvider.propTypes = {
  children: PropTypes.node
}

/**
 * Dumps (Removes the stored data) when the route is not one of the specified when list data initialized
 */
const useDataDumperByPath = (router) => {
  const [lists, dispatch] = useListsProvider()
  /**
   * In our NextJS we build routes from / and there are lists with navigation nested that goes to different domains
   * (Example, OVR INTRO, goes into /events and into /catalog/artworks) so the lists provider has to
   * be common for the entire app, as it is CSR navigation, if the user goes to another page totally unrelated and
   * then comes back, he will still have the list data.
   * This side effect here controls that when going outside of the defined paths the data will be dumped, so if he
   * comes back from other pages unrelated it will have fresh data and will not have the stored data.
   */
  React.useEffect(() => {
    const handleRouteChangeComplete = (pathname) => {
      const listWithoutDumpedData = {}
      Object.keys(lists).map((property) => {
        const isPathIncluded = lists[property]?.dumpWhenOutsideOf?.some((includedPath) =>
          pathname?.includes(includedPath)
        )
        if (isPathIncluded || lists[property]?.dumpWhenOutsideOf?.length === 0) {
          listWithoutDumpedData[property] = { ...lists[property] }
        }
      })
      if (Object.keys(lists).length !== Object.keys(listWithoutDumpedData).length) {
        dispatch({ type: A_DUMB, payload: listWithoutDumpedData })
      }
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [dispatch, lists, router.events])
}

/**
 * Responsible of scrolling back to accessed item in lists
 * @param router
 */
const useItemFocus = (router) => {
  const [lists, dispatch] = useListsProvider()
  /**
   * The position must be set using setScrollPosition inside useLists.js ( Normally you want to do that when you click
   * on certain item )
   * If set, this will take care of restoring the scrolling position and then, to remove it.
   */
  React.useEffect(() => {
    const handleRouteChangeComplete = (pathname) => {
      Object.keys(lists).map((property) => {
        const isPathIncluded = pathname.includes(lists[property]?.pathToApplyScrollPosition)
        if (isPathIncluded) {
          const scrollPosition = lists[property]?.scrollPosition ?? { x: 0, y: 0 }
          window.scrollTo(scrollPosition.x, scrollPosition.y)
          dispatch({
            listId: property,
            type: A_REMOVE_SCROLL_POSITION,
            payload: { scrollPosition: null, pathToApplyScrollPosition: null }
          })
        }
      })
    }

    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [dispatch, lists, router.events])
}

/**
 * Wrapper that holds the list logic (Data dump, and item focus)
 * @param children
 * @param router
 * @returns {*}
 * @constructor
 */
const ListsProviderInternal = ({ children, router }) => {
  useDataDumperByPath(router)
  useItemFocus(router)
  return children
}

ListsProviderInternal.propTypes = {
  children: PropTypes.node,
  router: PropTypes.object
}

/**
 * Final wrapper that is used in _app.js
 * @param router
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const ListsProviderWithDataDump = ({ children, router }) => (
  <ListsProvider>
    <ListsProviderInternal router={router}>{children}</ListsProviderInternal>
  </ListsProvider>
)

ListsProviderWithDataDump.propTypes = {
  children: PropTypes.node,
  router: PropTypes.object
}

export default withRouter(ListsProviderWithDataDump)

export const useListsProvider = () => useContext(StateContext)
