// this enables/blocks the room cards (e.g. upcoming vs open)
const isUserAllowedToEnter = (onlineShowData, isUserAllowed, isSpecialRoom, event = null) => {
  if (onlineShowData?.supervisor) {
    return true
  }
  if (isUserAllowed) {
    if (onlineShowData && onlineShowData.currentPhase === 'OPEN_FOR_VIP' && onlineShowData.vip) {
      return true
    }
    if (onlineShowData && onlineShowData.currentPhase === 'OPEN_FOR_EVERYONE' && onlineShowData.allowedVisitor) {
      return true
    }
    if (isSpecialRoom && onlineShowData.specialRoomVisitor) {
      return true
    }
    if (!isSpecialRoom && onlineShowData.currentPhase === 'SPECIAL_ROOM_OPEN_FOR_EVERYONE') {
      if (event) {
        if (event?.canSeeDetails) {
          return true
        } else {
          return false
        }
      }
    }
    return false
  }
  return false
}

export default isUserAllowedToEnter
