import React, { useState, createContext, useContext } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mch-group/uikit-components'
import styled from 'styled-components'
import { mutate } from 'swr'
import Router, { withRouter } from 'next/router'
import { useCollectionsContext } from '@modules/collections/contexts/useCollectionsContext'
import useRemoveCollection from '@modules/collections/hooks/useRemoveCollection'

import { useIsSWRReady } from '@hooks'
import { Collections as CollectionsService } from '@services'
import { cmsPageUrl } from '@utils'
import useMyCollectionsData from '@modules/collections/hooks/useMyCollectionsData'
import useRequestLabels from '@modules/collections/hooks/useRequestLabels'
import useEditCollectionDetail from '@modules/collections/hooks/useEditCollectionDetail'
import ModalTopBar from '@components/ModalTopBar'
import { NotificationsContext } from '@providers/Notifications'
import CollectionFormFormik from './Form'

// @ts-ignore
const CollectionFormContext = createContext()

const CollectionFormProvider = (props) => {
  useRequestLabels()
  const { removeCollection } = useRemoveCollection()
  const { myCollections, setActive } = useMyCollectionsData(false)
  // @ts-ignore
  const [{ labels = {}, list: myCollectionList, CMSProps }, dispatch] = useCollectionsContext()
  const [visible, setVisible] = useState(false)

  const { Collections, CollectionForms } = labels || {}
  const { showNotification } = useContext(NotificationsContext)
  const { collectionDetail, editCollection, resetDataOnCloseModal,
    collectionDetailValidating } = useEditCollectionDetail()

  const { isReady, setIsReady } = useIsSWRReady(collectionDetailValidating)

  const setNewCollectionSelected = React.useRef(false)

  const showForm = (params) => {
    if (params?.id) {
      editCollection(params.id)
      return
    }
    if (params?.setNewCollectionSelected) {
      setNewCollectionSelected.current = true
    }
    // Forcing is ready true, in those cases where there's no expected ID (Create mode)
    setIsReady(true)
    setVisible(true)
  }

  const closeForm = () => {
    setActive(false)
    setVisible(false)
    resetDataOnCloseModal()
    setIsReady(false)
  }

  React.useEffect(() => {
    collectionDetail && setVisible(true)
  }, [collectionDetail])

  const mID = 'create-collection'
  React.useEffect(() => {
    if (props.router.asPath !== '/collections/my-collections' && visible) {
      closeForm()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.router])
  return (
    <CollectionFormContext.Provider value={{ showForm }}>
      {visible && isReady && (
        // @ts-ignore
        <ModalStyled
          show
          size='md'
          variant='sheet'
          className={mID}
          aria-label={collectionDetail
            ? Collections.editCollectionPopupLabel
            : Collections.createNewCollectionLabel}
        >
          <div>
            <ModalTopBar
              title={collectionDetail
                ? Collections.editCollectionPopupLabel
                : Collections.createNewCollectionLabel}
              onClick={() => closeForm()}
              className={`${mID}__title`}
            />
            <Modal.Body className={`${mID}__content`}>
              <CollectionFormFormik
                // @ts-ignore
                collectionLabels={Collections}
                collectionFormLabels={CollectionForms}
                setVisible={() => { closeForm() }}
                submitFormCallback={async (data, values) => {
                  if (collectionDetail) { //edit
                    // @ts-ignore
                    const objIndex = myCollections?.findIndex(obj => obj.id == collectionDetail.id) // 0, 1
                    myCollections[objIndex].name = values.name
                    myCollections[objIndex].description = values.description
                    mutate(CollectionsService.getMyCollectionsEndpoint, { ...myCollections }, false) // list
                    // @ts-ignore
                    mutate(`${CollectionsService.getCollectionEndpoint}/${collectionDetail.id}`, { // list item
                      // @ts-ignore
                      ...collectionDetail,
                      name: values.name,
                      description: values.description
                    }, false)
                    // @ts-ignore
                    showNotification({
                      content: `${values.name} ${Collections.successfulUpdateLabel}`
                    })
                  } else { // create
                    const newMyCollectionList = [...myCollectionList]
                    /**
                     * "setNewCollectionSelected" sets the new created item as selected, while mantaining the current
                     * user selection from the other items. Needed when "adding to a new collection"
                    */
                    if (setNewCollectionSelected.current) {
                      data.hasSelectedEntityId = true
                    }
                    newMyCollectionList[0].items.unshift(data)
                    dispatch({ type: 'SET_LIST', payload: [...newMyCollectionList] })
                    // @ts-ignore
                    showNotification({
                      content: `${values.name} ${Collections.successfulCollectionCreatedLabel}`,
                      ctaLabel: Collections.viewLabel,
                      ctaAction: () => {
                        // "/collections/edit" will be a fallback (its the same url used in production to view/edit a collection)
                        const redirectUrl = CMSProps ? `${CMSProps.mcm_grp1_edit_page}?id=${data.id}` : `/collections/edit?id=${data.id}`
                        // when we create a collection, and the toaster appears, we click "view more" to visit the new
                        // collection page created. this redirection needs to work as Router.push otherwise it wont call
                        // all the requires APIs and Hooks on page load, neither can properly access router.query.id
                        Router.push(cmsPageUrl(redirectUrl), redirectUrl)
                      }
                    })
                  }
                  closeForm()
                }}
                collectionDelete={removeCollection}
                collectionDetail={collectionDetail}
              />
            </Modal.Body>
          </div>
        </ModalStyled>
      )}
      {props.children}
    </CollectionFormContext.Provider>
  )
}

const ModalStyled = styled(Modal)`
  padding-left: 0 !important;
`

CollectionFormProvider.propTypes = {
  children: PropTypes.node,
  pathname: PropTypes.string,
  router: PropTypes.object,
  useRemove: PropTypes.func,
  useData: PropTypes.func,
  useDataContext: PropTypes.func
}

export default withRouter(CollectionFormProvider)

export { CollectionFormContext }
