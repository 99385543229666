import ModuleTitle from './my-collections/ModuleTitle'
import CollectionCardSmall from './my-collections/CollectionCardSmall'
import MyCollectionsModule from './MyCollectionsModule'
import EditPreviewCollectionModule from './EditPreviewCollectionModule'
import ArtworksTab from './edit-preview-collection/Tabs/ArtworksTab'
import RoomsTab from './edit-preview-collection/Tabs/Rooms'
import GalleriesTab from './edit-preview-collection/Tabs/GalleriesTab'
import ArtistsTab from './edit-preview-collection/Tabs/ArtistsTab'
import EventsTab from './edit-preview-collection/Tabs/EventsTab'
import NewsTab from './edit-preview-collection/Tabs/NewsTab'
import CollectionAlbumCard from './my-collections/CollectionAlbumCard'
import CollectionAlbumCardCreate from './my-collections/CollectionAlbumCardCreate'

export {
  ModuleTitle,
  CollectionCardSmall,
  MyCollectionsModule,
  EditPreviewCollectionModule,
  ArtworksTab,
  RoomsTab,
  GalleriesTab,
  ArtistsTab,
  EventsTab,
  NewsTab,
  CollectionAlbumCard,
  CollectionAlbumCardCreate
}
