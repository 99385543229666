import React, { FC } from 'react'
import { TextLink } from '@mch-group/uikit-components'
import styled from 'styled-components'
import Text from '@components/Text'

/**
 *
 * @param htmlId Can be used for multiple instances in one same page, just pass different ID
 * @param text The text to clamp
 * @param labels Fold and Unfold button texts
 * @param className any extra class that needs to be passed
 * @param onFoldCallback Callback for after the fold is happening
 * @returns React Component with Read more functionality, including html tags
 */
const ReadMoreWithCSSClamp: FC<ReadMoreProps> = ({ htmlId = 'read-more', text, labels, className, onFoldCallback, lineClamp, marginBottom='mb-4' }) => {
  const initialState:foldStatus = {
    foldStatus: 'folded',
    isFirstLoad: true,
    isToggleNeeded: false
  }
  const [status, setStatus] = React.useState(initialState)

  // Cannot calculate div height in server, so, useEffect
  React.useEffect(() => {
    const newStatus = { ...status }
    const ghostText = document.querySelector<HTMLDivElement>(`#${htmlId} .ghost-text`)
    const truncatedText = document.querySelector<HTMLDivElement>(`#${htmlId} .text`)
    if (newStatus.isFirstLoad) {
      if (ghostText && truncatedText && (ghostText.offsetHeight > truncatedText.offsetHeight)) {
        newStatus.isToggleNeeded = true
      } else {
        newStatus.isToggleNeeded = false
      }
      newStatus.isFirstLoad = false
      setStatus(newStatus)
    }
  }, [htmlId, status])

  const onTrigger = () => {
    const newStatus = { ...status }
    newStatus.foldStatus = newStatus.foldStatus !== 'unfolded' ? 'unfolded' : 'folded'
    setStatus(newStatus)
    // When it goes from Opened to Closed...
    if (status.foldStatus === 'unfolded' && typeof onFoldCallback === 'function') {
      onFoldCallback()
    }
  }
  const isFolded = status.foldStatus === 'folded'
  return (
    <div id={htmlId} className={`position-relative ${className}`}>
      <TextStyled
        isHtml
        className={`${marginBottom} text ${isFolded ? 'truncate is-html clamped' : 'not-clamped'}`}
        $lineClamp={lineClamp}
      >
        {text}
      </TextStyled>
      <TextStyled
        isHtml
        className='mb-5 ghost-text'
        $lineClamp={lineClamp}
      >
        {text}
      </TextStyled>
      {status.isToggleNeeded &&
        <TextLink className='mt-3' onClick={onTrigger}>{isFolded ? labels.unfoldText : labels.foldText}</TextLink>}
    </div>
  )
}

interface ReadMoreProps {
  htmlId?: string,
  text: string,
  labels: {
    unfoldText: string,
    foldText: string
  },
  className?: string,
  onFoldCallback?: () => void,
  lineClamp?: number,
  marginBottom?: string
}

interface foldStatus {
  foldStatus: 'folded' | 'unfolded',
  isFirstLoad: boolean,
  isToggleNeeded: boolean
}

const TextStyled = styled(Text)`
  &.clamped {
    -webkit-line-clamp: ${props => (props.$lineClamp ? props.$lineClamp : 3)};
  }

  &.ghost-text {
    pointer-events: none;
    position: absolute;
    opacity: 0;

    > p {
      margin: 0;
    }
  }
`

export default ReadMoreWithCSSClamp